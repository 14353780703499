/* eslint-disable eqeqeq */
import { createStyles, makeStyles, styled } from "@material-ui/core";
import { getArchiveConversation, setMessageSeen } from "Api/chat";
import menuTypes from "Assets/constants/menuTypes";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import Button from "Components/Buttons/Button";
import { buttonTypes, buttonTypesRGB } from "Components/Buttons/buttonTypes";
import useUpdateEffect from "Hook/useUpdateEffect";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import ChatBoxStore from "Store/Actions/ChatBox";

import { datesAreOnSameDay, formatDatetoHoursAndSecs } from "Utils/Utils";

import AgentImage from "./images/AgentImage.png";
import ClientImage from "./images/ClientImage.png";
import Message from "./Message";
import { IMessage } from "./interfaces";

const useStyles = makeStyles(() =>
  createStyles({
    loadMore: {
      width: "50%",
      margin: "10px auto",
      backgroundColor: buttonTypesRGB.default,
      borderRadius: "10px",
      color: "white",
      lineHeight: 2.7,
      textTransform: "uppercase",
    },
    container: {
      marginBottom: "50px",
    },
  }),
);

const ActiveChat = () => {
  const { translate } = useTranslation();
  const classes = useStyles();
  const chatBoxState = useSelector(
    (state: RootStateOrAny) => state.chatReducer,
  );
  const totalCrmState = useSelector(
    (state: RootStateOrAny) => state.crmReducer,
  );
  const activeConversation =
    chatBoxState.conversations[chatBoxState.activeConversationId];

  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );

  const chatBox = new ChatBoxStore(useDispatch());

  const initialMessageList =
    chatBoxState.activeConversationId &&
    chatBoxState.activeConversationId !== 0 &&
    chatBoxState.conversations[chatBoxState.activeConversationId]
      ? chatBoxState.conversations[chatBoxState.activeConversationId]
          .messageList
      : {};

  const initialClientName =
    chatBoxState.activeConversationId &&
    chatBoxState.activeConversationId !== 0 &&
    totalCrmState.CHAT[chatBoxState.activeConversationId] &&
    typeof totalCrmState.CHAT[chatBoxState.activeConversationId].formFields[0]
      ?.fieldValue !== "undefined"
      ? totalCrmState.CHAT[chatBoxState.activeConversationId].formFields[0]
          ?.fieldValue
      : "Client";

  const [messageList, setMessageList] = useState(initialMessageList);
  const [clientName, setClientName] = useState(initialClientName);
  const [fetchingOldMessages, setFetchingOldMessages] = useState(false);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [conversationId, setConversationId] = useState(
    chatBoxState.activeConversationId ?? 0,
  );

  const scrollableDivRef = useRef(null);
  const messagesEndRef = useRef(null);

  const fetchArchiveConversation = async (): Promise<void> => {
    setFetchingOldMessages(true);

    const messages = await getArchiveConversation(
      chatBoxState.conversations[chatBoxState.activeConversationId]
        .lastLoadConversationId,
      chatBoxState.conversations[chatBoxState.activeConversationId].channelId,
    );

    if (Object.keys(messages).length > 0 && messages.length !== 0) {
      const newConversationId = messages.conversationId,
        archivedMessageList = {};

      messages.messageList.forEach((message) => {
        archivedMessageList[message.id] = {
          ...message,
        };
      });
      chatBox.setArchiveConversation(
        chatBoxState.activeConversationId,
        newConversationId,
        archivedMessageList,
      );

      setConversationId(newConversationId);
      setMessageList((prevMessageList) => ({
        ...prevMessageList,
        ...archivedMessageList,
      }));
    } else setFetchingOldMessages(false);
  };

  const setNextScroll = () => {
    if (scrollableDivRef.current)
      setCurrentScroll(
        -(
          scrollableDivRef.current.scrollHeight -
          scrollableDivRef.current.offsetHeight
        ),
      );
  };

  const scrollToBottom = async () => {
    await messagesEndRef.current.scrollIntoView();
    await messagesEndRef.current.scrollIntoView();
  };

  const chatScroll = (e) => {
    const { target } = e.nativeEvent;
    const { scrollHeight } = target;
    const bottom =
      Math.ceil(target.scrollHeight - target.scrollTop).toFixed(0) <
      target.clientHeight + 10;
    if (bottom) {
      chatBox.removeNewMessageCount(chatBoxState.activeConversationId);
      setMessageSeen(chatBoxState.activeConversationId);
    }

    // if (
    //   scrollHeight + target.scrollTop <=
    //   scrollableDivRef.current.offsetHeight
    // )
    //   fetchArchiveConversation();
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      scrollToBottom();
    }, 100);

    // Cleanup function to clear the timeout if the component unmounts before the timeout finishes
    return () => clearTimeout(timerId);
  }, [chatBoxState.isSendMessage]);

  useEffect(() => {
    const unseenCount = Object.values(activeConversation.messageList).reduce(
      (count: number, message: IMessage) => {
        return message.seen === false ? count + 1 : count;
      },
      0,
    );
    if ((unseenCount as number) > 0) {
      setMessageSeen(chatBoxState.activeConversationId);
    }
    scrollToBottom();
  }, [chatBoxState.activeConversationId]);

  useEffect(() => {
    setClientName(initialClientName);
  }, [initialClientName]);

  useEffect(
    () => () => {
      scrollableDivRef.current = false;
    },
    [],
  );

  useUpdateEffect(() => {
    if (
      requestState.menuType == menuTypes.CHAT &&
      chatBoxState.activeConversationId != 0
    ) {
      const tempInitialMessageListMessageList = {};

      initialMessageList.length > 0 &&
        initialMessageList.forEach((message) => {
          tempInitialMessageListMessageList[message.id] = {
            ...message,
          };
        });
      setMessageList(initialMessageList);
      setConversationId(chatBoxState.activeConversationId);
    }
  }, [initialMessageList]);

  useUpdateEffect(() => {
    if (fetchingOldMessages) setFetchingOldMessages(false);

    if (currentScroll === 0) setNextScroll();
  }, [messageList]);

  useUpdateEffect(() => {
    if (fetchingOldMessages) scrollableDivRef.current.style.opacity = "0.01";
    else {
      scrollableDivRef.current.scrollTop = currentScroll;
      setNextScroll();
      scrollableDivRef.current.style.opacity = "1";
    }
  }, [fetchingOldMessages]);

  const keys = Object.keys(messageList),
    size = keys.length;

  return useMemo(
    () => (
      <StyledActiveChat ref={scrollableDivRef} onScroll={chatScroll}>
        <Button
          className={classes.loadMore}
          buttonType={buttonTypes.dark}
          onClick={fetchArchiveConversation}>
          {translate("chat_box_load_old")}
        </Button>

        {keys
          ?.sort(
            (a, b) =>
              new Date(messageList[a].createdTime).getTime() -
              new Date(messageList[b].createdTime).getTime(),
          )
          .map((messageId, index) => {
            const message = messageList[messageId];
            const nextMessageId = keys[(index + 1) % size];
            const prevMessageId = keys[index === 0 ? 0 : index - 1];
            const { owner: nextMessageOwner } = messageList[nextMessageId];
            const {
              owner: currentMessageOwner,
              createdTime: currentMessageDate,
            } = messageList[messageId];
            const { createdTime: prevMessageDate } = messageList[prevMessageId];

            let shouldRenderImage;
            if (index + 1 === size || nextMessageOwner !== currentMessageOwner)
              shouldRenderImage = true;
            else if (nextMessageOwner === currentMessageOwner)
              shouldRenderImage = false;

            let shouldRenderDate;
            if (index === 0) shouldRenderDate = true;
            else if (
              datesAreOnSameDay(
                new Date(prevMessageDate),
                new Date(currentMessageDate),
              )
            )
              shouldRenderDate = false;
            else shouldRenderDate = true;

            const Image = message.owner === "client" ? ClientImage : AgentImage,
              header =
                message.owner === "client"
                  ? clientName.length !== 0
                    ? clientName
                    : "Client"
                  : activeConversation && activeConversation.agentName
                  ? activeConversation.agentName
                  : message.fullName;

            return (
              <Message
                owner={message.owner}
                caption={message.caption}
                messageId={message.id}
                conversationId={conversationId}
                base64={message.base64 ?? null}
                key={message.id}
                shouldRenderImage={shouldRenderImage}
                shouldRenderDate={shouldRenderDate}
                fileName={message.fileName}
                extension={message.extension}
                messageType={message.type}
                replyMessageContent={message.replyMessageContent}
                replyServiceMessageId={message.replyServiceMessageId}
                replyMessageAuthor={message.replyMessageAuthor}
                isSent={message.serviceMessageId}
                status={message.status}
                image={Image}
                header={header}
                text={message.content}
                fullName={message.fullName}
                date={formatDatetoHoursAndSecs(message.createdTime)}
                originalDate={message.createdTime}
              />
            );
          })}
        {chatBoxState.replyMessageView && (
          <div className={classes.container}></div>
        )}
        <div ref={messagesEndRef}></div>
      </StyledActiveChat>
    ),
    [
      messageList,
      chatBoxState.activeConversationId,
      initialMessageList,
      clientName,
      chatBoxState.replyMessageView,
    ],
  );
};

export default ActiveChat;

const StyledActiveChat = styled("div")({
  width: "100%",
  paddingBottom: "var(--textbox-height)",
  maxHeight:
    "calc(100vh - var(--nav-bar-height) - var(--textbox-height) - var(--active-chat-info-height) - var(--chat-header-height) - 15px)",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  overflowX: "hidden",
});
