import { createStyles, makeStyles } from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";

export const useCchStyles = makeStyles(() =>
  createStyles({
    root: {},
    content: {
      width: "calc(100% - 5px)",
      height: 150,
      resize: "none",
      padding: 13,
      overflow: "hidden",
      boxSizing: "border-box",
      marginBottom: "auto",
      borderColor: grey[400],
      borderRadius: 13,
      marginTop: 20,
    },
    contentLabel: {
      float: "left",
      marginLeft: "22%",
      marginTop: 20,
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    addButton: {
      position: "relative",
      float: "right",
    },
    dropzone: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: 50,
      marginTop: 10,
      borderWidth: 1,
      borderRadius: 10,
      borderColor: "#050038",
      borderStyle: "dashed",
      backgroundColor: "#F5F4F7",
      color: "rgba(2, 1, 16, 0.4)",
      outline: "none",
      transition: "border .24s ease-in-out",
      cursor: "pointer",
    },
    attachment: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: 10,
    },
    button: { margin: 5 },
    img: { width: 18 },
    deleteAttachment: {
      color: red[500],
      cursor: "pointer",
      marginLeft: -12,
    },
    closeIcon: {
      marginRight: 10,
    },
    maxLength: {
      fontSize: 11,
      color: grey[700],
      display: "flex",
      justifyContent: "end",
      marginRight: 3,
    },
  }),
);
