import React from "react";

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import { Document, Page } from "@react-pdf/renderer";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const Transactionpdf = ({ data }) => {
  const { translate } = useTranslation();

  return (
    <Document>
      <Page size='A4'>
        <Table data={data}>
          <TableHeader>
            <TableCell>{translate("request_reference_number")}</TableCell>
            <TableCell>{translate("request_currency")}</TableCell>
            <TableCell>{translate("request_date")}</TableCell>
            <TableCell>{translate("request_time")}</TableCell>
            <TableCell>{translate("request_amount")}</TableCell>
            <TableCell>{translate("request_transaction_type")}</TableCell>
            <TableCell>{translate("request_autorization_code")}</TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell getContent={(r) => r.referenceNumber} />
            <DataTableCell getContent={(r) => r.currency} />
            <DataTableCell getContent={(r) => r.date} />
            <DataTableCell getContent={(r) => r.time} />
            <DataTableCell getContent={(r) => r.amount} />
            <DataTableCell getContent={(r) => r.transactionType} />
            <DataTableCell getContent={(r) => r.autorizationCode} />
          </TableBody>
        </Table>
      </Page>
    </Document>
  );
};

export default Transactionpdf;
