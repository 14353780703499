import { styled } from "@material-ui/core";
import React from "react";
import { permit } from "Utils/Utils";

import { tabs } from "./constants";
import { AccountTreeIcon, CheckBoxIcon, CreateIcon } from "./Icons";

interface IRightPanelTabs {
  currentTab: any;
  setCurrentTab: any;
}

const RightPanelTabs = ({
  currentTab,
  setCurrentTab,
}: IRightPanelTabs): React.ReactElement => {
  const Icon = ({ tab }): React.ReactElement => {
    let Render = null;
    switch (tab) {
      case tabs.Topics.name:
        Render = AccountTreeIcon;
        break;
      case tabs.Tasks.name:
        Render = CheckBoxIcon;
        break;
      case tabs.Notes.name:
        Render = CreateIcon;
        break;
      default:
        Render = <></>;
    }

    Render = styled(Render)({
      color: "#020110",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 17,
      position: "relative",
      top: 3,
      marginRight: 6,
    });

    return <Render />;
  };

  return (
    <Container>
      {Object.keys(tabs).map((key, index) => {
        const { name, permission } = tabs[key];
        const component = (
          <Tab
            className={currentTab === name ? "active" : ""}
            onClick={() => {
              setCurrentTab(name);
            }}
            key={index}>
            <Icon tab={name} />
            {name}
          </Tab>
        );
        if (permission) return permit(component, permission);
        return component;
      })}
    </Container>
  );
};

export default RightPanelTabs;

const Container = styled("div")({
  width: "100%",
  height: "5%",
  padding: 5,
  borderBottom: "1px solid #DCDDE6",
  paddingLeft: 0,
  paddingRight: 0,
});

const Tab = styled("div")({
  display: "inline-block",
  cursor: "pointer",
  color: "#81808A",
  fontWeight: 600,
  fontSize: 12,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  width: "20%",
  padding: "10px 10px 10px 0",
  float: "left",
  "&.active": {
    color: "#020110",
  },
});
