import React, { useState, useEffect, useContext } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { styled } from "@material-ui/core";

import { defaultStates as defaultCrmStates } from "Store/Reducers/crmReducer";
import { RequestContextStore } from "Components/Layout/AppLayout";
import { getCustomerRequests } from "Api/request";

import Search from "./Search";
import RequestTable from "./RequestTable";

const CustomerRequest = () => {
  const [requests, setRequests] = useState([]);
  const [chosenRequests, setChosenRequests] = useState([]);

  const totalCrmState = useSelector(
    (state: RootStateOrAny) => state.crmReducer,
  );
  const requestContextStore: any = useContext(RequestContextStore);
  const crmState = requestContextStore.getCurrentStateFromTotalState(
    totalCrmState,
    defaultCrmStates,
  );

  const fetchRequests = (currentCustomerId) =>
    getCustomerRequests(currentCustomerId)
      .then((res) => {
        setChosenRequests(res);
        setRequests(res);
      })
      .catch((e) => console.log(e));

  useEffect(() => {
    let currentCustomerId = crmState.customerId;

    if (currentCustomerId) fetchRequests(currentCustomerId);
    else {
      setRequests([]);
      setChosenRequests([]);
    }
  }, [crmState]);

  return (
    <Container>
      <Main>
        <Search
          requests={requests}
          chosenRequests={chosenRequests}
          setChosenRequests={setChosenRequests}
        />
        <RequestTable requests={chosenRequests} />
      </Main>
    </Container>
  );
};

export default CustomerRequest;

const Container = styled("div")({
  height: "calc(100vh - var(--nav-bar-height) - 100px)",
});

const Main = styled("div")({
  height: "calc(100vh - var(--nav-bar-height) - 240px)",
});
