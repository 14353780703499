import { styled } from "@material-ui/core";

import {
  PersonIcon,
  AccountCircleOutlinedIcon,
  PaymentIcon,
  ChatIcon,
} from "Assets/icons";
import React from "react";

import { fields } from "../constants";
import { IInputs } from "../interfaces";

export const useCrmInput = (props: IInputs): any => {
  const styledIcon = (Icon): React.ReactNode =>
    styled(Icon)({
      color: "#9F9DAD",
      fontSize: 20,
      lineHeight: 17,
      fontWeight: 600,
    });

  const chooseIcon = (): React.ReactNode => {
    let icon = null,
      ResultIcon = null;
    switch (props.label) {
      case fields.CIF:
        ResultIcon = styledIcon(AccountCircleOutlinedIcon);
        icon = <ResultIcon />;
        break;
      case fields.FIN:
        ResultIcon = styledIcon(PersonIcon);
        icon = <ResultIcon />;
        break;
      case fields.CardNumber:
        ResultIcon = styledIcon(PaymentIcon);
        icon = <ResultIcon />;
        break;
      default:
        ResultIcon = styledIcon(ChatIcon);
        icon = <ResultIcon />;
        break;
    }

    return icon;
  };

  const handleInput = (e): void => {
    props.handleChange(e.target.value, props.id, props.label);
  };

  return {
    handleInput,
    chooseIcon,
  };
};

useCrmInput.displayName = "useCrmInput";
