import { styled } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  getRequestById,
  getRequestFields,
  searchAllRequest,
} from "Api/request";
import menuTypes from "Assets/constants/menuTypes";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { buttonTypes } from "Components/Buttons/buttonTypes";
import LinkButton from "Components/Buttons/LinkButton";
import Drawer from "Components/Drawer/Drawer";
import { ITableCredentials } from "Components/Table/ITableCredentials";
import Table from "Components/Table/Table";
import { permissions } from "Config/config";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Call from "Store/Actions/Call";
import Cch from "Store/Actions/Cch";
import ChatBox from "Store/Actions/ChatBox";
import Note from "Store/Actions/Note";
import Request from "Store/Actions/Request";
import SoftPhoneStore from "Store/Actions/SoftPhone";
import Topic from "Store/Actions/Topic";
import {
  formatDate,
  formatDateTwo,
  permit,
  roundOffToHour,
  toTimeFormat,
  validatePhoneNumber,
} from "Utils/Utils";

export const CallRequestTable = (props) => {
  const { translate } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const chatBox = new ChatBox(useDispatch());
  const softPhoneStore = new SoftPhoneStore(useDispatch());
  const topic = new Topic(useDispatch());
  const cch = new Cch(useDispatch());
  const note = new Note(useDispatch());
  const request = new Request(useDispatch());
  const callReducer = new Call(useDispatch());

  const params = {
    fullTextSearch: null,
    fieldName: null,
    order: "desc",
    page: 0,
    recordSize: 3,
    fieldSet: {
      agent: [localStorage.getItem("userID")],
      createdDate: [
        formatDateTwo(new Date(new Date().setHours(0, 0, 0, 0))),
        formatDateTwo(roundOffToHour(new Date())),
      ],
      communicationType: ["SIPPHONE"],
      type: ["in", "out", "ivr", "abandon", null],
      showAll: [""],
    },
  };

  const handleClose = (): void => {
    props.setCallRequestTable(false);
  };

  const editRequest = async (id: number | string) => {
    const result = await getRequestById(id);
    if (result.requestSource) {
      props.setCallRequestTable(false);
      if (result.requestSource.call) {
        chatBox.toggle(false);
        chatBox.setChatFullWidth(false);
        softPhoneStore.toggle(true);
        // console.log(result.statusDetailsId, result.statusDetails);
        request.setSelectedRequestDataOnEditRequest(
          {
            id: result.statusDetailsId,
            name: result.statusDetails,
          },
          menuTypes.CALL,
        );

        request.addRequestOption(menuTypes.CALL, result.id, {
          id: result.statusDetailsId,
          name: result.statusDetails,
        });

        const call = { ...result.requestSource.call, id: result.id };

        callReducer.addCallToEdit(call, result.id);

        if (result.notes && result.notes.length > 0)
          note.setAllNotes(menuTypes.CALL, result.id, result.notes);

        if (result.classifiers && result.classifiers.length > 0)
          topic.addNewPaths(menuTypes.CALL, result.id, result.classifiers);

        if (result.tasks && result.tasks.length > 0)
          cch.addAllCCHs(menuTypes.CALL, result.id, result.tasks);
      }
    }
  };

  const fetchRows = async () => {
    const data = await searchAllRequest({
      ...(params as unknown as ITableCredentials),
    });
    if (data.content) {
      const contents = data.content.map((row) => {
        row.enterQueueDate = row.enterQueueDate
          ? formatDate(row.enterQueueDate)
          : "";
        row.createdDate = row.createdDate ? formatDate(row.createdDate) : "";
        row.startDate = row.startDate ? formatDate(row.startDate) : "";
        row.completeDate = row.completeDate ? formatDate(row.completeDate) : "";
        row.queueWaitTime = toTimeFormat(row.queueWaitTime);
        row.durationTime = toTimeFormat(row.durationTime);
        row.number = validatePhoneNumber(row.number);
        row = Object.assign(
          {
            actions: (
              <>
                {permit(
                  <LinkButton
                    buttonType={buttonTypes.info}
                    onClick={() => editRequest(row.id)}>
                    {translate("edit")}
                  </LinkButton>,
                  permissions.request_edit,
                )}
              </>
            ),
          },
          row,
        );
        row = Object.assign(
          {
            holdTime: toTimeFormat(row.requestSource?.call?.holdDuration),
          },
          row,
        );
        return row;
      });
      setRows(contents);
    } else {
      setRows([]);
    }
  };

  useEffect(() => {
    (async function anyNameFunction() {
      const columnsData = await getRequestFields();
      const filteredColumns = columnsData?.map((column) => ({
        name: column.fieldName,
        sortable: false,
      }));
      filteredColumns.unshift({ name: "actions", sortable: false });
      console.log("filteredColumns", filteredColumns);
      setColumns(filteredColumns);
      fetchRows();
    })();
  }, []);

  return (
    <Drawer
      open={props.isCallRequestTable}
      handleClose={handleClose}
      style={{ width: "60%" }}>
      {rows.length > 0 ? (
        <Table
          hiddenPagination={true}
          searchFieldShow={false}
          additionalButtons={false}
          fetchRows={fetchRows}
          columns={columns}
          rows={rows}
        />
      ) : (
        <NoDataComponent>
          <h1>{translate("no_data")}</h1>
        </NoDataComponent>
      )}
    </Drawer>
  );
};

const NoDataComponent = styled("div")({
  position: "absolute",
  height: "99%",
  width: "100%",
  backgroundColor: grey[100],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: grey[500],
});
