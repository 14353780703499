import { PlayArrowIcon, PauseIcon } from "Assets/icons";

import React from "react";

import { Icon, STEP } from "./constants";
import { useAudioPlayer } from "./hooks";
import { IAudioPlayer } from "./interfaces";
import { useAudioPlayerStyles } from "./styles";
import {
  Timer,
  MessageContainer,
  AudipPlayerContainer,
  Controller,
  MessageFooter,
  Owner,
  Date,
  ProgressBar,
} from "./styles/AudioPlayer";

const AudioPlayer = ({
  src,
  owner,
  status,
  fullName,
  date,
}: IAudioPlayer): React.ReactElement => {
  const classes = useAudioPlayerStyles({ owner });
  const {
    isPlaying,
    trackProgress,
    duration,
    currentTime,
    convertedDuration,
    handleController,
    onScrub,
    onScrubEnd,
  } = useAudioPlayer({ src });

  return (
    <MessageContainer className={owner === "client" ? "client" : "agent"}>
      <AudipPlayerContainer>
        <Controller onClick={handleController}>
          {isPlaying ? (
            <PauseIcon
              className={classes.controllerIcon}
              style={{ color: owner === "client" ? "#00B287" : "#fff" }}
            />
          ) : (
            <PlayArrowIcon
              className={classes.controllerIcon}
              style={{ color: owner === "client" ? "#00B287" : "#fff" }}
            />
          )}
        </Controller>
        <ProgressBar
          type='range'
          step={`${STEP / 1000}`}
          min='0'
          value={trackProgress}
          max={duration ? duration : `${duration}`}
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          className={classes.progressBar}
        />
      </AudipPlayerContainer>
      <Timer className={owner === "client" ? "client" : "agent"}>
        {currentTime} /{" "}
        {!convertedDuration.includes("NaN") ? convertedDuration : "--:--"}
      </Timer>
      <MessageFooter>
        <Owner>{fullName}</Owner>
        <Date className={owner === "client" ? "client" : "agent"}>{date}</Date>
        <span>
          {owner !== "client" && (
            <Icon status={status} useStyles={useAudioPlayerStyles} />
          )}
        </span>
      </MessageFooter>
    </MessageContainer>
  );
};

export default AudioPlayer;

AudioPlayer.displayName = "AudioPlayer";
