import { styled } from "@material-ui/core";
import * as Sentry from "@sentry/browser";

import { ErrorIcon } from "Assets/icons";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Props, State } from "./interfaces";

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(_error: Error): void {
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      Sentry.captureException(_error);
    });
  }

  public render(): any {
    if (this.state.hasError) {
      return (
        <Container>
          <Icon />
          <Title>Something went wrong</Title>
          <Button
            onClick={() => {
              this.props.history.push("/");
              window.location.reload();
            }}>
            Go to main page
          </Button>
          <Button
            onClick={() => {
              window.location.reload();
            }}>
            Refresh page
          </Button>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);

const Container = styled("div")({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
});

const Icon = styled(ErrorIcon)({
  fontSize: 40,
  color: "rgba(196, 35, 35)",
});

const Title = styled("h1")({
  marginTop: 0,
  marginBottom: 40,
});

const Button = styled("div")({
  width: 100,
  cursor: "pointer",
  borderRadius: 5,
  fontWeight: 500,
  padding: 7,
  backgroundColor: "#E7F1FE",
  color: "#1871ED",
  textAlign: "center",
  display: "inline",
  marginRight: 10,
});
