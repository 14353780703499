import React from "react";
import { styled } from "@material-ui/core/styles";
import { actionTypes, colors } from "./constants";
import { PhoneIcon, CallEndIcon } from "./Icons";

interface IActionButton {
  onClick: Function;
  className?: string;
  type: string;
  active?: boolean;
}

const CallButton = (props: IActionButton) => {
  let { active, onClick, type } = props;
  let Icon =
    type === actionTypes.makeCall
      ? PhoneIcon
      : type === actionTypes.endCall && CallEndIcon;

  const ActionIcon = styled(Icon)({
    color: "white",
    fontSize: 32,
    marginTop: 14,
  });

  const ButtonContainer = styled("div")({
    height: 64,
    width: 64,
    margin: "0 auto",
    borderRadius: 48,
    cursor: "pointer",
    backgroundColor:
      type === actionTypes.makeCall
        ? colors.active
        : type === actionTypes.endCall && colors.end,
  });

  return (
    <ButtonContainer
      onClick={() => {
        active && onClick();
      }}>
      <ActionIcon />
    </ButtonContainer>
  );
};

export default CallButton;
