import { createStyles, makeStyles } from "@material-ui/core";

export const useBreakStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: "0 10px",
      borderRadius: "0 8px 8px 8px",
      boxShadow: "0px 8px 24px rgba(5, 0, 56, 0.1)",
    },
  }),
);
