import React, { forwardRef } from "react";
import {
  IconButton as MuiIconButton,
  IconButtonProps,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import { buttonTypes } from "./buttonTypes";
import { commonStyle } from "./commonStyle";
export interface IIconButton extends IconButtonProps {
  Icon?: any;
  buttonType: buttonTypes;
  iconClassName?: any;
  tooltipClassName?: any;
  tooltipText?: string;
  active?: boolean;
  styleButton?: any;
  button?: any;
  fontSize?: string;
}

const IconButton = forwardRef((props: IIconButton, ref) => {
  const classes = commonStyle(props);
  const {
    tooltipText,
    Icon,
    buttonType,
    active,
    styleButton,
    button,
    fontSize,
    ...rest
  } = props;
  const icon = (
    <MuiIconButton
      {...rest}
      className={clsx(classes.button, rest.className, button)}>
      <Icon
        fontSize={fontSize ? fontSize : "default"}
        className={clsx(classes.icon, rest.iconClassName, styleButton)}
      />
    </MuiIconButton>
  );

  if (tooltipText)
    return (
      <Tooltip
        className={clsx(rest.tooltipClassName)}
        title={tooltipText}
        ref={ref}>
        <div>{icon}</div>
      </Tooltip>
    );

  return icon;
});

export default IconButton;
