export const updateObject = (state, object) => ({
  ...state,
  ...object,
});

export function deepClone(obj: any): any {
  var clone = {};
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      var value = obj[key];
      switch (Object.prototype.toString.call(value)) {
        case "[object Object]":
          clone[key] = deepClone(obj[key]);
          break;
        case "[object Array]":
          clone[key] = value.slice(0);
          break;
        default:
          clone[key] = value;
      }
    }
  }
  return clone;
}

export function highlight(inputText: string, highlight: string): string {
  var index = inputText.toLowerCase().indexOf(highlight.toLowerCase());
  if (index >= 0) {
    inputText =
      inputText.substring(0, index) +
      "<span style='background-color: #F5F5F5;' >" +
      inputText.substring(index, index + highlight.length) +
      "</span>" +
      inputText.substring(index + highlight.length);
  }
  return inputText;
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

export function formatDateTime(date) {
  var d = new Date(date),
    hours = d.getHours(),
    minutes = d.getMinutes();

  let formatedDate = formatDate(date);

  return formatedDate + " " + hours + ":" + minutes;
}

export function formatDiapason(date) {
  var d = new Date(date),
    hours = d.getHours(),
    minutes = d.getMinutes();

  let formatedDate = formatDate(date);

  return formatedDate + " " + hours + ":" + minutes;
}

export const getRequestStates = (defaultStates, state, menuType, requestId) => {
  let requestSatates = defaultStates;

  if (state[menuType][requestId])
    requestSatates = { ...state[menuType][requestId] };

  return requestSatates;
};

export const updateTotalState = (state, menuType, requestId, requestStates) => {
  const updatedState = { ...state };
  updatedState[menuType][requestId] = requestStates;
  return updatedState;
};

export const cloneArray = (arr: any): any => {
  var clone = [];
  arr.forEach(function (value) {
    var arr_elem = {};
    for (var prop in value) arr_elem[prop] = value[prop];
    clone.push(arr_elem);
  });
  return clone;
};

export default updateObject;
