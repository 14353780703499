import IValidateFormValueProps from "./IValidateFormValueProps";

class Form {
  validateValue = (params: IValidateFormValueProps): void => {
    if (!params.required && !params.value) {
      params.setError(false);
    }
    if (params.required && !params.value) {
      params.setError(true);
    }
    if (params.regex && !new RegExp(params.regex, "g").test(params.value)) {
      params.setError(true);
    }
    if (
      params.required &&
      !params.value &&
      params.regex &&
      !new RegExp(params.regex, "g").test(params.value)
    ) {
      params.setError(true);
    }
    if (params.regex && !!params.value && new RegExp(params.regex, "g").test(params.value)) {
      params.setError(false);
    }
  };
}

export default Form;
