import i18n from "i18n/config";

export const mainColumns = [
  {
    name: "contractNumber",
    alias: i18n.t("request_contract_number"),
    sortable: false,
  },
  {
    name: "currentBalance",
    alias: i18n.t("request_current_balance"),
    sortable: false,
  },
  { name: "mainDebt", alias: i18n.t("request_main_debt"), sortable: false },
  {
    name: "firstPaymentDate",
    alias: i18n.t("request_first_payment_date"),
    sortable: false,
  },
  {
    name: "lastPaymentDate",
    alias: i18n.t("request_last_payment_date"),
    sortable: false,
  },
  { name: "branch", alias: i18n.t("request_branch"), sortable: false },
  {
    name: "collectorName",
    alias: i18n.t("request_collector_name"),
    sortable: false,
  },
  {
    name: "collectorNote",
    alias: i18n.t("request_collector_note"),
    sortable: false,
  },
];

export const secondaryColumns = [
  {
    name: "monthlyPayment",
    alias: i18n.t("request_monthly_payment"),
    sortable: false,
  },
  {
    name: "interestRate",
    alias: i18n.t("request_interest_rate"),
    sortable: false,
  },
  { name: "totalDebt", alias: i18n.t("request_total_debt"), sortable: false },
  { name: "overDays", alias: i18n.t("request_over_days"), sortable: false },
  {
    name: "amountFinanced",
    alias: i18n.t("request_amount_financed"),
    sortable: false,
  },
  {
    name: "interestDebt",
    alias: i18n.t("request_interest_debt"),
    sortable: false,
  },
];
