import { styled } from "@material-ui/core";
import {
  AccountCircleOutlinedIcon,
  SystemUpdateAltOutlinedIcon,
  MailOutlineOutlinedIcon,
  SdStorageOutlinedIcon,
  CloseIcon,
  SearchIcon,
  CallIcon,
  DeviceHubIcon,
} from "Assets/icons";
import React, { ComponentType } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Request from "Store/Actions/Request";
import { permit } from "Utils/Utils";
import { handleRegClick } from "../Request/RequestFooter/CancelButton";
import { tabs } from "./constants";
import Notification from "Notifications/Notification";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

interface IRequestTabs {
  currentTab: any;
  setCurrentTab: any;
}

const RequestTabs = (props: IRequestTabs): React.ReactElement => {
  const { translate } = useTranslation();
  const request = new Request(useDispatch());
  const { currentTab, setCurrentTab } = props;

  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );

  const { menuType, activeCallId, acwBreakId, selectedBreakId } = useSelector(
    ({
      requestReducer,
      chatReducer,
      callReducer,
      breakReducer,
    }: RootStateOrAny) => ({
      menuType: requestReducer.menuType,
      showRegistration: requestReducer.showRegistration,
      conversations: chatReducer.conversations,
      activeCallId: callReducer.activeCallId,
      acwBreakId: callReducer.acwBreakId,
      selectedBreakId: breakReducer.selectedBreakId,
    }),
  );

  const styleTheIcon = (Icon, tab): ComponentType =>
    styled(Icon)({
      color: currentTab === tab ? "#020110" : "#81808A",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 17,
      position: "relative",
      top: 3,
      marginRight: 6,
    });

  const chooseTabIcon = (tab): React.ReactNode => {
    let icon = null,
      ResultIcon = null;

    switch (tab) {
      case tabs.Registration.name:
        ResultIcon = styleTheIcon(AccountCircleOutlinedIcon, tab);
        icon = <ResultIcon />;
        break;
      case tabs.Requests.name:
        ResultIcon = styleTheIcon(SystemUpdateAltOutlinedIcon, tab);
        icon = <ResultIcon />;
        break;
      case tabs.SmsTemplates.name:
        ResultIcon = styleTheIcon(MailOutlineOutlinedIcon, tab);
        icon = <ResultIcon />;
        break;
      case tabs.KnowledgeBase.name:
        ResultIcon = styleTheIcon(SdStorageOutlinedIcon, tab);
        icon = <ResultIcon />;
        break;
      case tabs.LastCallRequests.name:
        ResultIcon = styleTheIcon(CallIcon, tab);
        icon = <ResultIcon />;
        break;
      case tabs.CustomerSearch.name:
        ResultIcon = styleTheIcon(SearchIcon, tab);
        icon = <ResultIcon />;
        break;
      case tabs.Jira.name:
        ResultIcon = styleTheIcon(DeviceHubIcon, tab);
        icon = <ResultIcon />;
        break;
      default:
        icon = <div></div>;
    }

    return icon;
  };

  const closeRegistration = (): void => {
    if (requestState.showRegistration) request.setShowRegistration(false);
  };
  const RegistrationPageNotification = (): void => {
    Notification.success(translate("reg_page_save_notfi"));
  };

  return (
    <Container>
      <Tabs>
        {Object.keys(tabs).map((key, index) => {
          const { name, permission } = tabs[key];
          let component = null;
          if (name !== tabs.LastCallRequests.name) {
            component = (
              <Tab
                className={currentTab === name ? "active" : ""}
                onClick={() => {
                  setCurrentTab(name);
                }}
                key={index}>
                {chooseTabIcon(name)}
                {name}
              </Tab>
            );
          } else if (
            name === tabs.LastCallRequests.name &&
            softPhoneState.phoneConnected
          ) {
            component = (
              <Tab
                onClick={() => {
                  setCurrentTab(name);
                }}
                key={index}>
                {chooseTabIcon(name)}
                {name}
              </Tab>
            );
          }

          if (permission) return permit(component, permission);
          return component;
        })}
      </Tabs>

      <Icon
        onClick={() =>
          handleRegClick(
            RegistrationPageNotification,
            closeRegistration,
            menuType,
            activeCallId,
            acwBreakId,
            selectedBreakId,
            softPhoneState,
          )
        }
      />
    </Container>
  );
};

export default RequestTabs;

const Container = styled("div")({
  width: "97%",
  margin: "0 auto",
  padding: 5,
  borderBottom: "1px solid #DCDDE6",
  display: "flex",
});

const Tabs = styled("div")({
  width: "98%",
  minWidth: "98%",
  maxWidth: "98%",
});

const Tab = styled("div")({
  display: "inline-block",
  cursor: "pointer",
  color: "#81808A",
  fontWeight: 600,
  fontSize: 12,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  padding: "10px 10px 10px 0",
  float: "left",
  textAlign: "left",
  marginRight: 15,
  "&.active": {
    color: "#020110",
  },
});

const Icon = styled(CloseIcon)({
  cursor: "pointer",
  fontSize: 20,
  lineHeight: 17,
  color: "#414147",
  position: "relative",
  top: 10,
  "&:hover": {
    color: "#050038",
  },
});
