/* eslint-disable @typescript-eslint/no-empty-function */
import { checkBreak, requestBreak } from "Api/configuration";
import { getCallInfo, toggleSocketStartStop } from "Api/voip";

import menuTypes from "Assets/constants/menuTypes";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import Notification from "Notifications/Notification";

import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import ISipStorage from "Sip/interfaces/ISipStorage";
import SipStorage from "Sip/SipStorage";
import BreakActions from "Store/Actions/Breaks";
import Call from "Store/Actions/Call";
import { default as RequestActions } from "Store/Actions/Request";
import SoftPhoneStore from "Store/Actions/SoftPhone";
import { formatStore } from "Store/ContextStore";

import { callTypes, voip_ip, ws_port, ws_security, sipLog } from "../constants";
import { AppLayoutHookProps, ITotalState } from "../interfaces";

export const useAppLayout = (): AppLayoutHookProps => {
  const { translate } = useTranslation();

  const breaksState = useSelector(
    (state: RootStateOrAny) => state.breakReducer,
  );

  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );

  const [newIncomingCallId, setNewIncomingCallId] = useState<string>(null);
  const [sipStorage, setSipStorage] = useState<ISipStorage>(null);
  const [callIsEstablishing, setCallIsEstablishing] = useState<boolean>(false);
  const [terminatedCallId, setTerminatedCallId] = useState<string>("");
  const [savingCallId, setSavingCallId] = useState<string>(null);
  const [sipNumber, setSipNumber] = useState<string>(
    localStorage.getItem("sipNumber"),
  );
  const [ringtone, setringtone] = useState<HTMLAudioElement>(null);
  const [outgoingRingTone, setOutgoingRingTone] =
    useState<HTMLAudioElement>(null);

  const { isCallContinuing } = useSelector(
    ({ softPhoneReducer }: RootStateOrAny) => ({
      isCallContinuing: softPhoneReducer.isCallContinuing,
    }),
  );

  const breaks = new BreakActions(useDispatch());
  const request = new RequestActions(useDispatch());
  const call = new Call(useDispatch());
  const softPhoneStore = new SoftPhoneStore(useDispatch());

  const {
    sessionStatus,
    conversations,
    activeConversationId,
    isHidden,
    chatOpen,
    chatFullWidth,
    calls,
    activeCallId,
    randomNumber,
    menuType,
    phoneConnected,
    isSavedBeforeACWCall,
    softPhoneLines,
    isACWCallOver,
    isBreakOnHold,
    isSavedBeforeHoldCall,
    softPhoneOpen,
    currentLine,
    active,
    activePlatformId,
  } = useSelector(
    ({
      authReducer,
      chatReducer,
      callReducer,
      requestReducer,
      softPhoneReducer,
      webSocketReducer,
      socialMediaReducer,
    }: RootStateOrAny) => ({
      sessionStatus: authReducer.sessionStatus,
      conversations: chatReducer.conversations,
      activeConversationId: chatReducer.activeConversationId,
      isHidden: chatReducer.isHidden,
      chatOpen: chatReducer.open,
      chatFullWidth: chatReducer.chatFullWidth,
      calls: callReducer.calls,
      activeCallId: callReducer.activeCallId,
      randomNumber: callReducer.randomNumber,
      menuType: requestReducer.menuType,
      phoneConnected: softPhoneReducer.phoneConnected,
      isSavedBeforeACWCall: softPhoneReducer.isSavedBeforeACWCall,
      softPhoneLines: softPhoneReducer.lines,
      isACWCallOver: softPhoneReducer.isACWCallOver,
      isBreakOnHold: softPhoneReducer.isBreakOnHold,
      isSavedBeforeHoldCall: softPhoneReducer.isSavedBeforeHoldCall,
      softPhoneOpen: softPhoneReducer.open,
      currentLine: softPhoneReducer.currentLine,
      active: webSocketReducer.active,
      activePlatformId: socialMediaReducer.activePlatformId,
    }),
  );

  const activeConversation = conversations[activeConversationId];

  const isLeftPanelOPen =
    phoneConnected || Object.keys(calls).length !== 0 || !isHidden;

  const chatIsVisible = activeConversationId > 0;

  const breaksStateRef = useRef(breaksState);
  breaksStateRef.current = breaksState;

  const setCallContinuing = (isCallContinuing: boolean): void => {
    softPhoneStore.setCallContinuing(isCallContinuing);
  };

  const setIsCompleteCallByAgent = (isCompleteCallByAgent: boolean): void => {
    softPhoneStore.setIsCompleteCallByAgent(isCompleteCallByAgent);
  };

  const outCallIsAnswered = (sipCallId: string): void => {
    softPhoneStore.outCallIsAnswered(sipCallId);
  };

  const setSavedInCall = (isCallContinuing = false): void => {
    softPhoneStore.setSavedInCall(isCallContinuing);
  };

  const reconnecting = (): void => {
    softPhoneStore.isReconnecting();
  };

  const connected = (): void => {
    softPhoneStore.isConnected();
  };

  const disconnected = (): void => {
    softPhoneStore.isDisconnected();
  };

  const setOutgoingCall = (isOutGoingCall = false): void => {
    softPhoneStore.triggerOutgoingCall(isOutGoingCall);
  };

  const sipWebsocketPermission =
    JSON.parse(localStorage.getItem("authorities"))
      ?.configuration_close_sip_websocket ===
    "configuration_close_sip_websocket";

  const setAcwCallOver = useCallback(
    (isAcwCallOver = true) => {
      if (localStorage.getItem("autoAcw") === "true") {
        softPhoneStore.setAcwCallOver(isAcwCallOver);
      }
    },
    [isSavedBeforeACWCall],
  );

  const closeBreakFunction = async (): Promise<void> => {
    if (
      !(
        breaksState.closeBreakId === "default" ||
        breaksState.closeBreakId == null
      ) &&
      !requestState.isActivating
    )
      await checkBreak(localStorage.getItem("userID"));
    breaks.setCloseBreakId(null);
    breaks.setSelectedBreakId("default");
  };

  const registerUa = !sipWebsocketPermission
    ? (): void => {
        if (!sipStorage && sipNumber)
          setSipStorage(
            new SipStorage({
              user: sipNumber,
              password: "rcom" + sipNumber,
              ip: voip_ip,
              wsPort: parseInt(ws_port),
              wsServer: `${ws_security}://${voip_ip}:${ws_port}/ws`,
              sipLog,
              setCallContinuing,
              setIsCompleteCallByAgent,
              outCallIsAnswered,
              setSavedInCall,
              startOutgoingRingTone,
              stopOutgoingRingTone,
              reconnecting,
              connected,
              disconnected,
              setOutgoingCall,
              setAcwCallOver,
              uaEventsHandler: {
                onUaRegistered: () => {
                  toggleSocketStartStop(
                    localStorage.getItem("userID"),
                    localStorage.getItem("firstName"),
                    localStorage.getItem("lastName"),
                    true,
                  );
                  //storage.sendMessage(localStorage.getItem('sipNumber'), 'test message');
                  softPhoneStore.setRegistered(true);
                },
                onUaUnregistered: () => {
                  toggleSocketStartStop(
                    localStorage.getItem("userID"),
                    localStorage.getItem("firstName"),
                    localStorage.getItem("lastName"),
                    false,
                  );
                  softPhoneStore.setRegistered(false);
                },
                onSessionDirectionChanged: () => {},
                onSessionInvite: (storage, sessionId) => {
                  const sessionsKeys = Object.keys(storage.activeSessions);
                  if (
                    sessionsKeys?.length > 1 ||
                    breaksStateRef.current.selectedBreakId !== "default" ||
                    randomNumber !== null
                  ) {
                    console.log("onSessionInvite reject storage", storage);
                    console.log("onSessionInvite reject sessionId", sessionId);
                    storage.activeSessions[sessionId].reject();
                    return;
                  }

                  if (localStorage.getItem("autoAcw") === "true") {
                    softPhoneStore.setAcwCallOver(false);
                    softPhoneStore.setSaveBeforeACWCallOver(false);
                  }

                  softPhoneStore.setBreakOnHold(false);
                  softPhoneStore.setSaveBeforeHoldCallOver(false);
                  softPhoneStore.setWillBeSaveAfterHoldCallOver(false);

                  addCallToLine(storage, sessionId);
                  setNewIncomingCallId(sessionId);
                  console.log("onSessionInvite storage", storage);
                  console.log("onSessionInvite sessionId", sessionId);
                },
                onSessionAccepted: (sessionId) => {
                  stopRingTone();
                  setSavingCallId(sessionId);

                  softPhoneStore.setBreakOnHold(false);
                  softPhoneStore.setSaveBeforeHoldCallOver(false);
                  softPhoneStore.setWillBeSaveAfterHoldCallOver(false);

                  if (localStorage.getItem("autoAcw") === "true") {
                    terminatedCallId !== null && setTerminatedCallId(null);
                    softPhoneStore.setAcwCallOver(false);
                    softPhoneStore.setSaveBeforeACWCallOver(false);
                    const userId = parseInt(localStorage.getItem("userID"));

                    requestBreak(userId).then((response) => {
                      if (!response) throw new Error();
                    });
                  }
                  console.log("onSessionAccepted sessionId", sessionId);
                },
                // onSessionBye: (sessionId) => {
                // setTerminatedCallId(sessionId);
                // if (localStorage.getItem("autoAcw") === "true")
                //   softPhoneStore.setAcwCallOver(true);
                // },
                onSessionTerminated: (sessionId) => {
                  setNewIncomingCallId(null);
                  setTerminatedCallId(sessionId);
                  setCallIsEstablishing(false);
                  softPhoneStore.setSpyCallActive(false);
                  console.log("onSessionTerminated sessionId", sessionId);
                },
              },
            }),
          );
        else sipStorage.register();
      }
    : () => {};

  const unRegisterUa = (): void => {
    if (sipStorage) sipStorage.unregister();
  };

  const addCallToLine = async (storage, sessionId): Promise<void> => {
    const lines = softPhoneLines;
    const updatedLines = { ...lines };
    const activeSession = storage.activeSessions[sessionId];

    const sipCallId = activeSession.request.getHeader("Call-Id");
    const uid = activeSession.request.getHeader("X-Uid");
    for (const lineId in Object.keys(lines)) {
      if (!lines[lineId]) {
        const fetchedCallInfo = await getCallInfo(uid);
        if (fetchedCallInfo !== null)
          updatedLines[lineId] = {
            callType: callTypes.IN,
            sipCallId,
            uid,
            createdAt: new Date(),
            callerName:
              activeSession.incomingInviteRequest?.message?.from?._displayName,
            isSavedInCall: false,
            ...fetchedCallInfo,
          };
        else {
          setTimeout(async () => {
            const fetchedCallInfo = await getCallInfo(uid);
            updatedLines[lineId] = {
              callType: callTypes.IN,
              sipCallId,
              uid,
              createdAt: new Date(),
              callerName:
                activeSession.incomingInviteRequest?.message?.from
                  ?._displayName,
              isSavedInCall: false,
              ...fetchedCallInfo,
            };
          }, 2500);
        }
        break;
      }
    }
    softPhoneStore.setLines(updatedLines);
  };

  const startOutgoingRingTone = (): void => {
    call.setOutCallAnimation(true);
  };

  const stopOutgoingRingTone = (): void => {
    if (outgoingRingTone) {
      outgoingRingTone.pause();
      call.setOutCallAnimation(false);
    }
  };

  const stopRingTone = (): void => {
    if (ringtone) ringtone.pause();
  };

  const getRequestId = (): number | string => {
    return menuType === menuTypes.CHAT
      ? activeConversationId
      : activeCallId === 0
      ? currentLine
      : activeCallId;
  };

  //get state by menuType and requestId
  const getCurrentStateFromTotalState = (
    totalState: ITotalState,
    defaultState: any,
  ): void => totalState[menuType][getRequestId()] ?? defaultState;

  useEffect(() => {
    if (isACWCallOver && !isSavedBeforeACWCall)
      softPhoneStore.setWillBeSaveAfterACWCallOver(true);
  }, [isACWCallOver && !isSavedBeforeACWCall]);

  useEffect(() => {
    if (isBreakOnHold && !isSavedBeforeHoldCall)
      softPhoneStore.setWillBeSaveAfterHoldCallOver(true);
  }, [isBreakOnHold && !isSavedBeforeHoldCall]);

  useEffect(() => {
    if (sipStorage) sipStorage.register();
  }, [sipStorage]);

  useEffect(() => {
    if (!chatOpen) request.setShowRegistration(false);
  }, [chatOpen]);

  useEffect(() => {
    if (menuType === menuTypes.CHAT) {
      if (activeConversation && activeConversation.editMode) {
        if (isCallContinuing) {
          Notification.info(translate("request_active_reg_page"));
          request.setShowRegistration(false);
        } else {
          request.setShowRegistration(true);
        }
      }
      request.addRequestOption(menuType, activeConversationId, {
        id: 9,
        name: "Tamamlanmamış",
      });
    } else if (menuType === menuTypes.CALL && activeCallId && softPhoneOpen) {
      if (isCallContinuing) {
        Notification.info(translate("request_active_reg_page"));
        request.setShowRegistration(false);
      } else {
        request.setShowRegistration(true);
      }
    }
  }, [activeConversationId, activeCallId, softPhoneOpen]);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      closeBreakFunction();
    });
  }, [+breaksState.closeBreakId > 0]);

  // SoftPhone Store
  const softStore = formatStore({
    outgoingRingTone: [outgoingRingTone, setOutgoingRingTone],
    ringtone: [ringtone, setringtone],
    sipNumber: [sipNumber, setSipNumber],
    newIncomingCallId: [newIncomingCallId, setNewIncomingCallId],
    savingCallId: [savingCallId, setSavingCallId],
    callIsEstablishing: [callIsEstablishing, setCallIsEstablishing],
    terminatedCallId: [terminatedCallId, setTerminatedCallId],
    sipStorage,
    registerUa,
    unRegisterUa,
  });

  const store = formatStore({ getRequestId, getCurrentStateFromTotalState });

  return {
    store,
    softStore,
    chatFullWidth,
    chatIsVisible,
    isLeftPanelOPen,
    chatOpen,
    active,
    activePlatformId,
    sessionStatus,
  };
};

useAppLayout.displayName = "useAppLayout";
