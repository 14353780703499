import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import React from "react";

import CancelButton from "./CancelButton";
import SaveButton from "./SaveButton";
import StatusSelector from "./StatusSelector";

const RequestFooter = (): React.ReactElement => (
  <Container>
    <Grid container spacing={1} justifyContent='space-around'>
      <Grid item xs={3}>
        <StatusSelector />
      </Grid>
      <Grid item xs={7}>
        <SaveButton />
      </Grid>
      <Grid item xs={2}>
        <CancelButton />
      </Grid>
    </Grid>
  </Container>
);

export default RequestFooter;

const Container = styled("div")({
  padding: 20,
  paddingBottom: 0,
});
