import i18n from "i18next";
import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { IUseOLTranslation } from "./interfaces";

const UseOLTranslation = (): IUseOLTranslation => {
  const { t } = useTranslation();
  const [lang, setLang] = useState<string>(localStorage.getItem("lang"));

  const translate = (key: string, data: any = {}): string => t(key, data);

  const changeLanguage = (lang: string): void => {
    i18n.changeLanguage(lang);
    setLang(lang);
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en");
      setLang("en");
    }
  }, []);

  return { lang, translate, changeLanguage };
};

export default UseOLTranslation;

UseOLTranslation.displayName = "UseOLTranslation";
