import React, { useContext } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { Grid, styled } from "@material-ui/core";
import { SearchIcon } from "../Icons";
import { searchBy } from "../constants";

import { defaultStates as defaultFaqStates } from "Store/Reducers/faqReducer";
import { RequestContextStore } from "Components/Layout/AppLayout";
import Faq from "Store/Actions/Faq";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const Search = (props) => {
  const { translate } = useTranslation();
  let { value, onInput } = props;
  const totalFaqState = useSelector(
    (state: RootStateOrAny) => state.faqReducer,
  );
  const requestContextStore: any = useContext(RequestContextStore);
  const faqState = requestContextStore.getCurrentStateFromTotalState(
    totalFaqState,
    defaultFaqStates,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const faq = new Faq(useDispatch());

  const handleSearchBy = (searchBy) => {
    faq.setSearchBy(
      requestState.menuType,
      requestContextStore.getRequestId(),
      searchBy,
    );
  };

  return (
    <Container>
      <Grid container>
        <Grid container item xs={6} spacing={0}>
          <Icon />
          <Input
            value={value}
            onChange={onInput}
            placeholder={translate("search")}
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={6}>
          <SearchContainer>
            <SearchBy
              onClick={() => handleSearchBy(searchBy.all)}
              className={faqState.searchBy === searchBy.all ? "active" : ""}>
              {translate("request_faq_by_all")}
            </SearchBy>
            <SearchBy
              onClick={() => handleSearchBy(searchBy.question)}
              className={
                faqState.searchBy === searchBy.question ? "active" : ""
              }>
              {translate("request_faq_by_questions")}
            </SearchBy>
            <SearchBy
              onClick={() => handleSearchBy(searchBy.answer)}
              className={faqState.searchBy === searchBy.answer ? "active" : ""}>
              {translate("request_faq_by_answers")}
            </SearchBy>
          </SearchContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Search;

const Container = styled(Grid)({
  borderBottom: "1px solid #DCDDE6",
  textAlign: "left",
  width: "98%",
  margin: "0 auto",
  padding: 5,
  paddingRight: 0,
  paddingLeft: 25,
});

const Icon = styled(SearchIcon)({
  color: "#F68F1F",
  position: "relative",
  right: 3,
  top: 8,
});

const Input = styled("input")({
  width: "90%",
  marginTop: 11,
  border: "none",
  outline: "none",
  fontSize: 13,
  textIndent: 7,
});

const SearchContainer = styled("div")({ float: "right" });

const SearchBy = styled("div")({
  color: "#9F9DAD",
  display: "inline-block",
  cursor: "pointer",
  fontSize: 10,
  fontWeight: 600,
  marginRight: 16,
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  "&.active": {
    color: "#050038",
  },
});
