/* eslint-disable no-console */
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import WebConf, { BMUrl } from "Config/WebConf";
import Notification from "Notifications/Notification";
import swalAlert from "Notifications/SwalAlert";
import React, { useState, ReactElement } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import SockJsClient from "react-stomp";
import { default as BreakActions } from "Store/Actions/Breaks";

/**
 * Websocket BM component
 */

const WebSocketBM = (): ReactElement => {
  const { translate } = useTranslation();
  const breaksState = useSelector(
    (state: RootStateOrAny) => state.breakReducer,
  );
  const breaks = new BreakActions(useDispatch());
  const [error, setError] = useState(false);

  const onMessageReceive = (msg, topic): void => {
    if (topic === WebConf.socketBM.requested)
      breaks.setBreaks([msg, ...breaksState.breaks]);
    const queueListPermission =
      JSON.parse(localStorage.getItem("authorities"))
        ?.break_permission_management === "break_permission_management";
    if (queueListPermission && msg.requestingUserName !== undefined) {
      Notification.info(
        `${msg.requestingUserName} wants to take a ${msg.breakName} break`,
        60000,
      );
    }
    if (
      topic === WebConf.socketBM.notification &&
      msg.userId === parseInt(localStorage.getItem("userID"))
    ) {
      msg.status === "ACCEPTED"
        ? Notification.success(msg.message, 60000)
        : Notification.error(msg.message, 60000);
    }

    if (topic === WebConf.socketBM.responsed) {
      const filterBreaksList = breaksState.breaks?.filter(
        (item) => item.id !== msg.id,
      );
      breaks.setBreaks(filterBreaksList);
    }
  };

  const onConnectionFailure = (event): void => {
    if (!error) {
      swalAlert
        .custom({
          text: translate("web_socket_break_error"),
          title: translate("error"),
          icon: "warning",
          cancelButtonColor: "#6c757db5",
          showCancelButton: true,
          confirmButtonColor: "#328add",
          confirmButtonText: translate("web_socket_reload_page"),
        })
        .then((result) => {
          if (result.isConfirmed) window.location.reload();
        });
      setError(true);
    }
    console.log("WebsocketBM Connection Failure", event);
  };

  const socketConnected = (): void => {
    console.log("CONNECTED");
    error && setError(false);
  };

  return (
    <SockJsClient
      url={BMUrl + WebConf.socketBM.connect}
      headers={{ user: localStorage.getItem("userID") }}
      topics={[
        WebConf.socketBM.requested,
        WebConf.socketBM.notification,
        WebConf.socketBM.responsed,
      ]}
      onMessage={onMessageReceive}
      onConnectFailure={onConnectionFailure}
      onConnect={socketConnected}
      autoReconnect={true}
      debug={true}
    />
  );
};

export default WebSocketBM;
