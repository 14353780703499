import { styled } from "@material-ui/core";
import menuTypes from "Assets/constants/menuTypes";
import { permissions } from "Config/config";
import useUpdateEffect from "Hook/useUpdateEffect";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Call from "Store/Actions/Call";
import ChatBox from "Store/Actions/ChatBox";
import Request from "Store/Actions/Request";
import SoftPhoneStore from "Store/Actions/SoftPhone";
import { permit } from "Utils/Utils";

import {
  OutgoingMessage,
  PhoneEdit,
  PanelSwitch,
  Phone,
  ChatBox as Inbox,
} from "./";
import { menuItems } from "./constants";
import { SettingsEthernetIcon, KeyboardTabIcon } from "./Icons";
import "./styles/style.css";

interface IActiveMenu {
  id: string | number;
  menuType: menuTypes;
}

const LeftPanel = (): React.ReactElement => {
  const webSocketState = useSelector(
    (state: RootStateOrAny) => state.webSocketReducer,
  );
  const chatBoxState = useSelector(
    (state: RootStateOrAny) => state.chatReducer,
  );
  const callState = useSelector((state: RootStateOrAny) => state.callReducer);
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );

  const softPhoneStore = new SoftPhoneStore(useDispatch());
  const request = new Request(useDispatch());
  const chatBox = new ChatBox(useDispatch());
  const call = new Call(useDispatch());

  const [panelOpened, setPanelOpened] = useState<boolean>(false);
  const [conversationNewMessagesCount, setConversationNewMessagesCount] =
    useState({});
  const [activeMenu, setActiveMenu] = useState<IActiveMenu>({
    id: 0,
    menuType: menuTypes.CALL,
  });

  const playSoundOnNewIncomingMessage = (): void => {
    let hasNewMessage = false;
    let hasNewConversation = false;
    const updatedConversationNewMessagesCount = {};
    let newConversationAudio;
    Object.keys(chatBoxState.conversations).forEach((key) => {
      const conversation = chatBoxState.conversations[key];
      if (conversation.newMessageCount && conversation.newMessageCount > 0) {
        if (conversationNewMessagesCount[key] === undefined) {
          hasNewConversation = true;
          switch (conversation.type) {
            case Object.keys(menuItems)[3]: // Telegram
              newConversationAudio = new Audio(
                require("Assets/sounds/newConversationTelegramSound.mp3"),
              );
              break;

            default:
              newConversationAudio = new Audio(
                require("Assets/sounds/newConversationWpSound.mp3"),
              );
              break;
          }
        } else {
          if (conversation.newMessageCount > conversationNewMessagesCount[key])
            hasNewMessage = true;
        }
      }
      updatedConversationNewMessagesCount[key] = conversation.newMessageCount
        ? conversation.newMessageCount
        : 0;
    });
    setConversationNewMessagesCount(updatedConversationNewMessagesCount);

    const newMessageAudio = new Audio(
      require("Assets/sounds/newMessageCount.mp3"),
    );
    if (hasNewConversation) newConversationAudio.play();
    else if (hasNewMessage) newMessageAudio.play();
  };

  useEffect(() => {
    playSoundOnNewIncomingMessage();
  }, [chatBoxState.conversations]);

  const setPanelSize = (): void => {
    if (panelOpened) {
      setPanelOpened(false);
      request.setLeftPanelOpen(false);
    } else {
      setPanelOpened(true);
      request.setLeftPanelOpen(true);
    }
  };

  const toggleChatBox = (conversationId): void => {
    if (
      activeMenu.menuType == menuTypes.CHAT &&
      conversationId === activeMenu.id
    ) {
      if (chatBoxState.open) {
        chatBox.toggle(false);
      } else {
        chatBox.toggle(true);
      }
    } else chatBox.toggle(true);
  };

  const toggleSoftPhone = (callId): void => {
    if (activeMenu.menuType == menuTypes.CALL && callId === activeMenu.id) {
      if (softPhoneState.open) softPhoneStore.toggle(false);
      else softPhoneStore.toggle(true);
    } else softPhoneStore.toggle(true);
  };

  const menuIsActive = (id, menuType: menuTypes): boolean =>
    id == activeMenu.id && menuType == activeMenu.menuType;

  const selectMenu = (id, menuType: menuTypes): void => {
    if (menuType == menuTypes.CALL) {
      toggleSoftPhone(id);
      call.setActiveCallId(id);
      chatBox.toggle(false);
      softPhoneStore.toggle(true);
      chatBox.setChatFullWidth(false);
      setActiveMenu({
        id,
        menuType: menuTypes.CALL,
      });
    } else {
      toggleChatBox(id);
      chatBox.readNewMessages(id);
      softPhoneStore.toggle(false);
      setActiveMenu({
        id,
        menuType: menuTypes.CHAT,
      });
    }
    chatBox.setActiveConversation(id);
    request.setMenuType(menuType);
  };

  useUpdateEffect(() => {
    let id = null;

    switch (requestState.menuType) {
      case menuTypes.CALL:
        id = callState.activeCallId;
        break;
      case menuTypes.CHAT:
        id = chatBoxState.activeConversationId;
        break;
      default:
        break;
    }
    if (id)
      setActiveMenu({
        id: id,
        menuType: requestState.menuType,
      });
  }, [
    requestState.menuType,
    callState.activeCallId,
    chatBoxState.activeConversationId,
  ]);

  const getChatBox = (
    id: number | string,
    icon: any,
    color: string,
    platform: string,
    isEditMode: boolean,
    isActive: boolean,
    badgeContent: number | null,
    isForwarded: boolean,
  ): React.ReactElement => {
    const messageList = chatBoxState.conversations[id].messageList;
    const lastMessageInfo =
      messageList[
        Object.keys(messageList)[Object.keys(messageList).length - 1]
      ];

    return (
      <Inbox
        key={id}
        conversationId={id}
        lastMessageInfo={lastMessageInfo}
        icon={icon}
        color={color}
        platform={platform}
        isEditMode={isEditMode}
        isActive={isActive}
        isOpen={panelOpened}
        badgeContent={badgeContent}
        handleSelectMenu={async () => selectMenu(id, menuTypes.CHAT)}
        isForwarded={isForwarded}
      />
    );
  };

  const getPhone = (
    id: number | string,
    menuText: string,
    isActive: boolean,
  ): React.ReactElement => (
    <PhoneEdit
      key={id}
      isOpen={panelOpened}
      isActive={isActive}
      handleSelectMenu={async () => selectMenu(id, menuTypes.CALL)}
      phoneNumber={menuText}
    />
  );

  useEffect(() => {
    softPhoneState.isCallContinuing && selectMenu(0, menuTypes.CALL);
  }, [softPhoneState.isCallContinuing, callState.calls]);

  return useMemo(
    () =>
      softPhoneState.phoneConnected ||
      Object.keys(callState.calls).length !== 0 ||
      !chatBoxState.isHidden ? (
        <StyledLeftPanel className={panelOpened ? "opened" : "closed"}>
          {softPhoneState.phoneConnected && (
            <Phone
              isActive={menuIsActive(0, menuTypes.CALL)}
              handleSelectMenu={async () => selectMenu(0, menuTypes.CALL)}
              isOpen={panelOpened}
            />
          )}
          {webSocketState.connected &&
            permit(
              <OutgoingMessage isOpen={panelOpened} />,
              permissions.omnichannel_send_message_template,
            )}

          <Divider />
          <ChatBoxContainer>
            {callState.calls &&
              Object.keys(callState.calls).map((callId) => {
                const call = callState.calls[callId];
                return getPhone(
                  callId,
                  call.number,
                  menuIsActive(callId, menuTypes.CALL),
                );
              })}
            {Object.keys(callState.calls).length > 0 && panelOpened && (
              <Divider />
            )}
            {webSocketState.connected &&
              !chatBoxState.isHidden &&
              chatBoxState.conversations &&
              Object.keys(chatBoxState.conversations).map((conversationId) => {
                const conversation = chatBoxState.conversations[conversationId];
                const { platform } = conversation;
                const { icon, color } = menuItems[conversation.platform]
                  ? menuItems[conversation.platform]
                  : menuItems.OTHER;
                return getChatBox(
                  conversation.conversationId,
                  icon,
                  color,
                  platform,
                  chatBoxState.conversations[conversation.conversationId] &&
                    chatBoxState.conversations[conversation.conversationId]
                      .editMode,
                  menuIsActive(conversation.conversationId, menuTypes.CHAT),
                  conversation.newMessageCount &&
                    conversation.newMessageCount > 0
                    ? conversation.newMessageCount
                    : null,
                  chatBoxState.conversations[conversation.conversationId]
                    .isForwarded,
                );
              })}
          </ChatBoxContainer>
          <PanelSwitch
            Icon={panelOpened ? KeyboardTabIcon : SettingsEthernetIcon}
            isOpen={panelOpened}
            setIsOpen={setPanelSize}
          />
        </StyledLeftPanel>
      ) : (
        <></>
      ),
    [
      chatBoxState.conversations,
      chatBoxState.isHidden,
      callState.calls,
      callState.activeCallId,
      activeMenu,
      panelOpened,
      softPhoneState.isCallContinuing,
      softPhoneState.lines[softPhoneState.currentLine] &&
        softPhoneState.lines[softPhoneState.currentLine].sipCallId,
      softPhoneState.phoneConnected,
      webSocketState.connected,
      softPhoneState.open,
    ],
  );
};

export default LeftPanel;

LeftPanel.displayName = "LeftPanel";

const StyledLeftPanel = styled("div")({
  height: "calc(100vh - var(--nav-bar-height) - 18px)",
  minHeight: "calc(100vh - var(--nav-bar-height) - 18px)",
  borderTopLeftRadius: 24,
  backgroundColor: "#F5F4F7",
  position: "relative",
  float: "left",
  left: 0,
  flex: 1,
  flexGrow: 0,
  transition: "all 0.2s ease",
  padding: 8,
  boxShadow: "0px 16px 24px rgba(5, 0, 56, 0.1)",
  "&.opened": {
    minWidth: 230,
    width: 230,
  },
  "&.closed": {
    width: 55,
    minWidth: 55,
    "&.div-root-2923": {
      width: "80%",
    },
  },
});

const Divider = styled("hr")({
  border: "none",
  borderTop: "1px solid #DCDDE6",
  marginTop: 10,
});

const ChatBoxContainer = styled("div")({
  overflowY: "auto",
  overflowX: "hidden",
  height: "calc(100vh - var(--nav-bar-height) - 180px)",
  "&::-webkit-scrollbar": {
    width: 3,
    height: 6,
  },
});
