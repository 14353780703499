import React from "react";
import exportFromJSON from "export-from-json";

import { createStyles, makeStyles, Tooltip } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { default as MuiToolbar } from "@material-ui/core/Toolbar";
import CloudDownloadTwoToneIcon from "@material-ui/icons/CloudDownloadTwoTone";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import GetAppIcon from "@material-ui/icons/GetApp";

import { buttonTypes } from "Components/Buttons/buttonTypes";
import IconButton from "Components/Buttons/IconButton";
import LoadingComponent from "Components/Loading/LoadingComponent";

import ColumnsCheck from "./ColumnsCheck";
import { ITableCredentials } from "./ITableCredentials";
import Search from "./Search";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

interface IToolBar {
  dense: boolean;
  canExportData?: boolean;
  prefix?: string;
  setDense: Function;
  handleSearchChange?(value: string): void;
  additionalButtons?: any;
  fetchRows?: Function;
  fetchRowsForExport?: Function;
  searchFieldShow: boolean;
  export: Function;
  tableCredentials: ITableCredentials;
  showFilterDrawer: boolean;
}

const useToolbarStyles = makeStyles(() =>
  createStyles({
    root: {
      background: grey[100],
    },
    tabs: {
      flex: 1,
    },
    search: {
      flex: 2,
    },
    actions: {
      flex: 1,
    },
    action: {
      position: "relative",
      float: "right",
      right: "0",
    },
  }),
);

const UPPER_LIMIT = 10000000;

export default function ToolBar(props: IToolBar) {
  const { translate } = useTranslation();
  const classes = useToolbarStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [loading, setLoading] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleColumnsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColumnsClose = () => {
    setAnchorEl(null);
  };

  const refreshTable = () => {
    if (props.fetchRows)
      props.fetchRows({
        fullTextSearch: null,
        fieldName: null,
        fieldSet: null,
        order: "desc",
        page: 0,
        recordSize: props.tableCredentials.recordSize,
      });
  };

  const exportData = async () => {
    setLoading(true);
    await props.export(props.tableCredentials);
    setLoading(false);
  };

  const exportData2 = async () => {
    const withBOM = true;
    props
      .fetchRowsForExport({
        fullTextSearch: null,
        fieldName: null,
        fieldSet: null,
        order: "desc",
        page: 0,
        recordSize: UPPER_LIMIT,
      })
      .then((res: any) => {
        const fileName = `${props.prefix} ${new Date().toLocaleString(
          "en-GB",
        )}`;
        const exportType = exportFromJSON.types.xls;
        let data = res.map((item: any) => {
          ["deepness", "id", "parentId", "userId"].forEach(
            (e) => delete item[e],
          );
          return item;
        });

        const chunkSize = 500000;
        for (let i = 0; i < data.length; i += chunkSize) {
          const chunk = data.slice(i, i + chunkSize);
          exportFromJSON({ data: chunk, fileName, exportType, withBOM });
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div>
      <MuiToolbar className={classes.root}>
        <div className={classes.tabs}></div>
        <div className={classes.search}>
          {props.searchFieldShow && (
            <Search
              handleChange={props.handleSearchChange}
              showFilterDrawer={props.showFilterDrawer}
            />
          )}
        </div>
        <div className={classes.actions}>
          <Tooltip title={translate("table_filter_list")}>
            <IconButton
              buttonType={buttonTypes.default}
              Icon={FilterListIcon}
              onClick={handleColumnsClick}
              className={classes.action}></IconButton>
          </Tooltip>
          <Tooltip title={translate("table_refresh")}>
            <IconButton
              buttonType={buttonTypes.default}
              Icon={RefreshIcon}
              onClick={refreshTable}
              className={classes.action}></IconButton>
          </Tooltip>
          {props.export && (
            <Tooltip title={translate("table_excel_export")}>
              <IconButton
                buttonType={buttonTypes.success}
                onClick={exportData}
                className={classes.action}
                Icon={CloudDownloadTwoToneIcon}></IconButton>
            </Tooltip>
          )}
          {props.canExportData && (
            <Tooltip title={translate("table_export_data")}>
              <IconButton
                buttonType={buttonTypes.success}
                className={classes.action}
                Icon={GetAppIcon}
                onClick={exportData2}></IconButton>
            </Tooltip>
          )}
          {props.additionalButtons}
          <ColumnsCheck
            anchorEl={anchorEl}
            open={open}
            handleClose={handleColumnsClose}
          />
        </div>
        {loading ? <LoadingComponent /> : null}
      </MuiToolbar>
    </div>
  );
}
