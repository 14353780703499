import {
  Typography,
  FormControl,
  createStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import FormElement from "Components/Form/FormElement";
import FormFieldWrapper from "Components/Form/FormFieldWrapper";
import { fetchData } from "networking";
import React, { useEffect, useState } from "react";

interface IOption {
  id?: string;
  inputValue?: string;
  value: string;
  name: string;
}

interface IFormSelect {
  options: Array<IOption> | null;
  id?: string;
  value?: string | Array<any>;
  required: boolean;
  placeHolder: string;
  multiple?: boolean;
  optionCanBeAdded?: boolean;
  hideAddNewOption?: boolean;
  handleRemoveAddedOption?: Function;
  handleChange: Function;
  [x: string]: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: "100%",
    },
    inputFocused: {
      boxShadow: "0 0 1px 1px white !important",
    },
    listbox: {
      bottom: "20px important",
    },
  }),
);

const filter = createFilterOptions<IOption>();

function FormSelect(props: IFormSelect) {
  const classes = useStyles(props);
  const [options, setOptions] = useState<Array<IOption>>(props.options);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchInputValue] = useState("");

  const getRenderedOption = (option) => {
    if (props.handleAddOption && option.value === null) {
      return (
        <Typography
          onClick={() => props.handleAddOption(searchInputValue)}
          noWrap>
          {option.name}
        </Typography>
      );
    }
    return <Typography noWrap>{option.name}</Typography>;
  };

  const getRenderedInput = (params) => (
    <TextField {...params} onFocus={props.onFocus ? props.onFocus : null} />
  );

  useEffect(() => {
    props.setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    let active = true;
    if (!loading) return undefined;

    (async () => {
      const response = await fetchData(props.url + searchInputValue); //props.url fetchData
      const updatedOptions = await response.json();
      if (active) {
        const filteredOptions = Object.keys(updatedOptions).map((key) => {
          return {
            id: updatedOptions[key]["id"],
            name: updatedOptions[key]["name"],
            value: updatedOptions[key]["id"],
          };
        }) as IOption[];
        setOptions(filteredOptions);
      }
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const handleChange = (_event: React.ChangeEvent<any>, values, reason) => {
    //check if value removed and if removed value is newly added
    if (reason === "remove-option") {
      if (typeof props.value === "object") {
        const removedOption = props.value.filter((x) => !values.includes(x));
        if (removedOption[0] && props.handleRemoveAddedOption)
          props.handleRemoveAddedOption(removedOption);
      }
    }

    props.setValue(values);
    props.handleChange(values, props.id, props.placeHolder);
  };

  return (
    <FormFieldWrapper>
      <FormControl variant='filled' className={classes.formControl}>
        <Autocomplete
          onChange={handleChange}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const filteredIncludesInputValue =
              filtered.filter((option) => {
                return option.name.toLowerCase() === params.inputValue;
              }).length === 0;

            if (
              props.hideAddNewOption === false &&
              props.optionCanBeAdded === true &&
              params.inputValue !== "" &&
              filteredIncludesInputValue === true
            )
              filtered.push({
                inputValue: params.inputValue,
                name: params.inputValue,
                value: null,
              });

            return filtered;
          }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={loading}
          getOptionSelected={(option, value) => option.name === value.name}
          size={"small"}
          multiple={props.multiple}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          value={props.value}
          classes={{
            inputFocused: classes.inputFocused,
            listbox: classes.listbox,
          }}
          options={options ? options : []}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") return option;

            // Add "xxx" option created dynamically
            if (option.inputValue) return option.inputValue;

            // Regular option
            return option.name;
          }}
          renderOption={(option) => getRenderedOption(option)}
          freeSolo
          renderInput={(params) => getRenderedInput(params)}
        />
      </FormControl>
    </FormFieldWrapper>
  );
}

export default FormElement(FormSelect);
