import React from "react";
import { styled } from "@material-ui/core";

interface IFullPath {
  path: Array<string>;
}

const FullPath = (props: IFullPath) => {
  let { path } = props;

  return (
    <FullPathContainer>
      {path &&
        path.map((elem, index, arr) => {
          if (index === arr.length - 1)
            return <FullPathLast key={index}>{elem}</FullPathLast>;
          else return <FullPathPrefix key={index}>{elem} / </FullPathPrefix>;
        })}
    </FullPathContainer>
  );
};

export default FullPath;

const FullPathContainer = styled("div")({
  textAlign: "left",
  marginTop: 10,
});

const FullPathPrefix = styled("span")({
  color: "#81808A",
  fontWeight: 600,
  fontSize: 12,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
});

const FullPathLast = styled("span")({
  color: "#E97D09",
  fontWeight: 600,
  fontSize: 12,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
});
