import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Popover,
} from "@material-ui/core";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import Button from "Components/Buttons/Button";
import { buttonTypes } from "Components/Buttons/buttonTypes";
import React from "react";

import { ILastMsgPopover } from "./interfaces";
import { useLastMsgPopoverStyles } from "./styles";

const LastMsgPopover = ({
  openLastMsg,
  handleCloseLastMsg,
  lastMsgList,
  selectedLastMsgRange,
  handleSelectedLastMsgRange,
  handleSendLastMsg,
}: ILastMsgPopover): React.ReactElement => {
  const classes = useLastMsgPopoverStyles();
  const { translate } = useTranslation();

  return (
    <Popover
      open={openLastMsg}
      style={{ left: "8%" }}
      onClose={handleCloseLastMsg}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}>
      <div className={classes.popover}>
        <FormControl required className={classes.formControl}>
          <InputLabel id='language'>
            {translate("chat_box_select_language")}
          </InputLabel>
          <Select
            labelId='demo-simple-select-outlined-label'
            id='demo-simple-select-outlinedt'
            value={selectedLastMsgRange}
            onChange={handleSelectedLastMsgRange}
            label={translate("date_range")}>
            {lastMsgList?.map((option, index) => (
              <MenuItem key={index} value={option.content}>
                <span style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  {option.language}
                </span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.buttonContainer}>
          <Button
            disabled={selectedLastMsgRange.length === 0}
            className={classes.buttonClass}
            onClick={handleSendLastMsg}
            buttonType={buttonTypes.success}>
            {translate("send")}
          </Button>
          <Button
            className={classes.buttonClass}
            onClick={handleCloseLastMsg}
            buttonType={buttonTypes.primary}>
            {translate("close")}
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default LastMsgPopover;
