/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import menuTypes from "Assets/constants/menuTypes";
import { useCallback } from "react";

import { noteActions as actionTypes } from "../Actions/actionTypes";

export default class Note {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setNewNote = useCallback(
    (menuType: menuTypes, requestId: number, note: string) =>
      this.dispatch({
        type: actionTypes.SET_NEW_NOTE,
        menuType,
        requestId,
        note,
      }),
    [this],
  );

  setAllNotes = useCallback(
    (menuType: menuTypes, requestId: number, notes: Array<Object>) =>
      this.dispatch({
        type: actionTypes.SET_ALL_NOTES,
        menuType,
        requestId,
        notes,
      }),
    [this],
  );

  clearNotesByRequestId = useCallback(
    (menuType: menuTypes, requestId: number) =>
      this.dispatch({
        type: actionTypes.CLEAR_NOTES_BY_REQUEST_ID,
        menuType,
        requestId,
      }),
    [this],
  );
}
