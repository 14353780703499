import { makeStyles, createStyles } from "@material-ui/core";

export const useDateInputStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        marginBottom: 3,
      },
      "& .MuiButtonBase-root": {
        marginRight: 0,
      },
    },
  },
});

export const useSelectInputStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: "100%",
    },
    inputFocused: {
      boxShadow: "0 0 1px 1px white !important",
    },
    root: {},
    errorRoot: {
      "& label": {
        color: "red !important",
      },
      "& fieldset": {
        borderColor: "red !important",
      },
    },
    icon: {
      color: "orange",
      position: "absolute",
      right: "-35px",
      top: "5px",
      fontSize: "30px",
      cursor: "pointer",
    },
    listbox: {
      bottom: "20px important",
    },
    inputAdded: {
      marginTop: "7px",
      "& input:focus": {
        boxShadow: "0 0 1px 1px white !important",
      },
    },
  }),
);

export const useCompanyAutoCompleteStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: "100%",
      marginTop: 15,
      padding: 5,
    },
    inputFocused: {
      boxShadow: "0 0 1px 1px white !important",
      border: "none",
    },
    root: {},
    errorRoot: {
      "& label": {
        color: "red !important",
      },
      "& fieldset": {
        borderColor: "red !important",
      },
    },
    listbox: {
      bottom: "20px important",
    },
  }),
);

export const useProfileImageStyles = makeStyles(() =>
  createStyles({
    input: {
      display: "none",
    },
  }),
);
