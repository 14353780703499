import { getAllBreakListByUserId } from "Api/configuration";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { default as BreakActions } from "Store/Actions/Breaks";

import { BreakMenuHookProps, IBreakList, IBreakMenu } from "../interfaces";

export const useBreakMenu = ({
  handleBreak,
}: IBreakMenu): BreakMenuHookProps => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [breakList, setBreakList] = useState<Array<IBreakList>>([]);

  const breaks = new BreakActions(useDispatch());

  const open = Boolean(anchorEl);

  const fetchAllBreakListByUserId = async (): Promise<void> => {
    const response = await getAllBreakListByUserId(
      localStorage.getItem("userID"),
    );

    if (response)
      setBreakList([
        { id: "default", name: "Available", duration: null },
        ...response,
      ]);
  };

  const onClick = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleBreakAction = (name: string, id: number | string): void => {
    handleBreak(name, id);
    handleClose();
    breaks.setSelectedBreakId(id);
    breaks.setBreakName(name);
  };

  useEffect(() => {
    fetchAllBreakListByUserId();
  }, [open]);

  return {
    open,
    anchorEl,
    breakList,
    onClick,
    handleClose,
    handleBreakAction,
  };
};

useBreakMenu.displayName = "useBreakMenu";
