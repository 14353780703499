import menuTypes from "Assets/constants/menuTypes";

import { INoteReducer, IAction, IState } from "./interfaces/noteReducer.types";
import { noteActions as actionTypes } from "../Actions/actionTypes";
import {
  getRequestStates,
  updateObject,
  updateTotalState,
  deepClone,
} from "../utility";

const initialState: IState = {
  [menuTypes.CALL]: {},
  [menuTypes.CHAT]: {},
};

export const defaultStates: INoteReducer = {
  newNote: "",
  notes: [],
};

const setNewNote = (state: IState, action: Partial<IAction>): IState => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestStates = updateObject(requestStates, { newNote: action.note });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const setAllNotes = (state: IState, action: Partial<IAction>): IState => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestStates = updateObject(requestStates, { notes: action.notes });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const clearNotesByRequestId = (
  state: IState,
  action: Partial<IAction>,
): IState => {
  const notes = deepClone(state[action.menuType]);

  delete notes[action.requestId];

  return updateObject(state, { [action.menuType]: notes });
};

const noteReducer = (
  state = initialState,
  action: Partial<IAction>,
): IState => {
  switch (action.type) {
    case actionTypes.SET_NEW_NOTE:
      return setNewNote(state, action);
    case actionTypes.SET_ALL_NOTES:
      return setAllNotes(state, action);
    case actionTypes.CLEAR_NOTES_BY_REQUEST_ID:
      return clearNotesByRequestId(state, action);
    default:
      return state;
  }
};

export default noteReducer;
