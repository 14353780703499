/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { styled } from "@material-ui/core/styles";
import { RequestContextStore } from "Components/Layout/AppLayout";
import Topic from "Store/Actions/Topic";
import { defaultStates as defaultTopicStates } from "Store/Reducers/topicReducer";
import Classificators from "./Classificators";
import SearchInput from "./SearchInput";
import Paths from "./Paths";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const ClassificatorSearch = () => {
  const { translate } = useTranslation();
  const totalTopicState = useSelector(
    (state: RootStateOrAny) => state.topicReducer,
  );

  const topic = new Topic(useDispatch());
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );

  const requestContextStore: any = useContext(RequestContextStore);

  const topicState = requestContextStore.getCurrentStateFromTotalState(
    totalTopicState,
    defaultTopicStates,
  );

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const segments = value.split("/").map((segment) => segment.trimStart());
    const lastSegment =
      segments.length > 0 ? segments[segments.length - 1] : "";
    topic.setClassificatorSearchValue(
      requestState.menuType,
      requestContextStore.getRequestId(),
      lastSegment,
    );
  };

  return useMemo(
    () => (
      <Wrapper>
        <SearchInput
          value={topicState.searchValue}
          onInput={onInput}
          placeholder={translate("request_search_topics")}
        />
        <Classificators />
        <Paths />
      </Wrapper>
    ),
    [
      topicState.searchValue,
      topicState.isSearching,
      requestState.menuType,
      requestContextStore.getRequestId(),
    ],
  );
};

export default ClassificatorSearch;

const Wrapper = styled("div")({
  maxHeight: "75%",
  height: "75%",
  minHeight: "75%",
  position: "relative",
  backgroundColor: "white",
  zIndex: 2,
  paddingLeft: 10,
  paddingRight: 10,
  borderBottom: "1px solid #DCDDE6",
});
