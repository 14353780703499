import React from "react";

import { ThemeProvider } from "@material-ui/core";
import { ToastContainer } from "react-toastify";

import Authentication from "Components/Authentication/Authentication";
import ContainerWithHistory from "Components/Container";
import Theme from "./Assets/styles/Theme";

import "./Assets/styles/App.css";
import "devextreme/dist/css/dx.light.css";
import "react-toastify/dist/ReactToastify.min.css";

import 'devextreme/dist/css/dx.material.blue.light.css';

const App = () => (
  <div className='App'>
    <Authentication>
      <ThemeProvider theme={Theme}>
        <ContainerWithHistory />
        <ToastContainer
          theme='light'
          position='bottom-right'
          autoClose={3000}
          limit={5}
          hideProgressBar={false}
          rtl={false}
          newestOnTop
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </Authentication>
  </div>
);

export default App;
