/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { createStyles, makeStyles, styled } from "@material-ui/core/styles";
import useUpdateEffect from "Hook/useUpdateEffect";

import PhoneNumber from "./PhoneNumber";
import InCallActionButtonContainer from "./InCallActionButtonContainer";

import { deepClone } from "Store/utility";
import { callTypes } from "./constants";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
      marginBottom: 10,
      backgroundColor: "white",
    },
    row: {
      height: 40,
      backgroundColor: "",
    },
    table: {},
  }),
);

export default function CallInfo() {
  const { translate } = useTranslation();
  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );
  const [rows, setRows] = useState({
    number: "",
    duration: "",
    queue: "",
    waitingDurations: {},
  });
  const [counterInterval, setCounterInterval] = useState(null);
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    const updatedRows = deepClone(rows);
    const info = softPhoneState.lines[softPhoneState.currentLine];
    updatedRows.number = info?.number ?? "";
    updatedRows.duration = info?.duration ?? "";
    updatedRows.queue = info?.queue ?? "";
    updatedRows.waitingDurations[softPhoneState.currentLine] = Number(
      updatedRows.waitingDurations[softPhoneState.currentLine] ??
        info?.waitingDuration ??
        0,
    );
    setRows(updatedRows);
  }, [softPhoneState.currentLine, softPhoneState.lines]);

  useUpdateEffect(() => {
    const info = softPhoneState.lines[softPhoneState.currentLine];
    if (counterInterval) {
      clearInterval(counterInterval);
    }
    if (info) {
      const interval = setInterval(() => {
        if (info.isAnswered) {
          if (typeof info.answeredAt == "string")
            info.answeredAt = new Date(info.answeredAt);
          if (info.hangupedAt) {
            if (typeof info.hangupedAt == "string")
              info.hangupedAt = new Date(info.hangupedAt);

            let time = null;

            if (info.hangupedAt.getTime() > info.answeredAt.getTime()) {
              time = info.hangupedAt.getTime() - info.answeredAt.getTime();
            } else {
              time = info.answeredAt.getTime() - info.hangupedAt.getTime();
            }
            setDuration((duration) => {
              if (!duration) return Math.floor(time / 1000);
              return duration + 1;
            });
          } else {
            let time = null;

            if (new Date().getTime() > info.answeredAt.getTime()) {
              time = new Date().getTime() - info.answeredAt.getTime();
            } else {
              time = info.answeredAt.getTime() - new Date().getTime();
            }
            setDuration((duration) => {
              if (!duration) return Math.floor(time / 1000);
              return duration + 1;
            });
          }
        } else {
          if (info.callType === callTypes.IN) {
            setRows((prevRows) => {
              const newWaitingDurations = { ...prevRows.waitingDurations };
              newWaitingDurations[softPhoneState.currentLine] =
                (newWaitingDurations[softPhoneState.currentLine] || 0) + 1;
              return { ...prevRows, waitingDurations: newWaitingDurations };
            });
          }
        }
      }, 1000);
      setCounterInterval(interval);
    } else {
      setDuration(null);
    }
    return () => clearInterval(counterInterval);
  }, [rows]);

  const classes = useStyles();
  const currentLineWaitingDuration =
    rows.waitingDurations[softPhoneState.currentLine];

  return useMemo(
    () => (
      <div className={classes.root}>
        <PhoneNumber number={rows.number} />
        <InfoContainer>
          <Info>
            <InfoTitle>{translate("softphone_duration")}</InfoTitle>{" "}
            <InfoValue>
              {duration} {duration ? " sec" : ""}
            </InfoValue>
          </Info>
          <Info>
            <InfoTitle>{translate("softphone_queue")}</InfoTitle>
            <InfoValue>{rows.queue}</InfoValue>
          </Info>
          <Info>
            <InfoTitle>{translate("softphone_waiting_duration")}</InfoTitle>{" "}
            <InfoValue>
              {currentLineWaitingDuration}{" "}
              {currentLineWaitingDuration ? " sec" : ""}
            </InfoValue>
          </Info>
        </InfoContainer>
        <InCallActionButtonContainer />
      </div>
    ),
    [rows, currentLineWaitingDuration, duration],
  );
}

const InfoContainer = styled("ul")({
  padding: 0,
  backgroundColor: "inherit",
  listStyleType: "none",
  width: 250,
  margin: "0 auto",
});

const Info = styled("li")({
  height: 40,
  borderTop: "1px solid #050038",
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  color: "#050038",
});

const InfoTitle = styled("span")({
  float: "left",
  marginTop: 10,
});

const InfoValue = styled("span")({
  float: "right",
  marginTop: 10,
});
