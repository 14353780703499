import i18n from "i18n/config";

export const personalColumns = [
  {
    name: "cif",
    alias: i18n.t("crm_card_customer_cif"),
    sortable: true,
  },
  {
    name: "customerType",
    alias: i18n.t("crm_card_customer_customer_type"),
    sortable: true,
  },
  {
    name: "fullName",
    alias: i18n.t("crm_card_customer_fullName"),
    sortable: true,
  },
  {
    name: "firstName",
    alias: i18n.t("crm_card_customer_firstName"),
    sortable: true,
  },
  {
    name: "lastName",
    alias: i18n.t("crm_card_customer_lastName"),
    sortable: true,
  },
  {
    name: "middleName",
    alias: i18n.t("crm_card_customer_middleName"),
    sortable: true,
  },
  {
    name: "dateOfBirth",
    alias: i18n.t("crm_card_customer_date_of_birth"),
    sortable: true,
  },
  {
    name: "gender",
    alias: i18n.t("crm_card_customer_gender"),
    sortable: true,
  },
  {
    name: "identificationDoc",
    alias: i18n.t("crm_card_customer_identification_doc"),
    sortable: true,
  },
  {
    name: "employeeInfo",
    alias: i18n.t("crm_card_customer_employee_info"),
    sortable: true,
  },
  {
    name: "permanentAddress",
    alias: i18n.t("crm_card_customer_permanent_address"),
    sortable: true,
  },
  {
    name: "registrationAddress",
    alias: i18n.t("crm_card_customer_registration_address"),
    sortable: true,
  },
  {
    name: "domicileAddress",
    alias: i18n.t("crm_card_customer_domicile_address"),
    sortable: true,
  },
  {
    name: "residentStatus",
    alias: i18n.t("crm_card_customer_resident_status"),
    sortable: true,
  },
  {
    name: "phones",
    alias: i18n.t("crm_card_customer_phones"),
    sortable: true,
  },
];

export const accountsColumns = [
  {
    name: "accountNumber",
    alias: i18n.t("crm_card_customer_account_number"),
    sortable: true,
  },
  {
    name: "acyCurrBalance",
    alias: i18n.t("crm_card_customer_acy_curr_balance"),
    sortable: true,
  },
  {
    name: "avlBalance",
    alias: i18n.t("crm_card_customer_avl_balance"),
    sortable: true,
  },
  {
    name: "branchCode",
    alias: i18n.t("crm_card_customer_branch_code"),
    sortable: true,
  },
  {
    name: "branchName",
    alias: i18n.t("crm_card_customer_branch_name"),
    sortable: true,
  },
  {
    name: "currency",
    alias: i18n.t("crm_card_customer_currency"),
    sortable: true,
  },
  {
    name: "ibanRgd",
    alias: i18n.t("crm_card_customer_iban_rgd"),
    sortable: true,
  },
  {
    name: "lcyCurrBalance",
    alias: i18n.t("crm_card_customer_lcy_curr_balance"),
    sortable: true,
  },
  { name: "name", alias: i18n.t("crm_card_customer_name"), sortable: true },
  {
    name: "openDate",
    alias: i18n.t("crm_card_customer_open_date"),
    sortable: true,
  },
];

export const cardsColumns = [
  {
    name: "availableBalance",
    alias: i18n.t("crm_card_customer_available_balance"),
    sortable: true,
  },
  {
    name: "blockedAmount",
    alias: i18n.t("crm_card_customer_blocked_amount"),
    sortable: true,
  },
  {
    name: "cardAccountNumber",
    alias: i18n.t("crm_card_customer_card_account_number"),
    sortable: true,
  },
  {
    name: "cardHolderName",
    alias: i18n.t("crm_card_customer_card_holder_name"),
    sortable: true,
  },
  {
    name: "cardNumber",
    alias: i18n.t("crm_card_customer_card_number"),
    sortable: true,
  },
  {
    name: "cardStatus",
    alias: i18n.t("crm_card_customer_card_status"),
    sortable: true,
  },
  {
    name: "cardTypeName",
    alias: i18n.t("crm_card_customer_card_type_name"),
    sortable: true,
  },
  {
    name: "cif",
    alias: i18n.t("crm_card_customer_cif"),
    sortable: true,
  },
  {
    name: "currency",
    alias: i18n.t("crm_card_customer_currency"),
    sortable: true,
  },
  {
    name: "securityCode",
    alias: i18n.t("crm_card_customer_security_code"),
    sortable: true,
  },
  {
    name: "expiryDate",
    alias: i18n.t("crm_card_customer_expiry_date"),
    sortable: true,
  },
];

export const loansColumns = [
  {
    name: "amount",
    alias: i18n.t("crm_card_customer_amount"),
    sortable: true,
  },
  {
    name: "branchCode",
    alias: i18n.t("crm_card_customer_branch_code"),
    sortable: true,
  },
  {
    name: "branchName",
    alias: i18n.t("crm_card_customer_branch_name"),
    sortable: true,
  },
  {
    name: "contractReference",
    alias: i18n.t("crm_card_customer_contract_reference"),
    sortable: true,
  },
  {
    name: "currency",
    alias: i18n.t("crm_card_customer_currency"),
    sortable: true,
  },
  {
    name: "currentNumberOfOverdueDays",
    alias: i18n.t("crm_card_customer_current_number_of_overdue_days"),
    sortable: true,
  },
  {
    name: "customerId",
    alias: i18n.t("crm_card_customer_customer_id"),
    sortable: true,
  },
  {
    name: "customerName",
    alias: i18n.t("crm_card_customer_customer_name"),
    sortable: true,
  },
  {
    name: "dueAmount",
    alias: i18n.t("crm_card_customer_due_amount"),
    sortable: true,
  },
  {
    name: "status",
    alias: i18n.t("crm_card_customer_customer_status"),
    sortable: true,
  },
  {
    name: "interest",
    alias: i18n.t("crm_card_customer_interest"),
    sortable: true,
  },
  {
    name: "lastPaymentDate",
    alias: i18n.t("crm_card_customer_last_payment_date"),
    sortable: true,
  },
  {
    name: "latePaymentAmount",
    alias: i18n.t("crm_card_customer_late_payment_amount"),
    sortable: true,
  },
  {
    name: "mainInterestDueAmount",
    alias: i18n.t("crm_card_customer_main_interest_due_amount"),
    sortable: true,
  },
  {
    name: "maturityDate",
    alias: i18n.t("crm_card_customer_maturity_date"),
    sortable: true,
  },
  {
    name: "nextInstallmentAmount",
    alias: i18n.t("crm_card_customer_next_installment_amount"),
    sortable: true,
  },
  {
    name: "nextInstallmentDate",
    alias: i18n.t("crm_card_customer_next_installment_date"),
    sortable: true,
  },
  {
    name: "overdueInterestAmount",
    alias: i18n.t("crm_card_customer_overdue_interest_amount"),
    sortable: true,
  },
  {
    name: "overduePrincipalAmount",
    alias: i18n.t("crm_card_customer_overdue_principal_amount"),
    sortable: true,
  },
  {
    name: "penaltyAmount",
    alias: i18n.t("crm_card_customer_penalty_amount"),
    sortable: true,
  },
  {
    name: "principalDueAmount",
    alias: i18n.t("crm_card_customer_principal_due_amount"),
    sortable: true,
  },
  {
    name: "productAccount",
    alias: i18n.t("crm_card_customer_product_account"),
    sortable: true,
  },
  {
    name: "productName",
    alias: i18n.t("crm_card_customer_product_name"),
    sortable: true,
  },
  {
    name: "startDate",
    alias: i18n.t("crm_card_customer_start_date"),
    sortable: true,
  },
  {
    name: "term",
    alias: i18n.t("crm_card_customer_term"),
    sortable: true,
  },
];

export const nextProductColumns = [
  {
    name: "cif",
    alias: i18n.t("crm_card_next_product_cif"),
    sortable: true,
  },
  {
    name: "startDate",
    alias: i18n.t("crm_card_next_product_start_date"),
    sortable: true,
  },
  {
    name: "endDate",
    alias: i18n.t("crm_card_next_product_end_date"),
    sortable: true,
  },
  {
    name: "suggestions",
    alias: i18n.t("crm_card_next_product_suggestions"),
    sortable: true,
  },
];

export const identificationDocColumns = [
  {
    name: "serialNumber",
    alias: i18n.t("crm_card_customer_serial_number"),
    sortable: true,
  },
  {
    name: "documentType",
    alias: i18n.t("crm_card_customer_document_type"),
    sortable: true,
  },
  {
    name: "issuingDate",
    alias: i18n.t("crm_card_customer_issuing_date"),
    sortable: true,
  },
  {
    name: "maturityDate",
    alias: i18n.t("crm_card_customer_maturity_date"),
    sortable: true,
  },
  {
    name: "issuedByAuthority",
    alias: i18n.t("crm_card_customer_issued_by_authority"),
    sortable: true,
  },
  {
    name: "pinCode",
    alias: i18n.t("crm_card_customer_pincode"),
    sortable: true,
  },
];

export const addressColumns = [
  {
    name: "addressLine1",
    alias: i18n.t("crm_card_customer_address_line1"),
    sortable: true,
  },
  {
    name: "addressLine2",
    alias: i18n.t("crm_card_customer_address_line2"),
    sortable: true,
  },
  {
    name: "addressLine3",
    alias: i18n.t("crm_card_customer_address_line3"),
    sortable: true,
  },
  {
    name: "addressLine4",
    alias: i18n.t("crm_card_customer_address_line4"),
    sortable: true,
  },
  {
    name: "country",
    alias: i18n.t("crm_card_customer_country"),
    sortable: true,
  },
  {
    name: "city",
    alias: i18n.t("crm_card_customer_city"),
    sortable: true,
  },
  {
    name: "district",
    alias: i18n.t("crm_card_customer_district"),
    sortable: true,
  },
];

export const phonesColumns = [
  {
    name: "number",
    alias: i18n.t("crm_card_customer_number"),
    sortable: true,
  },
  {
    name: "type",
    alias: i18n.t("crm_card_customer_type"),
    sortable: true,
  },
  {
    name: "isd",
    alias: i18n.t("crm_card_customer_isd"),
    sortable: true,
  },
];

export const employeeInfoColumns = [
  {
    name: "currentEmployer",
    alias: i18n.t("crm_card_customer_current_employer"),
    sortable: true,
  },
  {
    name: "netSalary",
    alias: i18n.t("crm_card_customer_net_salary"),
    sortable: true,
  },
  {
    name: "totalExperience",
    alias: i18n.t("crm_card_customer_total_experience"),
    sortable: true,
  },
];

export const suggestionsColumns = [
  {
    name: "code",
    alias: i18n.t("crm_card_next_product_suggestions_code"),
    sortable: true,
  },
  {
    name: "name",
    alias: i18n.t("crm_card_next_product_suggestions_name"),
    sortable: true,
  },
  {
    name: "smsText",
    alias: i18n.t("crm_card_next_product_suggestions_sms_text"),
    sortable: true,
  },
];
