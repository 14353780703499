enum messageTypes {
  TEXT = "TEXT",
  AUDIO = "AUDIO",
  IMAGE = "IMAGE",
  APPLICATION = "APPLICATION",
  VIDEO = "VIDEO",
  STORY_MENTION = "STORY_MENTION",
  TEMPLATE = "TEMPLATE",
  SURVEY = "SURVEY",
}

export default messageTypes;
