/* eslint-disable react/prop-types */
import { styled } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { deepClone } from "Store/utility";

import Form from "./Form";

/**
 *  This components handles validation of any of form inputs
 */
export default function FormElement(Component) {
  return function WihFormElementComponent({ ...props }) {
    const [value, setValue] = useState(props.value);
    const [error, setError] = useState(false);
    const form = new Form();
    const { clickReset } = useSelector(
      (state: RootStateOrAny) => state.crmReducer,
    );
    const { fieldReset, tagsName } = useSelector(
      (state: RootStateOrAny) => state.taskReducer,
    );

    useEffect(() => {
      if (props.checkValidation) {
        form.validateValue({
          required: props.required,
          value,
          regex: props.regex,
          setError,
        });
      }
    }, [props.checkValidation, value]);

    useEffect(() => {
      switch (props.label) {
        case "Name":
        case "Last Name":
        case "Date of Birth":
        case "Registration DateTime":
        case "Age":
        case "Salary":
          setValue("");
          break;
        default:
          break;
      }
    }, [clickReset]);

    useEffect(() => {
      setValue(props.value);
    }, [props.value]);

    useEffect(() => {
      if (tagsName && tagsName.length > 0) {
        for (let i = 0; i < tagsName.length; i++) {
          tagsName[i] && tagsName[i].toLowerCase();
          props.label && props.label.toLowerCase();
          switch (props.label) {
            case tagsName[i]:
              setValue("");
              break;
            default:
              break;
          }
        }
      }
    }, [fieldReset, tagsName]);

    useEffect(() => {
      if (props.handleValidation) {
        props.handleValidation(error, props.id);
      }
    }, [error, props.checkValidation]);

    const childrenProps = deepClone(props);
    childrenProps.setValue = setValue;
    childrenProps.error = error;
    childrenProps.value = value;

    return (
      <FieldWrapper>
        <Component {...childrenProps} />
      </FieldWrapper>
    );
  };
}

const FieldWrapper = styled("div")({});
