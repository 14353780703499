import React, { useContext, useEffect, useState } from "react";

import {
  accountsColumns,
  addressColumns,
  cardsColumns,
  identificationDocColumns,
  loansColumns,
  personalColumns,
  phonesColumns,
  employeeInfoColumns,
  nextProductColumns,
  suggestionsColumns,
} from "../constants";
import { IbarIntegrationSearchStore } from "../IbarIntegrationSearchPanel";
import {
  IbarIntegrationTableHookProps,
  IIbarIntegrationSearchStore,
  IColumns,
} from "../interfaces";

export const useIbarIntegrationTable = (): IbarIntegrationTableHookProps => {
  const [columns, setColumns] = useState<IColumns[]>(null);
  const [data, setData] = useState<any>(null);

  const store: IIbarIntegrationSearchStore = useContext(
    IbarIntegrationSearchStore,
  );

  const rows = store.rows.get();
  const tabIndex = store.tabIndex.get();
  const loading = store.isLoading.get();

  const cellFormat = (cell, columns): JSX.Element => {
    if (Array.isArray(cell)) {
      return (
        <>
          {cell.map((item) => (
            <>
              {columns.map((column, idx) => (
                <p key={idx}>{`${column.alias}: ${
                  typeof item[column.name] !== "object"
                    ? item[column.name]
                    : item[column.name]?.name
                }`}</p>
              ))}
              <br />
            </>
          ))}
        </>
      );
    } else {
      return (
        <>
          {columns.map((column, idx) => (
            <p key={idx}>{`${column.alias}: ${cell[column.name]}`}</p>
          ))}
        </>
      );
    }
  };

  useEffect(() => {
    if (rows) {
      const contents = [];
      setData(rows);

      switch (tabIndex) {
        case 0:
          setColumns(personalColumns);
          rows &&
            rows.map((row, index) => {
              contents[index] = {
                ...row,
                identificationDoc: cellFormat(
                  row.identificationDoc,
                  identificationDocColumns,
                ),
                permanentAddress: cellFormat(
                  row.permanentAddress,
                  addressColumns,
                ),
                registrationAddress: cellFormat(
                  row.registrationAddress,
                  addressColumns,
                ),
                domicileAddress: cellFormat(
                  row.domicileAddress,
                  addressColumns,
                ),
                phones: cellFormat(row.phones, phonesColumns),
                employeeInfo: cellFormat(row.employeeInfo, employeeInfoColumns),
              };
            });
          setData(contents);
          break;
        case 1:
          setColumns(accountsColumns);
          break;
        case 2:
          setColumns(cardsColumns);
          break;
        case 3:
          setColumns(loansColumns);
          break;
        case 4:
          setColumns(nextProductColumns);
          Object.keys(rows).length > 0
            ? setData({
                ...rows,
                suggestions: cellFormat(rows.suggestions, suggestionsColumns),
              })
            : setData([]);
          break;
        default:
          setColumns(null);
          break;
      }
    } else {
      setColumns(null);
      setData(null);
    }
  }, [rows]);

  return {
    columns,
    data,
    loading,
  };
};

useIbarIntegrationTable.displayName = "useIbarIntegrationTable";
