import ISipLog from "Sip/interfaces/ISipLog";

export default class SipLog implements ISipLog {
  shortCut: string;
  webSqlDbName: string;
  webSqlTableName: string;
  db: any;
  keyShortCutForLogs: Array<string> = [];

  constructor(credentials) {
    this.shortCut = credentials.shortCut ? credentials.shortCut : "SIP";
    this.webSqlDbName = credentials.webSqlDbName
      ? credentials.webSqlDbName
      : "sip";
    this.webSqlTableName = credentials.webSqlTableName
      ? credentials.webSqlTableName
      : "logs";
    this.fetchSipLogs = credentials.fetchSipLogs
      ? credentials.fetchSipLogs
      : this.fetchSipLogs;
    document.onkeydown = this.KeyDown;
  }

  fetchSipLogs = () => [];

  checkLogs = () => {
    window.open();
  };

  KeyDown = (e) => {
    let evtobj = e;
    let shortCutKeysArray = ["Shift"];
    let shortCutKeysString = "Shift";
    const chars = [...this.shortCut];
    for (let i in chars) {
      shortCutKeysArray.push(chars[i]);
      shortCutKeysString += "," + chars[i];
    }
    if (shortCutKeysArray.indexOf(evtobj.key) !== -1 && evtobj.shiftKey) {
      this.keyShortCutForLogs.push(evtobj.key);
      if (
        new RegExp(shortCutKeysString, "g").test(
          this.keyShortCutForLogs.join(","),
        )
      ) {
        this.keyShortCutForLogs = [];
        this.fetchSipLogs();
      }
    } else {
      this.keyShortCutForLogs = [];
    }
  };
}
