/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { buttonTypes } from "Components/Buttons/buttonTypes";
import IconButton from "Components/Buttons/IconButton";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "relative",
      marginBottom: 12,
    },
    number: {
      margin: "0 auto",
      fontSize: 24,
      lineHeight: "182%",
      letterSpacing: "0.02em",
      color: "#050038",
    },
    button: {
      height: "64px",
      width: "64px",
      backgroundColor: "rgb(210, 210, 210)",
    },
  }),
);

interface IDigit {
  number: number;
  handleClick?: Function;
}

export default function Digit(props: IDigit) {
  const classes = useStyles();

  const handleClick = (number: number) => {
    if (props.handleClick) props.handleClick(number);
  };

  return useMemo(
    () => (
      <div className={classes.root}>
        <IconButton
          className={classes.button}
          onClick={() => handleClick(props.number)}
          Icon={() => <span className={classes.number}>{props.number}</span>}
          buttonType={buttonTypes.default}
        />
      </div>
    ),
    [props.number, props.handleClick],
  );
}
