import { styled } from "@material-ui/core";

import {
  getSMSTemplates,
  searchByAll,
  searchByText,
  searchByTitle,
} from "Api/sms";

import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { RequestContextStore } from "Components/Layout/AppLayout";
import LoadingComponent from "Components/Loading/LoadingComponent";

import useUpdateEffect from "Hook/useUpdateEffect";
import Notification from "Notifications/Notification";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import Sms from "Store/Actions/Sms";
import {
  defaultStates as defaultSmsStates,
  PER_PAGE,
} from "Store/Reducers/smsReducer";

import ChosenTemplate from "./ChosenTemplate";
import constants from "./constants";
import Search from "./Search";
import TemplateLists from "./TemplateLists";
import GeneratorTabs from "Pages/GeneratorTabs";

const SmsTemplates = (): React.ReactElement => {
  const { translate } = useTranslation();
  const totalSmsState = useSelector(
    (state: RootStateOrAny) => state.smsReducer,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const sms = new Sms(useDispatch());

  const requestContextStore: any = useContext(RequestContextStore);
  const smsState = requestContextStore.getCurrentStateFromTotalState(
    totalSmsState,
    defaultSmsStates,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const showErrorModal = (text): void => {
    Notification.error(text);
  };

  const fetchTemplates = async (
    page: number = smsState.pagination.page,
    size: number = smsState.pagination.size,
  ): Promise<void> => {
    const type = requestState.menuType,
      requestId = requestContextStore.getRequestId();
    setIsLoading(true);

    return getSMSTemplates(page, size)
      .then((response) => {
        setIsLoading(false);
        if (response) {
          const { content, totalElements, totalPages } = response;

          sms.setAllTemplates(type, requestId, content);
          sms.setPagination(type, requestId, {
            totalElements,
            totalPages,
            currentInterval: `${(page + 1) * size - PER_PAGE + 1} - ${
              page * size + content.length
            }, ${totalElements}`,
          });
        } else setError(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onSearchByTitle = async (
    title: string,
    page: number = smsState.pagination.page,
    size: number = smsState.pagination.size,
  ): Promise<void> => {
    const type = requestState.menuType,
      requestId = requestContextStore.getRequestId();
    setIsLoading(true);
    sms.setIsSearching(type, requestId, true);

    try {
      const res = await searchByTitle(title, page, size);
      if (res) {
        setIsLoading(false);
        sms.setIsSearching(type, requestId, false);
        const { content, totalElements, totalPages } = res;

        sms.setAllTemplates(type, requestId, content);
        sms.setPagination(type, requestId, {
          page,
          size,
          totalElements,
          totalPages,
          currentInterval: `${(page + 1) * size - PER_PAGE + 1} - ${
            page * size + content.length
          }, ${totalElements}`,
        });
      } else throw new Error();
    } catch (e) {
      setIsLoading(false);
      sms.setIsSearching(type, requestId, false);
      showErrorModal(translate("error_occurred"));
    }
  };

  const onSearchByText = async (
    title: string,
    page: number = smsState.pagination.page,
    size: number = smsState.pagination.size,
  ): Promise<void> => {
    const type = requestState.menuType,
      requestId = requestContextStore.getRequestId();
    setIsLoading(true);
    sms.setIsSearching(type, requestId, true);

    try {
      const res = await searchByText(title, page, size);
      if (res) {
        setIsLoading(false);
        sms.setIsSearching(type, requestId, false);
        const { content, totalElements, totalPages } = res;

        sms.setAllTemplates(type, requestId, content);
        sms.setPagination(type, requestId, {
          page,
          size,
          totalElements,
          totalPages,
          currentInterval: `${(page + 1) * size - PER_PAGE + 1} - ${
            page * size + content.length
          }, ${totalElements}`,
        });
      } else throw new Error();
    } catch (e) {
      setIsLoading(false);
      sms.setIsSearching(type, requestId, false);
      showErrorModal(translate("error_occurred"));
    }
  };

  const onSearchByAll = async (
    title: string,
    page: number = smsState.pagination.page,
    size: number = smsState.pagination.size,
  ): Promise<void> => {
    const type = requestState.menuType,
      requestId = requestContextStore.getRequestId();
    setIsLoading(true);
    sms.setIsSearching(type, requestId, true);

    try {
      const res = await searchByAll(title, page, size);
      if (res) {
        setIsLoading(false);
        sms.setIsSearching(type, requestId, false);
        const { content, totalElements, totalPages } = res;

        sms.setAllTemplates(type, requestId, content);
        sms.setPagination(type, requestId, {
          page,
          size,
          totalElements,
          totalPages,
          currentInterval: `${(page + 1) * size - PER_PAGE + 1} - ${
            page * size + content.length
          }, ${totalElements}`,
        });
      } else throw new Error("");
    } catch (e) {
      setIsLoading(false);
      sms.setIsSearching(type, requestId, false);
      showErrorModal(e);
    }
  };

  useUpdateEffect(() => {
    const { search, onSearch, searchString, pagination, isSearching } =
      smsState;
    if (onSearch && searchString !== "") {
      if (search === constants.searchBy.title)
        onSearchByTitle(searchString, pagination.page);
      else if (search === constants.searchBy.text)
        onSearchByText(searchString, pagination.page);
      else if (search === constants.searchBy.all)
        onSearchByAll(searchString, pagination.page);
    } else !isSearching && fetchTemplates();
  }, [smsState.pagination.page]);

  useEffect(() => {
    fetchTemplates();
  }, [requestState.menuType, requestContextStore.getRequestId()]);

  return (
    <>
      <GeneratorTabs />
      <Container>
        {!error && (
          <Main>
            <Search
              fetchTemplates={fetchTemplates}
              onSearchByTitle={onSearchByTitle}
              onSearchByText={onSearchByText}
              onSearchByAll={onSearchByAll}
            />
            <TemplateLists />
          </Main>
        )}

        {smsState.chosenId !== -1 && (
          <ChosenTemplate isLoading={isLoading} setIsLoading={setIsLoading} />
        )}
        {isLoading ? <LoadingComponent /> : null}
      </Container>
    </>
  );
};

export default SmsTemplates;

const Container = styled("div")({
  height: "calc(100vh - var(--nav-bar-height) - 100px)",
});

const Main = styled("div")({
  height: "calc(100vh - var(--nav-bar-height) - 240px)",
});
