import {
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Box,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import FormElement from "./FormElement";

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: "100%",
      display: "inherit",
    },
  }),
);

const FormRadio = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(props.value ? props.value[0] : false);

  const handleChange = (_event, value) => {
    setValue(value);
    props.handleChange(value, props.id, props.label);
  };

  return (
    <FormControl variant='filled' className={classes.formControl} error={props.error}>
      <RadioGroup
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='row-radio-buttons-group'
        value={value}
        onChange={handleChange}>
        {props.options.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.value}
            control={<Radio required={props.required} />}
            label={<Box color={props.error? "red" : "inherit"}>{option.value}</Box>}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{props.errorText}</FormHelperText>
    </FormControl>
  );
};

export default FormElement(FormRadio);
