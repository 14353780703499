/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import menuTypes from "Assets/constants/menuTypes";
import { useCallback } from "react";

import { crmActions as actionTypes } from "./actionTypes";
import { ICompany } from "./interfaces";
import { customerTypes } from "../../Components/Request/LeftPanel/constants/customerTypes";

export default class Crm {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setCompanies = useCallback(
    (menuType: menuTypes, requestId: number, companies: Array<ICompany>) =>
      this.dispatch({
        type: actionTypes.SET_COMPANIES,
        companies: companies,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  addCompany = useCallback(
    (menuType: menuTypes, requestId: number, company: ICompany) =>
      this.dispatch({
        type: actionTypes.ADD_COMPANY,
        company: company,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setFormFields = useCallback(
    (menuType: menuTypes, requestId: number, formFields: any) =>
      this.dispatch({
        type: actionTypes.SET_FORM_FIELDS,
        formFields,
        menuType,
        requestId,
      }),
    [this],
  );

  setIdentifiers = useCallback(
    (menuType: menuTypes, requestId: number, identifiers: any) =>
      this.dispatch({
        type: actionTypes.SET_IDENTIFIERS,
        identifiers,
        menuType,
        requestId,
      }),
    [this],
  );

  setOptionsByDictionaryId = useCallback(
    (
      menuType: menuTypes,
      requestId: number,
      dictionaryId: number,
      options: any,
    ) =>
      this.dispatch({
        type: actionTypes.SET_OPTIONS_BY_DICTIONARY_ID,
        options: options,
        dictionaryId: dictionaryId,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setCheckValidation = useCallback(
    (
      menuType: menuTypes,
      requestId: number,
      checkValidationGroup: customerTypes,
    ) =>
      this.dispatch({
        type: actionTypes.SET_CHECK_VALIDATION_GROUP,
        checkValidationGroup: checkValidationGroup,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setFormIsValid = useCallback(
    (menuType: menuTypes, requestId: number, formIsValid: boolean) =>
      this.dispatch({
        type: actionTypes.SET_FORM_IS_VALID,
        formIsValid: formIsValid,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  addCustomField = useCallback(
    (menuType: menuTypes, requestId: number, customerType: customerTypes) =>
      this.dispatch({
        customerType,
        type: actionTypes.ADD_CUSTOM_FIELD,
        menuType,
        requestId,
      }),
    [this],
  );

  removeLastField = useCallback(
    (menuType: menuTypes, requestId: number, customerType: customerTypes) =>
      this.dispatch({
        customerType: customerType,
        type: actionTypes.REMOVE_LAST_FIELD,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setProfileImage = useCallback(
    (
      menuType: menuTypes,
      requestId: number,
      profileImage: string | ArrayBuffer,
    ) =>
      this.dispatch({
        type: actionTypes.SET_PROFILE_IMAGE,
        profileImage,
        menuType,
        requestId,
      }),
    [this],
  );

  setFieldValue = useCallback(
    (
      menuType: menuTypes,
      requestId: number,
      id: string,
      fieldName: string,
      value: any,
    ) =>
      this.dispatch({
        type: actionTypes.SET_FIELD_VALUE,
        id: id,
        fieldName: fieldName,
        value: value,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setFieldPlaceHolder = useCallback(
    (menuType: menuTypes, requestId: number, id: string, value: string) =>
      this.dispatch({
        type: actionTypes.SET_FIELD_PLACEHOLDER,
        id: id,
        value: value,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setCustomerId = useCallback(
    (menuType: menuTypes, requestId: number, customerId: number) =>
      this.dispatch({
        type: actionTypes.SET_CUSTOMER_ID,
        customerId: customerId,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setCustomerData = useCallback(
    (
      menuType: menuTypes,
      requestId: number,
      identifiers: any,
      customerId: number,
      formFields: any,
      photo: string,
      companies: any,
    ) =>
      this.dispatch({
        type: actionTypes.SET_CUSTOMER_DATA,
        customerId,
        identifiers,
        formFields,
        photo,
        menuType,
        requestId,
        companies,
      }),

    [this],
  );

  setClickReset = useCallback(
    (clickReset: number) =>
      this.dispatch({
        type: actionTypes.CLICK_RESET,
        clickReset: clickReset,
      }),
    [this],
  );

  setCustomerCif = useCallback(
    (customerCif: string | null) =>
      this.dispatch({
        type: actionTypes.CUSTOMER_CIF,
        customerCif: customerCif,
      }),
    [this],
  );

  setAccountNumber = useCallback(
    (accountNumber: string | null) =>
      this.dispatch({
        type: actionTypes.ACCOUNT_NUMBER,
        accountNumber: accountNumber,
      }),
    [this],
  );

  setIdentifierValue = useCallback(
    (
      menuType: menuTypes,
      requestId: number,
      value: string,
      id: any,
      label: string,
    ) =>
      this.dispatch({
        type: actionTypes.SET_IDENTIFIER_VALUE,
        id: id,
        label: label,
        value: value,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setValuesToFormFieldsByCrmTableData = useCallback(
    (menuType: menuTypes, requestId: number, data: any) =>
      this.dispatch({
        type: actionTypes.SET_VALUE_TO_FROM_FIELDS_BY_CRM_TABLE_DATA,
        data: data,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setDefaultFields = useCallback(
    (defaultFields: any) =>
      this.dispatch({
        type: actionTypes.SET_DEFAULT_FIELDS,
        defaultFields: defaultFields,
      }),
    [this],
  );

  showCustomerSearch = useCallback(
    (customerSearchPanelIsShown: boolean) =>
      this.dispatch({
        type: actionTypes.SHOW_CUSTOMER_SEARCH,
        customerSearchPanelIsShown: customerSearchPanelIsShown,
      }),
    [this],
  );

  setCopyAttachments = useCallback(
    (attachmentsData: Array<any>) =>
      this.dispatch({
        type: actionTypes.COPY_ATTACHMENTS,
        attachmentsData: attachmentsData,
      }),
    [this],
  );

  setCopyAnswer = useCallback(
    (copyAnswer: string) =>
      this.dispatch({
        type: actionTypes.COPY_ANSWER,
        copyAnswer: copyAnswer,
      }),
    [this],
  );

  setCopyOnclick = useCallback(
    (copyIconOnclick: boolean) =>
      this.dispatch({
        type: actionTypes.COPY_ICON_ONCLICK,
        copyIconOnclick: copyIconOnclick,
      }),
    [this],
  );

  clearAllData = useCallback(
    (menuType: menuTypes, requestId: number) =>
      this.dispatch({
        type: actionTypes.CLEAR_ALL_DATA,
        menuType,
        requestId,
      }),
    [this],
  );

  removeEndedConversation = useCallback(
    (menuType: menuTypes, requestId: number) =>
      this.dispatch({
        type: actionTypes.REMOVE_ENDED_CONVERSATION,
        menuType,
        requestId,
      }),
    [this],
  );
}
