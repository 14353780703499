import { TextField } from "@material-ui/core";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import clsx from "clsx";
import FormSelect from "Components/Form/FormSelect";
import LoadingComponent from "Components/Loading/LoadingComponent";
import React from "react";

import CustomField from "./CustomField";
import useJira from "./hooks/useJira";
import { useJiraStyles, Container, Header, PostButton } from "./styles";

const Jira = (): React.ReactElement => {
  const classes = useJiraStyles();
  const { translate } = useTranslation();

  const {
    projects,
    handleTicket,
    issueTypes,
    customFields,
    handleCustomField,
    handleSave,
    ticket,
    loading,
    convertFileToIcon,
    getRootProps,
    getInputProps,
  } = useJira();

  return (
    <Container>
      <Header>{translate("request_jira_header")}</Header>
      <div className={classes.input}>
        <FormSelect
          placeHolder={translate("request_jira_field_project")}
          value={ticket.project}
          handleChange={(values) => {
            handleTicket("project", values);
          }}
          required={false}
          options={projects}
          hideAddNewOption={false}
          optionCanBeAdded={false}
        />
      </div>
      <div className={classes.input}>
        <FormSelect
          placeHolder={translate("request_jira_field_issue_type")}
          value={ticket.issueType}
          handleChange={(values) => {
            handleTicket("issueType", values);
          }}
          required={false}
          options={issueTypes}
          hideAddNewOption={false}
          optionCanBeAdded={false}
        />
      </div>
      <div className={classes.customFieldContainer}>
        {customFields.map((field) => (
          <CustomField
            key={field.id}
            id={field.id}
            name={field.name}
            type={field.schema?.type}
            onChange={(id, value) => {
              handleCustomField(id, value);
            }}
          />
        ))}
      </div>
      <section>
        <aside>
          <span className={classes.attachment}>
            {ticket.attachments &&
              ticket.attachments?.map((item, index) => {
                let fileName = item.path;
                if (item instanceof File) fileName = item.name;
                return convertFileToIcon(fileName, index);
              })}
          </span>
        </aside>
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />
          <p>{translate("tasks_drag_n_drop")}</p>
        </div>
      </section>
      <div className={classes.input}>
        <TextField
          placeholder={translate("request_jira_field_summary")}
          value={ticket.summary}
          onChange={(e) => {
            handleTicket("summary", e.target.value);
          }}
          multiline
          minRows={4}
          variant='outlined'
          fullWidth
        />
      </div>

      <PostButton
        onClick={handleSave}
        className={clsx({
          ["disabled"]: ticket.project === null || ticket.issueType === null,
        })}>
        Save
      </PostButton>
      {loading && <LoadingComponent />}
    </Container>
  );
};

export default Jira;

Jira.displayName = "Jira";
