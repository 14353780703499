import { Grid, AppBar, Toolbar } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import React, { ReactElement } from "react";

import AgentDailyInfo from "./AgentDailyInfo";
import Logo from "./Logo";
import NavigationBar from "./NavigationBar";
import Profile from "./Profile";
import StatusBox from "./StatusBox";

const NavBar = (): ReactElement => (
  <StyledAppBar color='transparent' position='static'>
    <StyledToolBar>
      <Grid container>
        <Grid item xs={1}>
          <Logo />
        </Grid>
        <Grid item xs={7} style={{ marginLeft: 20 }}>
          <NavigationBar />
        </Grid>
        <Grid item xs={2}>
          <StatusBox />
        </Grid>
        <Grid item xs={1}>
          <AgentDailyInfo />
        </Grid>
        <Grid item xs={1}>
          <Profile />
        </Grid>
      </Grid>
    </StyledToolBar>
  </StyledAppBar>
);

export default NavBar;

const StyledAppBar = styled(AppBar)({
  backgroundColor: "#363F4F",
  maxHeight: "var(--nav-bar-height)",
  color: "aliceblue",
  fontFamily: "Open Sans !important",
  padding: "0 20px",
});

const StyledToolBar = styled(Toolbar)({
  paddingLeft: 7,
  paddingRight: 7,
});
