import sessionStatuses from "Assets/constants/sessionStatuses";
import { allowedRoutes, availablePages } from "Config/config";
import Pages from "Pages/Pages";
import React from "react";

import { useSelector, RootStateOrAny } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

/**
 * Component that handles auth redirection logic and renders Pages wrapped with withRouter hoc
 */
export const Container = (props) => {
  const authState = useSelector((state: RootStateOrAny) => state.authReducer);

  const redirectIfUserIsNotAuthentificated = (pathname: string): any => {
    if (
      authState.sessionStatus === sessionStatuses.inactive &&
      Object.values(allowedRoutes).indexOf(pathname) === -1
    ) {
      sessionStorage.getItem("logOut") !== "true" &&
        localStorage.setItem("locationHref", window.location.href);
      return <Redirect to={allowedRoutes.login} />;
    }

    return null;
  };

  const redirectIfUserIsAlreadyAuthentificated = (pathname: string): any => {
    if (
      authState.sessionStatus === sessionStatuses.active &&
      Object.values(allowedRoutes).indexOf(pathname) >= 0
    ) {
      const pathSegments =
        localStorage.getItem("locationHref") !== null
          ? localStorage
              .getItem("locationHref")
              .split("/")
              .filter((segment) => segment !== "")
          : [];
      const urlText = pathSegments?.slice(2, 4).join("/");
      const taskId = pathSegments[pathSegments?.length - 1];
      sessionStorage.setItem("taskId", taskId !== undefined ? taskId : "");
      localStorage.setItem("locationHref", "");
      sessionStorage.setItem("logOut", "");
      return (
        <Redirect
          to={
            urlText === availablePages.myTasksUrl ||
            urlText === availablePages.allTasksUrl
              ? availablePages.myTasksUrl
              : availablePages.index
          }
        />
      );
    }

    return null;
  };

  return (
    <div className='d-flex flex-grow-1'>
      {redirectIfUserIsNotAuthentificated(props.location.pathname)}
      {redirectIfUserIsAlreadyAuthentificated(props.location.pathname)}
      <PagesWithHistory />
    </div>
  );
};

const PagesWithHistory = withRouter(Pages);
const ContainerWithHistory = withRouter(Container);

export default ContainerWithHistory;
