import React from "react";
import { styled } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { CloseIcon } from "./Icons";
import SoftPhoneStore from "Store/Actions/SoftPhone";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

export default function Header() {
  const { translate } = useTranslation();
  const softPhoneStore = new SoftPhoneStore(useDispatch());
  let sipNumber = localStorage.getItem("sipNumber");

  const handleCloseButton = () => {
    softPhoneStore.toggle(false);
  };

  return (
    <HeaderContainer>
      <SipName>
        {translate("softphone_sip")} {sipNumber}
      </SipName>
      <ActionContainer>
        <CloseButton onClick={handleCloseButton} />
      </ActionContainer>
    </HeaderContainer>
  );
}

const HeaderContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minHeight: "var(--softphone-header-height)",
  paddingLeft: "15px",
  paddingRight: "10px",
  width: "90%",
  margin: "0 auto",
  marginTop: 10,
  color: "#050038",
});

const SipName = styled("span")({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  color: "#050038",
});

const ActionContainer = styled("div")({
  backgroundColor: "inherit",
  float: "right",
  color: "#050038",
});

const CloseButton = styled(CloseIcon)({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 17,
  letterSpacing: "0.04em",
  cursor: "pointer",
});
