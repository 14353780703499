import { TextField, createStyles, makeStyles } from "@material-ui/core";
import FormTimePicker from "Components/Form/FormTimePicker";
import useUpdateEffect from "Hook/useUpdateEffect";
import React, { useState } from "react";
import fieldTypes from "./fieldTypes";
import FormDateTimePicker from "./FormDateTimePicker";
import FormFieldWrapper from "./FormFieldWrapper";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

interface IFormInputDiapason {
  size?: "small" | "medium";
  fieldType?: fieldTypes;
  id?: any;
  value?: string;
  regex?: string;
  required?: boolean;
  errorText?: string;
  handleChange?: Function;
  handleFromChange?: Function;
  handleToChange?: Function;
  [x: string]: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      width: "49%",
      "& input:focus": {
        boxShadow: "0 0 1px 1px white !important",
      },
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
    },
  }),
);

/**
 * Standard form input.
 *
 * @visibleName FormInput
 */
export function FormInputDiapason(props: IFormInputDiapason) {
  const { translate } = useTranslation();
  const classes = useStyles();
  const size = props.size ?? "medium";
  const variant = props.variant ?? "outlined";
  const fieldType = props.fieldType ?? "text";
  const type = props.fieldType === fieldTypes.NUMBER ? "number" : "text";
  const [error] = useState(false);

  const [fromValue, setFromValue] = useState(
    props.value && props.value[0] ? props.value[0] : "",
  );
  const [toValue, setToValue] = useState(
    props.value && props.value[1] ? props.value[1] : "",
  );

  useUpdateEffect(() => {
    if (props.value && props.value[0]) {
      setFromValue(props.value[0]);
    }
    if (props.value && props.value[1]) {
      setToValue(props.value[1]);
    }
  }, [props.value]);

  const handleFromChange = (value) => {
    //disable float in number type
    if (fieldType === fieldTypes.NUMBER && isNaN(parseInt(value.substr(-1)))) {
      value = value.substr(0, value.length - 1);
    }
    setFromValue(value);
    if (props.handleChange)
      props.handleChange([value, toValue], props.id, props.label);
  };

  const handleToChange = (value) => {
    //disable float in number type
    switch (fieldType) {
      case fieldTypes.NUMBER:
        if (isNaN(parseInt(value.substr(-1)))) {
          value = value.substr(0, value.length - 1);
        }
        break;
    }

    setToValue(value);
    if (props.handleChange)
      props.handleChange([fromValue, value], props.id, props.label);
  };

  const handleKeyDown = (event) => {
    if (fieldType === fieldTypes.NUMBER && event.key === ".") {
      event.preventDefault();
    }
  };

  const defaultProps = {
    type: type,
    label: props.label,
    required: props.required,
    error,
    size: size,
    variant: variant,
    onKeyDown: (e) => handleKeyDown(e),
    classes: { root: classes.input },
    InputLabelProps: {
      shrink: true,
    },
  };

  let fromElement = null;
  let toElement = null;

  switch (fieldType) {
    case fieldTypes.NUMBER:
      fromElement = (
        <TextField
          type={"number"}
          {...defaultProps}
          placeholder={translate("form_from")}
          value={fromValue || ""}
          onChange={(e) => handleToChange(e.target.value)}
        />
      );
      toElement = (
        <TextField
          type={"number"}
          {...defaultProps}
          onChange={(e) => handleToChange(e.target.value)}
          value={toValue || ""}
          placeholder={translate("form_from")}
        />
      );
      break;
    case fieldTypes.TIME_RANGE:
      fromElement = (
        <FormTimePicker
          {...defaultProps}
          label={defaultProps.label + " " + translate("min_duration")}
          placeholder={translate("form_from")}
          value={fromValue || ""}
          handleChange={handleFromChange}
        />
      );
      toElement = (
        <FormTimePicker
          {...defaultProps}
          label={defaultProps.label + " " + translate("max_duration")}
          handleChange={handleToChange}
          value={toValue || ""}
          placeholder={translate("form_from")}
        />
      );
      break;
    case fieldTypes.DATETIME_RANGE:
      fromElement = (
        <FormDateTimePicker
          {...defaultProps}
          placeholder={translate("form_from")}
          value={fromValue || ""}
          handleChange={handleFromChange}
        />
      );
      toElement = (
        <FormDateTimePicker
          {...defaultProps}
          handleChange={handleToChange}
          value={toValue || ""}
          placeholder={translate("form_from")}
        />
      );
      break;
    default:
      fromElement = (
        <TextField
          {...defaultProps}
          placeholder={translate("form_from")}
          value={fromValue || ""}
          onChange={(e) => handleFromChange(e.target.value)}
        />
      );
      toElement = (
        <TextField
          {...defaultProps}
          onChange={(e) => handleToChange(e.target.value)}
          value={toValue || ""}
          placeholder={translate("form_from")}
        />
      );
      break;
  }

  return (
    <FormFieldWrapper>
      <span className={classes.container}>
        {fromElement}
        {toElement}
      </span>
    </FormFieldWrapper>
  );
}
export default FormInputDiapason;
