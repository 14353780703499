import { FormControl, styled, Grid } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import FormElement from "Components/Form/FormElement";

import React from "react";

import { useFormSelect } from "./hooks";
import { IFormSelect, IOption } from "./interfaces";
import { useSelectInputStyles } from "./styles";

const filter = createFilterOptions<IOption>();

const FormSelect = (props: IFormSelect): React.ReactElement => {
  const classes = useSelectInputStyles();
  const {
    localLabel,
    open,
    loading,
    options,
    handleChange,
    setOpen,
    getRenderedOption,
    getRenderedInput,
    chooseIcon,
  } = useFormSelect(props);

  return (
    <Container>
      <Label>{localLabel}</Label>
      <Grid container>
        <Grid xs={11}>
          <FormControl variant='filled' className={classes.formControl}>
            <Autocomplete
              onChange={handleChange}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const filteredIncludesInputValue =
                  filtered.filter((option) => {
                    return option.name.toLowerCase() === params.inputValue;
                  }).length === 0;
                if (
                  props.hideAddNewOption === false &&
                  props.optionCanBeAdded === true &&
                  params.inputValue !== "" &&
                  filteredIncludesInputValue === true
                )
                  filtered.push({
                    inputValue: params.inputValue,
                    name: params.inputValue,
                    value: null,
                  });

                return filtered;
              }}
              open={open}
              onOpen={() => {
                if (props.onOpen) props.onOpen();

                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              loading={loading}
              getOptionSelected={(option, value) => option.name === value.name}
              size={props.size ?? "small"}
              multiple={props.multiple}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              value={props.value}
              classes={{
                root: props.error ? classes.errorRoot : classes.root,
                inputFocused: classes.inputFocused,
                listbox: classes.listbox,
              }}
              options={options ? options : []}
              getOptionLabel={(option) => {
                if (typeof option === "string") return option;
                if (option.inputValue) return option.inputValue;

                return option.name;
              }}
              renderOption={(option) => getRenderedOption(option)}
              freeSolo
              renderInput={(params) => getRenderedInput(params)}
            />
          </FormControl>
        </Grid>
        <Grid xs={1}>{chooseIcon()}</Grid>
      </Grid>
    </Container>
  );
};

export default FormElement(FormSelect);

FormSelect.displayName = "FormSelect";

const Container = styled("div")({
  display: "inline-block",
  width: "90%",
  float: "left",
  marginRight: 15,
  marginLeft: 15,
});

const Label = styled("span")({
  color: "#414147",
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  display: "block",
  textAlign: "left",
  marginBottom: 10,
  marginTop: 10,
});
