export const availablePages = {
  index: "",
  customers: "/crm/customers",
  companies: "/crm/companies",

  requestTable: "/request/requestTable",

  categories: "/generator/faq",
  smsTemplates: "/generator/sms",
  classificators: "/generator/classificators",
  messageTemplates: "/generator/message-templates",
  autoReplyMessage: "/generator/auto-reply-message",
  preDefinedMessage: "/generator/predefined-message",

  classificatorReport: "/report/classificator",
  queueReport: "/report/queue",
  callQueueReport: "/report/call-queue",
  chatQueueReport: "/report/chat-queue",
  agentWorkReport: "/report/agent-work",
  summaryCallReport: "/report/summary-call",
  summaryChatReport: "/report/summary-chat",
  callReport: "/report/call",
  agentCallReport: "/report/agent-call",
  traceByLocationReport: "/report/trace-by-location",
  missedCallReport: "/report/missed-call",
  taskReport: "/report/task",
  task2Report: "/report/task2",
  agentSessionReport: "/report/agent-session",
  agentSMMReport: "/report/agent-smm",
  chatReport: "/report/chat",
  smsSenderReport: "/report/sms-sender",
  fcrReport: "/report/fcr",
  ivrReport: "/report/ivr",
  crmDataReport: "/report/crm-data",

  userManagement: "/configuration/userManagement",
  roleManagement: "/configuration/roleManagement",
  breakManagement: "/configuration/breakManagement",
  taskManagement: "/configuration/taskManagement",
  callbackManagement: "/configuration/callbackManagement",
  ivrAnnounceManagement: "/configuration/ivrAnnounceManagement",
  dictionaryManagement: "/configuration/dictionaryManagement",
  queueManagement: "/configuration/queueManagement",
  queueManagementVoip: "/configuration/queueManagement/0",
  queueManagementOmni: "/configuration/queueManagement/1",

  breaks: "/breaks",
  callback: "/callback",
  callbackManual: "/callback/callbackManual",

  tasks: "/tasks/:tabIndex",
  myTasks: "/tasks/:tabIndex",
  myTasksUrl: "tasks/0",
  allTasksUrl: "tasks/1",
  allTasks: "/tasks/:tabIndex",
  myClosedTasks: "/tasks/:tabIndex",
  allClosedTasks: "/tasks/:tabIndex",

  dashboards: "/monitoring/dashboards",
  dashboard: "/monitoring/dashboard/:id",

  spyCall: "/spyCall",
  socialMedia: "/socialMedia",
  ivrSurvey: "/evaluation/survey",
  configuration: "/evaluation/configuration",
  qualityControl: "/evaluation/qualityControl",
  callCriteria: "/evaluation/callCriteria",
  chatCriteria: "/evaluation/chatCriteria",
  admin: "/evaluation/admin",
  operation: "/evaluation/operation",

  jiraConfiguration: "/jira/configuration",
  jiraTickets: "/jira/tickets",
  jiraPermissions: "/jira/permissions",
  jiraReport: "/jira/report",
};

export const allowedRoutes = {
  alreadyAuthorized: "/alreadyauthorized",
  login: "/login",
  registration: "/registration",
  resetPassword: "/resetpassword",
};

export const permissions = {
  main_menu_crm: "main_menu_crm",
  main_menu_requests: "main_menu_requests",
  main_menu_generator: "main_menu_generator",
  main_menu_management: "main_menu_management",
  main_menu_configuration: "main_menu_configuration",
  main_menu_reports: "main_menu_reports",
  main_menu_smm: "main_menu_smm",
  main_menu_evaluation: "main_menu_evaluation",
  main_menu_monitoring: "main_menu_monitoring",
  main_menu_jira: "main_menu_jira",

  faq_categories_page: "faq_categories_page",
  faq_category_add: "faq_category_add",
  faq_category_edit: "faq_category_edit",
  faq_category_delete: "faq_category_delete",
  faq_category_drag_and_drop: "faq_category_drag_and_drop",
  faq_category_clone: "faq_category_clone",
  faq_category_activate_or_deactivate: "faq_category_activate_or_deactivate",
  faq_add_question: "faq_add_question",
  faq_edit_question: "faq_edit_question",
  faq_delete_question: "faq_delete_question",

  request_info: "request_info",
  request_edit: "request_edit",
  request_play_voice_record: "request_play_voice_record",
  request_table_page: "request_table_page",
  request_my_requests_tab: "request_my_requests_tab",
  request_all_requests_tab: "request_all_requests_tab",
  request_atb_customer_search: "request_atb_customer_search",
  request_ibar_customer_search: "request_ibar_customer_search",
  request_classfier_note: "request_classfier_note",
  last_call_requests: "last_call_requests",
  request_multiple_conversation_forward:
    "request_multiple_conversation_forward",

  report_classificator: "report_faq",
  report_queue: "report_queue",
  report_call_queue: "report_call_queue",
  report_chat_queue: "report_chat_queue",
  report_agent_work: "report_agent_work",
  report_summary_call: "report_summary_call",
  report_summary_chat: "report_summary_chat",
  report_call: "report_call",
  report_agent_call: "report_agent_call",
  report_agent_session: "report_agent_session",
  report_agent_chat: "report_agent_chat",
  report_chat: "report_chat",
  report_trace_by_location: "report_trace_by_location",
  report_missed_call: "report_missed_call",
  report_task: "report_task",
  report_task2: "report_task2",
  sms_sender_report: "sms_sender_report",
  report_fcr: "report_fcr",
  report_ivr: "report_ivr",
  report_crm_data: "report_crm_data",

  break_permission_management: "break_permission_management",
  break_permission_operations: "break_permission_operations",

  configuration_user_management: "configuration_user_management",
  configuration_role_management: "configuration_role_management",
  configuration_break_management: "configuration_break_management",
  configuration_task_management: "configuration_task_management",
  configuration_user_create: "configuration_user_create",
  configuration_user_edit: "configuration_user_edit",
  configuration_user_delete: "configuration_user_delete",
  configuration_user_reset_password: "configuration_user_reset_password",
  configuration_user_activation: "configuration_user_activation",
  configuration_user_add_queue: "configuration_user_add_queue",
  configuration_user_add_conversation_count:
    "configuration_user_add_conversation_count",
  configuration_ivr_announce: "configuration_ivr_announce",
  configuration_queue_management: "configuration_queue_management",
  configuration_queue_management_omnichannel:
    "configuration_queue_management_omnichannel",
  configuration_queue_management_voip: "configuration_queue_management_voip",
  configuration_close_sip_websocket: "configuration_close_sip_websocket",
  authentication_mfa_disable_button: "authentication_mfa_disable_button",
  authentication_enroll_google_auth: "authentication_enroll_google_auth",

  get_user_queues: "get_user_queues",

  callback_admin: "callback_admin",
  callback_tab_menu: "callback_tab_menu",
  callback_missed_calls: "callback_missed_calls",
  callback_manual: "callback_manual",

  task_mngmt_adding_category: "task_mngmt_adding_category",
  task_mngmt_editing_category: "task_mngmt_editing_category",
  task_mngmt_deleting_category: "task_mngmt_deleting_category",
  task_mngmt_activate_deactivate_category:
    "task_mngmt_activate_deactivate_category",
  task_mngmt_all_tasks: "task_mngmt_all_tasks",
  task_mngmt_tasks_page: "task_mngmt_tasks_page",
  task_mngmt_all_closed_tasks: "task_mngmt_all_closed_tasks",
  task_emails: "task_emails",
  task_action_button: "task_action_button",
  task_detail_info_category: "task_detail_info_category",
  task_detail_info_content: "task_detail_info_content",
  task_admin_all_tasks_access: "task_admin_all_tasks_access",
  task_my_tasks: "task_my_tasks",
  task_my_closed_tasks: "task_my_closed_tasks",
  task_registration_tab: "task_registration_tab",

  crm_customer_tab: "crm_customer_tab",
  crm_company_tab: "crm_company_tab",
  crm_editing_customer: "crm_editing_customer",

  monitoring_dashboards_page: "monitoring_dashboards_page",
  monitoring_spy_call: "monitoring_spy_call",

  sms_create: "sms_create",
  sms_edit: "sms_edit",
  sms_delete: "sms_delete",
  sms_get: "sms_get",

  social_media: "social_media",
  social_media_facebook: "social_media_facebook",
  social_media_instagram: "social_media_instagram",

  classifier_create: "classifier_create",
  classifier_list: "classifier_list",
  classifier_delete: "classifier_delete",
  classifier_update: "classifier_update",

  evaluation_ivr_survey_menu: "evaluation_ivr_survey_menu",
  evaluation_configuration_menu: "evaluation_configuration_menu",
  evaluation_configuration_quality_control:
    "evaluation_configuration_quality_control",
  evaluation_configuration_call_criteria:
    "evaluation_configuration_call_criteria",
  evaluation_configuration_chat_criteria:
    "evaluation_configuration_chat_criteria",
  evaluation_configuration_criteria_create:
    "evaluation_configuration_criteria_create",
  evaluation_configuration_criteria_edit:
    "evaluation_configuration_criteria_edit",
  evaluation_configuration_criteria_delete:
    "evaluation_configuration_criteria_delete",
  evaluation_admin_menu: "evaluation_admin_menu",
  evaluation_operation_menu: "evaluation_operation_menu",
  evaluation_operation_my_evaluation: "evaluation_operation_my_evaluation",
  evaluation_operation_all_evaluation: "evaluation_operation_all_evaluation",

  omnichannel_get_message_templates: "get_template",
  omnichannel_send_message_template: "send_template",
  omnichannel_all_queue_list_api: "omnichannel_all_queue_list_api",
  chat_survey_evaluation: "chat_survey_evaluation",
  chat_predefined_message: "chat_predefined_message",

  generator_auto_reply_message: "generator_auto_reply_message",
  generator_predefined_message: "generator_predefined_message",

  jira_configuration: "jira_configuration",
  jira_permission: "jira_permission",
  jira_ticket: "jira_ticket",
  jira_report: "jira_report",
  jira_request: "jira_request",
  jira_registration: "jira_registration",

  task_advanced_global_tag: "task_advanced_global_tag",
  task_list_access: "task_list_access",

  voip_redirect_to_ivr: "voip_redirect_to_ivr",
};

export const appPrefix = "";
