import React, { useContext, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { styled } from "@material-ui/core";
import { KeyboardArrowDownIcon } from "../Icons";

import { defaultStates as defaultFaqStates } from "Store/Reducers/faqReducer";
import { RequestContextStore } from "Components/Layout/AppLayout";
import Faq from "Store/Actions/Faq";

interface IClassificator {
  id: number;
  title: string;
  depth: number;
  children: Array<any>;
  faqCount: number;
}

const Category = (props: IClassificator) => {
  const totalFaqState = useSelector(
    (state: RootStateOrAny) => state.faqReducer,
  );
  const requestContextStore: any = useContext(RequestContextStore);
  const faqState = requestContextStore.getCurrentStateFromTotalState(
    totalFaqState,
    defaultFaqStates,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const faq = new Faq(useDispatch());

  const [opened, setOpened] = useState(false);

  const toggleGetChildren = () => {
    faq.setExpandAllCategories(
      requestState.menuType,
      requestContextStore.getRequestId(),
      false,
    );
    if (opened) setOpened(false);
    else setOpened(true);
  };

  const handleClick = (category) => {
    let type = requestState.menuType,
      requestId = requestContextStore.getRequestId();
    faq.setExpandAllCategories(type, requestId, false);

    if (opened) {
      setOpened(false);
      faq.setSelectedCategory(type, requestId, null);
    } else {
      setOpened(true);
      faq.setSelectedCategory(type, requestId, category);
    }
  };

  let ExpandIcon = null;

  if (props.children && props.children.length > 0) {
    if (opened || faqState.expandAll) ExpandIcon = ExpandCategoryIcon;
    else ExpandIcon = CloseCategoryIcon;
  }

  const StyledCategory = styled("div")({
    position: "relative",
    textAlign: "left",
    cursor: "pointer",
    marginLeft: props.depth * 15,
    borderBottom: "1px solid #DCDDE6",
    padding: 5,
    fontSize: 13,
    color: "#020110",
    fontWeight: 400,
    letterSpacing: "0.02em",
    "&:hover": {
      backgroundColor: "#F5F4F7",
    },
    "&.active": {
      backgroundColor: "#EAF3F0",
      color: "#009E65",
      borderRight: "3px solid #009E65",
    },
  });

  let element = (
    <StyledCategory
      className={faqState.selectedCategory?.id === props.id ? "active" : ""}
      onClick={() => handleClick(props)}>
      {ExpandIcon && <ExpandIcon onClick={toggleGetChildren} />}
      <CategoryText className={props.depth === 0 ? "root" : ""}>
        {props.title} <Count>{props.faqCount}</Count>
      </CategoryText>
    </StyledCategory>
  );

  return (
    <>
      {element}
      {(opened || faqState.expandAll) &&
        props.children &&
        props.children.length > 0 &&
        props.children.map((category) => (
          <Category
            key={category.id}
            id={category.id}
            title={category.title}
            faqCount={category.faqCount}
            children={category.children}
            depth={props.depth + 1}
          />
        ))}
    </>
  );
};

export default Category;

const CategoryText = styled("span")({
  cursor: "pointer",
  paddinTop: 12,
  paddingBottom: 12,
  "&.root": {
    fontWeight: 600,
  },
});

const Count = styled("span")({
  color: "#81808A",
  fontSize: 12,
  float: "right",
  letterSpacing: "0.02em",
  marginTop: 7,
  marginRight: 12,
});

const ExpandCategoryIcon = styled(KeyboardArrowDownIcon)({
  color: "#81808A",
  fontSize: 15,
  lineHeight: 12,
  position: "relative",
  top: 3,
  transform: "rotate(180deg)",
});

const CloseCategoryIcon = styled(KeyboardArrowDownIcon)({
  color: "#81808A",
  fontSize: 15,
  lineHeight: 12,
  position: "relative",
  top: 3,
});
