import _ from "lodash";

import { ICallReducer, IAction } from "./interfaces/callReducer.types";
import { callActionTypes } from "../Actions/actionTypes";
import { deepClone, updateObject } from "../utility";

const initialState: ICallReducer = {
  calls: {},
  activeCallId: 0,
  open: false,
  acwBreakId: null,
  autoAcw: false,
  outCallAnimation: false,
  randomNumber: null
};

const addCallToEdit = (state: ICallReducer, action: IAction): ICallReducer => {
  const updatedCalls = deepClone(state.calls);
  updatedCalls[action.id] = { ...action.call };
  return updateObject(state, {
    open: true,
    activeCallId: action.id,
    calls: updatedCalls,
  });
};

const removeCallFromEdit = (
  state: ICallReducer,
  action: { id: string | number },
): ICallReducer => {
  const calls = deepClone(state.calls);

  delete calls[action.id];

  return updateObject(state, {
    open: false,
    activeCallId: 0,
    calls,
  });
};

const removeCallById = (
  state: ICallReducer,
  action: { id: any },
): ICallReducer => {
  let updatedCalls = deepClone(state.calls);
  updatedCalls = _.omit(updatedCalls, action.id);
  return updateObject(state, {
    open: false,
    activeCallId: 0,
    calls: updatedCalls,
  });
};

const callReducer = (
  state = initialState,
  action: { type: any; activeCallId: any; id: any; status: any; randomNumber: number | null },
): ICallReducer => {
  switch (action.type) {
    case callActionTypes.ADD_CALL_TO_EDIT:
      return addCallToEdit(state, action);
    case callActionTypes.REMOVE_CALL_FROM_EDIT:
      return removeCallFromEdit(state, action);
    case callActionTypes.REMOVE_CALL_BY_ID:
      return removeCallById(state, action);
    case callActionTypes.SET_ACTIVE_CALL_ID:
      return updateObject(state, { activeCallId: action.activeCallId });
    case callActionTypes.SET_ACW_BREAK_ID:
      return { ...state, acwBreakId: action.id };
    case callActionTypes.SET_AUTO_ACW:
      return { ...state, autoAcw: action.status };
    case callActionTypes.SET_OUT_CALL_ANIMATION:
      return { ...state, outCallAnimation: action.status };
    case callActionTypes.SET_RANDOM_NUMBER:
      return { ...state, randomNumber: action.randomNumber };
    default:
      return state;
  }
};

export default callReducer;
