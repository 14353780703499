import React from "react";
import { styled } from "@material-ui/core/styles";
import { actionTypes, colors } from "./constants";

interface IInCallAction {
  Icon: any;
  name: string;
  active?: boolean;
  type?: string;
  onClick?: Function;
}

const InCallActionbutton = (props: IInCallAction) => {
  let { Icon, name, onClick, active, type } = props;

  const InCallActionIcon = styled(Icon)({
    display: "block",
    margin: "0 auto",
    color: active
      ? type === actionTypes.holdCall
        ? colors.hold
        : type === actionTypes.muteCall
        ? colors.mute
        : type === actionTypes.transferCall
        ? colors.active
        : "#050038"
      : "#050038",
    fontSize: 20,
    transform: name === "Transfer" ? "rotateY(180deg)" : "",
  });

  const InCallAction = styled("div")({
    width: 115,
    height: 52,
    borderRadius: 8,
    backgroundColor: active
      ? type === actionTypes.holdCall
        ? colors.fadedHold
        : type === actionTypes.muteCall
        ? colors.fadedMute
        : type === actionTypes.transferCall
        ? colors.fadedActive
        : "rgba(210, 210, 210)"
      : "rgba(210, 210, 210)",
    paddingTop: 18,
    cursor: "pointer",
  });

  const InCallActionName = styled("span")({
    color: active
      ? type === actionTypes.holdCall
        ? colors.hold
        : type === actionTypes.muteCall
        ? colors.mute
        : type === actionTypes.transferCall
        ? colors.active
        : "#050038"
      : "#050038",
    fontSize: 12,
  });

  return (
    <InCallAction onClick={() => onClick()}>
      <InCallActionIcon />
      <InCallActionName>{name}</InCallActionName>
    </InCallAction>
  );
};

export default InCallActionbutton;
