import React from "react";
import { styled } from "@material-ui/core/styles";
import { SearchIcon } from "./Icons";

interface ISearch {
  placeholder: string;
  onInput: any;
  value: string;
}

export default function SearchInput(props: ISearch) {
  return (
    <Wrapper>
      <StyledSearchIcon />
      <StyledSearchInput
        value={props.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onInput(e)}
        placeholder={props.placeholder}
        autoComplete='off'
      />
    </Wrapper>
  );
}

const StyledSearchIcon = styled(SearchIcon)({
  color: "#F68F1F",
  position: "relative",
  right: 3,
  top: 5,
});

const Wrapper = styled("div")({
  borderBottom: "1px solid #DCDDE6",
  textAlign: "left",
});

const StyledSearchInput = styled("input")({
  width: "90%",
  marginTop: 10,
  border: "none",
  outline: "none",
  fontSize: 13,
  height: 30,
  textIndent: 7,
});
