/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { styled, Grid } from "@material-ui/core";
import { SearchIcon } from "./Icons";
import { searchBy } from "./constants";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const Search = (props) => {
  const { translate } = useTranslation();
  const [searchString, setSearchString] = useState("");
  const [searchByIndex, setSearchByIndex] = useState(0);

  let { requests, setChosenRequests } = props;

  const searchByAgentName = (value) => {
    let filteredRequests = requests.filter((request) =>
      request.agentFullName.toLowerCase().includes(value),
    );
    setChosenRequests(filteredRequests);
  };

  const searchByQueue = (value) => {
    let filteredRequests = requests.filter((request) =>
      request.queueName.toLowerCase().includes(value),
    );
    setChosenRequests(filteredRequests);
  };

  const searchByTopic = (value) => {
    let filteredRequests = requests.filter((request) =>
      request.topics.some((topic) => topic.toLowerCase() === value),
    );
    setChosenRequests(filteredRequests);
  };

  const handleSearch = (value) => {
    value = value.toLowerCase();
    if (value === "") setChosenRequests(requests);
    else if (searchByIndex === 0) searchByQueue(value);
    else if (searchByIndex === 1) searchByTopic(value);
    else if (searchByIndex === 2) searchByAgentName(value);
  };

  const handleChange = (event) => {
    let { value } = event.target;

    setSearchString(value);
    handleSearch(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") handleSearch(searchString);
  };

  useEffect(() => {
    if (searchString) handleSearch(searchString);
  }, [searchByIndex]);

  return (
    <Container>
      <Grid container>
        <Grid container item xs={6} spacing={0}>
          <Icon />
          <Input
            autoFocus
            placeholder={translate("request_search_request")}
            name='Search'
            autoComplete='off'
            value={searchString}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={6}>
          <SearchContainer>
            {searchBy.map((option, index) => (
              <SearchBy
                className={searchByIndex === index ? "active" : ""}
                key={index}
                onClick={() => setSearchByIndex(index)}>
                {option}
              </SearchBy>
            ))}
          </SearchContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Search;

const Container = styled(Grid)({
  borderBottom: "1px solid #DCDDE6",
  paddingBottom: 10,
  width: "100%",
  margin: "0 auto",
  marginTop: 10,
});

const Input = styled("input")({
  width: "90%",
  border: "none",
  outline: "none",
  display: "inline-block",
  "&::placeholder": {
    color: "rgba(2, 1, 16, 0.4)",
  },
});

const SearchContainer = styled("div")({ float: "right" });

const Icon = styled(SearchIcon)({
  color: "#F68F1F",
  fontWeight: 600,
  fontSize: 14,
  display: "inline-block",
  position: "relative",
  top: 3,
  left: 0,
  marginLeft: 8,
  marginRight: 8,
});

const SearchBy = styled("div")({
  color: "#9F9DAD",
  fontSize: 12,
  fontWeight: 600,
  marginRight: 25,
  display: "inline-block",
  cursor: "pointer",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  "&.active": {
    color: "#050038",
  },
});
