import { permissions } from "Config/config";
import i18n from "i18n/config";

export const tabs = {
  Registration: {
    name: i18n.t("request_registration"),
    permission: "",
  },
  Requests: {
    name: i18n.t("request_requests"),
    permission: "",
  },
  Jira: {
    name: i18n.t("request_jira"),
    permission: permissions.jira_registration,
  },
  SmsTemplates: {
    name: i18n.t("request_sms_templates"),
    permission: permissions.sms_get,
  },
  KnowledgeBase: {
    name: i18n.t("request_knowledge_base"),
    permission: permissions.faq_categories_page,
  },
  CustomerSearch: {
    name: i18n.t("request_customer_search"),
    permission: permissions.request_atb_customer_search,
  },
  LastCallRequests: {
    name: i18n.t("request_last_call_requests"),
    permission: permissions.last_call_requests,
  },
};
