import {
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import LoadingComponent from "Components/Loading/LoadingComponent";
import { getFieldByType } from "Components/Request/RightPanel/Cch/Fields";
import { characterLimit } from "Pages/TaskManagement/constants";

import React from "react";

import AutoComplete from "./AutoComplete";
import { defaultProps } from "./constants";
import { useCch } from "./hooks";
import Input from "./Input";
import SavedCch from "./SavedCch";
import TaskTableRow from "./TaskTableRow";

const Cch = (): React.ReactElement => {
  const {
    classes,
    categories,
    globalTags,
    advancedGlobalTags,
    defaultData,
    checkValidation,
    checkAdvancedValidation,
    cchState,
    ownersList,
    attachments,
    isLoading,
    isSaving,
    convertFileToIcon,
    getRootProps,
    getInputProps,
    saveCurrentCch,
    translate,
    fillFormValuesOnCategoryChange,
    getCategoryOptionById,
    setTagValue,
    setElementValue,
    getTagValueById,
    getAdvancedValueById,
    setAdvancedTagValue,
  } = useCch();

  return (
    <div className={classes.root}>
      <div>
        <TableContainer
          component={"div"}
          style={{
            width: "90%",
            margin: "0 auto",
            height: "70%",
            minHeight: "70%",
            maxHeight: "70%",
          }}>
          <Table style={{ width: "95%", paddingTop: 20, padding: 50 }}>
            <TableBody>
              <TaskTableRow
                label={translate("request_category")}
                Component={
                  <AutoComplete
                    multiple={false}
                    placeHolder={translate("request_category")}
                    handleChange={(value: any) => {
                      fillFormValuesOnCategoryChange(value ?? null);
                    }}
                    required={false}
                    value={getCategoryOptionById()?.name ?? null}
                    options={categories}
                    hideAddNewOption={false}
                    optionCanBeAdded={false}
                  />
                }
              />
              {globalTags.length > 0 && (
                <TaskTableRow
                  Component={
                    <Subtitle>{translate("request_global_tags")}</Subtitle>
                  }
                />
              )}
              {globalTags.map((tag, index) => (
                <TableRow key={index}>
                  <Cell>
                    <Label>{tag.name}</Label>
                    <Input
                      {...defaultData}
                      required={false}
                      label={tag.name}
                      checkValidation={checkValidation}
                      value={getTagValueById(tag.id)}
                      handleChange={(value: string) => {
                        setTagValue(tag.id, value);
                      }}
                    />
                  </Cell>
                </TableRow>
              ))}
              {advancedGlobalTags.length > 0 && (
                <TaskTableRow
                  Component={
                    <Subtitle>
                      {translate("request_advanced_global_tags")}
                    </Subtitle>
                  }
                />
              )}
              {advancedGlobalTags
                .filter((tag) => tag.globalTagCreationType === "ON_CREATE")
                .map((tag, index) => {
                  const value = getAdvancedValueById(tag.id);
                  const newField = getFieldByType(
                    tag,
                    value,
                    setAdvancedTagValue,
                    true,
                  );

                  if (newField) {
                    return (
                      <TableRow key={index}>
                        <Cell>
                          <Label>
                            {tag.name} {tag.mandatory && "*"}
                          </Label>
                          {newField}
                        </Cell>
                      </TableRow>
                    );
                  } else return null;
                })}
              <TaskTableRow
                label={translate("request_owner")}
                Component={
                  <AutoComplete
                    multiple={true}
                    handleChange={(values: any) => {
                      setElementValue("owners", values);
                    }}
                    required={false}
                    value={cchState.formValues.owners}
                    options={ownersList.map((option) => ({
                      ...option,
                      name: option.firstName + " " + option.lastName,
                      fullName: option.firstName + " " + option.lastName,
                    }))}
                    hideAddNewOption={false}
                    optionCanBeAdded={false}
                  />
                }
              />
              <TaskTableRow
                label={translate("request_callback_number")}
                Component={
                  <Input
                    {...defaultProps}
                    required={false}
                    label={translate("request_callback_number")}
                    checkValidation={checkValidation}
                    value={cchState.formValues.callbackNumber}
                    handleChange={(value: any) => {
                      setElementValue("callbackNumber", value);
                    }}
                  />
                }
              />
              <section className='container'>
                <aside>
                  <span className={classes.attachment}>
                    {attachments &&
                      attachments.map((item, index) => {
                        let fileName = item.path;

                        if (item instanceof File) fileName = item.name;

                        return convertFileToIcon(fileName, index);
                      })}
                  </span>
                </aside>
                <div {...getRootProps({ className: classes.dropzone })}>
                  <input {...getInputProps()} />
                  <p>{translate("request_drag_n_drop")}</p>
                </div>
              </section>
              <TaskTableRow
                label={translate("request_content")}
                Component={
                  <>
                    <textarea
                      className={classes.content}
                      value={cchState.formValues.content}
                      onChange={(e) =>
                        setElementValue("content", e.target.value)
                      }
                      maxLength={characterLimit}
                    />
                    <div className={classes.maxLength}>
                      {cchState.formValues.content.length}/{characterLimit}
                    </div>
                  </>
                }
              />
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div
        style={{
          width: "90%",
          margin: "0 auto",
          textAlign: "center",
          position: "sticky",
          bottom: "10px",
        }}>
        <SaveButton
          onClick={saveCurrentCch}
          disabled={
            isSaving ||
            getCategoryOptionById()?.name === undefined ||
            (cchState.formValues.advancedTagValues.length > 0 &&
              checkAdvancedValidation)
          }>
          {translate("add")}
        </SaveButton>
      </div>

      {cchState.allSavedCchs.length > 0 && (
        <>
          <Divider />
          <SavedCch />
        </>
      )}
      {isLoading ? <LoadingComponent /> : null}
    </div>
  );
};

export default Cch;

Cch.displayName = "Cch";

const Label = styled("span")({
  color: "#414147",
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  display: "block",
  textAlign: "left",
  marginTop: 5,
});

const Cell = styled(TableCell)({
  padding: 0,
  width: "90%",
  border: "none",
  paddingTop: 15,
});

const Divider = styled("hr")({
  border: "none",
  borderTop: "1px solid rgba(224, 224, 224, 1)",
});

const SaveButton = styled(Button)({
  background: "rgba(0, 178, 150, 0.1)",
  borderRadius: 24,
  color: "#00B287",
  fontSize: 12,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.06em",
  textTransform: "uppercase",
  cursor: "pointer",
  width: "40%",
  padding: "10px 16px",
});

const Subtitle = styled("span")({
  display: "block",
  textAlign: "left",
  fontSize: 15,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  color: "#050038",
  marginBottom: 10,
});
