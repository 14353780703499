import { InputAdornment, TextField } from "@material-ui/core";
import axios from "Api/axios";
import { setCodeVerify } from "Api/configuration";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { default as Button } from "Components/Buttons/Button";
import { buttonTypes } from "Components/Buttons/buttonTypes";
import Drawer from "Components/Drawer/Drawer";
import LoadingComponent from "Components/Loading/LoadingComponent";
import WebConf, { UserManagementUrl } from "Config/WebConf";
import Notification from "Notifications/Notification";
import React, { useEffect, useState } from "react";

import { useGoogleAuthDrawerStyles } from "./styles";

export interface IGoogleAuth {
  open: boolean;
  handleClose: any;
}

const GoogleAuthDrawer = ({
  open,
  handleClose,
}: IGoogleAuth): React.ReactElement => {
  const classes = useGoogleAuthDrawerStyles();
  const { translate } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQRCode] = useState(null);
  const [codeValue, setCodeValue] = useState("");

  const parseToken = (token): any => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    return JSON.parse(jsonPayload);
  };

  const handleVerify = async (): Promise<void> => {
    const response = await setCodeVerify(
      localStorage.getItem("username"),
      codeValue,
    );
    if (response) {
      Notification.success(translate("navbar_code_verify"));
      handleClose();
    } else {
      Notification.error(translate("navbar_code_verify_error"));
    }
  };

  const isMfaEnabled = parseToken(localStorage.getItem("token"))?.isMfaEnabled;
  const fetchQRCode = async (): Promise<void> => {
    setIsLoading(true);
    axios
      .get(
        `${UserManagementUrl}${
          WebConf.configuration.getQRCode
        }/${localStorage.getItem("username")}`,
        { responseType: "arraybuffer" },
      )
      .then((response) => {
        if (response) {
          // Convert the byte array to a Blob
          const blob = new Blob([response.data], { type: "image/png" });
          // Create a URL for the Blob
          const imageUrl = URL.createObjectURL(blob);
          setQRCode(imageUrl);
        } else {
          Notification.error(translate("navbar_qr_code_error"));
        }
      })
      .catch((error) => {
        Notification.error(error);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchQRCode();
  }, []);

  return (
    <Drawer style={{ width: "30%" }} open={open} handleClose={handleClose}>
      <div className={classes.headerStyle}>
        {isMfaEnabled
          ? translate("navbar_update_google_authenticator")
          : translate("navbar_enroll_google_authenticator")}
      </div>
      <div className={classes.container}>
        <div className={classes.imgWrap}>
          <img src={qrCode} alt='qrCode' />
        </div>
        <TextField
          id='outlined-basic'
          variant='outlined'
          onChange={(e) => setCodeValue(e.target.value)}
          value={codeValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <span className={classes.text}>Enter code</span>
              </InputAdornment>
            ),
          }}
        />
        <Button
          className={classes.buttonVerify}
          onClick={() => handleVerify()}
          buttonType={buttonTypes.primary}>
          {translate("verify")}
        </Button>
        <Button
          className={classes.buttonCancel}
          onClick={handleClose}
          buttonType={buttonTypes.primary}>
          {translate("cancel")}
        </Button>
      </div>
      {isLoading ? <LoadingComponent /> : null}
    </Drawer>
  );
};

export default GoogleAuthDrawer;
