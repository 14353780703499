import { Popover } from "@material-ui/core";
import React from "react";

import { formatSecondsToTime } from "Utils/Utils";

import { useBreakMenu } from "./hooks";
import { IBreakMenu } from "./interfaces";
import { useBreakStyles } from "./styles";
import {
  BreakDuration,
  BreaksIcon,
  BreaksList,
  Break,
  BreakName,
} from "./styles/BreakMenu";

const BreakMenu = ({ handleBreak }: IBreakMenu): React.ReactElement => {
  const classes = useBreakStyles();
  const { open, anchorEl, breakList, onClick, handleClose, handleBreakAction } =
    useBreakMenu({ handleBreak });

  return (
    <>
      <BreaksIcon onClick={onClick} />
      {breakList.length > 0 && (
        <Popover
          classes={{ paper: classes.paper }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}>
          <BreaksList>
            {breakList &&
              breakList.map((item, index) => (
                <Break
                  key={index}
                  onClick={() => handleBreakAction(item.name, item.id)}>
                  <BreakName>{item.name}</BreakName>
                  {item.duration && item.duration !== 0 ? (
                    <BreakDuration>
                      {formatSecondsToTime(item.duration)}
                    </BreakDuration>
                  ) : (
                    <BreakDuration />
                  )}
                </Break>
              ))}
          </BreaksList>
        </Popover>
      )}
    </>
  );
};

export default BreakMenu;

BreakMenu.displayName = "BreakMenu";
