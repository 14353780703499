import WebConf, { OmniServerUrl } from "Config/WebConf";

import { fetchData } from "networking";
import { useEffect, useState } from "react";

import { IImage } from "../interfaces";

/*
 * Conversation image. Opens in new tab when user clicks it
 */
export const useImage = ({
  isSent,
  fileName,
  extension,
  base64,
}: IImage): any => {
  const [loadedUrl, setLoadedUrl] = useState(base64);
  const [imageText, setImageText] = useState("");

  const loadMessage = (): void => {
    fetchData(
      OmniServerUrl +
        WebConf.chat.loadFileBase64 +
        "?id=" +
        fileName +
        "." +
        extension,
    )
      .then((response) => {
        if (response.status === 200) return response.text();
        else return null;
      })
      .then((result) => {
        if (result) {
          setLoadedUrl(
            "data:image/png;base64, " + JSON.parse(result).fileInStr,
          );
          setImageText(JSON.parse(result).text);
        }
      });
  };

  useEffect(() => {
    !base64 && loadMessage();
  }, [base64]);

  return {
    isSent,
    loadedUrl,
    imageText,
  };
};

useImage.displayName = "useImage";
