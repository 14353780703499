import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TelegramIcon from "@material-ui/icons/Telegram";
import InsertCommentIcon from "@material-ui/icons/InsertComment";
import InstagramIcon from "@material-ui/icons/Instagram";
import ChatIcon from "@material-ui/icons/Chat";

const Icons = {
  WHATSAPP: {
    icon: WhatsAppIcon,
    color: "#25D366",
  },
  TELEGRAM: {
    icon: TelegramIcon,
    color: "#0088cc",
  },
  FB: {
    icon: FacebookIcon,
    color: "#4267B2",
  },
  INSTAGRAM: {
    icon: InstagramIcon,
    color: "#fff",
  },
  WEBCHAT: {
    icon: InsertCommentIcon,
    color: "#0056C1",
  },
  OTHER: {
    icon: ChatIcon,
    color: "orange",
  },
};

export default Icons;
