import { socialMediaActions as actionTypes } from "../Actions/actionTypes";

const initialState = {
  activePlatformId: null,
  activeAccountId: null,
  platforms: [],
  accountsById: [],
  postsById: {},
  activePost: {},
  activeReplyMessageId: '',
  commentsByActivePost: {},
  replysByActivePost: [],
  unRepliedComments: {},
  activeActionData: null,
};

const socialMediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_PLATFORM_ID:
      return { ...state, activePlatformId: action.activePlatformId };
    case actionTypes.SET_ACTIVE_ACCOUNT_ID:
      return { ...state, activeAccountId: action.activeAccountId };
    case actionTypes.SET_PLATFORMS:
      return { ...state, platforms: action.platforms };
    case actionTypes.SET_ACCOUNTS_BY_ID:
      return { ...state, accountsById: action.accountsById };
    case actionTypes.SET_POSTS_BY_ID:
      return { ...state, postsById: action.postsById };
    case actionTypes.SET_ACTIVE_POST:
      return { ...state, activePost: action.activePost };
    case actionTypes.SET_ACTIVE_REPLY_MESSAGE_ID:
      return { ...state, activeReplyMessageId: action.activeReplyMessageId };
    case actionTypes.SET_COMMENTS_BY_ACTIVE_POST:
      return { ...state, commentsByActivePost: action.commentsByActivePost };
    case actionTypes.SET_REPLYS_BY_ACTIVE_POST:
      return { ...state, replysByActivePost: action.replysByActivePost };
    case actionTypes.SET_UN_REPLIED_COMMENTS:
      return { ...state, unRepliedComments: action.unRepliedComments };
    case actionTypes.SET_ACTIVE_ACTION_DATA:
      return { ...state, activeActionData: action.activeActionData };
    default:
      return state;
  }
};

export default socialMediaReducer;
