import menuTypes from "Assets/constants/menuTypes";
import { faqActions as actionTypes } from "../Actions/actionTypes";
import {
  deepClone,
  getRequestStates,
  updateObject,
  updateTotalState,
} from "../utility";
import { searchBy } from "Components/Request/FAQ/constants";

const initialState = {
  [menuTypes.CALL]: {},
  [menuTypes.CHAT]: {},
};

export const defaultStates = {
  searchValue: "",
  questionSearchValue: "",
  expandAll: false,
  allCategories: [],
  originalCategories: [],
  allQuestions: [],
  selectedCategory: null,
  selectedQuestion: null,
  selectedAnswer: "",
  searchBy: searchBy.all,
};

const setCategorySearchValue = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestSatates = updateObject(requestSatates, { searchValue: action.value });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setExpandAllCategories = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    expandAll: action.expandAll,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setAllCategories = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestSatates = updateObject(requestSatates, {
    allCategories: action.categories,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setAllQuestions = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestSatates = updateObject(requestSatates, {
    allQuestions: action.questions,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setOriginalCategories = (
  state,
  { menuType, requestId, originalCategories },
) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    menuType,
    requestId,
  );

  requestSatates = updateObject(requestSatates, {
    originalCategories,
  });

  return updateTotalState(state, menuType, requestId, requestSatates);
};

const setSelectedCategory = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestSatates = updateObject(requestSatates, {
    selectedCategory: action.selectedCategory,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setQuestionSearchValue = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    questionSearchValue: action.value,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setSelectedQuestion = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    selectedQuestion: action.selectedQuestion,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const clearAllFields = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    ...initialState,
    allCategories: requestSatates.allCategories,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setSelectedAnswer = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    selectedAnswer: action.answer,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setSearchBy = (state, action) => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    searchBy: action.searchBy,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const clearAllData = (state, action) => {
  const updatedMenuStates = deepClone(state[action.menuType]);

  delete updatedMenuStates[action.requestId];

  return updateObject(state, { [action.menuType]: updatedMenuStates });
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORY_SEARCH_VALUE:
      return setCategorySearchValue(state, action);

    case actionTypes.SET_EXPAND_ALL_CATEGORIES:
      return setExpandAllCategories(state, action);

    case actionTypes.SET_ALL_CATEGORIES:
      return setAllCategories(state, action);

    case actionTypes.SET_ORIGINAL_CATEGORIES:
      return setOriginalCategories(state, action);

    case actionTypes.SET_ALL_QUESTIONS:
      return setAllQuestions(state, action);

    case actionTypes.SET_SELECTED_CATEGORY:
      return setSelectedCategory(state, action);

    case actionTypes.SET_QUESTION_SEARCH_VALUE:
      return setQuestionSearchValue(state, action);

    case actionTypes.SET_SELECTED_QUESTION:
      return setSelectedQuestion(state, action);

    case actionTypes.CLEAR_ALL_FIELDS:
      return clearAllFields(state, action);

    case actionTypes.SET_SELECTED_ANSWER:
      return setSelectedAnswer(state, action);

    case actionTypes.SET_SEARCH_BY:
      return setSearchBy(state, action);

    case actionTypes.CLEAR_ALL_DATA:
      return clearAllData(state, action);

    default:
      return state;
  }
};

export default faqReducer;
