import {
  styled,
  Popover,
  Select,
  MenuItem as Option,
  FormControl,
  Input,
  TextField,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { createNewConversation } from "Api/chat";
import { getAllMessageTemplates } from "Api/messageTemplates";
import { default as useTranslate } from "Assets/hooks/useOLTranslation";
import clsx from "clsx";

import LoadingComponent from "Components/Loading/LoadingComponent";
import Notification from "Notifications/Notification";
import React, { useState, useEffect } from "react";

import { AddIcon } from "./Icons";
import { useOutgoingMessageStyles } from "./styles/styles";

const initialData = {
  channelId: "994",
  template: null,
  accountName: "FB",
  accountPlatform: "WHATSAPP",
};
const fullName = `${localStorage.getItem("firstName")} ${localStorage.getItem(
  "lastName",
)}`;
const userId = localStorage.getItem("userID");
export const OutgoingMessage = ({ isOpen }) => {
  const { translate } = useTranslate();
  const classes = useOutgoingMessageStyles();
  const [data, setData] = useState({ ...initialData });
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (target): void => {
    setAnchorEl(target);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleData = (field, value): void => {
    setData((data) => ({ ...data, [field]: value }));
  };

  const fetchMessageTemplates = (): void => {
    setLoading(true);

    getAllMessageTemplates()
      .then((response) => {
        if (response) setTemplates(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reset = (): void => {
    setData({ ...initialData });
  };
  const formatData = ({
    channelId,
    template,
    accountName,
    accountPlatform,
  }) => ({
    channelId,
    accountName,
    accountPlatform,
    templateName: template?.name,
    templateText: template?.text,
    fullName,
    userId,
  });

  const handleSubmit = (): Promise<void> => {
    setLoading(true);
    return createNewConversation(formatData(data))
      .then((response) => {
        if (response) {
          Notification.success(translate("chat_box_new_conversation_created"));
          reset();
          handleClose();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    if (!value.startsWith("994")) {
      handleData("channelId", "994");
      return;
    }
    const numberAfterPrefix = value.slice(3);
    let cleanedValue = numberAfterPrefix.replace(/[^0-9]/g, "");
    if (cleanedValue.startsWith("0")) {
      cleanedValue = cleanedValue.substring(1);
    }
    if (cleanedValue.length > 9) {
      cleanedValue = cleanedValue.slice(0, 9);
    }
    handleData("channelId", `994${cleanedValue}`);
  };

  useEffect(() => {
    fetchMessageTemplates();
  }, []);

  return (
    <MenuItem className={clsx({ isOpen })}>
      <Wrapper className={clsx({ isOpen })} onClick={handleOpen}>
        <AddIcon />
      </Wrapper>
      <Popover
        classes={{ paper: classes.paper, root: classes.paperRoot }}
        anchorEl={anchorEl}
        anchorReference={"none"}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <Header>
          <Label>{translate("chat_box_create_new_conversation")}</Label>
        </Header>
        <FormControl className={classes.formControl}>
          <TextField
            label={translate("chat_box_phone_number")}
            value={data.channelId}
            onChange={handlePhoneChange}
          />
          <FormHelperTextStyle>Example: 994 55 12345678</FormHelperTextStyle>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id='templates'>
            {translate("chat_box_templates")}
          </InputLabel>
          <Select
            labelId='templates'
            value={data.template}
            onChange={(event) => {
              handleData("template", event.target.value);
            }}
            input={<Input />}>
            {templates.map((template) => (
              <Option key={template.id} value={template}>
                {template.name}
              </Option>
            ))}
          </Select>
          {data.template?.text && (
            <TemplateContent>
              {translate("chat_box_template_content")} {data.template?.text}
            </TemplateContent>
          )}
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label={translate("chat_box_account_name")}
            value={data.accountName}
            disabled
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label={translate("chat_box_account_platform")}
            value={data.accountPlatform}
            disabled
          />
        </FormControl>
        <SaveButton onClick={handleSubmit}>{translate("save")}</SaveButton>
      </Popover>

      {loading && <LoadingComponent />}
    </MenuItem>
  );
};

const Wrapper = styled("div")({
  height: 30,
  width: 30,
  borderRadius: 32,
  lineHeight: "34px",
  color: "orange",
  border: "5px solid orange",
  margin: "0 auto",
  backgroundColor: "white",
  "&.isOpen": {
    border: "1px solid transparent",
    margin: "0 0 0 12px",
  },
  "& svg": {
    fontSize: 30,
  },
});

const MenuItem = styled("div")({
  width: "100%",
  height: 35,
  cursor: "pointer",
  padding: "5px 0",
  "&.open": {
    background: "linear-gradient(282.31deg, #FF5C00 0%, #FFA13A 100%)",
    borderRadius: 32,
    padding: "7px 0 5px 0",
  },
});

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: 15,
  marginBottom: 15,
});

const Label = styled("span")({
  color: "rgba(5, 0, 56, 1)",
  fontSize: 14,
  fontWeight: 600,
  marginTop: 5,
});

const SaveButton = styled("div")({
  cursor: "pointer",
  backgroundColor: "rgb(0, 178, 150)",
  color: "#fff",
  borderRadius: 8,
  textAlign: "center",
  fontSize: 13,
  fontWeight: 600,
  padding: "5px 2px",
  marginTop: 25,
});

const TemplateContent = styled("div")({
  color: "#808080",
  fontSize: 12,
  fontWeight: 400,
  marginBottom: 10,
  marginTop: 10,
});

const FormHelperTextStyle = styled(FormHelperText)({
  color: "#FF0000",
});
