import orangeline from "Assets/images/orangeline.png";
import i18n from "i18n/config";

export function requestPermission() {
  document.addEventListener("DOMContentLoaded", function () {
    if (!Notification) {
      alert(i18n.t("desktop_notifications_not_available"));
      return;
    }
    if (Notification.permission !== "granted") Notification.requestPermission();
  });
}

export function notifyAgent(title: string, body: string, logo = orangeline) {
  if (Notification.permission !== "granted") {
    console.error(i18n.t("desktop_notifications_not_allowed"));
    Notification.requestPermission();
  } else {
    var notification = new Notification(title, {
      icon: logo,
      body: body,
    });
    setTimeout(notification.close.bind(notification), 15000);
  }

  return notification;
}
