import { createStyles, makeStyles } from "@material-ui/core";

export const useStatusSelectorStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
      color: "#050038",
      fontSize: 12,
      lineHeight: "140%",
      letterSpacing: "0.02em",
    },
    label: {
      color: "#050038",
      fontSize: 12,
      lineHeight: "140%",
      letterSpacing: "0.02em",
      display: "inline-block",
      marginRight: 15,
      position: "relative",
      top: 2,
    },
    selectEmpty: {},
    select: {
      color: "#050038",
      fontSize: 12,
      lineHeight: "140%",
      letterSpacing: "0.02em",
    },
  }),
);
