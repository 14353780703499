import { grey } from "@material-ui/core/colors";
import { styled } from "@material-ui/core/styles";

import Loading from "Assets/images/loading.gif";
import React, { useMemo } from "react";

import { useImage } from "./hooks";
import { IImage } from "./interfaces";

const openImageInNewTab = (base64): void => {
  const image = "<img src='" + base64 + "' />";
  const w = window.open("");
  w.document.write(image);
};

const Image = (props: IImage): React.ReactElement => {
  const { loadedUrl, imageText } = useImage(props);

  let Content = null;
  if (!loadedUrl)
    Content = (
      <LoadingImage>
        <StyledLoading src={Loading} />
      </LoadingImage>
    );
  else
    Content = (
      <StyledImage
        src={loadedUrl}
        onClick={() => openImageInNewTab(loadedUrl)}
      />
    );

  return useMemo(
    () => (
      <ImageWrapper>
        {Content}
        {imageText && (
          <StyledText>
            <Text>{imageText}</Text>
          </StyledText>
        )}
      </ImageWrapper>
    ),
    [loadedUrl, imageText],
  );
};

export default Image;

Image.displayName = "Image";

const ImageWrapper = styled("div")({
  borderRadius: 7,
});

const StyledImage = styled("img")({
  objectFit: "fill",
  borderRadius: "20px",
  cursor: "pointer",
  maxWidth: 300,
});

const LoadingImage = styled("div")({
  height: "120px",
  width: "100%",
  backgroundColor: grey[100],
  margin: "5px",
  borderRadius: "7px",
  position: "relative",
});

const StyledLoading = styled("img")({
  position: "absolute",
  top: "50%",
  right: "50%",
  width: "20px",
  transform: "translate(50%,-50%)",
});

const StyledText = styled("div")({
  background: "white",
  wordBreak: "break-word",
  borderRadius: "12px 12px 2px 12px",
  maxWidth: 350,
  padding: 7,
  fontSize: 13,
  lineHeight: "160%",
  letterSpacing: "0.02em",
  textAlign: "left",
});

const Text = styled("span")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  lineHeight: "140%",
  padding: "1px 6px 4px 8px",
  textAlign: "justify",
});
