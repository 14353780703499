import { styled } from "@material-ui/core/styles";

import {
  LocalPhoneIcon,
  FacebookIcon,
  InstagramIcon,
  WhatsAppIcon,
  TelegramIcon,
  InsertCommentIcon,
  ChatIcon,
} from "Assets/icons";
import React, { useEffect, useState } from "react";

import { fields } from "../constants";
import { IInputs } from "../interfaces";

export const useIdentifierInput = ({
  id,
  fieldValue,
  handleChange,
  label,
}: IInputs): any => {
  const [localLabel, setLocalLabel] = useState("");
  const [localValue, setLocalValue] = useState("");
  const [isInput, setIsInput] = useState(false);

  const styledIcon = (Icon): React.ReactNode =>
    styled(Icon)({
      color: "#9F9DAD",
      fontSize: 20,
      lineHeight: 17,
      fontWeight: 600,
    });

  const chooseIcon = (): React.ReactNode => {
    let icon = null,
      ResultIcon = null;
    switch (label) {
      case fields.Facebook:
        ResultIcon = styledIcon(FacebookIcon);
        icon = <ResultIcon />;
        break;
      case fields.Whatsapp:
        ResultIcon = styledIcon(WhatsAppIcon);
        icon = <ResultIcon />;
        break;
      case fields.Webchat:
        ResultIcon = styledIcon(InsertCommentIcon);
        icon = <ResultIcon />;
        break;
      case fields.Instagram:
        ResultIcon = styledIcon(InstagramIcon);
        icon = <ResultIcon />;
        break;
      case fields.Telegram:
        ResultIcon = styledIcon(TelegramIcon);
        icon = <ResultIcon />;
        break;
      case fields.PhoneNumber:
        ResultIcon = styledIcon(LocalPhoneIcon);
        icon = <ResultIcon />;
        break;
      default:
        ResultIcon = styledIcon(ChatIcon);
        icon = <ResultIcon />;
        break;
    }

    return icon;
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsInput(true);
    const newInputValue = e.target.value;
    setLocalValue(newInputValue);
  };

  useEffect(() => {
    setLocalLabel(label ? label : "");
    setLocalValue(fieldValue ? fieldValue : "");
  }, [label, fieldValue]);

  useEffect(() => {
    isInput && handleChange(localValue, id, label);
    setIsInput(false);
  }, [localValue]);

  return {
    localLabel,
    localValue,
    handleInput,
    chooseIcon,
  };
};

useIdentifierInput.displayName = "useIdentifierInput";
