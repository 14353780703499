/* eslint-disable eqeqeq */
import * as SIP from "sip.js";
import SipLog from "Sip/SipLog";

import IUa from "Sip/interfaces/IUa";
import ISipLog from "Sip/interfaces/ISipLog";
import { logType } from "Sip/constants/logType";

function onTrackAddedEvent(session) {
  var pc = session.sessionDescriptionHandler.peerConnection;

  var remoteAudioStream = new MediaStream();

  pc.getTransceivers().forEach(function (transceiver) {
    // Add Media
    var receiver = transceiver.receiver;
    if (receiver.track) {
      if (receiver.track.kind == "audio") {
        console.log("Adding Remote Audio Track");
        remoteAudioStream.addTrack(receiver.track);
      }
    }
  });

  // Attach Audio
  if (remoteAudioStream.getAudioTracks().length >= 1) {
    var remoteAudio = document.createElement("audio");
    remoteAudio.srcObject = remoteAudioStream;
    remoteAudio.onloadedmetadata = function () {
      remoteAudio.play();
      console.log(
        "🚀 ~ file: SipStorage.ts ~ line 44 ~ onTrackAddedEvent ~ remoteAudio",
        remoteAudio,
      );
    };
  }
}

function onSessionDescriptionHandlerCreated(lineObj, sdh) {
  if (sdh) {
    if (sdh.peerConnection) {
      // console.log(sdh);
      sdh.peerConnection.ontrack = function () {
        // console.log(event);
        onTrackAddedEvent(lineObj);
      };
    } else {
      console.warn(
        "onSessionDescriptionHandler fired without a peerConnection",
      );
    }
  } else {
    console.warn(
      "onSessionDescriptionHandler fired without a sessionDescriptionHandler",
    );
  }
}

//implements ISipStorage
export default class SipStorage {
  user: string;
  password: string;
  wsPort: number;
  ip: string;
  wsServer: string;
  userAgent: SIP.UserAgent;
  registerer: SIP.Registerer;
  activeSessions: any = {};
  sipLog: ISipLog = new SipLog({});
  uaEventsHandler: any;
  setCallContinuing = (_isCallContinuing: boolean): void => {};
  setIsCompleteCallByAgent = (_isCompleteCallByAgent: boolean): void => {};
  outCallIsAnswered = (_sessionId: string): void => {};
  setSavedInCall = (_isSavedInCall: boolean = false): void => {};
  startOutgoingRingTone = (): void => {};
  stopOutgoingRingTone = (): void => {};
  reconnecting = (): void => {};
  connected = (): void => {};
  disconnected = (): void => {};
  setOutgoingCall = (): void => {};
  setAcwCallOver = (_isAcwCallOver: boolean = true): void => {};
  remoteAudio = [];

  callConfig = {
    sessionDescriptionHandlerOptions: {
      earlyMedia: true,
      constraints: {
        audio: true,
        video: false,
      },
    },
  };

  constructor(credentials: IUa) {
    this.user = credentials.user;
    this.startOutgoingRingTone =
      credentials.startOutgoingRingTone ?? (() => {});
    this.stopOutgoingRingTone = credentials.stopOutgoingRingTone ?? (() => {});
    this.reconnecting = credentials.reconnecting ?? (() => {});
    this.connected = credentials.connected ?? (() => {});
    this.disconnected = credentials.disconnected ?? (() => {});
    this.setCallContinuing = credentials.setCallContinuing ?? (() => {});
    this.setIsCompleteCallByAgent =
      credentials.setIsCompleteCallByAgent ?? (() => {});
    this.outCallIsAnswered = credentials.outCallIsAnswered ?? (() => {});
    this.setSavedInCall = credentials.setSavedInCall ?? (() => {});
    this.setAcwCallOver = credentials.setAcwCallOver.bind(this);
    this.password = credentials.password;
    this.ip = credentials.ip ? credentials.ip : "127.0.0.1";
    this.wsPort = credentials.wsPort ? credentials.wsPort : 8089;
    this.wsServer = credentials.wsServer
      ? credentials.wsServer
      : `wss://${this.ip}:${this.wsPort}/ws`;
    this.sipLog = credentials.sipLog ? credentials.sipLog : new SipLog({});
    this.uaEventsHandler = credentials.uaEventsHandler
      ? credentials.uaEventsHandler
      : undefined;

    const sipCofig = {
      uri: SIP.UserAgent.makeURI("sip:" + this.user + "@" + this.ip + ":5066"),
      transportOptions: {
        server: this.wsServer,
        traceSip: true,
        connectionTimeout: 15,
      },
      logBuiltinEnabled: false,
      sessionDescriptionHandlerFactoryOptions: {
        iceGatheringTimeout: 500,
      },
      reconnectionAttempts: 5,
      displayName: this.user,
      authorizationUsername: this.user,
      authorizationPassword: this.password,
      contactParams: { transport: "wss" },
      hackIpInContact: true, // Asterisk should also be set to rewrite contact
      userAgentString: "Orange Phone (SIPJS - 0.20.1)",
      autoStart: false,
      autoStop: true,
      register: false,
      unregister: true,
      noAnswerTimeout: 120,

      // sipExtension100rel: // UNSUPPORTED | SUPPORTED | REQUIRED NOTE: rel100 is not supported
      //! TODO
      delegate: {
        onInvite: this.onInvite,
        onRefer: (referral) => {
          // Determine if you should accept or reject the referral
          if (this.shouldAcceptReferral) {
            referral.accept().then(() => {
              referral.makeInviter().invite();
            });
          } else {
            referral.reject();
          }
        },
      },
    };
    this.userAgent = new SIP.UserAgent(sipCofig);
  }

  addListener = (newState, sessionId) => {
    switch (newState) {
      case SIP.SessionState.Establishing:
        console.log("Ringing");
        if (this.uaEventsHandler && this.uaEventsHandler.onSessionProgress) {
          try {
            console.log("progress!", newState);
            this.uaEventsHandler.onSessionProgress(newState);
          } catch ($e) {
            console.log("$e", $e);
          }
        }
        break;
      case SIP.SessionState.Established:
        console.log("Answered");
        this.setSavedInCall();
        this.setCallContinuing(true);
        // let from = this.activeSessions[sessionId].request.headers.From[0];
        // from = typeof from === "object" ? from.raw : from; // raw uses in incoming calls

        // let to = this.activeSessions[sessionId].request.headers.To[0];
        // to = typeof to === "object" ? to.raw : to;

        console.log("accepted!", newState);
        this.stopOutgoingRingTone();

        if (this.uaEventsHandler && this.uaEventsHandler.onSessionAccepted) {
          try {
            this.uaEventsHandler.onSessionAccepted(sessionId, newState);
          } catch ($e) {
            console.log("$e", $e);
          }
        }
        break;
      case SIP.SessionState.Terminating:
        console.log("terminating session", newState, sessionId);
        break;
      case SIP.SessionState.Terminated:
        console.log("Call terminated!");
        this.setOutgoingCall();
        this.setCallContinuing(false);
        this.setAcwCallOver(true);

        if (this.uaEventsHandler && this.uaEventsHandler.onSessionTerminated) {
          try {
            this.uaEventsHandler.onSessionTerminated(sessionId);
          } catch ($e) {
            console.log("$e", $e);
          }
        }

        // List of probable causes with definitions : https://sipjs.com/api/0.11.0/causes/
        try {
          console.log(this.activeSessions[sessionId]);
          if (typeof this.activeSessions[sessionId] != "undefined") {
            delete this.activeSessions[sessionId];
          } else {
            console.error(
              "Outgoing call is terminated by caller.",
              new Error().stack,
              logType.INFO,
            );
          }
        } catch ($e) {
          console.log("$e", $e);
        }
        break;
      default:
        console.log("default", newState, sessionId);
    }
  };

  shouldAcceptReferral = (context) => {
    console.log("referRequested!");
    if (this.uaEventsHandler && this.uaEventsHandler.onSessionReferRequested) {
      try {
        console.log("referRequested!");
        this.uaEventsHandler.onSessionReferRequested(context);
      } catch ($e) {
        console.log("$e", $e);
      }
    }
  };

  onInvite = (session) => {
    console.log("session---------", session);
    this.setCallContinuing(true);
    let sessionId = session.request.getHeader("Call-Id");
    this.activeSessions[sessionId] = session;
    session.stateChange.addListener((newState: SIP.SessionState) =>
      this.addListener(newState, sessionId),
    );
    session.delegate = {
      onSessionDescriptionHandler: function (sdh) {
        onSessionDescriptionHandlerCreated(session, sdh);
      },
    };
    if (this.uaEventsHandler && this.uaEventsHandler.storeInvitedSessionId) {
      try {
        this.uaEventsHandler.storeInvitedSessionId(sessionId);
      } catch ($e) {
        console.log("$e", $e);
      }
    }
    if (this.uaEventsHandler && this.uaEventsHandler.onSessionInvite) {
      try {
        this.uaEventsHandler.onSessionInvite(this, sessionId);
      } catch ($e) {
        console.log("$e", $e);
      }
    }
  };

  makeCall = (destination): string => {
    try {
      const uri = SIP.UserAgent.makeURI("sip:" + destination + "@" + this.ip);
      if (!uri) {
        throw new Error("Failed to create target URI.");
      }

      var spdOptions = {
        earlyMedia: true,
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: { deviceId: "default" },
            video: false,
          },
        },
      };

      // Create new Session instance in "initial" state
      let session = new SIP.Inviter(this.userAgent, uri, spdOptions);

      // Setup session state change handler
      session.stateChange.addListener((newState: SIP.SessionState) =>
        this.addListener(newState, sessionId),
      );

      session.delegate = {
        onSessionDescriptionHandler: function (sdh) {
          onSessionDescriptionHandlerCreated(session, sdh);
        },
      };

      // Options including delegate to capture response messages
      const inviteOptions: SIP.InviterInviteOptions = {
        requestDelegate: {
          onAccept: (response) => {
            console.log("onAccept = " + response);
            this.outCallIsAnswered(sessionId);
          },
          onProgress: function () {
            // onInviteProgress(session,sip);
          },
          onReject: (response) => {
            console.log("onReject = " + response);
          },
        },
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: false,
          },
        },
      };

      // Send initial INVITE
      session
        .invite(inviteOptions)
        .then((request) => {
          console.log("Successfully sent INVITE");
          console.log("INVITE request = " + request);
        })
        .catch(() => {
          console.log("Failed to send INVITE");
        });
      this.startOutgoingRingTone();
      console.log("<this-------", session, "this------->");

      let sessionId = session.request.getHeader("Call-Id");

      this.activeSessions[sessionId] = session;
      return sessionId;
    } catch ($e) {
      console.log("$e", $e);
      return "0";
    }
  };

  acceptCall = (sessionId: string) => {
    try {
      console.log(
        this.activeSessions[sessionId],
        "this.activeSessions[sessionId]",
      );

      const result = this.activeSessions[sessionId].accept(this.callConfig);
      console.log(result, "resultresultresult");
    } catch ($e) {
      console.log("catch", $e);
      console.log("$e", $e);
    }
  };

  sendDtmf = (sessionId: string, tone: string) => {
    try {
      const inviter = this.activeSessions[sessionId];

      const sessionDescriptionHandler = inviter.sessionDescriptionHandler;

      // Check if the session description handler is a WebSessionDescriptionHandler
      if (sessionDescriptionHandler) {
        // Get the peer connection
        const peerConnection = sessionDescriptionHandler.peerConnection;

        // Get the senders from the peer connection
        const senders = peerConnection.getSenders();

        // Find a sender that can send DTMF
        const dtmfSender = senders.find((sender) => !!sender.dtmf);

        if (dtmfSender) {
          // Send the DTMF tone
          dtmfSender.dtmf.insertDTMF(tone);
        }
      }
    } catch ($e) {
      console.log("$e", $e);
    }
  };

  // -
  sendMessage = (destination: string) => {
    try {
      // this.userAgent.message(destination, message);
      console.log(destination + "@" + this.ip, "destination + '@' + this.ip");
    } catch ($e) {
      console.log($e);
      console.log("$e", $e);
    }
  };

  hold = (sessionId: string) => {
    try {
      const session = this.activeSessions[sessionId];
      if (session.isOnHold == true) {
        return;
      }
      session.isOnHold = true;

      var sessionDescriptionHandlerOptions =
        session.sessionDescriptionHandlerOptionsReInvite;
      sessionDescriptionHandlerOptions.hold = true;
      session.sessionDescriptionHandlerOptionsReInvite =
        sessionDescriptionHandlerOptions;

      var options = {
        requestDelegate: {
          onAccept: function () {
            if (
              session &&
              session.sessionDescriptionHandler &&
              session.sessionDescriptionHandler.peerConnection
            ) {
              var pc = session.sessionDescriptionHandler.peerConnection;
              // Stop all the inbound streams
              pc.getReceivers().forEach(function (RTCRtpReceiver) {
                if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = false;
              });
              // Stop all the outbound streams (especially usefull for Conference Calls!!)
              // pc.getSenders().forEach(function (RTCRtpSender) {
              // Mute Audio
              // if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
              //   if (RTCRtpSender.track.IsMixedTrack == true) {
              //     if (
              //       session.data.AudioSourceTrack &&
              //       session.data.AudioSourceTrack.kind == "audio"
              //     ) {
              //       console.log(
              //         "Muting Mixed Audio Track : " +
              //           session.data.AudioSourceTrack.label,
              //       );
              //       session.data.AudioSourceTrack.enabled = false;
              //     }
              //   }
              //   console.log(
              //     "Muting Audio Track : " + RTCRtpSender.track.label,
              //   );
              //   RTCRtpSender.track.enabled = false;
              // }
              // });
            }
            session.isOnHold = true;

            // Log Hold
          },
          onReject: function () {
            session.isOnHold = false;
          },
        },
      };
      session.invite(options).catch(function (error) {
        session.isOnHold = false;
        console.warn("Error attempting to put the call on hold:", error);
      });
      console.log(this.activeSessions[sessionId]);
    } catch ($e) {
      console.log("$e", $e);
    }
  };

  unhold = (sessionId: string) => {
    try {
      const session = this.activeSessions[sessionId];
      if (session.isOnHold == false) {
        return;
      }
      session.isOnHold = false;

      var sessionDescriptionHandlerOptions =
        session.sessionDescriptionHandlerOptionsReInvite;
      sessionDescriptionHandlerOptions.hold = false;
      session.sessionDescriptionHandlerOptionsReInvite =
        sessionDescriptionHandlerOptions;

      var options = {
        requestDelegate: {
          onAccept: function () {
            if (
              session &&
              session.sessionDescriptionHandler &&
              session.sessionDescriptionHandler.peerConnection
            ) {
              var pc = session.sessionDescriptionHandler.peerConnection;
              // Restore all the inbound streams
              pc.getReceivers().forEach(function (RTCRtpReceiver) {
                if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = true;
              });
              // Restorte all the outbound streams
              // pc.getSenders().forEach(function (RTCRtpSender) {
              // Unmute Audio
              // if (RTCRtpSender.track && RTCRtpSender.track.kind == "audio") {
              //   if (RTCRtpSender.track.IsMixedTrack == true) {
              //     if (
              //       session.data.AudioSourceTrack &&
              //       session.data.AudioSourceTrack.kind == "audio"
              //     ) {
              //       console.log(
              //         "Unmuting Mixed Audio Track : " +
              //           session.data.AudioSourceTrack.label,
              //       );
              //       session.data.AudioSourceTrack.enabled = true;
              //     }
              //   }
              //   console.log(
              //     "Unmuting Audio Track : " + RTCRtpSender.track.label,
              //   );
              //   RTCRtpSender.track.enabled = true;
              // }
              // });
            }
            session.isOnHold = false;
          },
          onReject: function () {
            session.isOnHold = true;
          },
        },
      };
      session.invite(options).catch(function (error) {
        session.isOnHold = true;
        console.warn("Error attempting to take to call off hold", error);
      });
    } catch ($e) {
      console.log("$e", $e);
    }
  };

  mute = (sessionId: string) => {
    try {
      console.log(
        this.activeSessions[sessionId].sessionDescriptionHandler.peerConnection,
        "this.activeSessions[sessionId].sessionDescriptionHandler.peerConnection",
      );
      this.activeSessions[sessionId].sessionDescriptionHandler.peerConnection
        .getLocalStreams()
        .forEach(function (stream) {
          stream.getAudioTracks().forEach(function (track) {
            track.enabled = false;
          });
        });
      this.activeSessions[sessionId].sessionDescriptionHandler.peerConnection
        .getReceivers()
        .forEach(function () {
          //stream.track.enabled = false;
          //console.log(stream, 'stream');
          //console.log(stream.track, 'track');
        });
    } catch ($e) {
      console.log("$e", $e);
    }
  };

  unmute = (sessionId: string) => {
    try {
      this.activeSessions[sessionId].sessionDescriptionHandler.peerConnection
        .getLocalStreams()
        .forEach(function (stream) {
          stream.getAudioTracks().forEach(function (track) {
            track.enabled = true;
          });
        });
    } catch ($e) {
      console.log("$e", $e);
    }
  };

  refer = (sessionId: string, destination: string) => {
    console.log("referRequested!");
    const session = this.activeSessions[sessionId];
    if (this.uaEventsHandler && this.uaEventsHandler.onSessionReferRequested) {
      try {
        this.uaEventsHandler.onSessionReferRequested(destination);
      } catch ($e) {
        console.log("$e", $e);
      }
    }
    try {
      session
        .refer(SIP.UserAgent.makeURI("sip:" + destination + "@" + this.ip), {
          requestDelegate: {
            onAccept: () => {
              session.bye().catch(function (error) {
                console.warn("Could not BYE after blind transfer:", error);
              });
            },
          },
        })
        .catch(function (error) {
          console.warn("Failed to REFER", error);
        });
    } catch ($e) {
      console.log("$e", $e);
    }
  };

  register = () => {
    this.userAgent.start().then(() => {
      console.log("Connected");

      this.registerer = new SIP.Registerer(this.userAgent);

      // Setup registerer state change handler
      this.registerer.stateChange.addListener((newState) => {
        switch (newState) {
          case SIP.RegistererState.Registered:
            console.log("Registered");
            console.log("this----", this);
            if (this.uaEventsHandler && this.uaEventsHandler.onUaRegistered) {
              try {
                this.connected();
                console.log("registered!", this.userAgent);
                this.uaEventsHandler.onUaRegistered(this);
              } catch ($e) {
                console.log("$e", $e);
              }
            }
            break;
          case SIP.RegistererState.Unregistered:
            console.log("Unregistered");
            console.log("unregistered!", this.userAgent);
            if (this.uaEventsHandler && this.uaEventsHandler.onUaUnregistered) {
              try {
                this.disconnected();
                console.log("unregistered!");
                this.uaEventsHandler.onUaUnregistered();
              } catch ($e) {
                console.log("$e", $e);
              }
            }
            break;
          case SIP.RegistererState.Terminated:
            console.log("Terminated");
            break;
        }
      });
      // Send REGISTER
      this.registerer
        .register()
        .then((request) => {
          console.log("Successfully sent REGISTER");
          console.log("Sent request = " + request);
        })
        .catch(() => {
          console.error("Failed to send REGISTER");
        });

      if (this.registerer.state === SIP.RegistererState.Registered) {
        // Currently registered
      }
    });
  };

  unregister = () => {
    this.registerer &&
      this.registerer
        .unregister()
        .then((request) => {
          console.log("Successfully sent un-REGISTER");
          console.log("Sent request = " + request);
        })
        .catch(() => {
          console.error("Failed to send un-REGISTER");
          console.log("Failed to send un-REGISTER");
        });
  };

  terminateCall = (sessionId: string) => {
    let bye = false;
    try {
      console.log(this.activeSessions[sessionId], "terminateCall");
      switch (this.activeSessions[sessionId]?._state) {
        case SIP.SessionState.Initial:
        case SIP.SessionState.Establishing:
          if (this.activeSessions[sessionId] instanceof SIP.Inviter) {
            // An unestablished outgoing session
            this.activeSessions[sessionId].cancel();
            this.setCallContinuing(false);
            this.setIsCompleteCallByAgent(true);
            console.log("cancel!");

            if (this.uaEventsHandler && this.uaEventsHandler.onSessionCancel) {
              try {
                console.log("cancel!");
                this.uaEventsHandler.onSessionCancel();
              } catch ($e) {
                console.log("$e", $e);
              }
            }
          } else {
            // An unestablished incoming session
            this.activeSessions[sessionId].reject();
            console.log("rejected!");
            this.setCallContinuing(false);
          }
          break;
        case SIP.SessionState.Established:
          // An established session
          this.activeSessions[sessionId].bye();
          bye = true;
          this.setAcwCallOver(true);
          this.setCallContinuing(false);
          this.setIsCompleteCallByAgent(true);
          console.log("bye!");
          console.log("terminateCall SIP.SessionState.Established", this);
          break;
        case SIP.SessionState.Terminated:
          console.log("Call terminated!");
          console.log("terminateCall SIP.SessionState.Terminated", bye);
          this.setOutgoingCall();
          this.setCallContinuing(false);
          this.setAcwCallOver(true);

          if (
            this.uaEventsHandler &&
            this.uaEventsHandler.onSessionTerminated
          ) {
            try {
              this.uaEventsHandler.onSessionTerminated(sessionId);
            } catch ($e) {
              console.log("$e", $e);
            }
          }

          // List of probable causes with definitions : https://sipjs.com/api/0.11.0/causes/
          try {
            console.log(this.activeSessions[sessionId]);
            if (typeof this.activeSessions[sessionId] != "undefined") {
              delete this.activeSessions[sessionId];
            } else {
              console.error(
                "Outgoing call is terminated by caller.",
                new Error().stack,
                logType.INFO,
              );
            }
          } catch ($e) {
            console.log("$e", $e);
          }
          // Cannot terminate a session that is already terminated
          break;
      }
      delete this.activeSessions[sessionId];
    } catch ($e) {
      console.log("$e", $e);
    }
  };
}
