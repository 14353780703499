import { styled } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
  searchByCif,
  searchByFin,
  searchByVoen,
  searchByFullnameAndDate,
} from "Api/atbCrmIntegration";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import LoadingComponent from "Components/Loading/LoadingComponent";
import moment from "moment";

import React, { useState } from "react";

import { customerColumns } from "./constants";
import InformationTable from "./InformationTable";
import TableDrawer from "./TableDrawer";

const CustomerSearch = (): React.ReactElement => {
  const { translate } = useTranslation();
  const [cif, setCif] = useState("");
  const [fin, setFin] = useState("");
  const [voen, setVoen] = useState("");
  const [fullName, setFullname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentCif, setCurrentCif] = useState("");
  const [currentCustomerType, setCurrentCustomerType] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCifChange = (e) => {
    setCif(e.target.value);
  };

  const searchCif = async () => {
    setLoading(true);

    return searchByCif({ cif })
      .then((res) => {
        if (res) setRows(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const handleFinChange = (e) => {
    setFin(e.target.value);
  };

  const searchFin = async () => {
    setLoading(true);

    return searchByFin({ fin })
      .then((res) => {
        if (res) setRows(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const handleVoenChange = (e) => {
    setVoen(e.target.value);
  };

  const searchVoen = async () => {
    setLoading(true);

    return searchByVoen({ voen })
      .then((res) => {
        if (res) setRows(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const handleFullnameChange = (e) => {
    setFullname(e.target.value);
  };

  const handleDateChange = (e) => {
    setDateOfBirth(e.target.value);
  };

  const searchFullnameAndDate = () => {
    if (!fullName || !dateOfBirth) {
      setError(true);
      return;
    } else setError(false);

    setLoading(true);

    return searchByFullnameAndDate({
      fullName,
      dateOfBirth: moment(new Date(dateOfBirth)).format("MM.DD.YYYY"),
    })
      .then((res) => {
        if (res) setRows(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const handleRowClick = (id) => {
    setCurrentCif(rows[id]?.cif ?? "");
    setCurrentCustomerType(rows[id]?.customerType ?? "");
    handleOpen();
  };

  return (
    <Container>
      <Main>
        <InputBox>
          <div style={{ width: "75%" }}>
            <Label>{translate("request_CIF")}</Label>
            <Input
              type='text'
              placeholder={translate("request_CIF")}
              value={cif}
              onChange={handleCifChange}
            />
          </div>
          <SearchButton onClick={searchCif}>
            <Icon />
          </SearchButton>
        </InputBox>
        <InputBox>
          <div style={{ width: "75%" }}>
            <Label>{translate("request_FIN")}</Label>
            <Input
              type='text'
              placeholder={translate("request_FIN")}
              value={fin}
              onChange={handleFinChange}
            />
          </div>
          <SearchButton onClick={searchFin}>
            <Icon />
          </SearchButton>
        </InputBox>
        <InputBox>
          <div style={{ width: "75%" }}>
            <Label>{translate("request_VOEN")}</Label>
            <Input
              type='text'
              placeholder={translate("request_VOEN")}
              value={voen}
              onChange={handleVoenChange}
            />
          </div>
          <SearchButton onClick={searchVoen}>
            <Icon />
          </SearchButton>
        </InputBox>
        <InputBox>
          <div style={{ width: "75%" }}>
            <Label>{translate("request_fullname")}</Label>
            <Input
              type='text'
              placeholder={translate("request_fullname")}
              value={fullName}
              onChange={handleFullnameChange}
            />
            <Label style={{ marginTop: 10 }}>
              {translate("request_date_of_birth")}
            </Label>
            <Input
              type='date'
              placeholder={translate("request_date_of_birth")}
              value={dateOfBirth}
              onChange={handleDateChange}
            />
            {error && (
              <ErrorMessage>
                {translate("request_must_added_error")}
              </ErrorMessage>
            )}
          </div>
          <SearchButton onClick={searchFullnameAndDate}>
            <Icon />
          </SearchButton>
        </InputBox>
      </Main>

      <TableContainer>
        {rows.length > 0 && (
          <InformationTable
            rows={rows}
            fetchRows={searchCif}
            columns={customerColumns}
            handleRowClick={handleRowClick}
          />
        )}
      </TableContainer>

      {loading && <LoadingComponent />}
      <TableDrawer
        open={open}
        onClose={handleClose}
        cif={currentCif}
        customerType={currentCustomerType}
      />
    </Container>
  );
};

export default CustomerSearch;

const Container = styled("div")({
  height: "calc(100vh - var(--nav-bar-height) - 100px)",
  padding: 15,
});

const Main = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const TableContainer = styled("div")({
  marginTop: 15,
});

const InputBox = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "25%",
  borderRight: "1px solid #A7AEBB",
  "&:not(first-of-type)": { paddingLeft: 10 },
  "&:last-of-type": { borderRight: "none" },
});

const Input = styled("input")({
  display: "block",
  width: "100%",
  color: "#020110",
  fontSize: 13,
  lineHeight: "100%",
  letterSpacing: "0.02em",
  border: "none",
  outline: "none",
  borderBottom: "1px solid #A7AEBB",
  paddingBottom: 5,
});

const SearchButton = styled("div")({
  background: "rgb(0, 178, 150)",
  width: "10%",
  height: 35,
  borderRadius: 8,
  textTransform: "uppercase",
  cursor: "pointer",
  margin: "0 auto",
  marginTop: 15,
});

const Label = styled("span")({
  color: "#414147",
  fontSize: 13,
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: 0.6,
  display: "block",
  textAlign: "left",
  marginBottom: 5,
});

const ErrorMessage = styled("span")({
  display: "block",
  textAlign: "left",
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "20px",
  letterSpacing: 0.6,
  color: "#FF5D5D",
  marginTop: 8,
});

const Icon = styled(SearchIcon)({
  color: "#fff",
  fontWeight: 600,
  fontSize: 22,
  lineHeight: 17,
  position: "relative",
  top: 5,
});
