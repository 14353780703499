import { useRef, useState } from "react";

import { IFormDynamicField } from "../interfaces";

export const useCustomInput = (props: IFormDynamicField): any => {
  const [label, setLabel] = useState(props.label);
  const [value, setValue] = useState(props.value);

  const timeoutRef = useRef(null);

  const handleChange = (event): void => {
    const value = event.target.value;
    setValue(value);
    props.handleChange(value, props.id, props.label);
  };

  const handlePlaceHolderChange = (event): void => {
    event.persist();

    const value = event.target.value;
    setLabel(value);

    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      props.handlePlaceHolderChange(props.id, value);
    }, 500);
  };

  return {
    label,
    value,
    handlePlaceHolderChange,
    handleChange,
  };
};

useCustomInput.displayName = "useCustomInput";
