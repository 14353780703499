import React, { useContext } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { Grid, styled } from "@material-ui/core";

import Categories from "./Categories/Main";
import Questions from "./Questions/Main";
import ViewQuestion from "./Questions/ViewQuestion";

import { defaultStates as defaultFaqStates } from "Store/Reducers/faqReducer";
import { RequestContextStore } from "Components/Layout/AppLayout";
import Faq from "Store/Actions/Faq";

const FaqContainer = () => {
  const totalFaqState = useSelector(
    (state: RootStateOrAny) => state.faqReducer,
  );
  const requestContextStore: any = useContext(RequestContextStore);
  const faqState = requestContextStore.getCurrentStateFromTotalState(
    totalFaqState,
    defaultFaqStates,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const faq = new Faq(useDispatch());

  const handleGoBack = () => {
    faq.setSelectedQuestion(
      requestState.menuType,
      requestContextStore.getRequestId(),
      null,
    );
  };

  return (
    <Container container>
      {faqState.selectedQuestion ? (
        <ViewQuestion handleGoBack={handleGoBack} />
      ) : (
        <>
          <CategoriesGrid item xs={4}>
            <Categories />
          </CategoriesGrid>
          <QuestionsGrid item xs={8}>
            <Questions />
          </QuestionsGrid>
        </>
      )}
    </Container>
  );
};

export default FaqContainer;

const Container = styled(Grid)({
  height: "calc(100vh - var(--nav-bar-height) - 160px)",
});

const CategoriesGrid = styled(Grid)({
  borderRight: "1px solid #F3F2F4",
  height: "calc(100vh - var(--nav-bar-height) - 160px)",
});

const QuestionsGrid = styled(Grid)({
  height: "calc(100vh - var(--nav-bar-height) - 160px)",
});
