import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import React from "react";

import { useIdentifierInput } from "./hooks";
import { IInputs } from "./interfaces";

const IdentifierInput = ({
  id,
  fieldValue,
  handleChange,
  label,
}: IInputs): React.ReactElement => {
  const { localLabel, localValue, handleInput, chooseIcon } =
    useIdentifierInput({
      id,
      fieldValue,
      handleChange,
      label,
    });

  return (
    <Container>
      <Label htmlFor={localLabel}>{localLabel}</Label>
      <Grid container>
        <Grid item xs={11}>
          <Input
            id={localLabel}
            type='text'
            value={localValue}
            onChange={handleInput}
          />
        </Grid>
        <Grid item xs={1}>
          {chooseIcon()}
        </Grid>
      </Grid>
    </Container>
  );
};

export default IdentifierInput;

IdentifierInput.displayName = "IdentifierInput";

const Container = styled("div")({
  display: "inline-block",
  width: "90%",
  float: "left",
  marginRight: 15,
  marginLeft: 15,
});

const Input = styled("input")({
  width: "100%",
  color: "#020110",
  fontSize: 13,
  lineHeight: "100%",
  letterSpacing: "0.02em",
  border: "none",
  outline: "none",
});

const Label = styled("label")({
  color: "#414147",
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  display: "block",
  textAlign: "left",
  marginBottom: 10,
  marginTop: 10,
});
