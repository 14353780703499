import { createTheme } from "@material-ui/core";
import { orange, red } from "@material-ui/core/colors";

const Theme = createTheme({
  palette: {
    secondary: orange,
    error: red,
  },
  overrides: {
    // Style sheet name ⚛️
    MuiMenu: {
      list: {
        fontSize: 13,
      },
    },
    MuiButton: {
      root: {
        fontSize: 11,
      },
    },
    MuiTypography: {
      root: {
        fontSize: 13,
      },
      noWrap: {
        fontSize: 13,
      },
    },
    MuiTextField: {
      root: {
        fontSize: 13,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 13,
        "& input:focus": {
          boxShadow: "0 0 1px 1px white !important",
        },
      },
    },
    MuiLink: {
      root: {
        fontSize: 13,
      },
    },
    MuiInput: {
      root: {
        fontSize: 13,
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: 13,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 13,
      },
      shrink: {
        //transform: 'translate(14px, -6px) scale(1) !important',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 13,
      },
    },
    MuiIconButton: {
      root: {
        padding: 10,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 18,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 13,
      },
    },
    MuiListItem: {
      root: {
        fontSize: "13px !important",
      },
    },
  },
});

export default Theme;
