import axios from "Api/axios";
import WebConf, {
  TaskManagementUrl,
  WorkScheduleUrl,
  FileServerUrl,
  defaultRoute,
} from "Config/WebConf";
import { fetchData } from "networking";

import {
  ICategory,
  WorkingType,
} from "Pages/Configuration/TaskManagement/interfaces";
import { formatDateString } from "Utils/UtilsFunc";

import { SendEmailData } from "./interfaces";

// GET
export const getAllCategories = () =>
  axios
    .get(TaskManagementUrl + WebConf.task.getAllCategories)
    .then((response) => response && response.data);

export const fetchAllCategories = () =>
  axios
    .get(TaskManagementUrl + WebConf.task.fetchAllCategories)
    .then((response) => response && response.data);

export const getCategoryData = (categoryId: number) =>
  axios
    .get(TaskManagementUrl + WebConf.task.findCategoryById(categoryId))
    .then((response) => response && response.data);

export const getCategoriesTable = (page: number, size: number) =>
  axios
    .get(
      TaskManagementUrl +
        WebConf.task.getCategoriesTable +
        "?page=" +
        page +
        "&size=" +
        size,
    )
    .then((response) => response && response.data);

export const findCategoryById = (id: number) =>
  axios
    .get(TaskManagementUrl + WebConf.task.findCategoryById(id))
    .then((response) => response && response.data);

export const getTaskById = (id: number) =>
  axios
    .get(TaskManagementUrl + WebConf.task.getTaskById(id))
    .then((response) => response && response.data);

export const getCommentsById = (id: number) =>
  axios
    .get(TaskManagementUrl + WebConf.task.getCommentsById + "/" + id)
    .then((response) => response && response.data);

export const getTaskLogsById = (id: number) =>
  axios
    .get(TaskManagementUrl + WebConf.task.getTaskLogsById + "/" + id)
    .then((response) => response && response.data);

export const getGlobalTagsById = (id: number) =>
  axios
    .get(TaskManagementUrl + WebConf.task.getGlobalTagsById(id))
    .then((response) => response && response.data);

export const getTaskNotificationByUserId = (id: number | string) =>
  axios
    .get(
      TaskManagementUrl + WebConf.task.getTaskNotificationByUserId + "/" + id,
    )
    .then((response) => response && response.data);

export const downloadAttachment = async (path: string) => {
  return await fetchData(
    defaultRoute + WebConf.file.downloadAttachment + "/" + path,
  );
};

export const searchByNameAdvancedTags = (name: string) =>
  axios
    .get(TaskManagementUrl + WebConf.task.searchByNameAdvancedTags(name))
    .then((response) => response && response.data);

export const getAllRegexes = () =>
  axios
    .get(TaskManagementUrl + WebConf.task.getAllRegexes)
    .then((response) => response && response.data);

// DELETE
export const deleteTaskById = (id: number) =>
  axios
    .delete(TaskManagementUrl + WebConf.task.getTaskById(id))
    .then((response) => response && response.status === 200);

export const deleteCategory = (categoryId: number) =>
  axios
    .delete(TaskManagementUrl + WebConf.task.deleteCategory(categoryId))
    .then((response) => response && response.status === 200);

// PUT
export const editTask = (task: any) =>
  axios
    .put(TaskManagementUrl + WebConf.task.editTask, task)
    .then((response) => response && response.data);

export const editReadNotification = (id: string) =>
  axios
    .put(
      TaskManagementUrl +
        WebConf.task.readNotificationById +
        "/" +
        id +
        "/read",
    )
    .then((response) => response && response.data);

// POST
export const saveGlobalTag = (name: string) => {
  const body = {
    name,
  };

  return axios
    .post(TaskManagementUrl + WebConf.task.saveGlobalTag, body)
    .then((response) => response && response.data);
};

export const activeTask = (taskId: number) =>
  axios
    .post(TaskManagementUrl + WebConf.task.activeTask(taskId))
    .then((response) => response && response.status === 200);

export const closeTask = (taskId: number, body) =>
  axios
    .post(TaskManagementUrl + WebConf.task.closeTask(taskId), body)
    .then((response) => response && response.status === 200);

export const toggleCategoryActivation = (
  categoryId: number,
  isActive: boolean,
) =>
  axios
    .post(
      TaskManagementUrl +
        WebConf.task.toggleCategoryActivation(categoryId, isActive),
    )
    .then((response) => response && response.status === 200);

export const saveCategory = (category: ICategory) =>
  axios
    .post(TaskManagementUrl + WebConf.task.saveCategory, category)
    .then((response) => response && response.data);

export const saveAllTasks = (taskList: any) =>
  axios
    .post(TaskManagementUrl + WebConf.task.saveAllTasks, taskList)
    .then((response) => response && response.data);

export const createTask = (task: any) =>
  axios
    .post(TaskManagementUrl + WebConf.task.createTask, task)
    .then((response) => response && response.data);

export const getTasksByUserTable = (
  page: number,
  size: number,
  filter,
  sortedData: any,
) => {
  const body = {
    page,
    size,
    fieldName: sortedData.fieldName,
    order: sortedData.order,
    ...filter,
    ownerIDList: null,
  };

  return axios
    .post(TaskManagementUrl + WebConf.task.getTasksByUser, body)
    .then((response) => response && response.data);
};

export const getAllTasksTable = (
  page: number,
  size: number,
  filter,
  sortedData: any,
) => {
  const body = {
    page,
    size,
    fieldName: sortedData.fieldName,
    order: sortedData.order,
    ...filter,
    ownerIDList: filter.ownerIDList?.length > 0 ? filter.ownerIDList : null,
  };

  return axios
    .post(TaskManagementUrl + WebConf.task.getAllTasks, body)
    .then((response) => response && response.data);
};

export const getClosedTasksByUserTable = (
  page: number,
  size: number,
  filter,
) => {
  const body = {
    page,
    size,
    ...filter,
    ownerIDList: null,
    status: "CLOSED",
  };

  return axios
    .post(TaskManagementUrl + WebConf.task.getClosedTasksByUser, body)
    .then((response) => response && response.data);
};

export const getAllClosedTasksTable = (page: number, size: number, filter) => {
  const body = {
    page,
    size,
    ...filter,
    ownerIDList: filter.ownerIDList?.length > 0 ? filter.ownerIDList : null,
    status: "CLOSED",
  };

  return axios
    .post(TaskManagementUrl + WebConf.task.getAllClosedTasks, body)
    .then((response) => response && response.data);
};

export const assignTask = (taskId: number) =>
  axios
    .post(TaskManagementUrl + WebConf.task.assignTask(taskId))
    .then((response) => response && response.status === 200);

export const addComment = (
  taskId: number,
  comment: string,
  attachments: object[],
) => {
  const body = {
    taskId,
    comment,
    attachments,
  };

  return axios
    .post(TaskManagementUrl + WebConf.task.saveComment, body)
    .then((response) => response && response.status === 200);
};

export const saveEmail = (name: string) => {
  const body = {
    name,
  };

  return axios
    .post(TaskManagementUrl + WebConf.task.saveEmail, body)
    .then((response) => response && response.data);
};

export const sendEmailApi = async (
  sendEmailData: SendEmailData,
  attachments: string,
) => {
  const body = {
    taskRecipients: sendEmailData.taskRecipients,
    text: sendEmailData.text,
    subject: sendEmailData.subject,
    fieldMap: sendEmailData.fieldMap,
    taskId: sendEmailData.taskId,
    attachments,
  };

  return axios
    .post(defaultRoute + WebConf.task.sendEmail, body)
    .then((response) => response && response.data);
};

export const saveAdvancedGlobalTag = (body) => {
  return axios
    .post(TaskManagementUrl + WebConf.task.saveAdvancedGlobalTag, body)
    .then((response) => response && response.data);
};

export const uploadAttachments = async (attachments: FormData) => {
  const params: RequestInit = {
    method: "POST",
    body: attachments,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const response = await fetchData(
    FileServerUrl + WebConf.file.uploadAttachments,
    params,
  );

  if (response && response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const getWorkSchedule = async (fromDate: Date, toDate: Date) => {
  const fromDateString = formatDateString(fromDate);
  const toDateString = formatDateString(toDate);

  return axios
    .get(
      WorkScheduleUrl +
        WebConf.task.getExceptionWorkDays(fromDateString, toDateString),
    )
    .then((response) => response && response.data);
};

export const getDefaultWorkSchedule = async () => {
  return axios
    .get(WorkScheduleUrl + WebConf.task.getDefaultWorkDays())
    .then((response) => response && response.data);
};

export const createExclusionDay = async (date: Date, workType: WorkingType) => {
  const dateString = formatDateString(date);
  return axios
    .post(WorkScheduleUrl, { dateExclusion: dateString, workingType: workType })
    .then((response) => response && response.data);
};

export const removeExclusionDay = async (id: number) => {
  return axios
    .delete(WorkScheduleUrl + "/" + id)
    .then((response) => response && response.data);
};
