import { styled } from "@material-ui/core/styles";
import React from "react";

interface ActionInfo {
  name: string;
  Icon: React.ReactElement<any, any>;
  onClick: Function;
}

const ActionIcon = (props: ActionInfo) => {
  let { Icon, name, onClick } = props;

  return (
    <StyledActionIcon onClick={() => onClick()}>
      {Icon}
      <ActionName>{name}</ActionName>
    </StyledActionIcon>
  );
};

export default ActionIcon;

const StyledActionIcon = styled("div")({
  minWidth: "26px",
  height: "41px",
  cursor: "pointer",
  display: "inline-block",
  marginRight: "15px",
});

const ActionName = styled("p")({
  textTransform: "uppercase",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  color: "#050038",
  marginTop: 6,
});
