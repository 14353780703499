import { styled } from "@material-ui/core";
import { KeyboardArrowDownIcon } from "Assets/icons";

export const BreaksIcon = styled(KeyboardArrowDownIcon)({
  display: "inline-block",
  position: "relative",
  top: 3,
  marginLeft: 5,
  cursor: "pointer",
});

export const BreaksList = styled("ul")({
  padding: 0,
});

export const Break = styled("li")({
  cursor: "pointer",
});

export const BreakName = styled("span")({
  color: "#050038",
  fontSize: 12,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
});

export const BreakDuration = styled("span")({
  color: "#9F9DAD",
  fontSize: 12,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  float: "right",
  position: "relative",
  top: 3,
  marginLeft: 15,
});
