import { Grid, styled } from "@material-ui/core";

import Crm from "Components/Request//LeftPanel/Crm";
import React, { useState } from "react";

import { tabs } from "./constants";
import LeftPanelTabs from "./LeftPanelTabs";

const RequestLeftPanel = (): React.ReactElement => {
  const [currentTab] = useState(tabs.CRM);

  let CurrentComponent = null;

  switch (currentTab) {
    case tabs.CRM:
      CurrentComponent = <Crm />;
      break;
    default:
      CurrentComponent = <div></div>;
  }

  return (
    <StyledRequestLeftPanel item xs={4}>
      <LeftPanelTabs />

      {CurrentComponent}
    </StyledRequestLeftPanel>
  );
};

export default RequestLeftPanel;

RequestLeftPanel.displayName = "RequestLeftPanel";

const StyledRequestLeftPanel = styled(Grid)({
  overflow: "auto",
  backgroundColor: "white",
  height: "100%",
  "&::-webkit-scrollbar": {
    width: 3,
    height: 6,
  },
});
