import React from "react";

import InformationTable from "./InformationTable";
import { cardColumns as columns } from "./constants";

const CardTable = ({ rows, fetchRows }) => (
  <InformationTable rows={rows} fetchRows={fetchRows} columns={columns} />
);

export default CardTable;
