import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    modalHeader: {
      textAlign: "center",
      padding: 10,
      background: "aliceblue",
    },
    tableRow: {
      minHeight: 60,
      height: 60,
    },
    tableCell: {
      width: "50%",
      textAlign: "center",
      padding: 0,
    },
    tableCellRight: {
      width: "40%",
      textAlign: "center",
      padding: 0,
      paddingRight: "10px",
    },
    uploadWrapper: {
      top: -40,
      position: "relative",
    },
    imageInput: {
      opacity: 0,
      top: -60,
      width: 60,
      height: 60,
      borderRadius: "50%",
      position: "relative",
      cursor: "pointer",
      float: "left",
    },
    header: {
      height: "150px",
      display: "flex",
      flexDirection: "column",
    },
    containerImage: {
      height: "80px",
      display: "flex",
      flexDirection: "column",
    },
    headerTop: {
      flex: "1",
      background: theme.palette.primary.main,
    },
    headerBottom: {
      borderRight: "1px solid " + grey[100],
      borderLeft: "1px solid " + grey[100],
      flex: "1",
      background: "white",
      textAlign: "center",
      fontSize: "25px",
    },
    headerBottomText: {
      marginTop: 60,
    },
    photoWrapper: {
      position: "relative",
      flex: 1,
      flexGrow: 0,
      height: 0,
      textAlign: "center",
    },
    avatar: {
      width: 60,
      height: 60,
      border: "1px solid orange",
      backgroundColor: "white",
      boxShadow: "0px 5px 15px rgba(5, 0, 56, 0.1)",
      margin: 5,
    },
    tableContainer: {
      marginTop: 5,
      marginBottom: 10,
    },
    defaultAvatar: {},
    headerStyle: {
      background: "#f7f7f7",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#746653",
      fontWeight: "bold",
      height: "40px",
      marginTop: "10px",
      marginBottom: "30px",
      fontSize: "15px",
    },
    addUserContainer: {
      marginLeft: "20%",
      marginRight: "20%",
    },
    inputContainer: {
      marginTop: "70px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "30px",
      marginBottom: 20,
    },
    buttonClass: {
      width: "25%",
      alignItems: "center",
      margin: "0 auto",
    },
    addUserHeader: {},
  }),
);
