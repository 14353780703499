import { breakActions as actionTypes } from "../Actions/actionTypes";

const initialState = {
  breaks: [],
  selectedBreakId: "default",
  closeBreakId: null,
  breakName: "",
  allowCallTime: null,
};

const breakReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BREAKS:
      return { ...state, breaks: action.breaks };
    case actionTypes.SET_SELECTED_BREAK_ID:
      return { ...state, selectedBreakId: action.breakId };
    case actionTypes.SET_CLOSE_BREAK_ID:
      return { ...state, closeBreakId: action.breakId };
    case actionTypes.SET_BREAK_NAME:
      return { ...state, breakName: action.breakName };
    case actionTypes.SET_ALLOW_CALL_TIME:
      return { ...state, allowCallTime: action.time };
    default:
      return state;
  }
};

export default breakReducer;
