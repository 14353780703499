/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { styled } from "@material-ui/core/styles";

import { defaultStates as defaultFaqStates } from "Store/Reducers/faqReducer";
import { RequestContextStore } from "Components/Layout/AppLayout";
import Faq from "Store/Actions/Faq";

import Header from "./Header";
import Search from "./Search";
import Categories from "./Categories";

const Main = () => {
  const totalFaqState = useSelector(
    (state: RootStateOrAny) => state.faqReducer,
  );
  const requestContextStore: any = useContext(RequestContextStore);
  const faqState = requestContextStore.getCurrentStateFromTotalState(
    totalFaqState,
    defaultFaqStates,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const faq = new Faq(useDispatch());

  const onInput = (event) => {
    let { value } = event.target;

    if (value === "")
      faq.setExpandAllCategories(
        requestState.menuType,
        requestContextStore.getRequestId(),
        false,
      );
    else
      faq.setExpandAllCategories(
        requestState.menuType,
        requestContextStore.getRequestId(),
        true,
      );
    faq.setCategorySearchValue(
      requestState.menuType,
      requestContextStore.getRequestId(),
      value,
    );
  };

  return useMemo(
    () => (
      <Container>
        <Header />
        <Search onInput={onInput} value={faqState.searchValue} />
        <Categories />
      </Container>
    ),
    [
      faqState.searchValue,
      requestState.menuType,
      requestContextStore.getRequestId(),
    ],
  );
};

export default Main;

const Container = styled("div")({});
