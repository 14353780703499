import menuTypes from "Assets/constants/menuTypes";

import { IState, IAction } from "./interfaces/topicReducer.types";
import { topicActions as actionTypes } from "../Actions/actionTypes";
import {
  getRequestStates,
  updateObject,
  updateTotalState,
  deepClone,
} from "../utility";

const initialState: IState = {
  [menuTypes.CALL]: {},
  [menuTypes.CHAT]: {},
};

export const defaultStates = {
  searchValue: "",
  isSearching: false,
  allClassificators: [],
  originalClassificators: [],
  expandAll: false,
  savedPaths: [],
  savedPathIds: [],
};

const setClassificatorSearchValue = (
  state: IState,
  action: Partial<IAction>,
): IState => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestStates = updateObject(requestStates, { searchValue: action.value });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const setClassificatorIsSearching = (
  state: IState,
  action: Partial<IAction>,
): IState => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestStates = updateObject(requestStates, {
    isSearching: action.isSearching,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const setOriginalClassificator = (
  state: IState,
  action: Partial<IAction>,
): IState => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestStates = updateObject(requestStates, {
    originalClassificators: action.originalClassificators,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const setExpandAllClassificators = (
  state: IState,
  action: Partial<IAction>,
): IState => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestSatates = updateObject(requestSatates, {
    expandAll: action.expandAll,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const setAllClassificators = (
  state: IState,
  action: Partial<IAction>,
): IState => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestStates = updateObject(requestStates, {
    allClassificators: action.allClassificators,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const addNewPath = (state: IState, action: Partial<IAction>): IState => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const savedPaths = [...requestStates.savedPaths, action.path];
  const savedPathIds = [...requestStates.savedPathIds, action.path.id];

  requestStates = updateObject(requestStates, {
    savedPaths,
    savedPathIds,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const addPathNote = (state, action) => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  let savedPaths = [...requestStates.savedPaths];

  for (let i = 0; i < savedPaths.length; i++) {
    if (savedPaths[i].id === action.id) {
      savedPaths[i].note = action.note;
      break;
    }
  }

  requestStates = updateObject(requestStates, {
    savedPaths,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const addNewPaths = (state: IState, action: Partial<IAction>): IState => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const savedPaths = [...action.paths];

  const ids = action.paths.map((path) => path.id);

  const savedPathIds = [...ids];

  requestStates = updateObject(requestStates, {
    savedPaths,
    savedPathIds,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const removePath = (state: IState, action: Partial<IAction>): IState => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  let { savedPaths, savedPathIds } = requestStates;

  savedPathIds = savedPathIds.filter((pathId) => pathId !== action.id);
  savedPaths = savedPaths.filter((savedPath) => savedPath.id !== action.id);

  requestStates = updateObject(requestStates, {
    savedPaths,
    savedPathIds,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const clearTopicsByRequestId = (
  state: IState,
  action: Partial<IAction>,
): IState => {
  const topics = deepClone(state[action.menuType]);

  delete topics[action.requestId];

  return updateObject(state, { [action.menuType]: topics });
};

const topicReducer = (
  state = initialState,
  action: Partial<IAction>,
): IState => {
  switch (action.type) {
    case actionTypes.SET_CLASSIFICATOR_SEARCH_VALUE:
      return setClassificatorSearchValue(state, action);
    case actionTypes.SET_CLASSIFICATOR_IS_SEARCHING:
      return setClassificatorIsSearching(state, action);
    case actionTypes.SET_EXPAND_ALL_CLASSIFICATORS:
      return setExpandAllClassificators(state, action);
    case actionTypes.SET_ALL_CLASSIFICATORS:
      return setAllClassificators(state, action);
    case actionTypes.SET_ORIGINAL_CLASSIFICATORS:
      return setOriginalClassificator(state, action);
    case actionTypes.ADD_NEW_PATH:
      return addNewPath(state, action);
    case actionTypes.ADD_PATH_NOTE:
      return addPathNote(state, action);
    case actionTypes.ADD_NEW_PATHS:
      return addNewPaths(state, action);
    case actionTypes.REMOVE_PATH:
      return removePath(state, action);
    case actionTypes.CLEAR_TOPICS_BY_REQUEST_ID:
      return clearTopicsByRequestId(state, action);
    default:
      return state;
  }
};

export default topicReducer;
