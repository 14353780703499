/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Paper,
  Popover,
  createStyles,
  makeStyles,
} from "@material-ui/core";

import { buttonTypes } from "Components/Buttons/buttonTypes";
import LinkButton from "Components/Buttons/LinkButton";

import { StoreContext } from "./Table";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
interface IColumnsCheck {
  anchorEl: any;
  open: boolean;
  handleClose: Function;
}

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: "10px",
    },
    cardActions: {
      marginLeft: "25px",
      marginRight: "25px",
    },
    paper: {
      margin: "5px",
      padding: "5px",
      width: "250px",
      float: "left",
      wordBreak: "break-word",
    },
  }),
);

export default function ColumnsCheck({
  anchorEl,
  open,
  handleClose,
}: IColumnsCheck) {
  const { translate } = useTranslation();
  const classes = useStyles();

  const store: any = useContext(StoreContext);

  const handleCheckBoxChange = (fieldName, isChecked) => {
    let updatedShowedColumns = [...store.showedColumns.get()];
    if (!isChecked) {
      updatedShowedColumns = updatedShowedColumns.filter(
        (field) => field !== fieldName,
      );
    } else {
      updatedShowedColumns.push(fieldName);
    }
    store.showedColumns.set(updatedShowedColumns);
  };

  const selectAll = () => {
    store.showedColumns.set(store.columns.get().map((column) => column.name));
  };

  const selectNone = () => {
    store.showedColumns.set([]);
  };

  return useMemo(
    () => (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={(e) => handleClose(e)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <Card className={classes.card}>
          <CardActions className={classes.cardActions}>
            <LinkButton buttonType={buttonTypes.default} onClick={selectAll}>
              {translate("select_all")}
            </LinkButton>
            <LinkButton buttonType={buttonTypes.default} onClick={selectNone}>
              {translate("select_none")}
            </LinkButton>
          </CardActions>
          <CardContent>
            {store.columns.get().map((column) => {
              return (
                <Paper className={classes.paper} key={column.name}>
                  <FormControlLabel
                    checked={store.showedColumns.get().includes(column.name)}
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleCheckBoxChange(column.name, e.target.checked)
                        }
                        name='checkedB'
                        color='primary'
                      />
                    }
                    label={column.alias ?? column.name}
                  />
                </Paper>
              );
            })}
          </CardContent>
        </Card>
      </Popover>
    ),
    [anchorEl, store.showedColumns.get(), store.columns.get()],
  );
}
