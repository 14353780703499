import i18n from "i18n/config";

export const columnsWithActions = [
  { name: "taskId", alias: i18n.t("tasks_Id"), sortable: false },
  { name: "category", alias: i18n.t("tasks_category"), sortable: false },
  {
    name: "creationDate",
    alias: i18n.t("tasks_creation_date"),
    sortable: false,
  },
  {
    name: "dueDate",
    alias: i18n.t("tasks_creation_due_date"),
    sortable: false,
  },
  {
    name: "expireInSeconds",
    alias: i18n.t("tasks_creation_expire_in_time"),
    sortable: true,
  },
  { name: "owners", alias: i18n.t("tasks_owners"), sortable: false },
  { name: "createdBy", alias: i18n.t("tasks_created_by"), sortable: false },
  { name: "status", alias: i18n.t("tasks_status"), sortable: false },
  { name: "actions", alias: i18n.t("tasks_actions"), sortable: false },
  { name: "sendEmail", alias: i18n.t("tasks_send_email"), sortable: false },
];

export const columnsWithoutActions = [
  { name: "taskId", alias: i18n.t("tasks_Id"), sortable: false },
  { name: "category", alias: i18n.t("tasks_category"), sortable: false },
  {
    name: "creationDate",
    alias: i18n.t("tasks_creation_date"),
    sortable: false,
  },
  {
    name: "dueDate",
    alias: i18n.t("tasks_creation_due_date"),
    sortable: false,
  },
  {
    name: "expireInSeconds",
    alias: i18n.t("tasks_creation_expire_in_time"),
    sortable: true,
  },
  { name: "owners", alias: i18n.t("tasks_owners"), sortable: false },
  { name: "createdBy", alias: i18n.t("tasks_created_by"), sortable: false },
  { name: "status", alias: i18n.t("tasks_status"), sortable: false },
  { name: "sendEmail", alias: i18n.t("tasks_send_email"), sortable: false },
];
