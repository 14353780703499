import React, { useEffect, useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { Grid, styled } from "@material-ui/core";

import { AccountBoxIcon } from "./icons";
import MediaIcons from "Assets/images/socials/medias";
import { getCountdownFromDateTime } from "Utils/Utils";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const ActiveChatInfo = () => {
  const { translate } = useTranslation();
  const chatBoxState = useSelector(
    (state: RootStateOrAny) => state.chatReducer,
  );
  const totalCrmState = useSelector(
    (state: RootStateOrAny) => state.crmReducer,
  );
  const [countdown, setCountdown] = useState(null);

  const activeConversation =
    chatBoxState.conversations[chatBoxState.activeConversationId];

  const activeConversationRequestId = activeConversation
    ? activeConversation.conversationId
    : null;

  const activeConversationCreatedTime = activeConversation
    ? activeConversation.createdTime
    : null;

  const activeConversationChannelId = activeConversation
    ? activeConversation.channelId
    : null;

  const initialClientName =
    chatBoxState.activeConversationId &&
    chatBoxState.activeConversationId !== 0 &&
    totalCrmState.CHAT[chatBoxState.activeConversationId] &&
    (totalCrmState.CHAT[chatBoxState.activeConversationId].formFields[0]
      ?.fieldValue?.length > 0 ||
      totalCrmState.CHAT[chatBoxState.activeConversationId].formFields[1]
        ?.fieldValue?.length > 0)
      ? totalCrmState.CHAT[chatBoxState.activeConversationId].formFields[0]
          .fieldValue +
        " " +
        totalCrmState.CHAT[chatBoxState.activeConversationId].formFields[1]
          .fieldValue
      : activeConversation && activeConversation.fullName
      ? activeConversation.fullName
      : activeConversationChannelId;

  const fullname =
    initialClientName && initialClientName.length > 1 && initialClientName;

  const data = activeConversation && activeConversation.base64Picture;
  const channel = activeConversation && activeConversation.channel;

  const Image = ({ data }) => (
    <img
      src={`data:image/jpeg;base64,${data}`}
      alt='alt'
      style={{
        width: "100%",
        borderRadius: "64px",
      }}
    />
  );

  useEffect(() => {
    setCountdown(getCountdownFromDateTime(activeConversationCreatedTime));

    const interval = setInterval(() => {
      setCountdown(getCountdownFromDateTime(activeConversationCreatedTime));
    }, 999);

    return () => clearInterval(interval);
  }, [activeConversationCreatedTime]);

  if (activeConversation) {
    let media = MediaIcons[activeConversation.platform]
      ? MediaIcons[activeConversation.platform]
      : MediaIcons.OTHER;
    var { icon: Icon, color } = media;

    var MediaIcon = styled(Icon)({
      display: "inline-block",
      float: "left",
      marginRight: 5,
      fontSize: 14,
      letterSpacing: "0.02em",
      lineHeight: "140%",
      color,
      background:
        activeConversation.platform === "INSTAGRAM"
          ? "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"
          : "",
    });
  }

  const profileImage = (
    <ImageContainer>
      {data != null ? <Image data={data} /> : <StyledAccountBoxIcon />}
    </ImageContainer>
  );

  return (
    <StyledChat>
      <Grid container>
        <Grid item xs={2}>
          {profileImage}
        </Grid>
        <Grid item xs={9}>
          <Grid container direction='column' justifyContent='center'>
            <Grid item xs={12}>
              <CustomerName>
                {translate("chat_box_request")} #{activeConversationRequestId}
              </CustomerName>
              <ChannelWrap>
                {activeConversation && (
                  <>
                    <MediaIcon />
                    <Channel>{channel} / </Channel>
                  </>
                )}
                <Timer>{countdown}</Timer>
              </ChannelWrap>
            </Grid>

            <Grid item xs={12}>
              <Fullname>
                {fullname?.length > 0 ? fullname : activeConversationChannelId}
              </Fullname>
              {activeConversation && activeConversation?.forwardedBy && (
                <ForwardName>
                  FWD by: {activeConversation?.forwardedBy}
                </ForwardName>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledChat>
  );
};

export default ActiveChatInfo;

const StyledChat = styled("div")({
  height: "var(--active-chat-info-height)",
  padding: "5px 0 10px 0",
  width: "100%",
});

const StyledAccountBoxIcon = styled(AccountBoxIcon)({
  color: "grey",
  fontSize: "40px",
  borderRadius: "64px",
});

const CustomerName = styled("div")({
  fontSize: 12,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  color: "#020110",
  display: "inline-block",
  float: "left",
  marginRight: "8px",
});

const ChannelWrap = styled("div")({
  display: "inline-flex",
  textAlign: "left",
  width: "100%",
});

const Timer = styled("div")({
  fontSize: 12,
  textAlign: "left",
  float: "left",
  display: "inline-block",
  color: "#020110",
  lineHeight: "140%",
  letterSpacing: "0.02em",
});

const Channel = styled("span")({
  fontSize: 12,
  textAlign: "left",
  float: "left",
  display: "inline-block",
  color: "#020110",
  lineHeight: "140%",
  letterSpacing: "0.02em",
  marginRight: 3,
});

const Fullname = styled("span")({
  fontSize: "12px",
  lineHeight: "140%",
  letterSpacing: "0.02em",
  float: "left",
  color: "#050038",
});

const ForwardName = styled("span")({
  marginLeft: 10,
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "140%",
  letterSpacing: "0.02em",
  float: "left",
  color: "#050038",
});

const ImageContainer = styled("div")({
  width: "100%",
  height: "100%",
  border: "1px solid white",
  borderRadius: 64,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
