import { styled } from "@material-ui/core/styles";

export const MessageContainer = styled("div")({
  width: "100%",
  maxWidth: 350,
  padding: 7,
  marginTop: 5,
  fontSize: 11,
  textAlign: "left",
  "&.client": {
    backgroundColor: "white",
    float: "left",
    color: "rgba(5, 0, 56, 1)",
    borderRadius: "12px 12px 12px 2px",
  },
  "&.agent": {
    backgroundColor: "#00B287",
    float: "right",
    color: "rgba(255, 255, 255, 0.6)",
    borderRadius: "12px 12px 2px 12px",
  },
});

export const Timer = styled("span")({
  letterSpacing: "0.02em",
  lineHeight: "140%",
  fontSize: 12,
  marginLeft: 8,
  "&.client": {
    color: "rgba(5, 0, 56, .6)",
  },
  "&.agent": {
    color: "rgba(255, 255, 255, 0.6)",
  },
});

export const Date = styled("span")({
  fontSize: 12,
  lineHeight: "16.8px",
  marginRight: 6,
  "&.client": {
    color: "rgba(5, 0, 56, .6)",
  },
  "&.agent": {
    color: "rgba(255, 255, 255, 0.6)",
  },
});

export const AudipPlayerContainer = styled("div")({
  display: "flex",
  margin: "12px 24px 6px 8px",
});

export const Controller = styled("div")({
  width: 24,
  height: 24,
  marginRight: "16px",
  cursor: "pointer",
});

export const Owner = styled("span")({
  fontSize: "12px",
  marginRight: "8px",
});

export const MessageFooter = styled("div")({
  float: "right",
  display: "inline-block",
  lineHeight: "140%",
});

export const ProgressBar = styled("input")({
  width: "90%",
  borderRadius: "8px",
  transition: "background 0.2s ease",
  color: "inherit",
  "-webkit-appearance": "none",
  background: "transparent",
  "&:focus": {
    outline: "none",
  },
  "&::-ms-track": {
    background: "transparent",
    borderColor: "transparent",
    color: "transparent",
  },
});
