import { styled } from "@material-ui/core";

export const Container = styled("div")({
  display: "inline-block",
  width: "90%",
  marginTop: 9,
  marginLeft: 10,
});

export const Loading = styled("span")({
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  color: "white",
  textAlign: "left",
});

export const Timer = styled("span")({
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  color: "white",
});

export const Status = styled("span")({
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  textAlign: "left",
});
