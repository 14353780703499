import React from "react";
import { styled } from "@material-ui/core/styles";

interface IActionButton {
  name?: string;
  Icon: any;
  onClick: Function;
  iconClassName?: Object;
}

const ActionButton = (props: IActionButton) => {
  let { Icon, name, onClick, iconClassName } = props;

  const ActionIcon = styled(Icon)({
    marginTop: 20,
    fontSize: 20,
  });

  return (
    <StyledActionIcon onClick={() => onClick()}>
      <ActionIcon style={iconClassName} />
      {name && <ActionName>{name}</ActionName>}
    </StyledActionIcon>
  );
};

export default ActionButton;

const StyledActionIcon = styled("div")({
  width: 64,
  height: 64,
  cursor: "pointer",
  color: "050038",
  margin: "0 auto",
});

const ActionName = styled("p")({
  textTransform: "uppercase",
  fontSize: 12,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  margin: 0,
});
