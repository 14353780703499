export enum fields {
  "PhoneNumber" = "Phone Number",
  "Facebook" = "Facebook",
  "Whatsapp" = "Whatsapp",
  "Telegram" = "Telegram",
  "Webchat" = "Webchat",
  "Instagram" = "Instagram",
  "CIF" = "CIF",
  "FIN" = "FIN",
  "CardNumber" = "Card Number",
  "Gender" = "Gender",
  "CompanyGender" = "Company Gender",
  "CompanyStatus" = "Company Status",
}
