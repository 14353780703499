import React, { useEffect, useState, useRef } from "react";
import { createStyles, makeStyles, styled } from "@material-ui/core/styles";
import { PlayArrowIcon, PauseIcon } from "./icons";
import { conversion } from "Utils/Utils";

interface IAudioPlayer {
  src: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    controllerIcon: {
      fontSize: 22,
      color: "#414147",
    },
    icon: {
      fontSize: 12,
      color: "rgba(255, 255, 255, 0.6)",
    },
  }),
);

const STEP = 25;

const AudioPlayerController = (props: IAudioPlayer) => {
  const classes = useStyles();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(conversion(0));
  const [trackProgress, setTrackProgress] = useState(0);

  const audioRef = useRef(new Audio(props.src));
  const intervalRef = useRef(null);

  let { duration } = audioRef.current;
  let convertedDuration = conversion(duration);
  duration = Math.floor(duration);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else audioRef.current.pause();
  }, [isPlaying]);

  useEffect(
    () => () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    },
    [],
  );

  const startTimer = () => {
    if (intervalRef.current !== null) clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) setIsPlaying(false);
      else {
        let { currentTime } = audioRef.current;
        setCurrentTime(conversion(currentTime));
        setTrackProgress(currentTime);
      }
    }, STEP);
  };

  const handlePlayButton = () => {
    setIsPlaying(true);
    if (audioRef.current.ended) {
      setCurrentTime(conversion(0));
      setTrackProgress(0);
    }
  };

  const handlePauseButton = () => {
    setIsPlaying(false);
  };

  const onScrub = (value) => {
    if (intervalRef.current !== null) clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(value);
    setIsPlaying(false);
  };

  const onScrubEnd = () => {
    if (!isPlaying) setIsPlaying(true);
    startTimer();
  };

  return (
    <AudipPlayerContainer>
      <Timer>
        {currentTime} /{" "}
        {!convertedDuration.includes("NaN") ? convertedDuration : "--:--"}
      </Timer>
      <Controller>
        {isPlaying ? (
          <PauseIcon
            onClick={handlePauseButton}
            className={classes.controllerIcon}
          />
        ) : (
          <PlayArrowIcon
            onClick={handlePlayButton}
            className={classes.controllerIcon}
          />
        )}
      </Controller>
      <ProgressBar
        type='range'
        step={`${STEP / 1000}`}
        min='0'
        value={trackProgress}
        max={duration ? duration : `${duration}`}
        onChange={(e) => onScrub(e.target.value)}
        onMouseUp={onScrubEnd}
        onKeyUp={onScrubEnd}
      />
    </AudipPlayerContainer>
  );
};

export default AudioPlayerController;

const AudipPlayerContainer = styled("div")({
  width: "100%",
  display: "flex",
  marginTop: "8px",
});

const Timer = styled("span")({
  color: "#020110",
  letterSpacing: "0.02em",
  fontSize: 14,
  marginRight: "5px",
  lineHeight: "160%",
});

const ProgressBar = styled("input")({
  display: "inline-block",
  marginLeft: "10px",
  width: "60%",
  borderRadius: "8px",
  transition: "background 0.2s ease",
  color: "inherit",
  "-webkit-appearance": "none",
  background: "transparent",
  "&:focus": {
    outline: "none",
  },
  "&::-ms-track": {
    background: "transparent",
    borderColor: "transparent",
    color: "transparent",
  },
  "&::-webkit-slider-thumb": {
    "-webkit-appearance": "none",
    border: "2px solid #FFF",
    width: "15px",
    height: "15px",
    marginTop: "-8px",
    borderRadius: "50%",
    background: "#00B287",
  },
  "&::-moz-range-thumb": {
    "-webkit-appearance": "none",
    border: "2px dashed #00B287",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: "transparent",
  },
  "&::-webkit-slider-runnable-track": {
    width: "100%",
    height: "0.01px",
    background: "inherit",
    border: "0.5px dashed #00B287",
  },
  "&::-moz-range-track": {
    width: "100%",
    height: "8.4px",
    cursor: "pointer",
    background: "transparent",
    borderColor: "red",
    borderWidth: "16px 0",
    color: "transparent",
  },
});

const Controller = styled("div")({
  width: "12px",
  height: "24px",
  cursor: "pointer",
});
