import { LogOut } from "Components/Authentication/Authentication";
import WebConf, { AuthServerUrl } from "Config/WebConf";

import i18n from "i18n/config";
import Notification from "Notifications/Notification";

export const currentRequests = [];

// let defaultPostParams = () => {
//   const params: RequestInit = {
//     method: "POST", // *GET, POST, PUT, DELETE, etc.
//     //mode: 'cors', // no-cors, *cors, same-origin
//     // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
//     // credentials: "same-origin", // include, *same-origin, omit
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + localStorage.getItem("token"),
//     },
//     // redirect: "follow", // manual, *follow, error
//     // referrer: "no-referrer", // no-referrer, *client
//     // keepalive: true,
//   };

//   return params;
// };

const defaultGETParams = (): RequestInit => {
  const params: RequestInit = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    redirect: "follow",
  };

  return params;
};

export function cancelAllCurrentRequests() {
  for (let i = currentRequests.length; i > -1; i--) {
    const abort = currentRequests[i][1];
    abort();
    currentRequests.pop();
  }
}

export async function fetchData(url, params = {}, canBeCancelled = true) {
  const controller = new AbortController();
  const { signal } = controller;

  const defaultParams = defaultGETParams();
  const fetchParams = {
    ...defaultParams,
    ...params,
    signal, // extend provided params with AbortController signal
  };

  let promise = null;

  try {
    promise = fetch(url, fetchParams);
  } catch (error) {
    return [];
  }

  const res = [promise, controller.abort.bind(controller)];
  if (canBeCancelled) currentRequests.push(res);

  const response = await promise;

  if (
    response.status === 200 ||
    response.status === 409 ||
    response.status === 201
  )
    return promise;
  else if (response.status === 400) {
    try {
      const result = await response.json();

      if (result.errors && Object.keys(result.errors).length > 0) {
        let htmlErrors = "";
        Object.keys(result.errors).forEach((error) => {
          htmlErrors += `${result.errors[error]}</br>`;
        });
        Notification.error(htmlErrors);
        return null;
      }
      return result.message;
    } catch (error) {
      return null;
    }
  } else if (response.status === 500) {
    const result = await response.json();
    if (result.errors && result.errors.length > 0)
      Notification.error(result.errors);

    return null;
  } else if (response.status === 417)
    return getTokenWhenTokenIsExpired(url, params);
  else if (response.status === 503)
    Notification.error(i18n.t("service_unavailable"));
  else return null;
}

const getTokenWhenTokenIsExpired = async (url, fetchParams) => {
  const params: RequestInit = {
    method: "POST",
  };
  const response = await fetch(
    AuthServerUrl +
      WebConf.auth.login +
      `?refresh_token=${localStorage.getItem("refresh_token")}`,
    params,
  );

  if (response.status === 200) {
    const result = await response.json();
    localStorage.setItem("token", result.access_token);
    localStorage.setItem("refresh_token", result.refresh_token);
    return fetchData(url, fetchParams);
  } else {
    LogOut();
  }
};

// export async function postData(
//   url = "",
//   body: BodyInit,
//   callbackFunc = null,
//   customParams = {},
//   successResponseStatus = 200,
// ) {
//   let params = defaultPostParams();
//   params["body"] = body;
//   if (Object.keys(customParams).length > 0)
//     params = { ...params, ...customParams };
//   if (callbackFunc) {
//     return fetch(url, params).then((response) => {
//       if (response.status === successResponseStatus) callbackFunc(response);
//       return;
//     });
//   } else {
//     const response = await fetch(url, params);
//     if (response.status === successResponseStatus) return response;

//     return;
//   }
// }
