import { commonStyles } from "Components/commonStyles";

import React from "react";

import BottomActionButtons from "./BottomActionButtons";
import CallInfo from "./CallInfo";
import CallSnackBar from "./CallSnackBar";
import Digits from "./Digits";
import Header from "./Header";
import { useSoftPhone } from "./hooks";
import Inputs from "./Inputs";
import { ISoftPhone } from "./interfaces";
import Lines from "./Lines";
import { useSoftPhoneStyles } from "./styles";

const SoftPhone = ({ isHidden }: ISoftPhone): React.ReactElement => {
  const commonClasses = commonStyles();
  const classes = useSoftPhoneStyles();

  const { softPhoneOpen, callInfo, isCallInfoActive, makeCall } =
    useSoftPhone();

  return (
    <>
      <div
        style={{
          display: softPhoneOpen && !isHidden ? "block" : "none",
        }}
        className={commonClasses.actionPhoneBox}>
        <Header />
        <Lines />
        <div className={classes.content}>
          {callInfo && isCallInfoActive ? (
            <CallInfo />
          ) : (
            <>
              <Inputs makeCall={makeCall} />
              <Digits makeCall={makeCall} />
            </>
          )}
          <BottomActionButtons />
        </div>
      </div>
      <CallSnackBar />
    </>
  );
};

export default SoftPhone;

SoftPhone.displayName = "SoftPhone";
