/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useMemo, useRef } from "react";

import {
  Button,
  Card,
  CardActions,
  ClickAwayListener,
  makeStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import SearchIcon from "@material-ui/icons/Search";

import fieldTypes from "Components/Form/fieldTypes";
import FormDatePicker from "Components/Form/FormDatePicker";
import FormDateTimePicker from "Components/Form/FormDateTimePicker";
import FormInput from "Components/Form/FormInput";
import FormInputDiapason from "Components/Form/FormInputDiapason";
import FormMultiSelect from "Components/Form/FormMultiSelect";
import FormCheckbox from "Components/Form/FormCheckbox";

import { getRandomInt } from "Utils/Utils";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { StoreContext } from "./Table";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    zIndex: 3,
    width: "90%",
    marginLeft: "5%",
  },
  cardActions: {
    borderTop: "1px solid " + grey[100],
    paddingTop: "10px",
    position: "relative",
    bottom: 0,
    textAlign: "center",
    justifyContent: "center",
    width: "100%",
  },
  fields: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px",
  },
  fieldWrapper: {
    padding: "5px",
    flex: "1 0 40%",
  },
  fieldWrapperSingle: {
    padding: "5px",
    flex: "1 0 40%",

    maxWidth: "48%",
  },
  actionButton: {},
});

interface IFilterDrawer {
  open: boolean;
  setOpen: Function;
  handleChange(fieldName: string, value: string): void;
  setSearchFieldSet(): void;
  resetFieldValues(): void;
}

export default function FilterDrawer({
  open,
  setOpen,
  setSearchFieldSet,
  resetFieldValues,
}: IFilterDrawer) {
  const { translate } = useTranslation();
  const classes = useStyles();

  const timeoutRef = useRef(null);
  const store: any = useContext(StoreContext);

  const setFieldValue = (
    value: any,
    fieldName: string,
    fieldType: fieldTypes,
  ) => {
    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      store.handleSearchFieldChange(value, fieldName, fieldType);
    }, 500);
  };

  const searchByFields = () => {
    setSearchFieldSet();
    setOpen(false);
  };

  const emptyFields = () => {
    resetFieldValues();
    setOpen(false);
  };

  return useMemo(
    () =>
      open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Card classes={{ root: classes.root }}>
            <div className={classes.fields}>
              {store.searchFields.get() &&
                store.searchFields.get().map((field) => {
                  const commonProps = {
                    id: null,
                    handleChange: (
                      value: any,
                      _id: string,
                      fieldName: string,
                    ) => setFieldValue(value, fieldName, field.type),
                    size: "small" as const,
                    value: field.value,
                    label: field.name,
                    checkValidation: false,
                  };
                  let element = null;
                  switch (field.type) {
                    case fieldTypes.TEXT:
                      element = <FormInput {...commonProps} />;
                      break;
                    case fieldTypes.DATE:
                      element = <FormDatePicker {...commonProps} />;
                      break;
                    case fieldTypes.DATETIME_RANGE:
                    case fieldTypes.NUMBER_RANGE:
                    case fieldTypes.TIME_RANGE:
                      element = (
                        <FormInputDiapason
                          fieldType={field.type}
                          {...commonProps}
                        />
                      );
                      break;
                    case fieldTypes.DATETIME:
                      element = <FormDateTimePicker {...commonProps} />;
                      break;
                    case fieldTypes.CHECKBOX:
                      element = <FormCheckbox {...commonProps} />;
                      break;
                    case fieldTypes.SELECT:
                      element = (
                        <FormMultiSelect
                          {...commonProps}
                          handleChange={(
                            option: any,
                            _id: string,
                            fieldName: string,
                          ) => setFieldValue(option, fieldName, field.type)}
                          options={field.options ? field.options : []}
                          placeHolder={field.name}
                        />
                      );
                      break;

                    default:
                      element = <FormInput {...commonProps} />;
                      break;
                  }

                  return (
                    <div
                      className={
                        field.name != "type"
                          ? classes.fieldWrapper
                          : classes.fieldWrapperSingle
                      }
                      key={getRandomInt(0, 99999999)}>
                      {element}
                    </div>
                  );
                })}
            </div>
            <CardActions className={classes.cardActions}>
              <Button
                color='primary'
                onClick={searchByFields}
                className={classes.actionButton}
                startIcon={<SearchIcon />}>
                {translate("search")}
              </Button>
              <Button onClick={emptyFields} className={classes.actionButton}>
                {translate("reset")}
              </Button>
            </CardActions>
          </Card>
        </ClickAwayListener>
      ),
    [open],
  );
}
