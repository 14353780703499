/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/ban-types */
import {
  Typography,
  CircularProgress,
  FormControl,
  createStyles,
  makeStyles,
  TextField,
  Paper,
  Button,
} from "@material-ui/core";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { fetchData } from "networking";
import React, { useEffect, useState } from "react";

import FormElement from "./FormElement";
import FormFieldWrapper from "./FormFieldWrapper";

interface IOption {
  id?: string;
  inputValue?: string;
  value: string;
  name: string;
}

interface IFormSelect {
  options: Array<IOption> | null;
  id?: string;
  value?: string | Array<any>;
  serverSide?: boolean; //default false
  required: boolean;
  placeHolder: string;
  regex?: string;
  errorText?: string;
  checkValidation?: boolean;
  style?: any;
  multiple?: boolean;
  optionCanBeAdded?: boolean;
  url?: string;
  onOpen?: any;
  hideAddNewOption?: boolean;
  handleRemoveAddedOption?: Function;
  handleValidation?: Function;
  handleChange: Function;
  handleAddOption?: Function;
  size?: "small" | "medium";
  handleOptionAdded?: Function;
  addValueToListIfNotExists?: boolean;
  addNewButton?: boolean;
  addNewButtonClick?: Function;
  variant?: any;
  error?: boolean;
  disableOptionsList?: boolean;
  [x: string]: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: "100%",
    },
    inputFocused: {
      boxShadow: "0 0 1px 1px white !important",
    },
    root: {},
    errorRoot: {
      "& label": {
        color: "red !important",
      },
      "& fieldset": {
        borderColor: "red !important",
      },
    },
    icon: {
      color: "orange",
      position: "absolute",
      right: "-35px",
      top: "5px",
      fontSize: "30px",
      cursor: "pointer",
    },
    listbox: {
      bottom: "20px important",
    },
    inputAdded: {
      marginTop: "7px",
      "& input:focus": {
        boxShadow: "0 0 1px 1px white !important",
      },
    },
  }),
);

const filter = createFilterOptions<IOption>();

const FormSelect = (props: IFormSelect): React.ReactElement => {
  const { translate } = useTranslation();

  const classes = useStyles(props);
  const [options, setOptions] = useState<Array<IOption>>(props.options);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const serverSide = props.serverSide ? props.serverSide : false;
  const [searchInputValue, setSearchInputValue] = useState("");

  const variant = props.variant ?? "outlined";

  const getRenderedOption = (option): React.ReactNode => {
    if (serverSide && props.handleAddOption && option.value === null) {
      return (
        <Typography
          onClick={() => props.handleAddOption(searchInputValue)}
          noWrap>
          {option.label ?? option.name}
        </Typography>
      );
    }
    return <Typography noWrap>{option.label ?? option.name}</Typography>;
  };

  const handleInputChange = (e): void => {
    const value = e.target.value;
    setSearchInputValue(value);
    setLoading(true);
  };

  const getRenderedInput = (params): React.ReactElement => {
    if (serverSide) {
      return (
        <TextField
          error={props.error}
          onChange={(e) => handleInputChange(e)}
          {...params}
          label={props.placeHolder}
          onFocus={props.onFocus ? props.onFocus : null}
          variant={variant}
          required={props.required}
          helperText={props.errorText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      );
    } else
      return (
        <TextField
          {...params}
          error={props.error}
          onFocus={props.onFocus ? props.onFocus : null}
          label={props.placeHolder}
          variant={variant}
          required={props.required}
          helperText={props.errorText}
        />
      );
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    props.setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    props.options?.length > 0 && setOptions(props.options);
  }, [props.options]);

  React.useEffect(() => {
    let active = true;
    if (!loading || !props.serverSide) {
      return undefined;
    }

    (async () => {
      const response = await fetchData(props.url + searchInputValue); //props.url fetchData
      const updatedOptions = await response.json();

      if (active) {
        const filteredOptions = Object.keys(updatedOptions).map((key) => {
          return {
            id: updatedOptions[key]["id"],
            name: updatedOptions[key]["name"],
            value: updatedOptions[key]["id"],
          };
        }) as IOption[];
        setOptions(filteredOptions);
      }
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const handleChange = (
    _event: React.ChangeEvent<any>,
    values,
    reason,
  ): void => {
    //check if value removed and if removed value is newly added
    if (reason === "remove-option") {
      if (typeof props.value === "object") {
        const removedOption = props.value.filter((x) => !values.includes(x));
        if (removedOption[0] && props.handleRemoveAddedOption)
          props.handleRemoveAddedOption(removedOption);
      }
    }

    props.setValue(values);
    props.handleChange(values, props.id, props.placeHolder);
  };

  const additionalProps: Record<string, unknown> = {};

  if (props.addValueToListIfNotExists) {
    additionalProps.filterOptions = (options, params) => {
      const filtered = filter(options, params);
      const filteredIncludesInputValue =
        filtered.filter((option) => {
          return option.name.toLowerCase() === params.inputValue;
        }).length === 0;
      // Suggest the creation of a new value
      if (
        props.hideAddNewOption === false &&
        props.optionCanBeAdded === true &&
        params.inputValue !== "" &&
        filteredIncludesInputValue === true
      ) {
        filtered.push({
          inputValue: params.inputValue,
          name: params.inputValue,
          value: null,
        });
      }

      return filtered;
    };
  }

  return (
    <FormFieldWrapper>
      <FormControl variant='filled' className={classes.formControl}>
        <Autocomplete
          disabled={props?.disabled ?? false}
          onChange={handleChange}
          open={open}
          onOpen={() => {
            if (props.onOpen) {
              props.onOpen();
            }
            if (props.disableOptionsList) {
              setOpen(false);
              return;
            }

            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={loading}
          // getOptionSelected={(option, value) => option.name === value.name}
          size={props.size ?? "small"}
          multiple={props.multiple}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          value={props.value}
          classes={{
            root: props.error ? classes.errorRoot : classes.root,
            inputFocused: classes.inputFocused,
            listbox: classes.listbox,
          }}
          options={options ? options : []}
          getOptionLabel={(option) => {
            if (option.label) return option.label;

            // Value selected with enter, right from the input
            if (typeof option === "string") return option;

            // Add "xxx" option created dynamically
            if (option.inputValue) return option.inputValue;

            // Regular option
            return option.name;
          }}
          renderOption={(option) => getRenderedOption(option)}
          freeSolo={props.freeSolo}
          renderInput={(params) => getRenderedInput(params)}
          PaperComponent={({ children }) => {
            return (
              <Paper>
                {props.addNewButton && (
                  <Button
                    fullWidth
                    onMouseDown={() => props.addNewButtonClick()}>
                    + {translate("task_mng_advanced_tag_add_new")}
                  </Button>
                )}
                {children}
              </Paper>
            );
          }}
          {...additionalProps}
        />
      </FormControl>
    </FormFieldWrapper>
  );
};

export default FormElement(FormSelect);

FormSelect.displayName = "FormSelect";
