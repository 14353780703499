import { downloadAttachment } from "Api/faq";
import { sendFileToFileServer } from "Api/file";

import messageTypes from "Assets/constants/messageTypes";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

import { buttonTypes } from "Components/Buttons/buttonTypes";
import IconButton from "Components/Buttons/IconButton";

import WebConf from "Config/WebConf";
import MicRecorder from "mic-recorder-to-mp3";

import FileTypes from "Pages/FaqGenerator/fileTypes";
import filesData from "Pages/FaqGenerator/fileTypesData";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import ChatBox from "Store/Actions/ChatBox";
import Crm from "Store/Actions/Crm";
import { blobToFile } from "Utils/Utils";

import { HighlightOffIcon } from "../icons";
import { useTextBoxStyles } from "../styles";

/**
 * Sends message on send button click and on enter pressed, if user presses shift + enter cursor goes to the next line
 */

export const useTextBox = (): any => {
  const { translate } = useTranslation();
  const classes = useTextBoxStyles();

  const [isLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [timerInterval, setTimerInterval] = useState(null);
  const [timerSeconds, setTimerSeconds] = useState(0);
  const [blobURL, setBlobURL] = useState("");
  const [blob, setBlob] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [screenshot, setScreenshot] = useState<File | null>(null);
  const voiceIconRef = useRef(null);

  const chatBox = new ChatBox(useDispatch());
  const crm = new Crm(useDispatch());

  const state = useSelector(({ chatReducer, crmReducer }: RootStateOrAny) => ({
    conversations: chatReducer.conversations,
    activeConversationId: chatReducer.activeConversationId,
    messages: chatReducer.messages,
    replyMessageData: chatReducer.replyMessageData,
    chatInstance: chatReducer.chatInstance,
    isSendLastMsg: chatReducer.isSendLastMsg,
    copyAttachments: crmReducer.copyAttachments,
    copyAnswer: crmReducer.copyAnswer,
    copyIconOnclick: crmReducer.copyIconOnclick,
  }));

  const {
    conversations,
    activeConversationId,
    messages,
    replyMessageData,
    chatInstance,
    isSendLastMsg,
    copyAttachments,
    copyAnswer,
    copyIconOnclick,
  } = state;

  const isVoiceIcon =
    conversations[activeConversationId]?.isVoiceMessageEnabled ?? false;

  const message = messages[activeConversationId]?.message ?? "";

  const handleVoiceClick = (): void => {
    setIsRecording(true);
    const newRecorder = new MicRecorder({ bitRate: 128 });
    setRecorder(newRecorder);
    startRecording(newRecorder);
  };

  const handleVoiceClose = (): void => {
    setIsRecording(false);
    setCanBeDeleted(false);
    setLoading(false);
  };

  const startRecording = (recorder): void => {
    recorder
      .start()
      .catch(() => alert(translate("chat_box_recorder_cant_started")));
  };

  const stopRecording = (): void => {
    recorder
      .stop()
      .getMp3()
      .then(([, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setBlobURL(blobURL);
        setBlob(blob);
        setCanBeDeleted(true);
      });
  };

  const sendMessage = async (
    messageType = messageTypes.TEXT,
  ): Promise<void> => {
    setLoading(true);
    chatBox.setIsSendMessage(
      Math.floor(Math.random() * 9000000000) + 1000000000,
    );
    if (message.length > 0) {
      const fomatedMessage = {
        message: {
          caption: null,
          content: message,
          createdTime: null,
          owner: "agent",
          replyServiceMessageId:
            typeof replyMessageData?.messageId === "number"
              ? replyMessageData?.messageId
              : null,
          serviceMessageId: "",
          status: null,
          type: messageType,
          fullName: `${localStorage.getItem(
            "firstName",
          )} ${localStorage.getItem("lastName")}`,
          userId: localStorage.getItem("userID"),
        },
        conversationId: activeConversationId,
      };
      chatInstance.sendMessage(
        WebConf.socket.sendMessage,
        JSON.stringify(fomatedMessage),
      );

      chatBox.setMessage("", activeConversationId);
      chatBox.setReplyMessageView(false);
      chatBox.setReplyMessageData({});
      crm.setCopyAnswer("");
    }

    if (copyAttachments.length > 0) {
      for (let i = 0; i < copyAttachments.length; i++) {
        const response = await downloadAttachment(copyAttachments[i].path);
        const blob = await response.blob();
        const file = blobToFile(blob, copyAttachments[i].originalFileName);
        const reader = new FileReader();
        reader.onload = async function () {
          const response = await sendFileToFileServer(file, "chat");
          if (response.length === 0 || !response[0].path) {
            alert(translate("chat_box_unable_upload"));
            return;
          }
          const formattedMessage = {
            message: {
              caption: file.name,
              content: "",
              extension: response[0].path.split(".").pop(),
              createdTime: new Date().toISOString(),
              fileName: response[0].path.split(".")[0],
              owner: "agent",
              replyServiceMessageId:
                typeof replyMessageData?.messageId === "number"
                  ? replyMessageData?.messageId
                  : null,
              serviceMessageId: "",
              status: null,
              type: messageTypes.APPLICATION,
              userId: localStorage.getItem("userID"),
            },
            conversationId: activeConversationId,
          };

          chatInstance.sendMessage(
            WebConf.socket.sendMessage,
            JSON.stringify(formattedMessage),
          );
        };
        reader.readAsDataURL(file);
      }
      crm.setCopyAttachments([]);
      crm.setCopyAnswer("");
    }
    setLoading(false);
  };

  const sendVoiceMessage = async (): Promise<void> => {
    setLoading(true);
    if (blob) {
      const fileName =
        "voice-record-" + new Date().toLocaleDateString() + ".mp3";
      const file = blobToFile(blob, fileName);
      const response = await sendFileToFileServer(file, "chat");
      if (response.length === 0 || !response[0].path) {
        alert(translate("chat_box_unable_upload"));
        return;
      }
      const fomatedMessage = {
        message: {
          caption: null,
          content: "",
          extension: "mp3",
          createdTime: new Date().toISOString(),
          fileName: response[0].path.split(".")[0],
          owner: "agent",
          replyServiceMessageId:
            typeof replyMessageData?.messageId === "number"
              ? replyMessageData?.messageId
              : null,
          serviceMessageId: "",
          status: null,
          type: messageTypes.AUDIO,
          fullName: `${localStorage.getItem(
            "firstName",
          )} ${localStorage.getItem("lastName")}`,
          userId: localStorage.getItem("userID"),
        },
        conversationId: activeConversationId,
      };
      chatInstance.sendMessage(
        WebConf.socket.sendMessage,
        JSON.stringify(fomatedMessage),
      );
      setLoading(false);
      setIsRecording(false);
      setCanBeDeleted(false);
      setBlobURL("");
      setBlob(null);
      setRecorder(null);
      chatBox.setReplyMessageView(false);
      chatBox.setReplyMessageData({});
    }
  };

  const sendAttachmentMessage = async (data: any): Promise<void> => {
    setLoading(true);
    if (data.files && data.files[0]) {
      const file = data.files[0];
      const reader = new FileReader();
      reader.onload = async function () {
        const response = await sendFileToFileServer(file, "chat");

        if (response.length === 0 || !response[0].path) {
          alert(translate("chat_box_unable_upload"));
          return;
        }
        const fomatedMessage = {
          message: {
            caption: file.name,
            content: "",
            extension: response[0].path.split(".").pop(),
            createdTime: new Date().toISOString(),
            fileName: response[0].path.split(".")[0],
            owner: "agent",
            replyServiceMessageId:
              typeof replyMessageData?.messageId === "number"
                ? replyMessageData?.messageId
                : null,
            serviceMessageId: "",
            status: null,
            type: messageTypes.APPLICATION,
            fullName: `${localStorage.getItem(
              "firstName",
            )} ${localStorage.getItem("lastName")}`,
            userId: localStorage.getItem("userID"),
          },
          conversationId: activeConversationId,
        };
        chatInstance.sendMessage(
          WebConf.socket.sendMessage,
          JSON.stringify(fomatedMessage),
        );
      };
      setLoading(false);
      reader.readAsDataURL(file);
    }
  };

  const sendSnippingToolFile = async (data: any): Promise<void> => {
    setLoading(true);
    if (data) {
      const reader = new FileReader();
      reader.onload = async function () {
        const response = await sendFileToFileServer(data, "chat");

        if (response.length === 0 || !response[0].path) {
          alert(translate("chat_box_unable_upload"));
          return;
        }
        const fomatedMessage = {
          message: {
            caption: data.name,
            content: "",
            extension: response[0].path.split(".").pop(),
            createdTime: new Date().toISOString(),
            fileName: response[0].path.split(".")[0],
            owner: "agent",
            replyServiceMessageId:
              typeof replyMessageData?.messageId === "number"
                ? replyMessageData?.messageId
                : null,
            serviceMessageId: "",
            status: null,
            type: messageTypes.APPLICATION,
            fullName: `${localStorage.getItem(
              "firstName",
            )} ${localStorage.getItem("lastName")}`,
            userId: localStorage.getItem("userID"),
          },
          conversationId: activeConversationId,
        };
        chatInstance.sendMessage(
          WebConf.socket.sendMessage,
          JSON.stringify(fomatedMessage),
        );
      };
      setLoading(false);
      reader.readAsDataURL(data);
    }
  };

  const getIconType = (
    name: string,
    index: number,
    buttonType: buttonTypes,
    icon: any,
  ): React.ReactElement => {
    return (
      <React.Fragment key={index}>
        <IconButton
          className={classes.icons}
          buttonType={buttonType}
          tooltipText={name}
          Icon={() => <img src={icon} alt={name} className={classes.img} />}
        />
        <HighlightOffIcon
          className={classes.deleteAttachment}
          onClick={() => handleDeleteAttachment(index)}
        />
      </React.Fragment>
    );
  };

  const convertFileToIcon = (name, path, index): React.ReactElement => {
    const fileType = FileTypes[path.split(".")[1].toUpperCase()];
    return getIconType(
      name,
      index,
      filesData[fileType].buttonType,
      filesData[fileType].icon,
    );
  };

  const handleDeleteAttachment = (order): void => {
    const tempAttachments = copyAttachments?.filter(
      (_item, index) => index !== order,
    );
    crm.setCopyAttachments(tempAttachments);
  };

  const handleKeyDown = (e): void => {
    if (e.key === "Enter" && !e.shiftKey) {
      sendMessage();
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  };

  const setTextBoxMessage = (e): void => {
    const text = e.target.value;
    chatBox.setMessage(text, activeConversationId);
  };

  useEffect(() => {
    const pasteEvent = (event) => {
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;
      let blob = null;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") === 0) {
          blob = items[i].getAsFile();
        }
      }

      if (blob !== null) {
        const screenshotFile = new File([blob], "screenshot.png", {
          type: "image/png",
        });
        setScreenshot(screenshotFile);
      }
    };
    window.addEventListener("paste", pasteEvent);
    return () => {
      window.removeEventListener("paste", pasteEvent);
    };
  }, []);

  useEffect(() => {
    if (screenshot !== null) {
      sendSnippingToolFile(screenshot);
      setScreenshot(null);
    }
  }, [screenshot]);

  useEffect(() => {
    if (copyAttachments.length === 0 && !copyAnswer) {
      crm.setCopyOnclick(false);
      crm.setCopyAnswer("");
    }
  }, [copyAttachments, copyAnswer]);

  useEffect(() => {
    let timer = 0;
    if (isRecording) {
      setTimerInterval(
        setInterval(() => {
          timer++;
          setTimerSeconds(timer);
        }, 1000),
      );
    } else {
      timer = 0;
      setTimerSeconds(0);
      clearInterval(timerInterval);
    }
  }, [isRecording]);

  useEffect(() => {
    if (isSendLastMsg !== null) {
      chatBox.setSendLastMsg(null);
      sendMessage(messageTypes.SURVEY);
    }
  }, [isSendLastMsg]);

  return {
    classes,
    isLoading,
    copyIconOnclick,
    copyAttachments,
    loading,
    isRecording,
    canBeDeleted,
    timerSeconds,
    blobURL,
    message,
    isVoiceIcon,
    voiceIconRef,
    handleVoiceClose,
    stopRecording,
    convertFileToIcon,
    sendAttachmentMessage,
    translate,
    setTextBoxMessage,
    handleKeyDown,
    handleVoiceClick,
    sendVoiceMessage,
    sendMessage,
  };
};

useTextBox.displayName = "useTextBox";
