import { createStyles, makeStyles } from "@material-ui/core";

export const useOutgoingMessageStyles = makeStyles(() =>
  createStyles({
    paperRoot: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    formControl: {
      width: "100%",
      color: "#050038",
      marginBottom: 15,
    },
    paper: {
      width: 350,
      padding: "0 25px 25px",
      borderRadius: 8,
    },
  }),
);
