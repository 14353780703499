import menuTypes from "Assets/constants/menuTypes";

import { requestActions as actionTypes } from "../Actions/actionTypes";
import { updateObject, deepClone } from "../utility";

const templateState = {
  [menuTypes.CALL]: {},
  [menuTypes.CHAT]: {},
};

const optionsTemplate = {
  [menuTypes.CALL]: [],
  [menuTypes.CHAT]: [],
};

const initialState = {
  menuType: menuTypes.CALL,
  options: optionsTemplate,
  chosenOptions: templateState,
  requestStatuses: null,
  selectedRequestType: null,
  customerNote: "",
  isSending: false,
  showRegistration: false,
  editCustomerId: 0,
  isActivating: true,
  isLeftPanelOpen: false,
  isEditRequest: false,
};

const setRequestTypes = (state, action) => {
  const updatedOptions = { ...state.options };
  updatedOptions[action.menuType] = action.options;
  return updateObject(state, { options: updatedOptions });
};

const addChosenOption = (state, action) => {
  const chosenOptions = deepClone(state.chosenOptions);

  chosenOptions[action.menuType][action.requestId] = action.option;

  return updateObject(state, { chosenOptions });
};

const removeOption = (state, action) => {
  const chosenOptions = deepClone(state.chosenOptions);

  delete chosenOptions[action.menuType][action.requestId];

  return updateObject(state, { chosenOptions });
};

const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MENU_TYPE:
      return updateObject(state, { menuType: action.menuType });

    case actionTypes.SET_REQUEST_TYPES:
      return setRequestTypes(state, action);

    case actionTypes.ADD_CHOSEN_OPTION:
      return addChosenOption(state, action);

    case actionTypes.REMOVE_OPTION:
      return removeOption(state, action);

    case actionTypes.SET_SELECTED_REQUEST_TYPE:
      return updateObject(state, {
        selectedRequestType: action.request,
      });
    case actionTypes.SET_REQUEST_DATA_ON_EDIT_REQUEST:
      return updateObject(state, {
        selectedRequestType: action.request,
        menuType: action.menuType,
        showRegistration: true,
      });
    case actionTypes.SET_REQUEST_SENDING:
      return updateObject(state, {
        isSending: action.isSending,
      });

    case actionTypes.SET_EDIT_CUSTOMER_ID:
      return updateObject(state, {
        editCustomerId: action.editCustomerId,
      });

    case actionTypes.SET_SHOW_REGISTRATION:
      return updateObject(state, {
        showRegistration: action.showRegistration,
      });

    case actionTypes.IS_ACTIVATING:
      return updateObject(state, {
        isActivating: action.isActivating,
      });

    case actionTypes.SET_NOTE:
      return updateObject(state, { customerNote: action.customerNote });

    case actionTypes.SET_LEFT_PANEL_OPEN:
      return updateObject(state, { isLeftPanelOpen: action.isLeftPanelOpen });

    case actionTypes.SET_IS_EDIT_REQUEST:
      return updateObject(state, { isEditRequest: action.isEditRequest });

    default:
      return state;
  }
};

export default requestReducer;
