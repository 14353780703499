import { Tab, Tabs } from "@material-ui/core";

import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import TabPanel from "Components/Tab/TabPanel";

import React, { useContext } from "react";

import { IbarIntegrationSearchStore } from "./IbarIntegrationSearchPanel";
import IbarIntegrationTable from "./IbarIntegrationTable";
import { IIbarIntegrationSearchStore } from "./interfaces";
import { useSearchPanelTabsStyles } from "./styles";

const SearchPanelTabs = (): React.ReactElement => {
  const { translate } = useTranslation();
  const classes = useSearchPanelTabsStyles();

  const store: IIbarIntegrationSearchStore = useContext(
    IbarIntegrationSearchStore,
  );
  const tabIndex = store.tabIndex.get();

  const handleChange = (index: number): void => {
    store.tabIndex.set(index);
  };

  return (
    <div className={classes.container}>
      <Tabs
        value={tabIndex}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable auto tabs example'>
        <Tab
          label={translate("crm_card_customer_personal_info")}
          onClick={() => handleChange(0)}
        />
        <Tab
          label={translate("crm_card_customer_account_info")}
          onClick={() => handleChange(1)}
        />
        <Tab
          label={translate("crm_card_customer_card_info")}
          onClick={() => handleChange(2)}
        />
        <Tab
          label={translate("crm_card_customer_loan_info")}
          onClick={() => handleChange(3)}
        />
        <Tab
          label={translate("crm_card_customer_next_product_to_buy")}
          onClick={() => handleChange(4)}
        />
      </Tabs>
      <TabPanel activeTabIndex={tabIndex} index={0}>
        <IbarIntegrationTable />
      </TabPanel>
      <TabPanel activeTabIndex={tabIndex} index={1}>
        <IbarIntegrationTable />
      </TabPanel>
      <TabPanel activeTabIndex={tabIndex} index={2}>
        <IbarIntegrationTable />
      </TabPanel>
      <TabPanel activeTabIndex={tabIndex} index={3}>
        <IbarIntegrationTable />
      </TabPanel>
      <TabPanel activeTabIndex={tabIndex} index={4}>
        <IbarIntegrationTable />
      </TabPanel>
    </div>
  );
};

export default SearchPanelTabs;

SearchPanelTabs.displayName = "SearchPanelTabs";
