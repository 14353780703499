import { buttonTypes } from "./buttonTypes";

export const colors = {
  [buttonTypes.info]: "#3f51b5",
  [buttonTypes.success]: "#008000",
  [buttonTypes.danger]: "#c42323",
  [buttonTypes.default]: "#9f9f9f",
  [buttonTypes.warning]: "#c8c800",
  [buttonTypes.primary]: "#007bff",
  [buttonTypes.secondary]: "#6c757d",
  [buttonTypes.light]: "#f8f9fa",
  [buttonTypes.dark]: "#343a40",
  [buttonTypes.white]: "#ffffff",
};
