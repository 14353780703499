import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { useSelector, RootStateOrAny } from "react-redux";

import { DialerSipIcon, EditIcon } from "./Icons";

interface IPhoneEdit {
  isOpen: boolean;
  isActive: boolean;
  handleSelectMenu: () => {};
  phoneNumber;
}

export const PhoneEdit = ({
  isOpen,
  isActive,
  handleSelectMenu,
  phoneNumber,
}: IPhoneEdit) => {
  const PhoneIcon = styled(DialerSipIcon)({
    color: isActive ? "white" : "green",
    marginLeft: isOpen ? 12 : 0,
    fontSize: 25,
  });

  const { isCallContinuing } = useSelector(
    ({ softPhoneReducer }: RootStateOrAny) => ({
      isCallContinuing: softPhoneReducer.isCallContinuing,
    }),
  );

  return (
    <MenuItem
      className={clsx({
        ["active"]: isActive,
        ["open"]: isOpen,
      })}
      onClick={isCallContinuing ? () => null : handleSelectMenu}>
      {!isOpen ? (
        <IconWrapper
          className={clsx({
            ["disabled"]: isCallContinuing,
          })}>
          <PhoneIcon />
          <EditBadge />
        </IconWrapper>
      ) : (
        <Grid container>
          <Grid item xs={3}>
            <IconWrapper
              className={clsx({
                ["disabled"]: isCallContinuing,
              })}>
              <PhoneIcon />
              <EditBadge />
            </IconWrapper>
          </Grid>
          <Grid item xs={9}>
            <PhoneNumber>{phoneNumber}</PhoneNumber>
          </Grid>
        </Grid>
      )}
    </MenuItem>
  );
};

PhoneEdit.displayName = "PhoneEdit";

const MenuItem = styled("div")({
  width: "100%",
  paddingTop: 10,
  margin: "5px 0",
  cursor: "pointer",
  left: 0,
  "&:hover": {
    backgroundColor: "white",
  },
  "&.active": {
    background: "linear-gradient(282.31deg, #FF5C00 0%, #FFA13A 100%)",
    borderRadius: "8px 2px 8px 8px",
  },
});

const IconWrapper = styled("div")({
  marginRight: -10,
  "&.disabled": {
    cursor: "no-drop",
  },
});

const PhoneNumber = styled("span")({
  color: "#050038",
  fontSize: 12,
  float: "left",
});

const EditBadge = styled(EditIcon)({
  fontSize: 11,
  fontWeight: 600,
  lineHeight: "80%",
  letterSpacing: "0.02em",
  backgroundColor: "#050038",
  border: "1px solid #FFFFFF",
  position: "relative",
  bottom: 0,
  right: 7,
  borderRadius: 64,
  width: 9,
  height: 9,
  padding: 1,
  color: "white",
  display: "inline-block",
});
