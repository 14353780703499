import React from "react";
import { styled } from "@material-ui/core";

const DrawerTabs = ({ tab, setTab, tabs }) => (
  <Container>
    <Tabs>
      {tabs.map((t, index) => (
        <Tab
          className={tab === index ? "active" : ""}
          onClick={() => {
            setTab(index);
          }}
          key={index}>
          {t}
        </Tab>
      ))}
    </Tabs>
  </Container>
);

export default DrawerTabs;

const Container = styled("div")({
  width: "100%",
  margin: "0 auto",
  borderBottom: "1px solid #DCDDE6",
  borderTop: "1px solid #DCDDE6",
  display: "flex",
  marginBottom: 15,
});

const Tabs = styled("div")({
  width: "100%",
  minWidth: "100%",
  maxWidth: "100%",
});

const Tab = styled("div")({
  display: "inline-block",
  cursor: "pointer",
  color: "#81808A",
  fontWeight: 600,
  fontSize: 12,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  padding: "10px 10px 10px 0",
  float: "left",
  textAlign: "left",
  marginRight: 15,
  "&.active": {
    color: "#020110",
  },
});
