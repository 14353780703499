import axios from "Api/axios";
import WebConf, { FaqServerUrl, FileServerUrl } from "Config/WebConf";
import { fetchData } from "networking";

import { serialize } from "Utils/Utils";

// GET
export const getAllCategories = () =>
  axios
    .get(FaqServerUrl + WebConf.faq.getAllCategories)
    .then((response) => response && response.data);

export const getFaqsByCategoryId = (id: number) =>
  axios
    .get(FaqServerUrl + WebConf.faq.getFaqsByCategoryId + "/" + id)
    .then((response) => response && response.data);

export const getCategoryById = (id: number) =>
  axios
    .get(FaqServerUrl + WebConf.faq.getCategoryById + "/" + id)
    .then((response) => response && response.data);

export const getQuestions = (
  questionSearchValue: string,
  selectedCategoryId: number,
) =>
  axios
    .get(
      FaqServerUrl +
        WebConf.faq.getQuestionBySearch +
        "?word=" +
        questionSearchValue +
        "&categoryId=" +
        (selectedCategoryId ?? ""),
    )
    .then((response) => response && response.data);

export const getQuestionsByAnswer = (
  answerSearchValue: string,
  selectedCategoryId: number,
) =>
  axios
    .get(
      FaqServerUrl +
        WebConf.faq.getAnswerBySearch +
        "?word=" +
        answerSearchValue +
        "&categoryId=" +
        (selectedCategoryId ?? ""),
    )
    .then((response) => response && response.data);

export const downloadAttachment = async (path: string) => {
  return await fetchData(
    FaqServerUrl + WebConf.file.downloadAttachment + "/" + path,
  );
};

// POST
export const shiftCategory = (
  parentId: number,
  categoryId: number,
  position: number,
) => {
  const body = {
    parentId: parentId ?? "",
    categoryId,
    position,
  };

  return axios
    .post(FaqServerUrl + WebConf.faq.shiftCategory + "?" + serialize(body))
    .then((response) => response && response.data);
};

export const shiftFaqUpAndDown = (id: number, position: number) => {
  const body = {
    id,
    position,
  };

  return axios
    .post(FaqServerUrl + WebConf.faq.shiftFaqUpAndDown + "?" + serialize(body))
    .then((response) => response && response.data);
};

export const uploadAttachments = (attachments: FormData) =>
  axios
    .post(`${FileServerUrl}${WebConf.file.uploadAttachments}`, attachments)
    .then((response) => response && response.data);

export const addFaqQuestion = (
  activeCategoryId: number,
  question: string,
  answer: string,
  stripHtml: string,
  attachments: Array<object>,
  id: number,
) => {
  const body = {
    id,
    categoryId: activeCategoryId,
    question,
    answers: [
      {
        id: null,
        answer: stripHtml,
        html: answer,
        type: "TEXT",
      },
    ],
    modifiedBy: {
      id: localStorage.getItem("userID"),
      fullName: `${localStorage.getItem("firstName")} ${localStorage.getItem(
        "lastName",
      )}`,
    },
    attachments,
  };

  return axios
    .post(FaqServerUrl + WebConf.faq.addFaqQuestion, body)
    .then((response) => (response && response.data ? true : false));
};

export const toggleActivation = (categoryId: number, isActive: boolean) => {
  const body = {
    categoryId,
    isActive,
  };

  return axios
    .post(FaqServerUrl + WebConf.faq.toggleActivation + "?" + serialize(body))
    .then((response) => response && response.status === 200);
};

export const cloneCategory = async (parentId: number, categoryId: number) => {
  const body = {
    parentId: parentId ?? "",
    categoryId,
  };

  return axios
    .post(FaqServerUrl + WebConf.faq.clone + "?" + serialize(body))
    .then((response) => response && response.status === 200);
};

export const createCategory = async (
  id: number,
  parentCategoryId: number,
  name: string,
  nameEn: string,
  nameRu: string,
  description: string,
) => {
  const userID = localStorage.getItem("userID"),
    fullName = `${localStorage.getItem("firstName")} ${localStorage.getItem(
      "lastName",
    )}`;
  const body = {
    id: id && id > 0 ? id : "",
    parentCategoryId:
      parentCategoryId && parentCategoryId > 0 ? parentCategoryId : "",
    name,
    nameEn,
    nameRu,
    description,
    modifiedBy: {
      id: userID,
      fullName,
    },
  };

  return axios
    .post(FaqServerUrl + WebConf.faq.createCategory, body)
    .then((response) => response && response.status === 200);
};

// DELETE
export const deleteFaqQuestion = (id: number) =>
  axios
    .delete(FaqServerUrl + WebConf.faq.deleteFaqQuestion + "/" + id)
    .then((response) => (response && response.status === 200 ? true : false));

export const deleteCategoryById = (id: number) =>
  axios
    .delete(FaqServerUrl + WebConf.faq.deleteCategoryById + "/" + id)
    .then((response) => (response && response.status === 200 ? true : false));
