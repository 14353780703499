import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const fullName =
  localStorage.getItem("firstName") + " " + localStorage.getItem("lastName");
const userID = localStorage.getItem("userID");
const sipNumber = localStorage.getItem("sipNumber");
const username = localStorage.getItem("username");

export const SentryInit = (): void => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({ maskAllText: true, blockAllMedia: true }),
    ],
    initialScope: {
      user: { userID, fullName, username, sipNumber },
    },
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const SentryConfigure = (): void => {
  Sentry.configureScope((scope) =>
    scope.setUser({ userID, fullName, username, sipNumber }),
  );
};

export const SentryCaptureException = (err): void => {
  Sentry.withScope((scope) => {
    scope.setLevel("error");
    scope.setFingerprint([String(err)]);
    Sentry.captureMessage(err);
    Sentry.captureException(err);
  });
};
