/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

import { styled } from "@material-ui/core";

import { getFaqsByCategoryId } from "Api/faq";

import { RequestContextStore } from "Components/Layout/AppLayout";
import Notification from "Notifications/Notification";

import Question from "./Question";
import FullPath from "./FullPath";

import { defaultStates as defaultFaqStates } from "Store/Reducers/faqReducer";
import Faq from "Store/Actions/Faq";

export const showErrorModal = (text: string) => {
  Notification.error(text);
};

const Questions = () => {
  const totalFaqState = useSelector(
    (state: RootStateOrAny) => state.faqReducer,
  );
  const requestContextStore: any = useContext(RequestContextStore);
  const faqState = requestContextStore.getCurrentStateFromTotalState(
    totalFaqState,
    defaultFaqStates,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const faq = new Faq(useDispatch());

  const getOptionsByCategoryId = async (id: number) => {
    return getFaqsByCategoryId(id)
      .then((response) => {
        if (response) {
          const tempData = response.map((item) => ({
            id: item.id,
            question: item.question,
            answer: item.answers.length > 0 ? item.answers[0].html : "",
            modifiedBy: item.modifiedBy ? item.modifiedBy.fullName : "",
            modificationDate: item.modificationDate,
            attachments: item.attachments,
            fullPath: item.category.fullPath,
          }));
          faq.setAllQuestions(
            requestState.menuType,
            requestContextStore.getRequestId(),
            tempData,
          );
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleSelectQuestion = (question) => {
    faq.setSelectedQuestion(
      requestState.menuType,
      requestContextStore.getRequestId(),
      question,
    );
  };

  useEffect(() => {
    if (faqState.selectedCategory && faqState.questionSearchValue === "")
      getOptionsByCategoryId(faqState.selectedCategory.id);
    else if (!faqState.selectedCategory)
      faq.setAllQuestions(
        requestState.menuType,
        requestContextStore.getRequestId(),
        [],
      );
  }, [faqState.selectedCategory]);

  const processThePath = (str) => str.split(" / ");

  let uniquePaths = [];

  return (
    <Container>
      <QuestionsList>
        {faqState.allQuestions &&
          faqState.allQuestions.map((question) => {
            let { fullPath } = question;
            var renderFullPath = null;
            if (!uniquePaths.includes(fullPath)) {
              uniquePaths.push(fullPath);
              renderFullPath = <FullPath path={processThePath(fullPath)} />;
            }
            return (
              <>
                {renderFullPath}
                <Question
                  handleSelectQuestion={handleSelectQuestion}
                  key={question.id}
                  question={{
                    id: question.id,
                    question: question.question,
                    answer: question.answer,
                    attachments: question.attachments,
                    modificationDate: question.modificationDate,
                    modifiedBy: question.modifiedBy,
                  }}
                />
              </>
            );
          })}
      </QuestionsList>
    </Container>
  );
};

export default Questions;

const Container = styled("div")({
  height: "calc(100vh - var(--nav-bar-height) - 230px)",
  overflowY: "auto",
  borderRadius: 16,
  width: "100%",
  "&::-webkit-scrollbar": {
    width: 3,
    height: 5,
  },
});

const QuestionsList = styled("ul")({
  padding: 15,
  paddingTop: 0,
  listStyle: "none",
});
