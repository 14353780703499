import { styled } from "@material-ui/core";

export const ActionButton = styled("div")({
  background: "rgba(0, 178, 150, 0.1)",
  borderRadius: 24,
  color: "#00B287",
  fontSize: 12,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  cursor: "pointer",
  width: "80%",
  padding: "8px 16px",
  margin: "0 auto",
  "&.disabled": {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  "&.disable": {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
});
