import { styled } from "@material-ui/core/styles";

import React from "react";

import { tabs } from "./constants";
import { useLeftPanelTabs } from "./hooks";

const LeftPanelTabs = (): React.ReactElement => {
  const { chooseTabIcon } = useLeftPanelTabs();

  return (
    <Container>
      {Object.keys(tabs).map((key, index) => (
        <Tab key={`${index}_${key}`}>
          {chooseTabIcon(tabs[key])}
          {tabs[key]}
        </Tab>
      ))}
    </Container>
  );
};

export default LeftPanelTabs;

LeftPanelTabs.displayName = "LeftPanelTabs";

const Container = styled("div")({
  width: "100%",
  height: "5%",
  padding: 5,
  borderBottom: "1px solid #DCDDE6",
  paddingLeft: 0,
  paddingRight: 0,
});

const Tab = styled("div")({
  display: "inline-block",
  cursor: "pointer",
  color: "#020110",
  fontWeight: 600,
  fontSize: 12,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  width: "20%",
  padding: "10px 10px 10px 0",
  float: "left",
});
