import { styled } from "@material-ui/core/styles";
import { getClassificatorsLikeTree } from "Api/classificator";
import { RequestContextStore } from "Components/Layout/AppLayout";
import TreeView from "devextreme-react/tree-view";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Topic from "Store/Actions/Topic";
import { defaultStates as defaultTopicStates } from "Store/Reducers/topicReducer";

import { AddIcon } from "./Icons";

const Classificators = (): React.ReactElement => {
  const [currentItem, setCurrentItem] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const totalTopicState = useSelector(
    (state: RootStateOrAny) => state.topicReducer,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const topic = new Topic(useDispatch());

  const requestContextStore: any = useContext(RequestContextStore);
  const topicState = requestContextStore.getCurrentStateFromTotalState(
    totalTopicState,
    defaultTopicStates,
  );

  const timeoutRef = useRef(null);
  const treeViewRef = useRef(null);

  const getConfigForClassifier = localStorage.getItem(
    "onlyChildClassifiersAllowed",
  );
  const getConfigForSearchLimit = localStorage.getItem(
    "classifierSearchCharacterLimit",
  );

  const classifierSearchCount =
    !getConfigForSearchLimit || getConfigForSearchLimit === "undefined"
      ? 4
      : getConfigForSearchLimit;

  const fetchClassificators = (): void => {
    getClassificatorsLikeTree()
      .then((res) => {
        if (res) {
          topic.setAllClassificators(
            requestState.menuType,
            requestContextStore.getRequestId(),
            res,
          );
          topic.setOriginalClassificators(
            requestState.menuType,
            requestContextStore.getRequestId(),
            res,
          );
        } else throw new Error();
      })
      .catch((e) => console.log(e));
  };

  useEffect(fetchClassificators, [
    requestState.menuType,
    requestContextStore.getRequestId(),
    requestState.showRegistration,
  ]);

  useEffect(() => {
    topicState.searchValue.length >= classifierSearchCount
      ? setSearchValue(topicState.searchValue.toLowerCase())
      : setSearchValue("");
  }, [topicState.searchValue, searchValue]);

  useEffect(() => {
    searchValue.length < 1 && treeViewRef.current.instance.collapseAll();
  }, [searchValue]);

  const toggleGetChildren = (e): void => {
    setCurrentItem(e.itemData);

    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
    }, 200);
  };

  const addPathOnDoubleClick = (path): void => {
    clearTimeout(timeoutRef.current);
    if (!topicState.savedPathIds.includes(path.id))
      topic.addNewPath(
        requestState.menuType,
        requestContextStore.getRequestId(),
        path,
      );
  };

  const addPath = (event, path): void => {
    event.stopPropagation();
    topic.addNewPath(
      requestState.menuType,
      requestContextStore.getRequestId(),
      path,
    );
  };

  const itemTemplate = (item): React.ReactNode => {
    const checkClassifiersAllowed =
      getConfigForClassifier === "true" ? item.children.length === 0 : true;

    return (
      <div>
        {item.name}
        {!topicState.savedPathIds.includes(item.id) &&
          checkClassifiersAllowed && (
            <AddButton
              onClick={(event) =>
                addPath(event, { id: item.id, path: item.fullPath })
              }
            />
          )}
      </div>
    );
  };

  return useMemo(
    () => (
      <StyledCategories
        onDoubleClick={() =>
          addPathOnDoubleClick({
            id: currentItem?.id,
            path: currentItem?.fullPath,
            note: "",
          })
        }>
        <TreeView
          id='treeview'
          ref={treeViewRef}
          items={topicState.allClassificators}
          itemRender={itemTemplate}
          searchMode='contains'
          searchTimeout={1000}
          searchValue={searchValue}
          displayExpr='name'
          itemsExpr='children'
          keyExpr='id'
          expandEvent='click'
          onItemClick={toggleGetChildren}
        />
      </StyledCategories>
    ),
    [
      topicState.allClassificators,
      searchValue,
      currentItem,
      topicState.savedPathIds,
    ],
  );
};

export default Classificators;

const StyledCategories = styled("div")({
  paddingBottom: 7,
  backgroundColor: "white",
  maxHeight: "calc(88% - 75px)",
  height: "calc(88% - 75px)",
  minHeight: "calc(88% - 75px)",
  overflowY: "auto",
  userSelect: "text",
  "&::-webkit-scrollbar": {
    width: 3,
    height: 5,
  },
  "& .dx-treeview-item-content": {
    textAlign: "left",
    userSelect: "text",
  },
});

const AddButton = styled(AddIcon)({
  color: "#F68F1F",
  fontSize: 22,
  fontWeight: 600,
  lineHeight: 12,
  position: "relative",
  top: 3,
  cursor: "pointer",
  float: "right",
  marginRight: 15,
  userSelect: "text",
});
