/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import menuTypes from "Assets/constants/menuTypes";
import { useCallback } from "react";

import { cchActions as actionTypes } from "./actionTypes";

export default class Cch {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setFormValues = useCallback(
    (menuType: menuTypes, requestId: number, formValues: any) =>
      this.dispatch({
        type: actionTypes.SET_FORM_FIELDS_VALUES,
        formValues,
        menuType,
        requestId,
      }),
    [this],
  );

  addSavedCch = useCallback(
    (menuType: menuTypes, requestId: number, savedCch: any) =>
      this.dispatch({
        type: actionTypes.ADD_SAVED_CCH,
        savedCch,
        menuType,
        requestId,
      }),
    [this],
  );

  addAllCCHs = useCallback(
    (menuType: menuTypes, requestId: number, allSavedCchs: any) =>
      this.dispatch({
        type: actionTypes.ADD_ALL_CCHS,
        allSavedCchs,
        menuType,
        requestId,
      }),
    [this],
  );

  removeSavedCch = useCallback(
    (menuType: menuTypes, requestId: number, id: number) =>
      this.dispatch({
        type: actionTypes.REMOVE_SAVED_CCH,
        id,
        menuType,
        requestId,
      }),
    [this],
  );

  clearTasksByRequestId = useCallback(
    (menuType: menuTypes, requestId: number) =>
      this.dispatch({
        type: actionTypes.CLEAR_TASKS_BY_REQUEST_ID,
        menuType,
        requestId,
      }),
    [this],
  );

  resetFormValues = useCallback(
    (menuType: menuTypes, requestId: number) =>
      this.dispatch({
        type: actionTypes.RESET_TASK_FORM_FIELDS,
        menuType,
        requestId,
      }),
    [this],
  );
}
