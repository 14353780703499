import React, { useState, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  Popover,
  ListItemText,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import Button from "Components/Buttons/Button";
import { buttonTypes } from "Components/Buttons/buttonTypes";
import debounce from "lodash.debounce";
import { useChatBoxStyles } from "./styles";

const PredefinedMessagePopover = ({
  open,
  predefinedMessages,
  selectedPredefinedMsg,
  handleClose,
  handleSelect,
  handleSend,
  translate,
}) => {
  const componentClasses = useChatBoxStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [hoveredContent, setHoveredContent] = useState(null);
  const [contentPosition, setContentPosition] = useState({ top: 0, left: 0 });
  const [selectedContent, setSelectedContent] = useState(null);

  const debouncedSetSearchTerm = useCallback(
    debounce((value) => setSearchTerm(value), 300),
    [],
  );
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSetSearchTerm(value);
  };

  const filteredMessages = predefinedMessages.filter((msg) =>
    msg.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleMouseEnter = useCallback((event, content) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const scrollY = window.scrollY;
    const scrollX = window.scrollX;
    const tooltipHeight = 100; // Estimated tooltip height
    const bottomSpace = window.innerHeight - rect.bottom;

    let topPosition = rect.top + scrollY - (window.innerHeight * 2) / 100;
    let leftPosition = rect.right + 10 + scrollX;

    if (bottomSpace < tooltipHeight) {
      topPosition = rect.top + scrollY - tooltipHeight;
    }

    topPosition -= window.innerHeight * 0.05; // 5% offset

    setHoveredContent(content);
    setContentPosition({
      top: topPosition,
      left: leftPosition,
    });
  }, []);

  const handleMouseLeave = () => {
    setHoveredContent(null);
  };

  const handleMessageClick = (message) => {
    handleSelect({ target: { value: message.content } });
    setSelectedContent(message.content);
  };

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "center", horizontal: "left" }}
      transformOrigin={{ vertical: "center", horizontal: "center" }}>
      <div className={componentClasses.predefinedPopover}>
        <div className={componentClasses.headerContainer}>
          <FormControl fullWidth>
            <InputLabel htmlFor='search'>{translate("Search")}</InputLabel>
            <Input
              id='search'
              type='text'
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder={translate("Search")}
            />
          </FormControl>
        </div>
        <div className={componentClasses.listContainer}>
          <List>
            {filteredMessages.map((msg) => (
              <div
                key={msg.id}
                className={componentClasses.listItemHover}
                style={{ position: "relative" }}
                onMouseEnter={(event) => handleMouseEnter(event, msg.content)}
                onMouseLeave={handleMouseLeave}>
                <ListItem
                  button
                  onClick={() => handleMessageClick(msg)}
                  className={`${componentClasses.listItem} ${
                    selectedContent === msg.content
                      ? componentClasses.selectedItem
                      : ""
                  }`}>
                  <ListItemText primary={msg.name} />
                </ListItem>
                {hoveredContent === msg.content && (
                  <div
                    id={`tooltip-${msg.id}`}
                    className={componentClasses.tooltip}
                    style={{
                      top: contentPosition.top,
                      left: contentPosition.left,
                      visibility: hoveredContent ? "visible" : "hidden",
                      opacity: hoveredContent ? 1 : 0,
                    }}>
                    <Typography>{hoveredContent}</Typography>
                  </div>
                )}
              </div>
            ))}
          </List>
        </div>
        <div className={componentClasses.buttonPreContainer}>
          <Button
            disabled={!selectedPredefinedMsg}
            className={componentClasses.buttonPreClass}
            onClick={handleSend}
            buttonType={buttonTypes.success}>
            {translate("Send")}
          </Button>
          <Button
            className={componentClasses.buttonPreClass}
            onClick={handleClose}
            buttonType={buttonTypes.primary}>
            {translate("Close")}
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default PredefinedMessagePopover;
