import menuTypes from "Assets/constants/menuTypes";

import constants from "Components/Request/SmsTemplates/constants";

import { smsActions as actionTypes } from "../Actions/actionTypes";
import { getRequestStates, updateObject, updateTotalState } from "../utility";

export const PER_PAGE = 10;

const initialState = {
  [menuTypes.CALL]: {},
  [menuTypes.CHAT]: {},
};

export const defaultStates = {
  templates: [],
  chosenTemplate: {},
  chosenId: -1,
  searchString: "",
  search: constants.searchBy.all,
  pagination: {
    page: 0,
    size: PER_PAGE,
    totalElements: 0,
    totalPages: 0,
    currentInterval: "",
  },
  isSearching: false,
  onSearch: false,
};

const setAllTemplates = (state, action) => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestStates = updateObject(requestStates, {
    templates: action.templates,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const setPagination = (state, action) => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const pagination = { ...requestStates.pagination, ...action.pagination };

  requestStates = updateObject(requestStates, {
    pagination,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const setChosenTemplate = (state, action) => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const { chosenTemplate, chosenId } = action;

  requestStates = updateObject(requestStates, {
    chosenTemplate,
    chosenId,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const setIsSearching = (state, action) => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const { isSearching } = action;

  requestStates = updateObject(requestStates, {
    isSearching,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const setSearch = (state, action) => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const { search } = action;

  requestStates = updateObject(requestStates, {
    search,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const setOnSearch = (state, action) => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const { onSearch } = action;

  requestStates = updateObject(requestStates, {
    onSearch,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const setSearchString = (state, action) => {
  let requestStates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const { searchString } = action;

  requestStates = updateObject(requestStates, {
    searchString,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestStates,
  );
};

const smsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ALL_TEMPLATES:
      return setAllTemplates(state, action);
    case actionTypes.SET_PAGINATION:
      return setPagination(state, action);
    case actionTypes.SET_CHOSEN_TEMPLATE:
      return setChosenTemplate(state, action);
    case actionTypes.SET_SEARCHING:
      return setIsSearching(state, action);
    case actionTypes.SET_SEARCH:
      return setSearch(state, action);
    case actionTypes.SET_ON_SEARCH:
      return setOnSearch(state, action);
    case actionTypes.SET_SEARCH_STRING:
      return setSearchString(state, action);
    default:
      return state;
  }
};

export default smsReducer;
