import WebConf, { AtbServerUrl } from "Config/WebConf";
import { fetchData } from "networking";

// ATB Customer Search Intergation

//POST
export const searchByCif = async (cif) => {
  const body: BodyInit = JSON.stringify(cif);

  const params: RequestInit = {
    method: "POST",
    body,
  };

  const response = await fetchData(
    AtbServerUrl + WebConf.atb.searchByCif,
    params,
  );

  if (response && response.status === 200) {
    try {
      return await response.json();
    } catch (error) {
      return [];
    }
  } else return [];
};

export const searchByFin = async (fin) => {
  const body: BodyInit = JSON.stringify(fin);

  const params: RequestInit = {
    method: "POST",
    body,
  };

  const response = await fetchData(
    AtbServerUrl + WebConf.atb.searchByFin,
    params,
  );

  if (response && response.status === 200) {
    try {
      return await response.json();
    } catch (error) {
      return [];
    }
  } else return [];
};

export const searchByVoen = async (fin) => {
  const body: BodyInit = JSON.stringify(fin);

  const params: RequestInit = {
    method: "POST",
    body,
  };

  const response = await fetchData(
    AtbServerUrl + WebConf.atb.searchByVoen,
    params,
  );

  if (response && response.status === 200) {
    try {
      return await response.json();
    } catch (error) {
      return [];
    }
  } else return [];
};

export const searchByFullnameAndDate = async (data) => {
  const body: BodyInit = JSON.stringify(data);

  const params: RequestInit = {
    method: "POST",
    body,
  };

  const response = await fetchData(
    AtbServerUrl + WebConf.atb.searchByFullnameAndDate,
    params,
  );

  if (response && response.status === 200) {
    try {
      return await response.json();
    } catch (error) {
      return [];
    }
  } else return [];
};

export const fetchAccounts = async (data) => {
  const body: BodyInit = JSON.stringify(data);

  const params: RequestInit = {
    method: "POST",
    body,
  };

  const response = await fetchData(
    AtbServerUrl + WebConf.atb.fetchAccounts,
    params,
  );

  if (response && response.status === 200) {
    try {
      return await response.json();
    } catch (error) {
      return [];
    }
  } else return [];
};

export const fetchDepositAccounts = async (data) => {
  const body: BodyInit = JSON.stringify(data);

  const params: RequestInit = {
    method: "POST",
    body,
  };

  const response = await fetchData(
    AtbServerUrl + WebConf.atb.fetchDepositAccounts,
    params,
  );

  if (response && response.status === 200) {
    try {
      return await response.json();
    } catch (error) {
      return [];
    }
  } else return [];
};

export const fetchDepositsByAccountNumber = async (data) => {
  const body: BodyInit = JSON.stringify(data);

  const params: RequestInit = {
    method: "POST",
    body,
  };

  const response = await fetchData(
    AtbServerUrl + WebConf.atb.fetchDepositsByAccountNumber,
    params,
  );

  if (response && response.status === 200) {
    try {
      return await response.json();
    } catch (error) {
      return [];
    }
  } else return [];
};

export const fetchCreditAccounts = async (data) => {
  const body: BodyInit = JSON.stringify(data);

  const params: RequestInit = {
    method: "POST",
    body,
  };

  const response = await fetchData(
    AtbServerUrl + WebConf.atb.fetchCreditAccounts,
    params,
  );

  if (response && response.status === 200) {
    try {
      return await response.json();
    } catch (error) {
      return [];
    }
  } else return [];
};

export const fetchCards = async (data) => {
  const body: BodyInit = JSON.stringify(data);

  const params: RequestInit = {
    method: "POST",
    body,
  };

  const response = await fetchData(
    AtbServerUrl + WebConf.atb.fetchCards,
    params,
  );

  if (response && response.status === 200) {
    try {
      return await response.json();
    } catch (error) {
      return [];
    }
  } else return [];
};

export const fetchTransactions = async (data) => {
  const body: BodyInit = JSON.stringify(data);

  const params: RequestInit = {
    method: "POST",
    body,
  };

  const response = await fetchData(
    AtbServerUrl + WebConf.atb.fetchTransactions,
    params,
  );

  if (response && response.status === 200) {
    try {
      return await response.json();
    } catch (error) {
      return [];
    }
  } else return [];
};

export const fetchTransactionsByAccount = async (data) => {
  const body: BodyInit = JSON.stringify(data);

  const params: RequestInit = {
    method: "POST",
    body,
  };

  const response = await fetchData(
    AtbServerUrl + WebConf.atb.fetchTransactionsByAccount,
    params,
  );

  if (response && response.status === 200) {
    try {
      return await response.json();
    } catch (error) {
      return [];
    }
  } else return [];
};
