/* eslint-disable eqeqeq */
import React from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Grid } from "@material-ui/core";
import SoftPhone from "Store/Actions/SoftPhone";
import LineButton from "./LineButton";
import { callTypes, colors } from "./constants";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const Lines = () => {
  const { translate } = useTranslation();
  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );
  const softPhoneStore = new SoftPhone(useDispatch());

  const checkIfLineIsCurrent = (index: number) => {
    if (softPhoneState.currentLine == index) return true;
    return false;
  };

  const handleLineClick = (index: number) => {
    softPhoneStore.setCurrentLine(index);
  };

  const checkIfLineIsActive = (index: number) => {
    const callData = softPhoneState.lines[index];
    if (callData) {
      if (callData.callType == callTypes.IN)
        if (callData.isAnswered) return true;
        else return true;
    }
    return false;
  };

  const checkIfBorderisColored = (index: number) => {
    let border =
      softPhoneState.lines[index]?.isOnHold && checkIfLineIsCurrent(index)
        ? `2px solid ${colors.active}`
        : softPhoneState.lines[index]?.isOnHold
        ? `2px solid ${colors.hold}`
        : softPhoneState.lines[index]?.isMuted
        ? `2px solid ${colors.mute}`
        : checkIfLineIsCurrent(index)
        ? `2px solid ${colors.active}`
        : "2px solid rgba(210, 210, 210)";
    return border;
  };

  return (
    <>
      <Grid container style={{ marginTop: 14 }}>
        <Grid
          item
          xs={4}
          style={{ borderRight: "1px solid rgba(210, 210, 210)" }}>
          <LineButton
            isCurrentLine={checkIfLineIsCurrent(0)}
            onClick={() => handleLineClick(0)}
            active={checkIfLineIsActive(0)}
            name={translate("softphone_line", { number: 1 })}
            lineNumber={0}
          />
        </Grid>
        <Grid
          item
          xs={4}
          style={{ borderRight: "1px solid rgba(210, 210, 210)" }}>
          <LineButton
            isCurrentLine={checkIfLineIsCurrent(1)}
            onClick={() => handleLineClick(1)}
            active={checkIfLineIsActive(1)}
            name={translate("softphone_line", { number: 2 })}
            lineNumber={1}
          />
        </Grid>
        <Grid item xs={4}>
          <LineButton
            isCurrentLine={checkIfLineIsCurrent(2)}
            onClick={() => handleLineClick(2)}
            active={checkIfLineIsActive(2)}
            name={translate("softphone_line", { number: 3 })}
            lineNumber={2}
          />
        </Grid>
      </Grid>
      <div style={{ alignSelf: "center" }}>
        <hr
          style={{
            border: "none",
            borderTop: checkIfBorderisColored(0),
            display: "inline-block",
            width: "32%",
            margin: 0,
            height: 1,
          }}
        />
        <hr
          style={{
            border: "none",
            borderTop: checkIfBorderisColored(1),
            display: "inline-block",
            width: "32%",
            margin: 0,
            height: 1,
          }}
        />
        <hr
          style={{
            border: "none",
            borderTop: checkIfBorderisColored(2),
            display: "inline-block",
            width: "32%",
            margin: 0,
            height: 1,
          }}
        />
      </div>
    </>
  );
};

export default Lines;
