import { TextField, createStyles, makeStyles } from "@material-ui/core";
import React from "react";

import fieldTypes from "./fieldTypes";
import FormElement from "./FormElement";
import FormFieldWrapper from "./FormFieldWrapper";

interface IFormInput {
  size?: "small" | "medium";
  fieldType?: fieldTypes;
  id?: any;
  value?: string;
  regex?: string;
  label?: string;
  name?: string;
  required?: boolean;
  errorText?: string;
  handleValidation?: any;
  checkValidation?: boolean;
  handleChange: any;
  handleKeyDown?: any;
  InputProps?: any;
  variant?: any;
  setValue?: any;
  error?: boolean;
  [x: string]: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      width: "100%",
      "& input:focus": {
        boxShadow: "0 0 1px 1px white !important",
      },

      "& > label": {
        display: "none",
      },
    },
  }),
);

/*
 * Standard form input.
 *
 * @visibleName FormInput
 */
export const FormInputPlain = (props: IFormInput): React.ReactElement => {
  const classes = useStyles();

  const size = props.size ?? "medium";
  const variant = props.variant ?? "outlined";
  const fieldType = props.fieldType ?? "text";
  const type = props.fieldType === fieldTypes.NUMBER ? "number" : "text";

  const handleChange = (event): void => {
    let value = event.target.value;

    //disable float in number type
    if (fieldType === fieldTypes.NUMBER && isNaN(parseInt(value.substr(-1)))) {
      value = value.substr(0, value.length - 1);
    }
    props.setValue(value);
    if (props.handleChange) props.handleChange(value, props.id, props.label);
  };

  const handleKeyDown = (event): void => {
    if (props.handleKeyDown) {
      props.handleKeyDown(event);
    }
    if (fieldType === fieldTypes.NUMBER && event.key === ".") {
      event.preventDefault();
    }
  };

  return (
    <FormFieldWrapper>
      <TextField
        id={props.id}
        type={type}
        error={props.error}
        placeholder={props.label}
        name={props.name}
        required={props.required}
        size={size}
        variant={variant}
        onKeyDown={(e) => handleKeyDown(e)}
        onChange={(e) => handleChange(e)}
        value={props.value || ""}
        helperText={props.errorText}
        classes={{ root: classes.input }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={props.InputProps}
      />
    </FormFieldWrapper>
  );
};
export default FormElement(FormInputPlain);
