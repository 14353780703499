import { createStyles, makeStyles, Tab, Tabs } from "@material-ui/core";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { availablePages, permissions } from "Config/config";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { permit } from "Utils/Utils";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      backgroundColor: "white",
    },
    linkStyle: {
      textDecoration: "none",
      color: "gray",
      "& > button": {
        lineHeight: "unset",
        letterSpacing: "unset",
        textTransform: "initial",
        opacity: 1,
        padding: "0px 5px",
      },
    },
  }),
);

const GeneratorTabs = (): React.ReactElement => {
  const { translate } = useTranslation();
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const tabs = [
    permit(
      {
        label: translate("navbar_faq"),
        path: availablePages.categories,
        permission: permissions.faq_categories_page,
      },
      permissions.faq_categories_page,
    ),
    permit(
      {
        label: translate("navbar_classificators"),
        path: availablePages.classificators,
        permission: permissions.classifier_list,
      },
      permissions.classifier_list,
    ),
    permit(
      {
        label: translate("navbar_sms_templates"),
        path: availablePages.smsTemplates,
        permission: permissions.sms_get,
      },
      permissions.sms_get,
    ),
    permit(
      {
        label: translate("navbar_message_templates"),
        path: availablePages.messageTemplates,
        permission: permissions.omnichannel_get_message_templates,
      },
      permissions.omnichannel_get_message_templates,
    ),
    permit(
      {
        label: translate("navbar_auto_reply_message"),
        path: availablePages.autoReplyMessage,
        permission: permissions.generator_auto_reply_message,
      },
      permissions.generator_auto_reply_message,
    ),
    permit(
      {
        label: translate("navbar_predefined_message"),
        path: availablePages.preDefinedMessage,
        permission: permissions.generator_predefined_message,
      },
      permissions.generator_predefined_message,
    ),
  ];

  const newArray = tabs.filter((item) => item !== null);

  useEffect(() => {
    let selectedIndex = 0;

    for (let i = 0; i < newArray.length; i++) {
      const tab = newArray[i];
      if (tab.path === window.location.pathname) {
        selectedIndex = i;
        break;
      }
    }
    setValue(selectedIndex);
  }, [newArray]);

  return (
    <div className={classes.container}>
      <Tabs
        value={value}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable auto tabs example'>
        {permit(
          <NavLink to={availablePages.categories} className={classes.linkStyle}>
            <Tab label={translate("navbar_faq")} />
          </NavLink>,
          permissions.faq_categories_page,
        )}
        {permit(
          <NavLink
            to={availablePages.classificators}
            className={classes.linkStyle}>
            <Tab label={translate("navbar_classificators")} />
          </NavLink>,
          permissions.classifier_list,
        )}
        {permit(
          <NavLink
            to={availablePages.smsTemplates}
            className={classes.linkStyle}>
            <Tab label={translate("navbar_sms_templates")} />
          </NavLink>,
          permissions.sms_get,
        )}
        {permit(
          <NavLink
            to={availablePages.messageTemplates}
            className={classes.linkStyle}>
            <Tab label={translate("navbar_message_templates")} />
          </NavLink>,
          permissions.omnichannel_get_message_templates,
        )}
        {permit(
          <NavLink
            to={availablePages.autoReplyMessage}
            className={classes.linkStyle}>
            <Tab label={translate("navbar_auto_reply_message")} />
          </NavLink>,
          permissions.generator_auto_reply_message,
        )}
        {permit(
          <NavLink
            to={availablePages.preDefinedMessage}
            className={classes.linkStyle}>
            <Tab label={translate("navbar_predefined_message")} />
          </NavLink>,
          permissions.generator_predefined_message,
        )}
      </Tabs>
    </div>
  );
};

export default GeneratorTabs;

GeneratorTabs.displayName = "GeneratorTabs";
