import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import AddIcon from "@material-ui/icons/Add";
import AssistantIcon from "@material-ui/icons/Assistant";
import AssistantPhotoIcon from "@material-ui/icons/AssistantPhoto";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import BackspaceOutlinedIcon from "@material-ui/icons/BackspaceOutlined";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";
import ChatIcon from "@material-ui/icons/Chat";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import DialpadIcon from "@material-ui/icons/Dialpad";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import EditIcon from "@material-ui/icons/Edit";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import ErrorIcon from "@material-ui/icons/Error";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FacebookIcon from "@material-ui/icons/Facebook";
import FlipIcon from "@material-ui/icons/Flip";
import FolderIcon from "@material-ui/icons/Folder";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ForumIcon from "@material-ui/icons/Forum";
import ForwardOutlinedIcon from "@material-ui/icons/ForwardOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HistoryIcon from "@material-ui/icons/History";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import InsertCommentIcon from "@material-ui/icons/InsertComment";
import InstagramIcon from "@material-ui/icons/Instagram";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import MicNoneIcon from "@material-ui/icons/MicNone";
import MicOffOutlinedIcon from "@material-ui/icons/MicOffOutlined";
import MinimizeIcon from "@material-ui/icons/Minimize";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NotesIcon from "@material-ui/icons/Notes";
import PauseIcon from "@material-ui/icons/Pause";
import PaymentIcon from "@material-ui/icons/Payment";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";
import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import PieChartIcon from "@material-ui/icons/PieChart";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import ReplayIcon from "@material-ui/icons/Replay";
import ReplyIcon from "@material-ui/icons/Reply";
import SdStorageOutlinedIcon from "@material-ui/icons/SdStorageOutlined";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import SettingsIcon from "@material-ui/icons/Settings";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import SmsIcon from "@material-ui/icons/Sms";
import SpeedIcon from "@material-ui/icons/Speed";
import StarsIcon from "@material-ui/icons/Stars";
import StopIcon from "@material-ui/icons/Stop";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import TelegramIcon from "@material-ui/icons/Telegram";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

export {
  CallIcon,
  SendIcon,
  CallEndIcon,
  ForumIcon,
  ErrorIcon,
  DeviceHubIcon,
  LiveHelpIcon,
  VerticalSplitIcon,
  PersonIcon,
  FolderIcon,
  CheckBoxIcon,
  SettingsIcon,
  PieChartIcon,
  EqualizerIcon,
  KeyboardArrowDownIcon,
  PowerSettingsNewIcon,
  PhoneIphoneIcon,
  AssistantIcon,
  InfoOutlinedIcon,
  ExpandMoreIcon,
  GetAppIcon,
  MailOutlineIcon,
  StarsIcon,
  CloseIcon,
  DeleteIcon,
  SearchIcon,
  PersonAddIcon,
  EditIcon,
  NotesIcon,
  PhoneEnabledIcon,
  AccountCircleOutlinedIcon,
  AddIcon,
  AssistantPhotoIcon,
  ChatIcon,
  DateRangeOutlinedIcon,
  DeleteForeverOutlinedIcon,
  FacebookIcon,
  InstagramIcon,
  InsertCommentIcon,
  LocalPhoneIcon,
  PaymentIcon,
  TelegramIcon,
  WhatsAppIcon,
  HistoryIcon,
  PhoneForwardedIcon,
  DateRangeIcon,
  CalendarTodayIcon,
  FormatListBulletedIcon,
  MoreVertIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  SmsIcon,
  VisibilityOffIcon,
  VisibilityIcon,
  AccountBoxIcon,
  CheckCircleOutlineIcon,
  AccountCircleIcon,
  ForwardOutlinedIcon,
  AccessTimeIcon,
  DoneIcon,
  DoneAllIcon,
  ErrorOutlineIcon,
  MicNoneIcon,
  HighlightOffIcon,
  SettingsVoiceIcon,
  AttachFileOutlinedIcon,
  PlayArrowIcon,
  PauseIcon,
  StopIcon,
  ReplayIcon,
  PermPhoneMsgIcon,
  MinimizeIcon,
  FlipIcon,
  PhoneIcon,
  BackspaceOutlinedIcon,
  MicOffOutlinedIcon,
  DialpadIcon,
  ReplyIcon,
  SpeedIcon,
  MailOutlineOutlinedIcon,
  SdStorageOutlinedIcon,
  SystemUpdateAltOutlinedIcon,
};
