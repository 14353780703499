import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import ForumIcon from "@material-ui/icons/Forum";
import CloseIcon from "@material-ui/icons/Close";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ImageIcon from "@material-ui/icons/Image";
import DescriptionIcon from "@material-ui/icons/Description";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

export {
  DeleteIcon,
  EditIcon,
  HighlightOffIcon,
  FileCopyIcon,
  MoreVertIcon,
  FiberManualRecordIcon,
  FormatListBulletedIcon,
  AddIcon,
  SearchIcon,
  ForumIcon,
  CloseIcon,
  PictureAsPdfIcon,
  ImageIcon,
  DescriptionIcon,
  AttachFileIcon,
  ListAltIcon,
  ArrowForwardIosIcon,
  DragIndicatorIcon,
};
