import {
  Card,
  Grid,
  createStyles,
  makeStyles,
  styled,
} from "@material-ui/core";
import clsx from "clsx";
import CustomerSearchPanel from "Components/Crm/CustomerSearchPanel/CustomerSearchPanel";
import LoadingComponent from "Components/Loading/LoadingComponent";
import RequestLeftPanel from "Components/Request/LeftPanel/RequestLeftPanel";
import RequestRightPanel from "Components/Request/RightPanel/RequestRightPanel";
import React, { useEffect, useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";

import { CallRequestTable } from "./CallRequestTable/CallRequestTable";
import { tabs } from "./constants";
import CustomerRequest from "./CustomerRequests/CustomerRequest";
import CustomerSearch from "./CustomerSearch/CustomerSearch";
import Faq from "./FAQ/FaqContainer";
import Jira from "./Jira/Jira";
import RequestFooter from "./RequestFooter/RequestFooter";
import RequestTabs from "./RequestTabs";
import SmsTemplates from "./SmsTemplates/SmsTemplates";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      top: 44,
      width: "calc(100% - 640px)",
      height: "calc(100% - var(--nav-bar-height))",
      display: "flex",
      zIndex: 502,
      position: "fixed",
      marginTop: 20,
      transition: "all 0.25s ease-in-out",
      marginBottom: 20,
      flexDirection: "column",
      backgroundColor: "white",
      overflowY: "auto",
      border: "1px solid white",
      borderTopRightRadius: 24,
    },
    requestHidden: {
      display: "none",
    },
    requestShown: {
      display: "block",
    },
  }),
);

const Request = (): React.ReactElement => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(tabs.Registration.name);
  const [isCallRequestTable, setCallRequestTable] = useState(false);
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );
  const callState = useSelector((state: RootStateOrAny) => state.callReducer);
  const chatBoxState = useSelector(
    (state: RootStateOrAny) => state.chatReducer,
  );

  useEffect(() => {
    if (requestState.isSending) setIsLoading(true);
    else setIsLoading(false);
  }, [requestState.isSending]);

  let CurrentComponent = null;

  switch (currentTab) {
    case tabs.Registration.name:
      CurrentComponent = (
        <MainContent container>
          <RequestLeftPanel />
          <RequestRightPanel />
        </MainContent>
      );
      break;
    case tabs.SmsTemplates.name:
      CurrentComponent = (
        <SmsMainContent>
          <SmsTemplates />
        </SmsMainContent>
      );
      break;
    case tabs.Requests.name:
      CurrentComponent = (
        <CustomerRequestContent>
          <CustomerRequest />
        </CustomerRequestContent>
      );
      break;
    case tabs.KnowledgeBase.name:
      CurrentComponent = (
        <FaqContent>
          <Faq />
        </FaqContent>
      );
      break;
    case tabs.CustomerSearch.name:
      CurrentComponent = (
        <CustomerSearchContent>
          <CustomerSearch />
        </CustomerSearchContent>
      );
      break;
    case tabs.Jira.name:
      CurrentComponent = (
        <JiraContent>
          <Jira />
        </JiraContent>
      );
      break;
    case tabs.LastCallRequests.name:
      setCallRequestTable(true);
      setCurrentTab(tabs.Registration.name);
      break;
    default:
      CurrentComponent = (
        <MainContent container>
          <RequestLeftPanel />
          <RequestRightPanel />
        </MainContent>
      );
  }

  const adjustTheWidth = (): number => {
    const isLeftPanelOpen =
      softPhoneState.phoneConnected ||
      Object.keys(callState.calls).length !== 0 ||
      !chatBoxState.isHidden;

    if (requestState.isLeftPanelOpen) {
      if (softPhoneState.open) return 595;
      else if (chatBoxState.open) return 810;
      else return 250;
    } else if (isLeftPanelOpen && !requestState.isLeftPanelOpen) {
      if (softPhoneState.open) return 415;
      else if (chatBoxState.open) return 630;
      else return 75;
    } else if (!isLeftPanelOpen) {
      if (softPhoneState.open) return 350;
      else if (chatBoxState.open) return 565;
      return 0;
    }
  };

  const adjustBorderRadius = (): boolean => {
    const isLeftPanelOpen =
      softPhoneState.phoneConnected ||
      Object.keys(callState.calls).length !== 0 ||
      !chatBoxState.isHidden;

    return !isLeftPanelOpen && !softPhoneState.open && !chatBoxState.open;
  };

  return (
    <Card
      style={{
        width: `calc(100% - ${adjustTheWidth()}px)`,
        borderTopLeftRadius: adjustBorderRadius() ? 24 : 0,
      }}
      className={clsx(
        classes.root,
        requestState.showRegistration
          ? classes.requestShown
          : classes.requestHidden,
      )}>
      <RequestTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      {CurrentComponent}
      {isCallRequestTable && (
        <CallRequestTable
          isCallRequestTable={isCallRequestTable}
          setCallRequestTable={setCallRequestTable}
        />
      )}
      <CustomerSearchPanel />
      {isLoading ? <LoadingComponent /> : null}
      <FooterContent />
    </Card>
  );
};

export default Request;

const MainContent = styled(Grid)({
  height: "85%",
  width: "98%",
  margin: "0 auto",
});

const SmsMainContent = styled("div")({
  height: "85%",
  width: "98%",
  margin: "0 auto",
});

const CustomerRequestContent = styled("div")({
  height: "85%",
  width: "98%",
  margin: "0 auto",
});

const FaqContent = styled("div")({
  height: "85%",
  width: "98%",
  margin: "0 auto",
});

const JiraContent = styled("div")({
  height: "85%",
  width: "98%",
  margin: "0 auto",
});

const CustomerSearchContent = styled("div")({
  height: "85%",
  width: "98%",
  margin: "0 auto",
});

const FooterContent = styled(RequestFooter)({
  height: "12%",
});
