import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import fieldTypes from "Components/Form/fieldTypes";
import FormElement from "Components/Form/FormElement";
import FormFieldWrapper from "Components/Form/FormFieldWrapper";

interface IFormInput {
  fieldType?: fieldTypes;
  id?: any;
  value?: string;
  regex?: string;
  required?: boolean;
  errorText?: string;
  handleValidation?: Function;
  checkValidation?: boolean;
  handleChange: Function;
  handleKeyDown?: Function;
  [x: string]: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      width: "100%",
      "& input:focus": {
        boxShadow: "0 0 1px 1px white !important",
      },
    },
  }),
);

const Taginput = (props: IFormInput) => {
  const classes = useStyles();
  const fieldType = props.fieldType ?? "text";

  const handleChange = (event) => {
    let { value } = event.target;

    if (fieldType === fieldTypes.NUMBER && isNaN(parseInt(value.substr(-1))))
      value = value.substr(0, value.length - 1);

    props.setValue(value);
    if (props.handleChange) props.handleChange(value, props.id, props.label);
  };

  const handleKeyDown = (event) => {
    if (props.handleKeyDown) props.handleKeyDown(event);

    if (fieldType === fieldTypes.NUMBER && event.key === ".")
      event.preventDefault();
  };

  return (
    <FormFieldWrapper>
      <TextField
        id={props.id}
        required={props.required}
        error={props.error}
        size='small'
        onKeyDown={(e) => handleKeyDown(e)}
        onChange={(e) => handleChange(e)}
        value={props.value || ""}
        classes={{ root: classes.input }}
      />
    </FormFieldWrapper>
  );
};
export default FormElement(Taginput);
