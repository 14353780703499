import {
  closeBreak,
  findBreakById,
  getAllBreaksList,
  takeBreak,
  requestBreak,
  cancelBreak,
} from "Api/configuration";

import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import Notification from "Notifications/Notification";

import { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import { default as BreakActions } from "Store/Actions/Breaks";
import Call from "Store/Actions/Call";
import SoftPhoneStore from "Store/Actions/SoftPhone";

import { formatSecondsToTime } from "Utils/Utils";

import { BreakSystemHookProps } from "../interfaces";

export const useBreakSystem = (): BreakSystemHookProps => {
  const { translate } = useTranslation();

  const [status, setStatus] = useState<string>("Offline");
  const [timer, setTimer] = useState<number>(null);
  const [formattedTime, setFormattedTime] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isInBreak, setIsInBreak] = useState<boolean>(false);

  const softPhoneStore = new SoftPhoneStore(useDispatch());
  const call = new Call(useDispatch());
  const breaks = new BreakActions(useDispatch());

  const state = useSelector(
    ({
      softPhoneReducer,
      webSocketReducer,
      callReducer,
      breakReducer,
    }: RootStateOrAny) => ({
      isBreakInCall: softPhoneReducer.isBreakInCall,
      isBreakOnHold: softPhoneReducer.isBreakOnHold,
      isCallContinuing: softPhoneReducer.isCallContinuing,
      phoneConnected: softPhoneReducer.phoneConnected,
      lines: softPhoneReducer.lines,
      connected: webSocketReducer.connected,
      acwBreakId: callReducer.acwBreakId,
      closeBreakId: breakReducer.closeBreakId,
      allowCallTime: breakReducer.allowCallTime,
      selectedBreakId: breakReducer.selectedBreakId,
      breakName: breakReducer.breakName,
    }),
  );

  const {
    isBreakInCall,
    isBreakOnHold,
    isCallContinuing,
    phoneConnected,
    lines,
    connected,
    acwBreakId,
    closeBreakId,
    allowCallTime,
    selectedBreakId,
    breakName,
  } = state;

  const fetchAllBreakLists = async (): Promise<void> => {
    const response = await getAllBreaksList();
    if (response && response.length > 0) {
      const acwBreak = response?.filter((item) => item.name === "ACW");
      acwBreak.length !== 0 && call.setAcwBreakId(acwBreak[0].id);
    }
  };

  const closeBreakFunction = async (
    userId,
    breakId,
    allowCallTime,
  ): Promise<void> => {
    if (breakId == null) return;

    setLoading(true);
    closeBreak(userId, breakId, allowCallTime)
      .then((response) => {
        setLoading(false);
        if (response) {
          setTimer(0);
          setFormattedTime(formatSecondsToTime(0));
          setStatus("Available");
          breaks.setCloseBreakId(null);
        } else Notification.error(translate("bad_request"));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const takeBreakFunction = async (
    userId,
    breakId,
    allowCallTime,
    name,
    isPermission,
  ): Promise<void> => {
    setLoading(true);
    setFormattedTime(formatSecondsToTime(0));
    takeBreak(userId, breakId, allowCallTime, isPermission)
      .then(async (response) => {
        setLoading(false);
        if (response && response.status === 200) {
          setTimer(response.data);
          setStatus(name);
        } else if (response && response.status === 201) {
          breaks.setSelectedBreakId("default");
          closeBreakFunction(
            +localStorage.getItem("userID"),
            closeBreakId,
            allowCallTime,
          );
          Notification.success(translate("break_system_send_break"));
        } else if (response?.status !== 409) {
          breaks.setSelectedBreakId("default");
          closeBreakFunction(
            +localStorage.getItem("userID"),
            closeBreakId,
            allowCallTime,
          );
          setTimer(0);
          setFormattedTime(formatSecondsToTime(0));
          setStatus("Available");
          Notification.error(response.data.message);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const requestBreakFunction = async (userId): Promise<void> =>
    requestBreak(userId).then((response) => {
      if (!response) throw new Error();
    });

  const cancelBreakFunction = async (userId): Promise<void> =>
    cancelBreak(userId).then((response) => {
      if (!response) throw new Error();
    });

  const handleBreak = async (
    name: string,
    id?: string | number,
  ): Promise<void> => {
    if (id === "default") {
      if (isBreakInCall) {
        softPhoneStore.setBreakInCall(false);
        await cancelBreakFunction(parseInt(localStorage.getItem("userID")));
      }

      setIsInBreak(true);
      await closeBreakFunction(
        +localStorage.getItem("userID"),
        closeBreakId,
        allowCallTime,
      );
    } else {
      setIsInBreak(false);
      if (!isBreakInCall) {
        breaks.setSelectedBreakId(id);
        breaks.setBreakName(name);
      }

      if (!isCallContinuing) {
        if (selectedBreakId === acwBreakId) {
          setTimer(0);
          setFormattedTime(formatSecondsToTime(0));
        }

        const findBreak = await findBreakById(+id);
        breaks.setSelectedBreakId(id);
        breaks.setCloseBreakId(id);
        setStatus(name);
        breaks.setAllowCallTime(findBreak.allowCallTime);
        takeBreakFunction(
          +localStorage.getItem("userID"),
          +id,
          findBreak.allowCallTime,
          name,
          findBreak.isPermissionRequired,
        );
      } else {
        const userId = parseInt(localStorage.getItem("userID"));
        softPhoneStore.setBreakInCall(true);
        await requestBreakFunction(userId);
      }
    }
  };

  const setSecondsFunction = (time): void => {
    // breaks.setAllowCallTime(allowCallTime);
    if (
      !(phoneConnected || connected) ||
      selectedBreakId === acwBreakId ||
      selectedBreakId === "default"
    ) {
      setTimer(++time);
      setFormattedTime(formatSecondsToTime(timer));
    } else {
      if (isCallContinuing && isBreakInCall)
        setFormattedTime(formatSecondsToTime(0));
      else {
        setTimer(--time);
        setFormattedTime(formatSecondsToTime(timer));
      }
    }
  };

  useEffect(() => {
    setTimer(0);
    setFormattedTime(formatSecondsToTime(0));

    fetchAllBreakLists();
  }, []);

  useEffect(() => {
    let id, name;
    const isLinesEmpty = Object.values(lines).every((value) => {
      if (value === null) {
        return true;
      }
      return false;
    });

    if (!isCallContinuing && isBreakInCall && isLinesEmpty) {
      if (localStorage.getItem("autoAcw") === "true" || isBreakOnHold) {
        id = acwBreakId;
        name = "ACW";
      } else {
        id = selectedBreakId;
        name = breakName;
      }
      handleBreak(name, id);
      softPhoneStore.setBreakInCall(false);
      softPhoneStore.setBreakOnHold(false);
    }
  }, [
    lines,
    isCallContinuing,
    isBreakInCall,
    isBreakOnHold,
    selectedBreakId,
    breakName,
    acwBreakId,
  ]);

  useEffect(() => {
    setTimer(0);
    setFormattedTime(formatSecondsToTime(0));

    const isSoftphoneConnected = phoneConnected || connected;

    if (selectedBreakId === "default" && isSoftphoneConnected)
      setStatus("Available");
  }, [selectedBreakId, phoneConnected, connected]);

  useEffect(() => {
    setTimer(0);
    setFormattedTime(formatSecondsToTime(0));

    if (!(phoneConnected || connected)) setStatus("Offline");
    else setStatus("Available");
  }, [phoneConnected, connected]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsFunction(timer);
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  return {
    loading,
    isInBreak,
    status,
    timer,
    formattedTime,
    connected,
    phoneConnected,
    handleBreak,
    translate,
  };
};

useBreakSystem.displayName = "useBreakSystem";
