import React from "react";
import { NavLink } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import { availablePages } from "Config/config";
import logo from "Assets/images/logo_white.png";

const Logo = () => {
  return (
    <NavLink to={availablePages.index}>
      <StyledLogo src={logo} alt='Logo' />
    </NavLink>
  );
};

export default Logo;

const StyledLogo = styled("img")({
  width: 130,
  left: 0,
  top: 12,
  position: "absolute",
});
