import React, { useContext } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { styled } from "@material-ui/core";
import { ArrowBackIosIcon } from "../Icons";

import FileTypes from "Pages/FaqGenerator/fileTypes";
import { attachments as files } from "Pages/FaqGenerator/constants";
import AgentImage from "Pages/FaqGenerator/assets/Images/AgentImage.png";

import "Pages/FaqGenerator/styles/editor.css";
import "react-quill/dist/quill.snow.css";

import { downloadAttachment } from "Api/faq";
import { downloadFile, formatDateFour } from "Utils/Utils";

import { defaultStates as defaultFaqStates } from "Store/Reducers/faqReducer";
import { RequestContextStore } from "Components/Layout/AppLayout";

import HtmlEditor from "devextreme-react/html-editor";

const ViewQuestion = (props) => {
  const totalFaqState = useSelector(
    (state: RootStateOrAny) => state.faqReducer,
  );
  const requestContextStore: any = useContext(RequestContextStore);
  const faqState = requestContextStore.getCurrentStateFromTotalState(
    totalFaqState,
    defaultFaqStates,
  );
  const image = AgentImage;

  const openImageInNewTab = async (path, name) => {
    let type = FileTypes[path.split(".")[1].toUpperCase()];
    let response = await downloadAttachment(path);
    let data = await response.blob();
    await downloadFile(data, name, type);
  };

  const getIconType = (
    key: number,
    name: string,
    path: string,
    type: FileTypes,
  ) => {
    let Icon = files[type].icon;
    const AttachmentIcon = styled(Icon)({
      display: "inline-block",
      color: "#81808A",
      fontSize: 15,
      lineHeight: "131%",
      position: "relative",
      top: 2,
      marginRight: 5,
    });

    return (
      <Attachment key={key} onClick={() => openImageInNewTab(path, name)}>
        <AttachmentIcon />
        <AttachmentName>{name}</AttachmentName>
      </Attachment>
    );
  };

  let { question, attachments, answer, modifiedBy, modificationDate } =
    faqState.selectedQuestion;

  return (
    <Container>
      <Main>
        <Header onClick={props.handleGoBack}>
          <GoBack />
          <Question>{question}</Question>
        </Header>

        {attachments.length > 0 && (
          <AttachmentContainer>
            {attachments.map((item, index) => {
              let type = FileTypes[item.path.split(".")[1].toUpperCase()];

              return getIconType(index, item.originalFileName, item.path, type);
            })}
          </AttachmentContainer>
        )}

        <HtmlEditor
          style={{
            margin: "10px auto",
            minHeight: "80%",
            height: "80%",
            maxHeight: "80%",
            width: "100%",
            border: "none",
          }}
          readOnly={true}
          value={answer}
        />
      </Main>

      <QuestionInfo>
        <StyledProfileLogo src={image} alt='profile image' />
        <Author>{modifiedBy}</Author>
        <Date>{formatDateFour(modificationDate)}</Date>
      </QuestionInfo>
    </Container>
  );
};

export default ViewQuestion;

const Container = styled("div")({
  height: "calc(100vh - var(--nav-bar-height) - 50px)",
  width: "100%",
  "&::-webkit-scrollbar": {
    width: 3,
    height: 5,
  },
});

const GoBack = styled(ArrowBackIosIcon)({
  color: "#414147",
  fontSize: 14,
  lineHeight: 17,
  letterSpacing: "0.04em",
  position: "relative",
  top: 3,
  right: 5,
});

const Question = styled("span")({
  fontSize: 12,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  color: "#050038",
});

const Header = styled("div")({
  padding: 16,
  borderBottom: "1px solid #DCDDE6",
  textAlign: "left",
  cursor: "pointer",
});

const AttachmentContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  marginTop: 10,
  width: "100%",
  maxHeight: "15%",
  margin: "0 auto",
  paddingBottom: 5,
  borderBottom: "1px solid #DCDDE6",
});

const Attachment = styled("div")({
  display: "inline-block",
  margin: "5px 10px 5px 0",
  backgroundColor: "#F5F4F7",
  borderRadius: 40,
  padding: 10,
  cursor: "pointer",
});

const AttachmentName = styled("span")({
  fontSize: 13,
  lineHeight: "130%",
  letterSpacing: "0.02em",
  color: "#020110",
});

const Main = styled("div")({
  minHeight: "85%",
  height: "85%",
  maxHeight: "85%",
});

const QuestionInfo = styled("div")({
  display: "block",
  width: "100%",
  margin: "0 auto",
  textAlign: "left",
  position: "relative",
  left: 0,
  bottom: 5,
});

const Author = styled("span")({
  color: "#050038",
  fontSize: 13,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  marginRight: 15,
});

const Date = styled("span")({
  color: "#050038",
  fontSize: 13,
  lineHeight: "140%",
  letterSpacing: "0.02em",
});

const StyledProfileLogo = styled("img")({
  height: 24,
  width: 24,
  borderRadius: "50%",
  position: "relative",
  top: 6,
  marginRight: 5,
  border: "1px solid orange",
  objectFit: "cover",
});
