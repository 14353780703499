import React from "react";
import { styled } from "@material-ui/core";

const Question = (props) => {
  return (
    <Answer onClick={() => props.handleSelectQuestion(props.question)}>
      <QuestionText>{props.question.question}</QuestionText>
    </Answer>
  );
};

export default Question;

const Answer = styled("li")({
  width: "100%",
  listStyle: "none",
  fontSize: 14,
  textAlign: "left",
  padding: 12,
  paddingRight: 0,
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #DCDDE6",
  borderRight: "none",
  backgroundColor: "white",
  borderRadius: 0,
  "&:hover": {
    backgroundColor: "#F5F4F7",
  },
  "&.active": {
    borderRight: "3px solid #00B287",
    backgroundColor: "#EAF3F0",
    borderRadius: "10px 0 0 10px",
  },
});

const QuestionText = styled("span")({
  color: "#050038",
  fontSize: 13,
  fontWeight: 600,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  overflowX: "hidden",
  width: "90%",
  "&.active": {
    color: "#009E65",
  },
});
