import { TextField } from "@material-ui/core";
import FormDateTimePicker from "Components/Form/FormDateTimePicker";
import React, { ReactElement } from "react";
import { formatDateSix } from "Utils/Utils";

import { useJiraStyles } from "./styles";

enum CustomFieldType {
  "string" = "string",
  "datetime" = "datetime",
  "date" = "date",
  "number" = "number",
  "array" = "array",
  "option" = "option",
  "user" = "user",
}

const CustomField = ({ id, type, name, onChange }): ReactElement => {
  const classes = useJiraStyles();
  if (type === CustomFieldType.string)
    return (
      <div className={classes.input}>
        <TextField
          id={id}
          name={name}
          onChange={(event) => {
            onChange(id, event.target.value);
          }}
          label={name}
          fullWidth
          variant='outlined'
        />
      </div>
    );
  if (type === CustomFieldType.number)
    return (
      <div className={classes.input}>
        <TextField
          id={id}
          name={name}
          onChange={(event) => {
            onChange(id, event.target.value);
          }}
          label={name}
          fullWidth
          variant='outlined'
          type='number'
        />
      </div>
    );
  if (type === CustomFieldType.datetime || type === CustomFieldType.date)
    return (
      <FormDateTimePicker
        label={name}
        auto
        ampm={false}
        variant='inline'
        orientation='landscape'
        openTo='minutes'
        handleChange={(value) => {
          onChange(id, formatDateSix(value));
        }}
      />
    );
  // TODO: add FormSelect with multiple prop
  if (type === CustomFieldType.array) return <></>;
  // TODO: add FormSelect with single prop
  if (type === CustomFieldType.option || type === CustomFieldType.option)
    return <></>;
  return null;
};

export default CustomField;
