import { useEffect, useState, useRef } from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import { conversion } from "Utils/Utils";

import { STEP } from "../constants";
import { AudioPlayerHookProps } from "../interfaces";

export const useAudioPlayer = ({ src }): AudioPlayerHookProps => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<string>(conversion(0));
  const [trackProgress, setTrackProgress] = useState<number>(0);
  const [convertedDuration, setConvertedDuration] = useState<string>("");
  const [duration, setDuration] = useState<number>(0);

  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const callData = softPhoneState.lines[softPhoneState.currentLine];

  const disposeAudioPlayer = (): void => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = "";
      audioRef.current.load();
      audioRef.current = null;
    }
  };

  const handleMetadataLoad = (): void => {
    if (audioRef.current) {
      const { duration } = audioRef.current;
      setDuration(Math.floor(duration));
      setConvertedDuration(conversion(duration));
    }
  };

  const startTimer = (): void => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      if (audioRef.current && !audioRef.current.ended) {
        const { currentTime } = audioRef.current;
        setCurrentTime(conversion(currentTime));
        setTrackProgress(currentTime);
      }
    }, STEP);
  };

  const handlePlayButton = (): void => {
    setIsPlaying(true);
    if (audioRef.current && audioRef.current.ended) {
      setCurrentTime(conversion(0));
      setTrackProgress(0);
    }
  };

  const handlePauseButton = (): void => {
    setIsPlaying(false);
  };

  const handleController = (): void => {
    isPlaying ? handlePauseButton() : handlePlayButton();
  };

  const onScrub = (value: string): void => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (audioRef.current) {
      audioRef.current.currentTime = Number(value);
      setTrackProgress(Number(value));
      setIsPlaying(false);
    }
  };

  const onScrubEnd = (): void => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  useEffect(() => {
    audioRef.current = new Audio();
    audioRef.current.addEventListener("loadedmetadata", handleMetadataLoad);

    return () => {
      disposeAudioPlayer();
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = src;
    }
  }, [src]);

  useEffect(() => {
    if (isPlaying && audioRef.current) {
      audioRef.current.play();
    } else if (!isPlaying && audioRef.current) {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (audioRef.current) {
      const { duration } = audioRef.current;
      setDuration(Math.floor(duration));
      setConvertedDuration(conversion(duration));
      setTrackProgress(0);
    }
  }, [audioRef.current]);

  useEffect(() => {
    if (isPlaying && audioRef.current) {
      intervalRef.current = setInterval(() => {
        if (audioRef.current && !audioRef.current.ended) {
          const { currentTime } = audioRef.current;
          setCurrentTime(conversion(currentTime));
          setTrackProgress(currentTime);
        } else {
          setIsPlaying(false);
        }
      }, STEP);
    } else if (!isPlaying && intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, [isPlaying]);

  useEffect(() => {
    callData?.sipCallId !== undefined && setIsPlaying(false);
  }, [callData?.sipCallId]);

  return {
    isPlaying,
    trackProgress,
    duration,
    currentTime,
    convertedDuration,
    handleController,
    onScrub,
    onScrubEnd,
  };
};

useAudioPlayer.displayName = "useAudioPlayer";
