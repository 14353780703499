import {
  PictureAsPdfIcon,
  ImageIcon,
  DescriptionIcon,
  AttachFileIcon,
  ListAltIcon,
} from "../Icons";
import FileTypes from "../fileTypes";

export const attachments: any = {
  [FileTypes.PDF]: {
    icon: PictureAsPdfIcon,
  },
  [FileTypes.JPG]: {
    icon: ImageIcon,
  },
  [FileTypes.JPEG]: {
    icon: ImageIcon,
  },
  [FileTypes.PNG]: {
    icon: ImageIcon,
  },
  [FileTypes.JFIF]: {
    icon: ImageIcon,
  },
  [FileTypes.DOC]: {
    icon: DescriptionIcon,
  },
  [FileTypes.DOCX]: {
    icon: DescriptionIcon,
  },
  [FileTypes.XLS]: {
    icon: ListAltIcon,
  },
  [FileTypes.XLSX]: {
    icon: ListAltIcon,
  },
  [FileTypes.UNDEFINED]: {
    icon: AttachFileIcon,
  },
};
