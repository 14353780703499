export const formatStore = (store) => {
  const formattedStore = [];
  Object.keys(store).map((stateName) => {
    const state = store[stateName];
    if (Object.prototype.toString.call(state) === "[object Array]")
      formattedStore[stateName] = {
        get() {
          return state[0];
        },
        set: state[1],
      };
    else formattedStore[stateName] = state;
    return null;
  });
  return formattedStore;
};
