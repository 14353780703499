import { getAllUsers } from "Api/configuration";
import {
  getAllCategories,
  getCategoryData,
  uploadAttachments,
  createTask,
} from "Api/task";

import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { buttonTypes } from "Components/Buttons/buttonTypes";
import IconButton from "Components/Buttons/IconButton";
import { RequestContextStore } from "Components/Layout/AppLayout";

import { checkFieldValidation } from "Components/Request/RightPanel/Cch/Fields";
import Notification from "Notifications/Notification";

import FileTypes from "Pages/FaqGenerator/fileTypes";
import filesData from "Pages/FaqGenerator/fileTypesData";
import { HighlightOffIcon } from "Pages/FaqGenerator/Icons";

import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import CchActions from "Store/Actions/Cch";
import { defaultStates as defaultCchStates } from "Store/Reducers/cchReducer";
import { formatDateThree } from "Utils/Utils";

import { defaultProps } from "../constants";
import { useCchStyles } from "../styles";

export const useCch = (): any => {
  const { translate } = useTranslation();
  const classes = useCchStyles();

  const totalCchState = useSelector(
    (state: RootStateOrAny) => state.cchReducer,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );

  const cch = new CchActions(useDispatch());

  const requestContextStore: any = useContext(RequestContextStore);
  const cchState = requestContextStore.getCurrentStateFromTotalState(
    totalCchState,
    defaultCchStates,
  );

  const [checkValidation, setCheckValidation] = useState(false);
  const [checkAdvancedValidation, setCheckAdvancedValidation] = useState(false);
  const [categories, setCategories] = useState([]);
  const [ownersList, setOwnersList] = useState([]);
  const [globalTags, setGlobalTags] = useState([]);
  const [advancedGlobalTags, setAdvancedGlobalTags] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDropAccepted: (files) => {
      setAttachments([...attachments, ...files]);
    },
  });
  const defaultData = { ...defaultProps, checkValidation };

  const saveCurrentCch = async (): Promise<any> => {
    setCheckValidation(true);

    if (
      cchState.formValues.categoryId &&
      cchState.formValues.dueDate &&
      cchState.formValues.owners
    ) {
      setIsSaving(true);
      setIsLoading(true);
      const uploadedAttachments = [
        ...attachments.filter((item) => item instanceof File),
      ];
      const previousAttachments = [
        ...attachments.filter((item) => !(item instanceof File)),
      ];

      const responseUploadAttachments = await uploadedAttachmentsCheck(
        uploadedAttachments,
      );

      const task = {
        ...cchState.formValues,
        attachments: [...previousAttachments, ...responseUploadAttachments],
        id: null,
        creationDate: formatDateThree(new Date()),
        created: true,
        owners:
          cchState.formValues.owners.length > 0
            ? cchState.formValues.owners.map(
                (owner: {
                  id: any;
                  email: any;
                  fullName: any;
                  firstName: any;
                  lastName: any;
                }) => ({
                  id: owner.id,
                  email: owner.email,
                  fullName: owner.fullName,
                  firstName: owner.firstName,
                  lastName: owner.lastName,
                }),
              )
            : [],
      };

      return createTask(task)
        .then((response) => {
          setIsLoading(false);
          setIsSaving(false);
          if (response) {
            task.id = response;

            cch.addSavedCch(
              requestState.menuType,
              requestContextStore.getRequestId(),
              task,
            );

            Notification.success(translate("request_task_added"));

            cch.resetFormValues(
              requestState.menuType,
              requestContextStore.getRequestId(),
            );

            setAttachments([]);
            setGlobalTags([]);
            setAdvancedGlobalTags([]);
            inputRef.current.value = "";
          }
        })
        .catch(() => {
          setIsLoading(false);
          setIsSaving(false);
        });
    }
  };

  const setElementValue = (key: string, value: any): any => {
    cch.setFormValues(
      requestState.menuType,
      requestContextStore.getRequestId(),
      {
        ...cchState.formValues,
        [key]: value,
      },
    );
  };

  const fetchCategories = async (): Promise<void> => {
    const categories = await getAllCategories();

    setCategories(categories);
  };

  const fetchAllUsers = async (): Promise<void> => {
    const users = await getAllUsers();
    setOwnersList(users);
  };

  const getTagValueById = (id: number): string => {
    const tag = cchState.formValues.globalTagValues.find(
      (tag: { id: number }) => tag.id === id,
    );
    if (tag) return tag.value;
    return "";
  };

  const getAdvancedValueById = (id: number): string => {
    const tag = cchState.formValues.advancedTagValues.find(
      (tag: { tagId: number }) => tag.tagId === id,
    );
    if (tag) return tag.value;
    return "";
  };

  const setTagValue = async (tagId: number, value: string): Promise<void> => {
    const updatedGlobalTagValues = cchState.formValues.globalTagValues;
    const valueExists = updatedGlobalTagValues.filter(
      (tag: { tagId: number }) => tag.tagId === tagId,
    );

    if (valueExists.length > 0) valueExists[0].value = value;
    else
      updatedGlobalTagValues.push({
        tagId,
        value,
      });

    cch.setFormValues(
      requestState.menuType,
      requestContextStore.getRequestId(),
      {
        ...cchState.formValues,
        globalTagValues: updatedGlobalTagValues,
      },
    );
  };

  const setAdvancedTagValue = async (
    tagId: number,
    value: string,
    mandatory?: boolean,
    regex?: string,
  ): Promise<void> => {
    const id = Number(tagId);
    const updatedGlobalTagValues = cchState.formValues.advancedTagValues;
    const valueExists = updatedGlobalTagValues.filter(
      (tag: { tagId: number }) => tag.tagId === id,
    );

    if (valueExists.length > 0) valueExists[0].value = value;
    else
      updatedGlobalTagValues.push({
        tagId: id,
        value,
        mandatory,
        regex,
      });

    cch.setFormValues(
      requestState.menuType,
      requestContextStore.getRequestId(),
      {
        ...cchState.formValues,
        advancedTagValues: updatedGlobalTagValues,
      },
    );
  };

  const fillFormValuesOnCategoryChange = async (category: {
    id: number;
    name: any;
  }): Promise<any> => {
    if (!category) {
      setGlobalTags([]);
      setAdvancedGlobalTags([]);
      cch.setFormValues(
        requestState.menuType,
        requestContextStore.getRequestId(),
        {
          ...cchState.formValues,
          globalTagValues: [],
          advancedTagValues: [],
          categoryId: null,
          categoryName: null,
        },
      );
      return;
    }
    const data = await getCategoryData(category.id);

    if (data) {
      cch.setFormValues(
        requestState.menuType,
        requestContextStore.getRequestId(),
        {
          ...cchState.formValues,
          owners: data.owners.map(
            (owner: { firstName: string; lastName: string }) => ({
              ...owner,
              name: owner.firstName + " " + owner.lastName,
            }),
          ),
          dueDate: data.dueDate,
          categoryId: category.id,
          categoryName: category.name,
          advancedTagValues: [],
        },
      );
      setGlobalTags(data.globalTags);

      const advancedTags = data.advancedGlobalTags.filter(
        (tag) => tag.globalTagCreationType === "ON_CREATE",
      );
      setAdvancedGlobalTags(advancedTags);
    }
  };

  const getCategoryOptionById = (): any =>
    categories?.find(
      (category) => category.id === cchState.formValues.categoryId,
    );

  const handleDeleteAttachment = (order: number): void => {
    let tempAttachments = attachments;
    tempAttachments = attachments.filter((_item, index) => index !== order);
    setAttachments(tempAttachments);
  };

  const getIconType = (
    name: string,
    index: number,
    buttonType: buttonTypes,
    icon: any,
  ): React.ReactNode => {
    return (
      <React.Fragment key={index}>
        <IconButton
          className={classes.button}
          buttonType={buttonType}
          tooltipText={name}
          Icon={() => <img src={icon} alt={name} className={classes.img} />}
        />
        <HighlightOffIcon
          className={classes.deleteAttachment}
          onClick={() => handleDeleteAttachment(index)}
        />
      </React.Fragment>
    );
  };

  const convertFileToIcon = (item: string, index: number): React.ReactNode => {
    const fileType = FileTypes[item.split(".")[1].toUpperCase()];
    const name = item;
    return getIconType(
      name,
      index,
      filesData[fileType].buttonType,
      filesData[fileType].icon,
    );
  };

  const uploadedAttachmentsCheck = async (
    uploadedAttachments: any[],
  ): Promise<any> => {
    let responseUploadAttachments = [];
    if (uploadedAttachments.length > 0) {
      const formdata = new FormData();
      attachments.forEach((item) => {
        if (item instanceof File) {
          formdata.append("files", item, item.name);
          formdata.append("directory", "Tasks");
        }
      });
      responseUploadAttachments = await uploadAttachments(formdata);
    }
    return responseUploadAttachments;
  };

  useEffect(() => {
    (async function anyNameFunction() {
      await fetchCategories();
      await fetchAllUsers();
    })();
  }, []);

  useEffect(() => {
    const activeFormValues = cchState.allSavedCchs.find(
      (data: { id: any }) => data.id === cchState.activeSavedCch,
    );
    if (activeFormValues)
      cch.setFormValues(
        requestState.menuType,
        requestContextStore.getRequestId(),
        {
          ...cchState.formValues,
          id: activeFormValues.id,
          category: activeFormValues.category,
          globalTagValues: activeFormValues.globalTagValues,
          advancedTagValues: activeFormValues.advancedTagValues,
          dueDate: activeFormValues.dueDate,
          owners: activeFormValues.owners,
          content: activeFormValues.content,
          callbackNumber: activeFormValues.callbackNumber,
        },
      );
  }, [cchState.activeSavedCch]);

  useEffect(() => {
    cch.resetFormValues(
      requestState.menuType,
      requestContextStore.getRequestId(),
    );
  }, [
    softPhoneState.lines[softPhoneState.currentLine],
    requestContextStore.getRequestId(),
  ]);

  useEffect(() => {
    advancedGlobalTags.length > 0 &&
      advancedGlobalTags.map((item) =>
        setAdvancedTagValue(item.id, "", item.mandatory, item.regex?.value),
      );
  }, [advancedGlobalTags]);

  useEffect(() => {
    const hasError = checkFieldValidation(
      cchState.formValues.advancedTagValues,
    );

    setCheckAdvancedValidation(hasError);
  }, [cchState.formValues]);

  return {
    classes,
    categories,
    globalTags,
    advancedGlobalTags,
    defaultData,
    checkValidation,
    checkAdvancedValidation,
    cchState,
    ownersList,
    attachments,
    isLoading,
    isSaving,
    convertFileToIcon,
    getRootProps,
    getInputProps,
    saveCurrentCch,
    translate,
    fillFormValuesOnCategoryChange,
    getCategoryOptionById,
    setTagValue,
    setElementValue,
    getTagValueById,
    getAdvancedValueById,
    setAdvancedTagValue,
  };
};

useCch.displayName = "useCch";
