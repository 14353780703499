import { callbackActions as actionTypes } from "../Actions/actionTypes";

const initialState = {
  callback: {},
  requestId: "",
};

const callbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CALLBACK:
      return { ...state, callback: action.callback };
    case actionTypes.SET_REQUEST_ID:
      return { ...state, requestId: action.requestId };
    default:
      return state;
  }
};

export default callbackReducer;
