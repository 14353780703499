import React, { useRef, useState } from "react";

/**
 * Hoc that trigger components onChange function only when specified timeout changes
 */
export default function withFieldOnchangeTImeout(Component, timeout = 500) {
  return function WithTimeOut(props) {
    const timeoutRef = useRef(null);
    const [value, setValue] = useState(props.value);

    const setFieldValue = (event) => {
      event.persist();
      setValue(event.target.value);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        props.onChange(event);
      }, timeout);
    };

    return <Component {...props} value={value} onChange={setFieldValue} />;
  };
}
