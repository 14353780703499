import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import messageTypes from "Assets/constants/messageTypes";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import clsx from "clsx";
import React from "react";
import { useSelector, RootStateOrAny } from "react-redux";

import { truncateToNCharacters, formatDatetoHoursAndSecs } from "Utils/Utils";

import {
  EditIcon,
  MicNoneIcon,
  ImageIcon,
  InsertDriveFileIcon,
  OndemandVideoIcon,
  BrokenImageIcon,
  AccessTimeIcon,
  DoneIcon,
  DoneAllIcon,
  ErrorOutlineIcon,
  ForwardOutlinedIcon,
} from "./Icons";

interface IChatBox {
  conversationId: number | string;
  color: string;
  icon: any;
  platform: string;
  lastMessageInfo: any;
  isEditMode: boolean;
  isActive: boolean;
  isOpen: boolean;
  isForwarded: boolean;
  badgeContent: number | null;
  handleSelectMenu: () => {};
}

export const ChatBox = ({
  isActive,
  handleSelectMenu,
  badgeContent,
  icon,
  isEditMode,
  isOpen,
  color,
  conversationId,
  platform,
  lastMessageInfo,
  isForwarded,
}: IChatBox) => {
  const { translate } = useTranslation();
  const totalCrmState = useSelector(
    (state: RootStateOrAny) => state.crmReducer,
  );
  const chatBoxState = useSelector(
    (state: RootStateOrAny) => state.chatReducer,
  );
  const { isCallContinuing } = useSelector(
    ({ softPhoneReducer }: RootStateOrAny) => ({
      isCallContinuing: softPhoneReducer.isCallContinuing,
    }),
  );

  const activeConversationChannelId = chatBoxState.conversations[conversationId]
    ? chatBoxState.conversations[conversationId].channelId
    : null;

  const initialClientName =
    typeof totalCrmState?.CHAT[conversationId]?.formFields[0]?.fieldValue !==
      "undefined" &&
    typeof totalCrmState?.CHAT[conversationId]?.formFields[1]?.fieldValue !==
      "undefined"
      ? `${totalCrmState.CHAT[conversationId].formFields[0].fieldValue} ${totalCrmState.CHAT[conversationId].formFields[1].fieldValue}`
      : (platform === "FB" || platform === "WHATSAPP") &&
        chatBoxState.conversations[conversationId].fullName
      ? chatBoxState.conversations[conversationId].fullName
      : activeConversationChannelId;

  const MessageIcon = styled(icon)({
    color: isActive ? "white" : color,
    marginLeft: isOpen ? 12 : 0,
    marginTop: isOpen ? 5 : 0,
    fontSize: 25,
    background:
      platform === "INSTAGRAM" && !isActive
        ? "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"
        : "",
  });

  const UserName = styled("span")({
    color: isActive ? "white" : "#050038",
    fontSize: 12,
    display: "block",
  });

  const Content = styled("span")({
    color: isActive ? "white" : badgeContent ? "#050038" : "#9F9DAD",
    fontWeight: badgeContent ? 600 : 400,
  });

  const Date = styled("span")({
    color: isActive ? "white" : "#9F9DAD",
  });

  const Divider = styled("span")({
    margin: "0 6px",
    color: isActive ? "white" : "#9F9DAD",
  });

  const chooseStatusIcon = () => {
    if (lastMessageInfo?.owner !== "client") return <></>;
    switch (lastMessageInfo?.status) {
      case null:
        return <AccessTimeIcon style={{ fontSize: 12, marginRight: 5 }} />;
      case "UNDEFINED":
        return <DoneIcon style={{ fontSize: 12, marginRight: 5 }} />;
      case "DELIVERY":
        return <DoneAllIcon style={{ fontSize: 12, marginRight: 5 }} />;
      case "READ":
        return <DoneAllIcon style={{ fontSize: 12, marginRight: 5 }} />;
      case "EXPIRED":
        return <ErrorOutlineIcon style={{ fontSize: 12, marginRight: 5 }} />;
      default:
        return <></>;
    }
  };

  const chooseContentType = () => {
    const type = lastMessageInfo?.type;

    switch (type) {
      case messageTypes.TEXT:
        return truncateToNCharacters(lastMessageInfo?.content, 15);

      case messageTypes.AUDIO:
        return (
          <>
            <MicNoneIcon style={{ fontSize: 13 }} />{" "}
            {translate("left_panel_audio")}
          </>
        );

      case messageTypes.VIDEO:
        return (
          <>
            <OndemandVideoIcon style={{ fontSize: 13 }} />{" "}
            {translate("left_panel_video")}
          </>
        );

      case messageTypes.IMAGE:
        return (
          <>
            <ImageIcon style={{ fontSize: 13 }} />{" "}
            {translate("left_panel_picture")}
          </>
        );

      case messageTypes.APPLICATION:
        return (
          <>
            <InsertDriveFileIcon style={{ fontSize: 13 }} />{" "}
            {translate("left_panel_document")}
          </>
        );

      default:
        return (
          <>
            <BrokenImageIcon style={{ fontSize: 13 }} />{" "}
            {translate("left_panel_unknown")}
          </>
        );
    }
  };

  const forwardedBadge = !isEditMode && isForwarded && (
    <ForwardedBadge>
      <ForwardedIcon />
    </ForwardedBadge>
  );

  const countBadge = badgeContent && (
    <CountBadge
      style={{
        right: badgeContent ? 25 : 12,
      }}>
      {badgeContent}
    </CountBadge>
  );

  const editBadge = isEditMode && (
    <EditBadge>
      <ChatEditIcon />
    </EditBadge>
  );

  const username =
    initialClientName && initialClientName.length > 1
      ? initialClientName
      : activeConversationChannelId;

  return (
    <MenuItem
      className={clsx({
        ["active"]: isActive,
        ["open"]: isOpen,
      })}
      onClick={handleSelectMenu}
      aria-disabled={true}>
      {!isOpen ? (
        <IconWrapper
          className={clsx({
            ["addMargin"]: badgeContent || isEditMode || isForwarded,
          })}>
          <MessageIcon />
          {editBadge}
          {forwardedBadge}
          {countBadge}
        </IconWrapper>
      ) : (
        <Grid container>
          <Grid item xs={3}>
            <IconWrapper
              className={clsx({
                ["addMargin"]: badgeContent || isEditMode || isForwarded,
              })}>
              <MessageIcon />
              {editBadge}
              {forwardedBadge}
              {countBadge}
            </IconWrapper>
          </Grid>
          <Grid item xs={9}>
            <UserName>{username}</UserName>
            <UserText>
              <Content>
                {chooseStatusIcon()}
                {chooseContentType()}
              </Content>
              <Divider>/</Divider>
              <Date>
                {formatDatetoHoursAndSecs(lastMessageInfo?.createdTime)}
              </Date>
            </UserText>
          </Grid>
        </Grid>
      )}
    </MenuItem>
  );
};

const UserText = styled("div")({ fontSize: 12 });

const ForwardedBadge = styled("span")({
  lineHeight: "80%",
  backgroundColor: "#050038",
  border: "2px solid #FFFFFF",
  position: "relative",
  bottom: 15,
  right: 12,
  borderRadius: 64,
  width: 5,
  height: 5,
  padding: 4,
  color: "white",
  display: "inline-block",
});

const CountBadge = styled("span")({
  lineHeight: "80%",
  backgroundColor: "#050038",
  position: "relative",
  bottom: 0,
  borderRadius: 64,
  minWidth: 6,
  height: 6,
  padding: 4,
  color: "white",
  display: "inline-block",
  fontSize: 10,
});

const EditBadge = styled("span")({
  lineHeight: "80%",
  backgroundColor: "#050038",
  border: "2px solid #FFFFFF",
  position: "relative",
  bottom: -4,
  right: 12,
  borderRadius: 64,
  width: 5,
  height: 5,
  padding: 4,
  color: "white",
  display: "inline-block",
});

const ForwardedIcon = styled(ForwardOutlinedIcon)({
  color: "white",
  position: "relative",
  top: -5,
  left: -2,
  fontSize: 10,
});

const ChatEditIcon = styled(EditIcon)({
  color: "white",
  position: "relative",
  top: -5,
  left: -2,
  fontSize: 10,
});

const MenuItem = styled("div")({
  width: "100%",
  paddingTop: 8,
  margin: "5px 0",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "white",
  },
  "&.active": {
    backgroundColor: "#00B287",
    borderRadius: "8px 2px 8px 8px",
  },
  "&.open": {
    textAlign: "left",
    paddingBottom: 5,
  },
  "&.editMode": {
    backgroundColor: "#00B287",
    borderRadius: "8px 2px 8px 8px",
  },
});

const IconWrapper = styled("div")({
  marginRight: 0,
  "&.addMargin": {
    marginRight: -10,
  },
});
