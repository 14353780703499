import React from "react";
import { createStyles, makeStyles, styled } from "@material-ui/core/styles";
import {
  AccessTimeIcon,
  DoneIcon,
  DoneAllIcon,
  ErrorOutlineIcon,
} from "./icons";
import IconButton from "Components/Buttons/IconButton";
import filesData from "Pages/FaqGenerator/fileTypesData";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      fontSize: 12,
      color: "rgba(255, 255, 255, 0.6)",
    },
    img: {
      width: "18px",
    },
  }),
);

const AttachmentTag = (props) => {
  const { translate } = useTranslation();
  const classes = useStyles();

  const MessageContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: 10,
    padding: 5,
    maxWidth: 350,
    float: props.owner === "client" ? "left" : "right",
    borderRadius:
      props.owner === "client" ? "12px 12px 12px 2px" : "12px 12px 2px 12px",
    color: props.owner === "client" ? "rgba(5, 0, 56, 1)" : "white",
    background: props.owner === "client" ? "white" : "#00B287",
  });

  return (
    <MessageContainer className={props.owner === "client" ? "client" : "agent"}>
      <AttachmentWrapper onClick={props.loadFile}>
        <IconButton
          buttonType={filesData[props.type].buttonType}
          tooltipText={translate("chat_box_download")}
          Icon={() => (
            <img
              src={filesData[props.type].icon}
              alt={props.type}
              className={classes.img}
            />
          )}
        />
        <AttachmentText>{props.caption}</AttachmentText>
      </AttachmentWrapper>
      <MessageFooter>
        <Owner>{props.fullName}</Owner>
        <Date className={props.owner === "client" ? "client" : "agent"}>
          {props.date}
        </Date>
        <span>
          {props.owner !== "client" && <Icon status={props.status} />}
        </span>
      </MessageFooter>
    </MessageContainer>
  );
};

export default AttachmentTag;

const AttachmentWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
});

const AttachmentText = styled("span")({
  padding: 5,
  fontSize: 12,
  fontWeight: 100,
  wordBreak: "break-word",
});

const Icon = ({ status }) => {
  let { icon } = useStyles();
  switch (status) {
    case null:
      return <AccessTimeIcon className={icon} />;
    case "UNDEFINED":
      return <DoneIcon className={icon} />;
    case "DELIVERY":
      return <DoneAllIcon className={icon} />;
    case "READ":
      return <DoneAllIcon className={icon} />;
    case "EXPIRED":
      return <ErrorOutlineIcon className={icon} />;
    default:
      return <></>;
  }
};

const Date = styled("span")({
  fontSize: 12,
  lineHeight: "16.8px",
  marginRight: 6,
  "&.client": {
    color: "rgba(5, 0, 56, .6)",
  },
  "&.agent": {
    color: "rgba(255, 255, 255, 0.6)",
  },
});

const Owner = styled("span")({
  fontSize: "12px",
  marginRight: "8px",
});

const MessageFooter = styled("div")({
  float: "right",
  display: "inline-block",
  lineHeight: "140%",
});
