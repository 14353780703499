import { createStyles, makeStyles, styled } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

export const Container = styled("div")({
  marginTop: 10,
  padding: 10,
  width: "50%",
  height: "90%",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 3,
    height: 6,
  },
});

export const Header = styled("div")({
  color: "rgba(5, 0, 56, 1)",
  fontSize: 20,
  fontWeight: 600,
  letterSpacing: "0.04em",
  textAlign: "left",
  marginBottom: 15,
});

export const PostButton = styled("div")({
  cursor: "pointer",
  backgroundColor: "rgb(0, 178, 150)",
  color: "#fff",
  borderRadius: 8,
  textAlign: "center",
  fontSize: 13,
  fontWeight: 600,
  lineHeight: "25px",
  padding: "5px 2px",
  width: "99%",
  height: 25,
  "&.disabled": {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
});

export const useJiraStyles = makeStyles(() =>
  createStyles({
    input: {
      marginBottom: 10,
    },
    customFieldContainer: {},
    attachment: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: 10,
    },
    dropzone: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: 70,
      borderWidth: 1,
      borderRadius: 5,
      borderColor: "#050038",
      borderStyle: "dashed",
      color: "rgba(2, 1, 16, 0.4)",
      outline: "none",
      transition: "border .24s ease-in-out",
      cursor: "pointer",
      fontSize: 11,
      marginBottom: 10,
    },
    button: { margin: 5, marginTop: 0 },
    img: { width: 18 },
    deleteAttachment: {
      color: red[500],
      cursor: "pointer",
      marginLeft: -12,
    },
  }),
);
