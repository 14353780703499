import { Grid, styled } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import Skeleton from "@material-ui/lab/Skeleton";
import { loadSource } from "Api/chat";
import { downloadAttachment } from "Api/faq";
import messageTypes from "Assets/constants/messageTypes";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import Notification from "Notifications/Notification";
import FileTypes from "Pages/FaqGenerator/fileTypes";
import React, { useEffect, useState } from "react";
import { downloadFile, formatDateDayTwo, isToday } from "Utils/Utils";

import AttachmentTag from "./AttachmentTag";
import AudioPlayer from "./AudioPlayer";
import {
  AccessTimeIcon,
  DoneIcon,
  DoneAllIcon,
  ErrorOutlineIcon,
} from "./icons";
import Image from "./Image";
import AgentImage from "./images/AgentImage.png";
import { IMessage } from "./interfaces";
import MessageText from "./MessageText";
import { useMessageStyles } from "./styles";

/**
 * Conversation message. Opens in new tab when user clicks it
 */
const Message = ({ ...props }: IMessage): React.ReactElement => {
  const { translate } = useTranslation();
  const image = props.image ? props.image : AgentImage;
  const [url, setUrl] = useState("");
  let Content = null;
  const classes = useMessageStyles();
  const type = FileTypes[props.extension?.toUpperCase()];

  const MessageHeader = styled("div")({
    fontSize: 11,
    textAlign: props.owner === "client" ? "left" : "right",
    fontWeight: 600,
    color: grey[600],
  });

  let icon = null;
  switch (props.status) {
    case null:
      icon = (
        <AccessTimeIcon className={`${classes.nullStatus} ${classes.icon}`} />
      );
      break;
    case "UNDEFINED":
      icon = <DoneIcon className={`${classes.undefined} ${classes.icon}`} />;
      break;
    case "DELIVERY":
      icon = <DoneAllIcon className={`${classes.undefined} ${classes.icon}`} />;
      break;
    case "READ":
      icon = (
        <DoneAllIcon className={`${classes.readStatus} ${classes.icon}`} />
      );
      break;
    case "EXPIRED":
      icon = (
        <ErrorOutlineIcon className={`${classes.nullStatus} ${classes.icon}`} />
      );
      break;
    default:
      break;
  }

  useEffect(() => {
    (async function anyNameFunction() {
      if (
        props.messageType === messageTypes.AUDIO ||
        props.messageType === messageTypes.VIDEO ||
        (props.messageType === messageTypes.STORY_MENTION &&
          props.extension === "mp4")
      ) {
        const fetchedUrl = await loadSource(props.fileName, props.extension);
        setUrl(fetchedUrl);
      }
    })();
  }, []);

  const loadFile = async (): Promise<void> => {
    const extension = "." + props.extension;
    return downloadAttachment(props.fileName + extension)
      .then(async (res) => {
        if (res) {
          const data = await res.blob();
          downloadFile(data, "file" + extension, props.extension);
        } else throw new Error();
      })
      .catch(() => {
        Notification.error(translate("chat_box_image_download_error"));
      });
  };

  if (
    props.messageType === messageTypes.TEXT ||
    props.messageType === messageTypes.TEMPLATE ||
    props.messageType === messageTypes.SURVEY
  ) {
    Content = (
      <MessageText
        owner={props.owner}
        text={props.text}
        fullName={props.fullName}
        header={props.header}
        status={props.status}
        date={props.date}
        messageId={props.messageId}
        replyMessageContent={props.replyMessageContent}
        replyServiceMessageId={props.replyServiceMessageId}
        replyMessageAuthor={props.replyMessageAuthor}
      />
    );
  } else if (props.messageType === messageTypes.AUDIO) {
    Content = (
      <span style={{ position: "relative" }}>
        {url && (
          <AudioPlayer
            src={url}
            owner={props.owner}
            isSent={props.isSent}
            header={props.header}
            fullName={props.fullName}
            status={props.status}
            date={props.date}
          />
        )}
      </span>
    );
  } else if (
    props.messageType === messageTypes.VIDEO ||
    (props.messageType === messageTypes.STORY_MENTION &&
      props.extension === "mp4")
  ) {
    Content = (
      <span style={{ position: "relative" }}>
        {url ? (
          <VideoTag
            controls
            preload='none'
            controlsList='nodownload noremoteplayback'
            disablePictureInPicture={true}
            src={url}
            style={{
              paddingBottom: props.owner !== "client" ? 10 : null,
            }}
          />
        ) : (
          <Skeleton
            variant='rect'
            width={200}
            height={120}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
        )}

        {props.owner !== "client" && (
          <span className={classes.iconContainer}>{icon}</span>
        )}
      </span>
    );
  } else if (props.messageType === messageTypes.APPLICATION) {
    Content = (
      <AttachmentTag
        type={type}
        loadFile={loadFile}
        owner={props.owner}
        fullName={props.fullName}
        date={props.date}
        status={props.status}
        caption={props.caption}
      />
    );
  } else {
    Content = (
      <>
        <Image
          messageId={props.messageId}
          conversationId={props.conversationId}
          base64={props.base64}
          isSent={props.isSent}
          fileName={props.fileName}
          extension={props.extension}
        />
        {props.caption && (
          <MessageText
            owner={props.owner}
            text={props.caption}
            fullName={props.fullName}
            header={props.header}
            status={props.status}
            date={props.date}
            messageId={props.messageId}
            replyMessageContent={props.replyMessageContent}
            replyServiceMessageId={props.replyServiceMessageId}
            replyMessageAuthor={props.replyMessageAuthor}
          />
        )}
      </>
    );
  }

  const clientMessage = (
    <Grid container style={{ marginLeft: "10px" }}>
      <Grid container direction='column-reverse' item xs={1}>
        {props.shouldRenderImage && (
          <StyledProfileLogo src={image} alt='profile image' />
        )}
      </Grid>
      <Grid item xs={9}>
        {Content && Content}
        {(props.messageType === messageTypes.VIDEO ||
          props.messageType === messageTypes.IMAGE) && (
          <Date>{props.date}</Date>
        )}
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );

  const agentMessage = (
    <Grid container>
      <Grid item xs={2}></Grid>
      <Grid item xs={9}>
        {props.messageType === messageTypes.VIDEO && (
          <MessageHeader>{props.header}</MessageHeader>
        )}
        {Content && Content}
      </Grid>
      <Grid container direction='column-reverse' item xs={1}>
        {props.shouldRenderImage && (
          <StyledProfileLogo src={image} alt='profile image' />
        )}
      </Grid>
    </Grid>
  );

  let message = null;
  if (props.owner === "client") message = clientMessage;
  else message = agentMessage;

  return (
    <Grid container>
      {props.shouldRenderDate && (
        <Date>
          {isToday(props.originalDate)
            ? translate("chat_box_today")
            : formatDateDayTwo(props.originalDate)}
        </Date>
      )}
      {message}
    </Grid>
  );
};

export default Message;

const Date = styled("span")({
  width: "100%",
  textAlign: "center",
  color: "#81808A",
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: 13,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  margin: "8px 0",
});

const VideoTag = styled("video")({
  left: "-10px",
  width: 220,
  height: 120,
  outline: "none",
  marginTop: 20,
  position: "relative",
});

const StyledProfileLogo = styled("img")({
  height: "24px",
  width: "24px",
  borderRadius: "50%",
  position: "relative",
  border: "1px solid white",
  objectFit: "cover",
});
