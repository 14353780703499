import { Grid } from "@material-ui/core";
import React from "react";

import BreakMenu from "./BreakMenu";
import { colors } from "./constants";
import { useBreakSystem } from "./hooks";
import { Timer, Container, Loading, Status } from "./styles/BreakSystem";

const BreakSystem = (): React.ReactElement => {
  const {
    loading,
    isInBreak,
    status,
    timer,
    formattedTime,
    connected,
    phoneConnected,
    handleBreak,
    translate,
  } = useBreakSystem();

  return (
    <Container>
      <Grid container spacing={0}>
        <Grid item xs={6} style={{ textAlign: "left" }}>
          {loading ? (
            <Loading>
              {!isInBreak
                ? translate("break_system_taking_break")
                : translate("break_system_closing_break")}
            </Loading>
          ) : (
            <Status
              style={{
                color:
                  status === "Available" ? colors.connected : colors.pending,
              }}>
              {status}
            </Status>
          )}
        </Grid>
        <Grid item xs={6}>
          {!loading && (
            <Timer
              style={{
                color:
                  timer < 0
                    ? colors.disconnected
                    : status === "Available"
                    ? colors.connected
                    : colors.pending,
              }}>
              {timer < 0 && "-"} {formattedTime}
            </Timer>
          )}
          {(connected || phoneConnected) && !loading && (
            <BreakMenu handleBreak={handleBreak} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default BreakSystem;

BreakSystem.displayName = "BreakSystem";
