/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import { fetchCrmUsers } from "Api/crm";

import Drawer from "Components/Drawer/Drawer";
import { RequestContextStore } from "Components/Layout/AppLayout";
import LoadingComponent from "Components/Loading/LoadingComponent";
import { getElementFormattedValues } from "Components/Request/LeftPanel/CrmForm";
import { customerTypes } from "Components/Request/LeftPanel/constants/customerTypes";

import { default as CrmActions } from "Store/Actions/Crm";
import { defaultStates as defaultCrmStates } from "Store/Reducers/crmReducer";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
    },
    row: {
      cursor: "pointer",
    },
    table: {
      minWidth: 750,
    },
  }),
);

/**
 * Compenent is a drawer witch renders table with data depends on total crm state
 */
export default function CustomerSearchPanel() {
  const { translate } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = useState({});
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [columns, setColumns] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const totalCrmState = useSelector(
    (state: RootStateOrAny) => state.crmReducer,
  );
  const crm = new CrmActions(useDispatch());
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const requestContextStore: any = useContext(RequestContextStore);
  const crmState = requestContextStore.getCurrentStateFromTotalState(
    totalCrmState,
    defaultCrmStates,
  );

  const handleClose = () => {
    crm.showCustomerSearch(false);
  };

  useEffect(() => {
    (async function anyNameFunction() {
      if (!totalCrmState.defaultFields) return;
      const updatedColumns = totalCrmState.defaultFields
        .filter((field) => field.customerType === customerTypes.PHYSICAL)
        .map((field) => field.fieldName);
      setColumns(updatedColumns);
    })();
  }, [totalCrmState.defaultFields]);

  const fetchRows = async () => {
    if (!crmState.formFields) return;

    const fieldSet = {};

    crmState.formFields.forEach((field) => {
      const formattedValues = getElementFormattedValues(field);

      if (field.fieldValue) {
        fieldSet[field.fieldName] = formattedValues.map(
          (valueObject) => valueObject.value,
        );
      }
    });

    setIsLoading(true);

    const result = await fetchCrmUsers({
      fieldSet: fieldSet,
      fullTextSearch: "",
      fieldName: "creationDate",
      order: "desc",
      customerType: customerTypes.PHYSICAL,
      templateField: false,
      page: crmState.pageNumber ? crmState.pageNumber + 1 : 1,
      recordSize: crmState.rowsPerPage ?? 0,
    });

    if (Object.keys(result).length > 0) {
      setTotalRowCount(result[0]);
      const updatedRows = {};
      result[1].forEach((row) => {
        let formattedRow = {};
        row.fields.forEach((field) => {
          formattedRow[field.fieldName] =
            field.values.length > 0
              ? field.values
                  .reduce(
                    (accumulator, value) => accumulator + "," + value.value,
                    "",
                  )
                  .substr(1)
              : "";
        });
        updatedRows[row.id] = formattedRow;
      });

      setRows(updatedRows);
    }
    setIsLoading(false);
  };

  const handleRowClick = (rowId) => {
    crm.setValuesToFormFieldsByCrmTableData(
      requestState.menuType,
      requestContextStore.getRequestId(),
      rows[rowId],
    );
    crm.showCustomerSearch(false);
  };

  useEffect(() => {
    if (totalCrmState.customerSearchPanelIsShown) {
      fetchRows();
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [totalCrmState.customerSearchPanelIsShown]);

  return (
    <div>
      <Drawer open={open} handleClose={handleClose}>
        <DialogTitle id='customized-dialog-title'>
          {translate("crm_card_customer_search")}
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              size={"medium"}
              aria-label='enhanced table'>
              <TableHead>
                <TableRow>
                  {columns
                    ? columns.map((column) => {
                        return (
                          <TableCell
                            style={{
                              textIndent: "15px",
                              borderRight: "1px solid " + grey[200],
                              fontWeight: 700,
                              textTransform: "uppercase",
                            }}
                            key={column}
                            align={"center"}>
                            {column}
                          </TableCell>
                        );
                      })
                    : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(rows).length > 0 ? (
                  Object.keys(rows).map((rowId) => {
                    const row = rows[rowId];
                    const labelId = `enhanced-table-checkbox-${rowId}`;
                    return (
                      <TableRow
                        className={classes.row}
                        hover
                        onClick={() => handleRowClick(rowId)}
                        role='checkbox'
                        tabIndex={-1}
                        key={labelId}>
                        {columns.map((column, index) => (
                          <TableCell align='center' key={index}>
                            {row[column]}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })
                ) : (
                  <h1 style={{ textAlign: "center" }}>
                    {translate("crm_card_no_data_found")}
                  </h1>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component='div'
            count={totalRowCount}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{translate("close")}</Button>
        </DialogActions>
        {isLoading ? <LoadingComponent /> : null}
      </Drawer>
    </div>
  );
}
