import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import LoadingComponent from "Components/Loading/LoadingComponent";

import React from "react";

import { useIbarIntegrationTable } from "./hooks";
import { NoDataComponent } from "./styles/IbarIntegrationTable";

const IbarIntegrationTable = (): React.ReactElement => {
  const { translate } = useTranslation();

  const { columns, data, loading } = useIbarIntegrationTable();

  const Row = ({ row }: any): JSX.Element => {
    return (
      <TableRow>
        {columns.map((column, idx) => (
          <TableCell key={idx}>{row[column.name]}</TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <>
      {columns ? (
        <TableContainer component={Paper}>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                {columns &&
                  columns.map((column, idx) => (
                    <TableCell key={idx}>{column.alias}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) ? (
                data &&
                data.length > 0 &&
                data.map((item, index) => <Row row={item} key={index} />)
              ) : (
                <Row row={data} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoDataComponent>
          <h1>{translate("no_data")}</h1>
        </NoDataComponent>
      )}

      {loading && <LoadingComponent />}
    </>
  );
};

export default IbarIntegrationTable;

IbarIntegrationTable.displayName = "IbarIntegrationTable";
