import React from "react";
import { styled } from "@material-ui/core";

interface IPhoneNumber {
  number: string;
}

const PhoneNumber = (props: IPhoneNumber) => <Number>{props.number}</Number>;

export default PhoneNumber;

const Number = styled("div")({
  fontSize: 18,
  color: "#050038",
  letterSpacing: "0.02em",
  lineHeight: "160%",
  textAlign: "center",
  marginBottom: 20,
});
