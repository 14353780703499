import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

interface IOption {
  id?: string;
  inputValue?: string;
  value: string;
  name: string;
}

interface IFormMultiSelect {
  id?: string;
  options: IOption[];
  value: string | any[];
  handleChange?: Function;
  size?: "small" | "medium";
  label: string;
  placeHolder: string;
  checkValidation: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: "100%",
      "& .MuiAutocomplete-inputRoot": {
        padding: "2px",
      },
      "& .MuiChip-root": {
        backgroundColor: "#fff",
        height: "20px",
      },
    },
  }),
);

const FormMultiSelect = (props: IFormMultiSelect) => {
  const classes = useStyles();

  const handleChange = (_event, values) => {
    props.handleChange(values, props.id, props.placeHolder);
  };

  const formatValue = () => {
    const formatValues = props.options.filter((option) => {
      return (
        typeof props.value !== "string" &&
        props.value.some((item) => {
          return item === option.name;
        })
      );
    });
    return formatValues;
  };

  return (
    <FormControl variant='filled' className={classes.formControl}>
      <Autocomplete
        multiple
        limitTags={1}
        id='multiple-limit-tags'
        options={props.options}
        getOptionLabel={(option) => option.name}
        defaultValue={typeof props.value == "string" ? [] : formatValue()}
        onChange={handleChange}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            label={props.label}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </FormControl>
  );
};

export default FormMultiSelect;
