import axios from "Api/axios";
import {
  IAccounts,
  ICards,
  ILoans,
  IPersonalInfo,
} from "Components/Crm/IbarIntegrationSearch/interfaces";
import WebConf, { IbarServerUrl } from "Config/WebConf";

// Ibar Customer Search Intergation

//GET
export const getAccounts = async (
  filterType: string,
  value: string,
): Promise<Array<IAccounts>> =>
  axios
    .get(
      `${IbarServerUrl}${WebConf.ibar.getAccounts}?filterType=${filterType}&value=${value}`,
    )
    .then((response) =>
      response && response.status === 200 ? response.data : [],
    );

export const getCards = async (
  filterType: string,
  value: string,
): Promise<Array<ICards>> =>
  axios
    .get(
      `${IbarServerUrl}${WebConf.ibar.getCards}?filterType=${filterType}&value=${value}`,
    )
    .then((response) =>
      response && response.status === 200 ? response.data : [],
    );

export const getLoans = async (
  filterType: string,
  value: string,
): Promise<Array<ILoans>> =>
  axios
    .get(
      `${IbarServerUrl}${WebConf.ibar.getLoans}?filterType=${filterType}&value=${value}`,
    )
    .then((response) =>
      response && response.status === 200 ? response.data : [],
    );

export const getPersonalInfo = async (
  filterType: string,
  value: string,
): Promise<Array<IPersonalInfo>> =>
  axios
    .get(
      `${IbarServerUrl}${WebConf.ibar.getPersonalInfo}?filterType=${filterType}&value=${value}`,
    )
    .then((response) =>
      response && response.status === 200 ? response.data : [],
    );

export const getNextProductToBuy = async (
  filterType: string,
  value: string,
): Promise<Array<IPersonalInfo>> =>
  axios
    .get(
      `${IbarServerUrl}${WebConf.ibar.getNextProductToBuy}?value=${value}&filterType=${filterType}`,
    )
    .then((response) =>
      response && response.status === 200 ? response.data : [],
    );
