import { cancelBreak, requestBreak } from "Api/configuration";
import {
  getAllVoipQueueList,
  getIvrsList,
  getUserSipNumbers,
  setIvrPoint,
} from "Api/voip";

import { StoreContext } from "Components/Layout/AppLayout";

import { ISoftStore } from "Components/Layout/interfaces";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import SoftPhoneStore from "Store/Actions/SoftPhone";

import { transferTabs } from "../constants";
import {
  IIvrList,
  IPoints,
  IUsersList,
  IVoipQueues,
  InCallActionButtonContainerHookProps,
} from "../interfaces";

export const useInCallActionButtonContainer =
  (): InCallActionButtonContainerHookProps => {
    const [muted, setMuted] = useState<boolean>(false);
    const [holded, setHolded] = useState<boolean>(false);
    const [transferred, setTransferred] = useState<boolean>(false);
    const [currentTransferTab, setCurrentTransferTab] = useState<string>(
      transferTabs.queues,
    );
    const [number, setNumber] = useState<string>("");
    const [selectedVoip, setSelectedVoip] = useState<string>("");
    const [selectedIvr, setSelectedIvr] = useState<number>(null);
    const [voipList, setVoipList] = useState<IVoipQueues[]>([]);
    const [selectedUser, setSelectedUser] = useState<string>("");
    const [userList, setUserList] = useState<IUsersList[]>([]);
    const [ivrAllList, setAllIvrList] = useState<IIvrList[]>([]);
    const [ivrList, setIvrList] = useState<IPoints[]>([]);
    const [tabList, setTabList] = useState<string[]>([]);
    const [searchUserString, setSearchUserString] = useState("");
    const [searchIvrString, setSearchIvrString] = useState("");
    const [ivrTabValue, setIvrTabValue] = useState("");

    const softPhoneStore = new SoftPhoneStore(useDispatch());

    const softPhoneState = useSelector(
      (state: RootStateOrAny) => state.softPhoneReducer,
    );

    const softStore: ISoftStore = useContext(StoreContext);

    const callData = softPhoneState.lines[softPhoneState.currentLine];
    const userId = parseInt(localStorage.getItem("userID"));

    const handleSearchUsers = (
      event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
      setSearchUserString(event.target.value);
    };

    const handleIvrTabChange = (e) => {
      e.preventDefault();
      const eventCOntent = e.target.textContent;
      setIvrTabValue(eventCOntent);
      const languageData = ivrAllList.find(
        (item) => item.language === eventCOntent,
      );
      if (languageData?.points) setIvrList(languageData?.points);
      else setIvrList([]);
    };

    const handleSearchIvr = (
      event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
      setSearchIvrString(event.target.value);
    };

    const fetchAllVoipQueueList = async (): Promise<void> => {
      const getAllVoipQueues = await getAllVoipQueueList();
      setVoipList(getAllVoipQueues);
    };

    const fetchAllUserList = async (): Promise<void> => {
      const userList = await getUserSipNumbers();
      setUserList(userList);
    };

    const fetchAllIvrList = async (): Promise<void> => {
      const ivrList = await getIvrsList();
      if (ivrList?.length > 0) {
        const languages = ivrList?.map((item) => item.language);
        setTabList(languages);
        setIvrTabValue(languages[0]);
        setAllIvrList(ivrList);
        const languageData = ivrList?.find(
          (item) => item.language === languages[0],
        );
        if (languageData?.points) setIvrList(languageData?.points);
        else setIvrList([]);
      }
    };

    const handleChangeNumber = (e): void => {
      e.preventDefault();
      setNumber(e.target.value);
      setSelectedVoip("");
      setSelectedUser("");
      setSelectedIvr(null);
    };

    const handleQueueChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
      setSelectedUser("");
      setSelectedIvr(null);
      setSelectedVoip((event.target as HTMLInputElement).value);
    };

    const handleUserChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
      setSelectedVoip("");
      setSelectedIvr(null);
      setSelectedUser((event.target as HTMLInputElement).value);
    };

    const handleIvrChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
      setSelectedVoip("");
      setSelectedUser("");
      setSelectedIvr(+(event.target as HTMLInputElement).value);
    };

    const handleClose = (): void => {
      setTransferred(false);
    };

    const handleSendCall = async (): Promise<void> => {
      if (currentTransferTab === transferTabs.ivr) {
        if (selectedIvr) {
          await setIvrPoint(selectedIvr, callData.uid);
          softPhoneStore.setConfrimRegPageSave(
            Math.floor(Math.random() * 9000000000) + 1000000000,
          );
          softStore.sipStorage.terminateCall(callData.sipCallId);
        }
      } else {
        const receiver =
          number.length > 0
            ? number
            : currentTransferTab === transferTabs.queues
            ? selectedVoip
            : currentTransferTab === transferTabs.users && selectedUser;

        softStore.sipStorage.refer(callData.sipCallId, receiver);
        softPhoneStore.setConfrimRegPageSave(
          Math.floor(Math.random() * 9000000000) + 1000000000,
        );
        softStore.sipStorage.terminateCall(callData.sipCallId);
      }
    };

    const toggleMuted = (): void => {
      if (!callData) setMuted(false);
      else if (callData.isMuted) {
        setMuted(false);
        softPhoneStore.toggleCallIsMuted(false, softPhoneState.currentLine);
        softStore.sipStorage.unmute(callData.sipCallId);
      } else {
        setMuted(true);
        softPhoneStore.toggleCallIsMuted(true, softPhoneState.currentLine);
        softStore.sipStorage.mute(callData.sipCallId);
      }
    };

    const toggleHolded = (): void => {
      if (!callData) setHolded(false);
      else if (callData.isOnHold) {
        setHolded(false);
        softPhoneStore.toggleCallIsOnHold(false, softPhoneState.currentLine);
        softStore.sipStorage.unhold(callData.sipCallId);
        process.env.REACT_APP_IS_BREAK_ON_HOLD &&
          localStorage.getItem("autoAcw") === "false" &&
          cancelBreakFunction(userId);
      } else {
        softStore.sipStorage.hold(callData.sipCallId);
        setHolded(true);
        softPhoneStore.toggleCallIsOnHold(true, softPhoneState.currentLine);
        process.env.REACT_APP_IS_BREAK_ON_HOLD &&
          localStorage.getItem("autoAcw") === "false" &&
          requestBreakFunction(userId);
      }
    };

    const toggleTransfer = (): void => {
      if (!callData) setTransferred(false);
      else if (transferred) setTransferred(false);
      else setTransferred(true);
    };

    const handleCallInfo = (): void => {
      if (softPhoneState.isCallInfoActive)
        softPhoneStore.setCallInfoStatus(false);
    };

    const requestBreakFunction = async (userId: number): Promise<void> =>
      requestBreak(userId).then((response) => {
        if (response) {
          softPhoneStore.setBreakInCall(true);
          softPhoneStore.setBreakOnHold(true);
        } else throw new Error();
      });

    const cancelBreakFunction = async (userId: number): Promise<void> =>
      cancelBreak(userId).then((response) => {
        if (response) {
          softPhoneStore.setBreakInCall(false);
          softPhoneStore.setBreakOnHold(false);
        } else throw new Error();
      });

    useEffect(() => {
      (async function () {
        if (currentTransferTab === transferTabs.queues) {
          if (voipList.length === 0) await fetchAllVoipQueueList();
        } else if (currentTransferTab === transferTabs.users) {
          if (userList.length === 0) await fetchAllUserList();
        } else if (currentTransferTab === transferTabs.ivr) {
          if (ivrList.length === 0) await fetchAllIvrList();
        }
      })();
    }, [currentTransferTab]);

    useEffect(() => {
      if (callData) {
        if (callData.sipCallId === softStore.terminatedCallId.get()) {
          setHolded(false);
          setMuted(false);
        } else {
          if (callData.isOnHold) setHolded(true);
          else setHolded(false);

          if (callData.isMuted) setMuted(true);
          else setMuted(false);
        }
      } else {
        setHolded(false);
        setMuted(false);
      }
    }, [callData, softStore.terminatedCallId.get()]);

    useEffect(() => {
      if (softPhoneState.isSavedBeforeHoldCall) {
        softPhoneStore.setBreakOnHold(false);
        softPhoneStore.setBreakInCall(false);
      }
    }, [softPhoneState.isSavedBeforeHoldCall]);

    return {
      muted,
      holded,
      transferred,
      number,
      currentTransferTab,
      selectedVoip,
      selectedIvr,
      voipList,
      selectedUser,
      userList,
      ivrList,
      tabList,
      searchUserString,
      searchIvrString,
      ivrTabValue,
      handleIvrTabChange,
      toggleMuted,
      toggleHolded,
      handleCallInfo,
      toggleTransfer,
      handleClose,
      handleChangeNumber,
      setCurrentTransferTab,
      handleQueueChange,
      handleUserChange,
      handleIvrChange,
      handleSendCall,
      handleSearchUsers,
      handleSearchIvr,
    };
  };

useInCallActionButtonContainer.displayName = "useInCallActionButtonContainer";
