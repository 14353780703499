import { Grid, styled } from "@material-ui/core";

import React from "react";

import { useCrmInput } from "./hooks";
import { IInputs } from "./interfaces";

const CrmInput = (props: IInputs): React.ReactElement => {
  const { handleInput, chooseIcon } = useCrmInput(props);

  return (
    <Container>
      <Label htmlFor={props.label}>{props.label}</Label>
      <Grid container>
        <Grid item xs={11}>
          <Input
            id={props.label}
            type='text'
            value={props.fieldValue}
            onChange={(e) => handleInput(e)}
          />
        </Grid>
        <Grid item xs={1}>
          {chooseIcon()}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CrmInput;

CrmInput.displayName = "CrmInput";

const Container = styled("div")({
  display: "inline-block",
  width: "90%",
  float: "left",
  marginRight: 15,
  marginLeft: 15,
});

const Input = styled("input")({
  width: "100%",
  color: "#020110",
  fontSize: 13,
  lineHeight: "100%",
  letterSpacing: "0.02em",
  border: "none",
  outline: "none",
});

const Label = styled("label")({
  color: "#414147",
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  display: "block",
  textAlign: "left",
  marginBottom: 10,
  marginTop: 10,
});
