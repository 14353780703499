/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import WebConf, { OmniServerUrl } from "Config/WebConf";
import { fetchData } from "networking";
import { useCallback } from "react";

import { chatActionTypes as actionTypes } from "./actionTypes";

export default class ChatBox {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setChatInstance = useCallback(
    (chatInstance: Object) =>
      this.dispatch({
        type: actionTypes.SET_CHAT_INSTANCE,
        chatInstance,
      }),
    [this],
  );

  toggle = useCallback(
    (open: boolean) =>
      this.dispatch({
        type: actionTypes.TOGGLE,
        open,
      }),
    [this],
  );

  setChatEnded = useCallback(
    (isChatEnded: boolean) =>
      this.dispatch({
        type: actionTypes.SET_CHAT_ENDED,
        isChatEnded,
      }),
    [this],
  );

  setChatSaved = useCallback(
    (conversationId: number, isChatSaved: boolean) =>
      this.dispatch({
        type: actionTypes.SET_CHAT_SAVED,
        conversationId,
        isChatSaved,
      }),
    [this],
  );

  setMessageSent = useCallback(
    (
      conversationId: number,
      messageId: number,
      serviceMessageId: string,
      status: number | string,
      currentMessage: any,
    ) =>
      this.dispatch({
        type: actionTypes.SET_MESSAGE_SENT,
        conversationId,
        messageId,
        serviceMessageId,
        status,
        currentMessage,
      }),
    [this],
  );

  setArchiveConversation = useCallback(
    (conversationId: number, newConversationId: number, messageList: any) =>
      this.dispatch({
        type: actionTypes.SET_ARCHIVE_CONVERSATION,
        conversationId,
        newConversationId,
        messageList,
      }),
    [this],
  );

  removeNewMessageCount = useCallback(
    (conversationId: number) =>
      this.dispatch({
        type: actionTypes.REMOVE_NEW_MESSAGE_COUNT,
        conversationId,
      }),
    [this],
  );

  setNewMessageCount = useCallback(
    (conversationId: number, count: number) =>
      this.dispatch({
        type: actionTypes.SET_NEW_MESSAGE_COUNT,
        conversationId,
        count,
      }),
    [this],
  );

  setIsSendMessage = useCallback(
    (isSendMessage: number) =>
      this.dispatch({
        type: actionTypes.IS_SEND_MESSAGE,
        isSendMessage,
      }),
    [this],
  );

  setCloseActiveChat = useCallback(
    (closeActiveChat: number | null) =>
      this.dispatch({
        type: actionTypes.CLOSE_ACTIVE_CHAT,
        closeActiveChat,
      }),
    [this],
  );

  addMessage = useCallback(
    (message, currentMessage, addToActiveConversation = false) =>
      this.dispatch({
        type: actionTypes.ADD_MESSAGE,
        message,
        conversationId: message.conversationId,
        addToActiveConversation,
        currentMessage,
      }),
    [this],
  );

  removeMessage = useCallback(
    (serviceMessageId, conversationId) =>
      this.dispatch({
        type: actionTypes.REMOVE_MESSAGE,
        serviceMessageId,
        conversationId,
      }),
    [this],
  );

  setAllConversations = useCallback(
    (conversations: Object) =>
      this.dispatch({
        type: actionTypes.SET_ALL_CONVERSATIONS,
        conversations,
      }),
    [this],
  );

  endChat = useCallback(
    (conversationId: number, userId: number) =>
      this.dispatch({
        type: actionTypes.END_CHAT,
        conversationId,
        userId,
      }),
    [this],
  );

  toggleChatsView = useCallback(
    () =>
      this.dispatch({
        type: actionTypes.TOGGLE_CHATS_VIEW,
      }),
    [this],
  );

  setActiveConversation = useCallback(
    (conversationId: number) =>
      this.dispatch({
        type: actionTypes.SET_ACTIVE_CONVERSATION,
        conversationId,
      }),
    [this],
  );

  setMessageImage = useCallback(
    (conversationId: number, messageId: number, base64: string) =>
      this.dispatch({
        type: actionTypes.SET_MESSAGE_IMAGE,
        conversationId,
        messageId,
        base64,
      }),
    [this],
  );

  addNewMessageCount = useCallback(
    (conversationId: number, adding = 1) =>
      this.dispatch({
        type: actionTypes.ADD_NEW_MESSAGE_COUNT,
        conversationId,
        adding,
      }),
    [this],
  );

  readNewMessages = useCallback(
    (conversationId: number) =>
      this.dispatch({
        type: actionTypes.READ_NEW_MESSAGES,
        conversationId,
      }),
    [this],
  );

  addConversationToEdit = useCallback(
    (message: any) =>
      this.dispatch({
        type: actionTypes.ADD_CONVERSATION_TO_EDIT,
        message,
      }),
    [this],
  );

  removeConversationFromEdit = useCallback(
    (conversationId: number) =>
      this.dispatch({
        type: actionTypes.REMOVE_CONVERSATION_FROM_EDIT,
        conversationId,
      }),
    [this],
  );

  tryToEndChat = async (
    conversationId: number,
    userId: number,
  ): Promise<Boolean> => {
    const body: BodyInit = JSON.stringify({
      conversationId: conversationId,
      userId: userId,
    });
    const params: RequestInit = {
      method: "POST",
      body: body,
    };
    const response = await fetchData(
      OmniServerUrl + WebConf.chat.endChat,
      params,
    );
    if (response && response.status === 200) {
      this.endChat(conversationId, userId);
    }
    return false;
  };

  setReplyMessageView = useCallback(
    (replyMessageView: boolean) =>
      this.dispatch({
        type: actionTypes.SET_REPLY_MESSAGE_VIEW,
        replyMessageView,
      }),
    [this],
  );

  setReplyMessageData = useCallback(
    (replyMessageData: object) =>
      this.dispatch({
        type: actionTypes.SET_REPLY_MESSAGE_DATA,
        replyMessageData,
      }),
    [this],
  );

  setChatFullWidth = useCallback(
    (chatFullWidth: boolean) =>
      this.dispatch({
        type: actionTypes.SET_CHAT_FULL_WIDTH,
        chatFullWidth,
      }),
    [this.dispatch],
  );

  setSendLastMsg = useCallback(
    (isSendLastMsg: object | number) =>
      this.dispatch({
        type: actionTypes.SET_SEND_LAST_MSG,
        isSendLastMsg,
      }),
    [this.dispatch],
  );

  setMessage = useCallback(
    (text: string, conversationId: number) =>
      this.dispatch({
        type: actionTypes.SET_MESSAGE,
        text,
        conversationId,
      }),
    [this.dispatch],
  );
}
