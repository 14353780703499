import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ForwardOutlinedIcon from "@material-ui/icons/ForwardOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MicNoneIcon from "@material-ui/icons/MicNone";
import PauseIcon from "@material-ui/icons/Pause";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ReplayIcon from "@material-ui/icons/Replay";
import SendIcon from "@material-ui/icons/Send";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import StopIcon from "@material-ui/icons/Stop";
import TextsmsIcon from "@material-ui/icons/Textsms";
import RateReviewIcon from "@material-ui/icons/RateReview";

export {
  AccountBoxIcon,
  CheckCircleOutlineIcon,
  AccountCircleIcon,
  ForwardOutlinedIcon,
  TextsmsIcon,
  AccessTimeIcon,
  DoneIcon,
  DoneAllIcon,
  ErrorOutlineIcon,
  MicNoneIcon,
  CloseIcon,
  HighlightOffIcon,
  SendIcon,
  SettingsVoiceIcon,
  AttachFileOutlinedIcon,
  PlayArrowIcon,
  PauseIcon,
  StopIcon,
  DeleteIcon,
  ReplayIcon,
  ExpandMoreIcon,
  PermPhoneMsgIcon,
  RateReviewIcon,
};
