import { styled } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import { LocalPhoneIcon } from "./Icons";

interface IPhone {
  isActive: boolean;
  isOpen: boolean;
  handleSelectMenu: () => {};
}

export const Phone = ({ isOpen, isActive, handleSelectMenu }: IPhone) => {
  const PhoneIconWrapper = styled("div")({
    height: 30,
    width: 30,
    borderRadius: 32,
    color: "orange",
    border: isOpen ? "1px solid transparent" : "5px solid orange",
    margin: isOpen ? "0 0 0 12px" : "-3px auto",
    backgroundColor: "white",
  });

  return (
    <MenuItem
      className={clsx({
        ["active"]: isActive,
        ["open"]: isOpen,
      })}
      onClick={handleSelectMenu}>
      <PhoneIconWrapper>
        <PhoneIcon />
      </PhoneIconWrapper>
    </MenuItem>
  );
};

Phone.displayName = "Phone";

const PhoneIcon = styled(LocalPhoneIcon)({ fontSize: 24, marginTop: 3 });

const MenuItem = styled("div")({
  width: "100%",
  height: 35,
  cursor: "pointer",
  padding: "5px 0",
  "&.open": {
    background: "linear-gradient(282.31deg, #FF5C00 0%, #FFA13A 100%)",
    borderRadius: 32,
    padding: "7px 0 5px 0",
  },
  "&.active": {
    background: "linear-gradient(282.31deg, #FF5C00 0%, #FFA13A 100%)",
    borderRadius: "8px 2px 8px 8px",
  },
});
