/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback } from "react";

import { ILines } from "Store/Reducers/interfaces/softPhoneReducer.types";

import { softPhoneActions as actionTypes } from "./actionTypes";

export default class SoftPhone {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  isConnected = useCallback(
    () =>
      this.dispatch({
        type: actionTypes.PHONE_CONNECTED,
      }),
    [this],
  );

  isDisconnected = useCallback(
    () =>
      this.dispatch({
        type: actionTypes.PHONE_DISCONNECTED,
      }),
    [this],
  );

  isPending = useCallback(
    () =>
      this.dispatch({
        type: actionTypes.PHONE_PENDING,
      }),
    [this],
  );

  isReconnecting = useCallback(
    () =>
      this.dispatch({
        type: actionTypes.PHONE_RECONNECTING,
      }),
    [this],
  );

  toggle = useCallback(
    (open: boolean) =>
      this.dispatch({
        type: actionTypes.TOGGLE_SOFT_PHONE,
        open,
      }),
    [this],
  );

  triggerCall = useCallback(
    (triggerCall: boolean) =>
      this.dispatch({
        type: actionTypes.TRIGGER_CALL,
        triggerCall,
      }),
    [this],
  );

  triggerOutgoingCall = useCallback(
    (triggerOutgoingCall: boolean) =>
      this.dispatch({
        type: actionTypes.TRIGGER_OUTGOING_CALL,
        triggerOutgoingCall,
      }),
    [this],
  );

  setRegistered = useCallback(
    (registered: boolean) =>
      this.dispatch({
        type: actionTypes.SET_REGISTERED,
        registered,
      }),
    [this],
  );

  setSavedInCall = useCallback(
    (isSavedInCall: boolean) =>
      this.dispatch({
        type: actionTypes.SET_SAVED_IN_CALL,
        isSavedInCall,
      }),
    [this],
  );

  setSavedInCallAtLine = useCallback(
    (index: string | number, isSavedInCall: boolean) =>
      this.dispatch({
        type: actionTypes.SET_SAVED_IN_CALL_AT_LINE,
        isSavedInCall,
        index,
      }),
    [this],
  );

  setCallContinuing = useCallback(
    (isCallContinuing: boolean) =>
      this.dispatch({
        type: actionTypes.SET_CALL_CONTINUING,
        isCallContinuing,
      }),
    [this],
  );

  setBreakInCall = useCallback(
    (isBreakInCall: boolean) =>
      this.dispatch({
        type: actionTypes.SET_BREAK_IN_CALL,
        isBreakInCall,
      }),
    [this],
  );

  setBreakOnHold = useCallback(
    (isBreakOnHold: boolean) =>
      this.dispatch({
        type: actionTypes.SET_BREAK_ON_HOLD,
        isBreakOnHold,
      }),
    [this],
  );

  setSaveBeforeHoldCallOver = useCallback(
    (isSavedBeforeHoldCall: boolean) =>
      this.dispatch({
        type: actionTypes.SET_SAVE_BEFORE_HOLD_CALL_OVER,
        isSavedBeforeHoldCall,
      }),
    [this],
  );

  setWillBeSaveAfterHoldCallOver = useCallback(
    (willBeSavedAfterHoldCallOver: boolean) =>
      this.dispatch({
        type: actionTypes.SET_SAVE_AFTER_HOLD_CALL_OVER,
        willBeSavedAfterHoldCallOver,
      }),
    [this],
  );

  setSpyCallActive = useCallback(
    (isSpyCallActive: boolean) =>
      this.dispatch({
        type: actionTypes.SET_SPY_CALL_ACTIVE,
        isSpyCallActive,
      }),
    [this],
  );

  setIsCompleteCallByAgent = useCallback(
    (isCompleteCallByAgent: boolean) =>
      this.dispatch({
        type: actionTypes.SET_IS_COMPLETE_CALL_BY_AGENT,
        isCompleteCallByAgent,
      }),
    [this],
  );

  setPhoneNumber = useCallback(
    (phoneNumber: string) =>
      this.dispatch({
        type: actionTypes.SET_PHONE_NUMBER,
        phoneNumber,
      }),
    [this],
  );

  setDTMF = useCallback(
    (dtmf: string) =>
      this.dispatch({
        type: actionTypes.SET_DTMF,
        dtmf,
      }),
    [this],
  );

  ssetContextName = useCallback(
    (contextName: string) =>
      this.dispatch({
        type: actionTypes.SET_CONTEXT_NAME,
        contextName,
      }),
    [this],
  );

  setCurrentLine = useCallback(
    (currentLine: string | number) =>
      this.dispatch({
        type: actionTypes.SET_CURRENT_LINE,
        currentLine,
      }),
    [this],
  );

  setPhoneSize = useCallback(
    (size: string) =>
      this.dispatch({
        type: actionTypes.SET_PHONE_SIZE,
        size,
      }),
    [this],
  );

  setCallInfoStatus = useCallback(
    (status: boolean) =>
      this.dispatch({
        type: actionTypes.SET_CALL_INFO_STATUS,
        status,
      }),
    [this],
  );

  acceptCall = useCallback(
    (lines: any, currentLine: string | number) =>
      this.dispatch({
        type: actionTypes.ACCEPT_CALL,
        lines,
        currentLine,
      }),
    [this],
  );

  updateWaitingDuration = useCallback(
    (waitingDuration: number, lineIndex: number) =>
      this.dispatch({
        type: actionTypes.UPDATE_WAITING_DURATION,
        waitingDuration,
        lineIndex,
      }),
    [this],
  );

  updateDuration = useCallback(
    (duration: any, lineIndex: number) =>
      this.dispatch({
        type: actionTypes.UPDATE_DURATION,
        duration,
        lineIndex,
      }),
    [this],
  );

  toggleCallIsEnded = useCallback(
    (state: boolean, index: number) =>
      this.dispatch({
        type: actionTypes.TOGGLE_CALL_IS_ENDED,
        state,
        index,
      }),
    [this],
  );

  toggleCallIsRegistered = useCallback(
    (state: boolean, index: number) =>
      this.dispatch({
        type: actionTypes.TOGGLE_CALL_IS_REGISTERED,
        state,
        index,
      }),
    [this],
  );

  toggleCallIsOnHold = useCallback(
    (state: boolean, index: number) =>
      this.dispatch({
        type: actionTypes.TOGGLE_CALL_IS_ON_HOLD,
        state,
        index,
      }),
    [this],
  );

  toggleCallIsMuted = useCallback(
    (state: boolean, index: number) =>
      this.dispatch({
        type: actionTypes.TOGGLE_CALL_IS_MUTED,
        state,
        index,
      }),
    [this],
  );

  outCallIsAnswered = useCallback(
    (sipCallId: string) =>
      this.dispatch({
        type: actionTypes.OUT_CALL_IS_ANSWERED,
        sipCallId,
      }),
    [this],
  );

  holdAllLinesExceptOne = useCallback(
    (index: number) =>
      this.dispatch({
        type: actionTypes.HOLD_ALL_LINES_EXCEPT_ONE,
        index,
      }),
    [this],
  );

  setLines = useCallback(
    (lines: ILines) =>
      this.dispatch({
        type: actionTypes.SET_LINES_PHONES,
        lines,
      }),
    [this],
  );

  emptyLine = useCallback(
    (index: number | string) =>
      this.dispatch({
        type: actionTypes.EMPTY_LINE,
        index,
      }),
    [this],
  );

  emptyLineBySipCallId = useCallback(
    (sipCallId: string) =>
      this.dispatch({
        type: actionTypes.EMPTY_LINE_BY_SIP_CALL_ID,
        sipCallId,
      }),
    [this],
  );

  setLinesAfterAccepted = useCallback(
    (lines: ILines) =>
      this.dispatch({
        type: actionTypes.SET_LINES_PHONES_AFTER_ACCEPTED,
        lines,
      }),
    [this],
  );

  setConfrimRegPageSave = useCallback(
    (confrimRegPageSave: number) =>
      this.dispatch({
        type: actionTypes.CONFRIM_REG_PAGE_SAVE,
        confrimRegPageSave,
      }),
    [this],
  );

  setSaveBeforeACWCallOver = useCallback(
    (isSavedBeforeACWCall: boolean) =>
      this.dispatch({
        type: actionTypes.SET_SAVE_BEFORE_ACW_CALL_OVER,
        isSavedBeforeACWCall,
      }),
    [this],
  );

  setWillBeSaveAfterACWCallOver = useCallback(
    (willBeSavedAfterACWCallOver: boolean) =>
      this.dispatch({
        type: actionTypes.SET_SAVE_AFTER_ACW_CALL_OVER,
        willBeSavedAfterACWCallOver,
      }),
    [this],
  );

  setAcwCallOver = useCallback(
    (isACWCallOver: boolean) =>
      this.dispatch({
        type: actionTypes.SET_ACW_CALL_OVER,
        isACWCallOver,
      }),
    [this],
  );
}
