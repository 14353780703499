/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import { Grid } from "@material-ui/core";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { StoreContext } from "Components/Layout/AppLayout";
import React, { useContext } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Call from "Store/Actions/Call";
import Request from "Store/Actions/Request";
import SoftPhone from "Store/Actions/SoftPhone";

import ActionButton from "./ActionButton";
import CallButton from "./CallButton";
import { actionTypes } from "./constants";
import { AccountCircleIcon, BackspaceOutlinedIcon } from "./Icons";

const BottomActionButtons = (): React.ReactElement => {
  const { translate } = useTranslation();
  const softStore: any = useContext(StoreContext);

  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );
  const request = new Request(useDispatch());
  const call = new Call(useDispatch());
  const softPhoneStore = new SoftPhone(useDispatch());
  const callData = softPhoneState.lines[softPhoneState.currentLine];

  const handleRegistration = (): void => {
    if (requestState.showRegistration) request.setShowRegistration(false);
    else request.setShowRegistration(true);
  };

  const terminateCall = (): void => {
    softStore.sipStorage.terminateCall(
      softPhoneState?.lines[softPhoneState.currentLine]?.sipCallId,
    );
    call.setOutCallAnimation(false);
    softPhoneStore.setCallInfoStatus(false);
    softPhoneStore.setPhoneNumber("");
  };

  window.onbeforeunload = () => {
    Object.keys(softPhoneState?.lines).map((line) => {
      softPhoneState?.lines[line] != null &&
        softStore.sipStorage.terminateCall(
          softPhoneState?.lines[line]?.sipCallId,
        );
    });
  };

  const makeOutCall = (): void => {
    const randomNumber = Math.floor(Math.random() * 1000);
    call.setRandomNumber(randomNumber);
    setTimeout(() => {
      call.setRandomNumber(null);
    }, 5000);

    softPhoneStore.triggerCall(true);
  };

  const handleDeleteNumber = (): void => {
    if (!softPhoneState.lines[softPhoneState.currentLine]) {
      let phoneNumber = softPhoneState.phoneNumber;
      phoneNumber = phoneNumber === "" ? phoneNumber : phoneNumber.slice(0, -1);
      softPhoneStore.setPhoneNumber(phoneNumber);
    }
  };

  return (
    <Grid container style={{ marginTop: 12 }}>
      <Grid item xs={1}></Grid>
      <Grid item xs={3}>
        <ActionButton
          name={translate("softphone_reg")}
          Icon={AccountCircleIcon}
          onClick={handleRegistration}
        />
      </Grid>
      <Grid item xs={3}>
        {callData ? (
          <CallButton
            active={
              !(
                localStorage.getItem("autoCall") == "true" &&
                Boolean(softStore.newIncomingCallId.get())
              )
            }
            onClick={terminateCall}
            type={actionTypes.endCall}
          />
        ) : (
          <CallButton
            active={softPhoneState.phoneNumber !== ""}
            onClick={makeOutCall}
            type={actionTypes.makeCall}
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {!softPhoneState.isCallInfoActive && (
          <ActionButton
            Icon={BackspaceOutlinedIcon}
            onClick={handleDeleteNumber}
            iconClassName={{
              fontSize: 25,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default BottomActionButtons;
