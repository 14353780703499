import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import { Document, Page } from "@react-pdf/renderer";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import React from "react";

const TransactionByAccountPdf = ({ data }): React.ReactElement => {
  const { translate } = useTranslation();

  return (
    <Document>
      <Page size='A4'>
        <Table data={data}>
          <TableHeader>
            <TableCell>{translate("request_account_number")}</TableCell>
            <TableCell>{translate("request_reference_number")}</TableCell>
            <TableCell>{translate("request_transaction_date")}</TableCell>
            <TableCell>{translate("request_amount")}</TableCell>
            <TableCell>{translate("request_transaction_details")}</TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell getContent={(r) => r.accountName} />
            <DataTableCell getContent={(r) => r.referenceNo} />
            <DataTableCell getContent={(r) => r.transactionDate} />
            <DataTableCell getContent={(r) => r.amount} />
            <DataTableCell getContent={(r) => r.transactionDetails} />
          </TableBody>
        </Table>
      </Page>
    </Document>
  );
};

export default TransactionByAccountPdf;
