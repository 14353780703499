/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo, useRef, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { styled } from "@material-ui/core/styles";

import { defaultStates as defaultFaqStates } from "Store/Reducers/faqReducer";
import { RequestContextStore } from "Components/Layout/AppLayout";
import Faq from "Store/Actions/Faq";

import Header from "./Header";
import Search from "./Search";
import Questions from "./Questions";
import { searchBy } from "../constants";
import { getQuestions, getQuestionsByAnswer } from "Api/faq";

const Main = () => {
  const totalFaqState = useSelector(
    (state: RootStateOrAny) => state.faqReducer,
  );
  const requestContextStore: any = useContext(RequestContextStore);
  const faqState = requestContextStore.getCurrentStateFromTotalState(
    totalFaqState,
    defaultFaqStates,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const faq = new Faq(useDispatch());
  const timeoutRef = useRef(null);

  const prossesQuestionsData = (questions) => {
    return questions
      .map((item) => {
        let changeDataValue = {
          id: item.id,
          question: item.question,
          answer: item.answers.length > 0 ? item.answers[0].html : "",
          modifiedBy: item.modifiedBy ? item.modifiedBy.fullName : "",
          modificationDate: item.modificationDate,
          attachments: item.attachments,
          fullPath: item.category.fullPath,
        };
        return changeDataValue;
      })
      .sort(function (a, b) {
        if (a.fullPath < b.fullPath) return -1;
        if (a.fullPath > b.fullPath) return 1;
        return 0;
      });
  };

  const onQuestionSearchChange = (value) =>
    getQuestions(value, faqState.selectedCategory?.id)
      .then((response) => {
        if (response)
          faq.setAllQuestions(
            requestState.menuType,
            requestContextStore.getRequestId(),
            prossesQuestionsData(response),
          );
      })
      .catch((e) => {
        console.error(e);
      });

  const onAnswerSearchChange = (value) =>
    getQuestionsByAnswer(value, faqState.selectedCategory?.id)
      .then((response) => {
        if (response)
          faq.setAllQuestions(
            requestState.menuType,
            requestContextStore.getRequestId(),
            prossesQuestionsData(response),
          );
      })
      .catch((e) => {
        console.error(e);
      });

  const onSearchForAll = (value) => {
    let data = [];

    return getQuestions(value, faqState.selectedCategory?.id)
      .then((response) => {
        if (Array.isArray(response)) {
          data = response;
          return getQuestionsByAnswer(value, faqState.selectedCategory?.id);
        }
      })
      .then((response) => {
        if (Array.isArray(response)) {
          data = [...data, ...response];
          const ids = data.map((o) => o.id);
          const sortedData = data.filter(
            ({ id }, index) => !ids.includes(id, index + 1),
          );

          faq.setAllQuestions(
            requestState.menuType,
            requestContextStore.getRequestId(),
            prossesQuestionsData(sortedData),
          );
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleSearch = (value) => {
    let search = faqState.searchBy;
    if (search === searchBy.question) onQuestionSearchChange(value);
    else if (search === searchBy.answer) onAnswerSearchChange(value);
    else if (search === searchBy.all) onSearchForAll(value);
  };

  const onInput = (event) => {
    event.persist();
    let { value } = event.target;

    faq.setQuestionSearchValue(
      requestState.menuType,
      requestContextStore.getRequestId(),
      value,
    );

    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;

      handleSearch(value);
    }, 500);
  };

  useEffect(() => {
    if (faqState.questionSearchValue)
      handleSearch(faqState.questionSearchValue);
  }, [faqState.searchBy]);

  return useMemo(
    () => (
      <Container>
        <Header />
        <Search onInput={onInput} value={faqState.questionSearchValue} />
        <Questions />
      </Container>
    ),
    [
      faqState.questionSearchValue,
      faqState.selectedQuestion,
      requestState.menuType,
      requestContextStore.getRequestId(),
    ],
  );
};

export default Main;

const Container = styled("div")({});
