import axios from "Api/axios";
import WebConf, { SMSUrl } from "Config/WebConf";

import { ITemplateData } from "Pages/SMSTemplates/interfaces";

import { IGetSMSTemplates } from "./interfaces/sms";

// GET
export const getSMSTemplates = (
  page: number,
  size: number,
): Promise<IGetSMSTemplates> =>
  axios
    .get(`${SMSUrl}/templates?page=${page}&size=${size}`)
    .then((response) => response && response.data);

// DELETE
export const deleteTemplate = (id: number): Promise<boolean> =>
  axios
    .delete(`${SMSUrl}/templates/${id}`)
    .then((response) => (response && response.status === 200 ? true : false));

// POST
export const createTemplate = (
  title: string,
  text: string,
): Promise<ITemplateData> => {
  const body = {
    id: null,
    title,
    text,
  };

  return axios
    .post(`${SMSUrl}/templates`, body)
    .then((response) => response && response.data);
};

export const updateTemplate = (
  id: number,
  title: string,
  text: string,
): Promise<ITemplateData> => {
  const body = {
    id,
    title,
    text,
  };

  return axios
    .post(`${SMSUrl}/templates`, body)
    .then((response) => response && response.data);
};

export const searchByText = (
  word: string,
  page: number,
  size: number,
): Promise<IGetSMSTemplates> => {
  const body = {
    word,
    page,
    size,
  };

  return axios
    .post(`${SMSUrl}/templates${WebConf.sms.searchByText}`, body)
    .then((response) => response && response.data);
};

export const searchByTitle = (
  word: string,
  page: number,
  size: number,
): Promise<IGetSMSTemplates> => {
  const body = {
    word,
    page,
    size,
  };

  return axios
    .post(`${SMSUrl}/templates${WebConf.sms.searchByTitle}`, body)
    .then((response) => response && response.data);
};

export const searchByAll = (
  word: string,
  page: number,
  size: number,
): Promise<IGetSMSTemplates> => {
  const body = {
    word,
    page,
    size,
  };

  return axios
    .post(`${SMSUrl}/templates${WebConf.sms.searchByAll}`, body)
    .then((response) => response && response.data);
};

export const sendSms = (
  to: string,
  message: string,
  title: string,
): Promise<boolean> => {
  const body = {
    to,
    message,
    title,
  };

  return axios
    .post(SMSUrl, body)
    .then((response) => (response && response.status === 200 ? true : false));
};
