import { taskActions as actionTypes } from "../Actions/actionTypes";

const initialState = {
  fieldReset: null,
  tagsName: [],
  fetchTasksRandomId: null
};

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMPTY_TAG_FIELDS:
      return { ...state, fieldReset: action.fieldReset };

    case actionTypes.TASK_TAGS_NAME:
      return { ...state, tagsName: action.tagsName };

    case actionTypes.FETCH_TASKS_RANDOM_ID:
      return { ...state, fetchTasksRandomId: action.fetchTasksRandomId };

    default:
      return state;
  }
};

export default taskReducer;
