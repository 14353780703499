import { Grid, styled } from "@material-ui/core";

import CustomerCard from "Components/Crm/CustomerCard";
import LoadingComponent from "Components/Loading/LoadingComponent";

import React from "react";

import { customerTypes } from "./constants/customerTypes";
import { getIdentifierField } from "./CrmForm";
import { useCrm } from "./hooks";

const Crm = (): React.ReactElement => {
  const {
    crmState,
    crm,
    requestState,
    requestContextStore,
    isLoading,
    getCrmField,
    getCrmFieldNameRow,
  } = useCrm();

  return (
    <StyledCrm>
      {
        <Grid container>
          <CustomerCard
            customerId={crmState.customerId}
            companies={crmState.companies}
            formFields={crmState.formFields}
            identifiers={crmState.identifiers}
            getCrmField={getCrmField}
            getCrmFieldNameRow={getCrmFieldNameRow}
            getIdentifierField={(field) =>
              getIdentifierField({
                channelType: field.channelType,
                onInputChange: (value, id, label) =>
                  crm.setIdentifierValue(
                    requestState.menuType,
                    requestContextStore.getRequestId(),
                    value,
                    id,
                    label,
                  ),
                value: field.fieldValue,
              })
            }
            editable
            avatar={crmState.profileImage}
            addNewField={(customerType: customerTypes) =>
              crm.addCustomField(
                requestState.menuType,
                requestContextStore.getRequestId(),
                customerType,
              )
            }
            removeLastField={(customerType: customerTypes) =>
              crm.removeLastField(
                requestState.menuType,
                requestContextStore.getRequestId(),
                customerType,
              )
            }
            setCompanies={(companies) => {
              crm.setCompanies(
                requestState.menuType,
                requestContextStore.getRequestId(),
                companies,
              );
            }}
            onSaveNewCompany={() =>
              crm.setCheckValidation(
                requestState.menuType,
                requestContextStore.getRequestId(),
                customerTypes.LEGAL,
              )
            }
            onAvatarLoaded={(value) => {
              crm.setProfileImage(
                requestState.menuType,
                requestContextStore.getRequestId(),
                value,
              );
            }}
          />
        </Grid>
      }
      {isLoading ? <LoadingComponent /> : null}
    </StyledCrm>
  );
};

export default Crm;

Crm.displayName = "Crm";

const StyledCrm = styled("div")({
  paddingTop: 7,
  paddingBottom: 40,
});
