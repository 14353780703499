import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "i18n/config";

class SwalAlert {
  mySwal = withReactContent(Swal);
  active = false;

  confirm = (
    title,
    okHandler = () => {},
    cancelHandler = () => {},
    message = i18n.t("confirm_to_continue"),
  ) => {
    this.mySwal
      .fire({
        title: title,
        text: message,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6c757d",
        confirmButtonText: i18n.t("yes"),
        cancelButtonText: i18n.t("no"),
      })
      .then((result) => {
        this.active = false;
        if (result.value) {
          okHandler();
        } else {
          cancelHandler();
        }
      });
  };

  custom = (config) => {
    return this.mySwal.fire(config);
  };
}

const swalAlert = new SwalAlert();
export default swalAlert;
