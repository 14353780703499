import { Tooltip } from "@material-ui/core";
import MuiButton, { ButtonProps } from "@material-ui/core/Button";
import clsx from "clsx";
import React from "react";
import { buttonTypes } from "./buttonTypes";
import { commonStyle } from "./commonStyle";

export interface IButton extends ButtonProps {
  Icon?: any;
  buttonType: buttonTypes;
  tooltipText?: string;
  active?: boolean;
}

export default function Button(props: IButton) {
  const classes = commonStyle(props);

  const { tooltipText, Icon, buttonType, active, children, ...rest } = props;

  const button = (
    <MuiButton
      {...rest}
      size='small'
      className={clsx(classes.button, props.className)}
      startIcon={props.Icon ? <Icon className={classes.icon} /> : null}>
      {children}
    </MuiButton>
  );

  if (tooltipText) {
    return (
      <Tooltip title={tooltipText}>
        <div>{button}</div>
      </Tooltip>
    );
  }

  return button;
}
