/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { styled } from "@material-ui/core/styles";
import { RequestContextStore } from "Components/Layout/AppLayout";
import { CloseIcon } from "./Icons";

import Topic from "Store/Actions/Topic";
import { defaultStates as defaultTopicStates } from "Store/Reducers/topicReducer";

const Paths = () => {
  const totalTopicState = useSelector(
    (state: RootStateOrAny) => state.topicReducer,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );

  const topic = new Topic(useDispatch());

  const requestContextStore: any = useContext(RequestContextStore);

  const topicState = requestContextStore.getCurrentStateFromTotalState(
    totalTopicState,
    defaultTopicStates,
  );

  const removePath = (id: number) => {
    topic.removePath(
      requestState.menuType,
      requestContextStore.getRequestId(),
      id,
    );
  };

  return useMemo(
    () => (
      <Container>
        {topicState.savedPaths.map((path) => (
          <FullPath key={path.id}>
            {path.path} <Icon onClick={() => removePath(path.id)} />
          </FullPath>
        ))}
      </Container>
    ),
    [
      requestState.menuType,
      requestContextStore.getRequestId(),
      topicState.savedPaths,
    ],
  );
};

export default Paths;

const Container = styled("div")({
  maxHeight: "12%",
  height: "12%",
  minHeight: "12%",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 3,
    height: 5,
  },
});

const FullPath = styled("button")({
  backgroundColor: "#F5F4F7",
  borderRadius: 6,
  padding: "5px 8px 6px 10px",
  color: "#020110",
  fontSize: 13,
  lineHeight: "130%",
  letterSpacing: "0.02em",
  textAlign: "left",
  marginTop: 8,
  marginRight: 8,
  border: "none",
  outline: "none",
  cursor: "default",
  float: "left",
  display: "block",
  userSelect: "text",
});

const Icon = styled(CloseIcon)({
  color: "#9F9DAD",
  fontSize: 12,
  fontWeight: 600,
  lineHeight: "130%",
  position: "relative",
  top: 2,
  left: 5,
  cursor: "pointer",
});
