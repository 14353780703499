import { Button, Select, styled } from "@material-ui/core";

import { SearchIcon } from "Assets/icons";

export const Input = styled("input")({
  border: "none",
  outline: "none",
  color: "#020110",
  fontSize: 13,
  letterSpacing: "0.02em",
  float: "left",
  borderBottom: "1px solid #DCDDE6",
  marginLeft: 20,
});

export const SelectBox = styled(Select)({
  width: 200,
});

export const SearchButton = styled(Button)({
  background: "rgb(0, 178, 150)",
  width: 40,
  height: 35,
  borderRadius: 8,
  textTransform: "uppercase",
  cursor: "pointer",
  margin: "0 auto",
  marginLeft: 30,
  "&:hover": {
    background: "rgb(0, 178, 150)",
  },
  "&.MuiButton-root.Mui-disabled": {
    background: "rgb(0, 0, 0, 0.3)",
  },
});

export const Icon = styled(SearchIcon)({
  color: "#fff",
  fontWeight: 600,
  fontSize: 22,
  lineHeight: 17,
  position: "relative",
});

export const Container = styled("div")({
  display: "flex",
});
