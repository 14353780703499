import axios from "Api/axios";
import { fetchData } from "networking";

import WebConf, { CrmServerUrl, FileServerUrl } from "Config/WebConf";
import Notification from "Notifications/Notification";

import { getObjectForSaveApi } from "Components/Request/LeftPanel/CrmForm";
import { customerTypes } from "Components/Request/LeftPanel/constants/customerTypes";

import { CrmUsersParams } from "./interfaces";

// GET
export const downloadAttachment = async (path: string) => {
  return await fetchData(
    CrmServerUrl + WebConf.file.downloadAttachment + "/" + path,
  );
};

export const getFormFields = async () => {
  const response = await fetchData(CrmServerUrl + WebConf.crm.getDefaultFields);

  if (response && response.status === 200) {
    const result = await response.json();
    return result;
  }
};

export const fetchCustomerData = async (channelType, identifier) => {
  const response = await fetchData(
    CrmServerUrl +
      WebConf.crm.getCustomerData +
      `?channelType=${channelType}&identifier=${identifier}`,
  );

  if (response && response.status === 200)
    return response.text().then((text) => (text ? JSON.parse(text) : null));

  return null;
};

// POST
export const fetchCrmUsers = async (requestBody: CrmUsersParams) => {
  return axios
    .post(CrmServerUrl + WebConf.crm.getCrmTableData, requestBody)
    .then((response) => response && response.data);
};

export const uploadAttachments = async (attachments: FormData) =>
  axios
    .post(`${FileServerUrl}${WebConf.file.uploadAttachments}`, attachments)
    .then((response) => response && response.data);

export const saveCrmData = async (
  fields: Array<any>,
  customerId: number,
  photo: string,
  identifiers: Array<any>,
  customerIds: Array<string | number>,
  customerType: customerTypes,
  customFormFields = null,
  fieldValueList: any = null,
) => {
  const formFields = customFormFields ? customFormFields : fields;

  fieldValueList = fieldValueList
    ? fieldValueList
    : getObjectForSaveApi(formFields, customerType);

  photo = photo && !photo.includes("base64") ? photo : null;

  const body: BodyInit = JSON.stringify({
    id: customerId,
    photo,
    identifiers,
    customerIds: customerType === customerTypes.LEGAL ? [] : customerIds,
    user: {
      id: localStorage.getItem("userID"),
      fullName:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("lastName"),
    },
    fieldValueList,
    customerType,
  });

  const params: RequestInit = {
    method: "POST",
    body,
  };

  return await fetchData(CrmServerUrl + WebConf.crm.saveCustomer, params);
};

export const fetchIntegrationSearch = async (url: string, data: any) => {
  const body: BodyInit = JSON.stringify(data);
  const params: RequestInit = {
    method: "POST",
    body: body,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  try {
    //https://orange.ibar.az/api/integration/
    const response = await fetch(url, params);
    if (response.status === 200) {
      let responseText = await response.text();
      return responseText;
    } else if (response.status === 400) {
      const jsonResponse = await response.json();
      Notification.error(jsonResponse.message);
      return "";
    } else return "no content";
  } catch {
    return "no content";
  }
};
