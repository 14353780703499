/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { styled, FormControl } from "@material-ui/core";
import AccountTable from "./AccountTable";
import DepositTable from "./DepositTable";
import {
  fetchAccounts,
  fetchDepositAccounts,
  fetchTransactionsByAccount,
} from "Api/atbCrmIntegration";
import useUpdateEffect from "Hook/useUpdateEffect";
import { BlobProvider } from "@react-pdf/renderer";
import moment from "moment";
import { roundOffToHour } from "Utils/Utils";
import FormDateTimePicker from "Components/Form/FormDateTimePicker";
import TransactionByAccountPdf from "./TransactionByAccountPdf";
import BlockInfo from "./BlockInfo";
import { tabs } from "./constants";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const AccountTab = ({ cif, customerType, setLoading }) => {
  const { translate } = useTranslation();
  const [rows, setRows] = useState([]);
  const [tab, setTab] = useState(0);
  const [transactions, setTransactions] = useState<any>({});
  const [blockInfo, setBlockInfo] = useState([]);
  const [clickedBlockInfo, setClickedBlockInfo] = useState(null);
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [toDate, setToDate] = useState(roundOffToHour(new Date()));

  useUpdateEffect(() => {
    fetchRows();
  }, [cif]);

  useEffect(() => {
    setLoading(true);
    setRows(addNewColumns(rows));
    setLoading(false);
  }, [transactions]);

  useEffect(() => {
    setTransactions({});
  }, [fromDate, toDate]);

  const handleBlockInfoButton = (data) => {
    if (data?.amountBlockInfo.length > 0) {
      let tempData = data.amountBlockInfo.map((item) => {
        return { [item?.amountBlockType]: item?.blockedAmount };
      });
      setBlockInfo(tempData);
    }
    setClickedBlockInfo(data.accountNumber);
  };

  const addNewColumns = (data) => {
    return data.map((row) => {
      row["actions"] = !transactions[row.accountNumber] ? (
        <div
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "blue",
          }}
          onClick={async () => {
            setLoading(true);
            let transaction = await fetchAsyncTransactionsByAccount(
              row.accountNumber,
            );
            setTransactions({
              [row.accountNumber]: transaction,
            });
          }}>
          {translate("request_get_transactions")}
        </div>
      ) : (
        <BlobProvider
          document={
            <TransactionByAccountPdf data={transactions[row.accountNumber]} />
          }>
          {({ url, loading }) => {
            if (loading) return <div>{translate("request_creating_pdf")}</div>;

            setLoading(false);
            return (
              <a href={url} target='_blank'>
                {translate("request_view_transactions")}
              </a>
            );
          }}
        </BlobProvider>
      );

      row = Object.assign(
        {
          blockInfo: (
            <div
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "blue",
              }}
              onClick={() => {
                handleBlockInfoButton(row);
              }}>
              {translate("request_view")}
            </div>
          ),
        },
        row,
      );

      return row;
    });
  };

  const fetchRows = () => {
    setLoading(true);
    setRows([]);
    if (tab === 0)
      return fetchAccounts({
        cif, // cif
        customerType, //customer Type
      })
        .then((res) => {
          if (res) {
            setRows(addNewColumns(res));
          }
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    if (tab === 1)
      return fetchDepositAccounts({
        cif, // cif
        accountType: "Depozit Hesabı",
      })
        .then((res) => {
          if (res) setRows(addNewColumns(res));
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
  };

  const fetchAsyncTransactionsByAccount = async (account) => {
    setLoading(true);
    let result = await fetchTransactionsByAccount({
      account,
      startDate: moment(new Date(fromDate)).format("DD.MM.YYYY"),
      endDate: moment(new Date(toDate)).format("DD.MM.YYYY"),
    });
    return result;
  };

  useEffect(() => {
    fetchRows();
  }, [tab]);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <AccountTable rows={rows} fetchRows={fetchRows} />;
      case 1:
        return <DepositTable rows={rows} setLoading={setLoading} />;
      default:
        return <div>{translate("request_deposit_account")}</div>;
    }
  };

  return (
    <Container>
      <FormContainer>
        <DateControl required>
          <FormDateTimePicker
            handleChange={setFromDate}
            value={fromDate}
            label={translate("from_date")}
            auto
            ampm={false}
            variant='inline'
            orientation='landscape'
            openTo='minutes'
          />
        </DateControl>
        <DateControl required>
          <FormDateTimePicker
            inputVariant='standard'
            handleChange={setToDate}
            value={toDate}
            label={translate("to_date")}
            ampm={false}
          />
        </DateControl>
      </FormContainer>
      <TabContainer>
        <Tabs>
          {tabs.map((t, index) => (
            <Tab
              className={tab === index ? "active" : ""}
              onClick={() => {
                setTab(index);
              }}
              key={index}>
              {t}
            </Tab>
          ))}
        </Tabs>
      </TabContainer>
      {renderComponent()}
      {clickedBlockInfo && (
        <BlockInfo
          blockInf={blockInfo}
          setBlockInfo={setBlockInfo}
          clickedBlockInfo={clickedBlockInfo}
          setClickedBlockInfo={setClickedBlockInfo}
        />
      )}
    </Container>
  );
};

export default AccountTab;

const Container = styled("div")({});

const TabContainer = styled("div")({
  width: "100%",
  margin: "0 auto",
  borderBottom: "1px solid #DCDDE6",
  display: "flex",
  marginBottom: 15,
});

const Tabs = styled("div")({
  width: "100%",
  minWidth: "100%",
  maxWidth: "100%",
});

const Tab = styled("div")({
  display: "inline-block",
  cursor: "pointer",
  color: "#81808A",
  fontWeight: 600,
  fontSize: 12,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  padding: "10px 10px 10px 0",
  float: "left",
  textAlign: "left",
  marginRight: 15,
  "&.active": {
    color: "#020110",
  },
});

const FormContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const DateControl = styled(FormControl)({ width: 160, marginRight: 5 });
