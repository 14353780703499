/* eslint-disable eqeqeq */
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { styled } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import FormElement from "./FormElement";
import FormFieldWrapper from "./FormFieldWrapper";

interface IFormDatePicker {
  id?: string;
  value?: Date;
  regex?: string;
  required?: boolean;
  errorText?: string;
  handleValidation?: Function;
  checkValidation?: boolean;
  handleChange: Function;
  [x: string]: any;
}

function FormDatePicker(props: IFormDatePicker) {
  const handleChange = (fulldate) => {
    props.setValue(fulldate);
    props.handleChange(fulldate, props.id, props.label);
  };

  let formattedValue = props.value ?? "";
  if (formattedValue) {
    if (typeof formattedValue === "string" && formattedValue != "")
      formattedValue = new Date(
        formattedValue.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"),
      );
  } else formattedValue = null;

  return (
    <FormFieldWrapper>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <StyledKeyboardDatePicker
          autoOk
          error={props.error}
          size='small'
          variant='inline'
          inputVariant='outlined'
          format='dd-MM-yyyy'
          margin='normal'
          label={props.label}
          value={formattedValue}
          onChange={(fulldate) => handleChange(fulldate)}
          InputLabelProps={{
            shrink: true,
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </FormFieldWrapper>
  );
}

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)({
  width: "100%",
  marginBottom: "0",
  marginTop: "0",
  "& input:focus": {
    boxShadow: "0 0 1px 1px white !important",
  },
});

export default FormElement(FormDatePicker);
