import i18n from "i18n/config";
import { toast } from "react-toastify";

class Notification {
  error = (message: string, timeout = 5000): void => {
    toast.error(message ? message : i18n.t("error"), { autoClose: timeout });
  };

  warning = (message: string, timeout = 5000): void => {
    toast.warning(message, { autoClose: timeout });
  };

  success = (message: string, timeout = 5000): void => {
    toast.success(message, { autoClose: timeout });
  };

  info = (message: string, timeout = 5000): void => {
    toast.info(message, { autoClose: timeout });
  };

  custom = (message, onOpen = () => {}, onClose = () => {}): void => {
    toast.info(message, {
      onOpen: () => onOpen(),
      onClose: () => onClose(),
    });
  };

  default = (message: string, timeout = 5000): void => {
    toast(message, { autoClose: timeout });
  };
}

export default new Notification();
