import { createStyles, makeStyles, styled } from "@material-ui/core/styles";
import React from "react";
import { useDispatch, RootStateOrAny } from "react-redux";
import { useSelector } from "react-redux";

import ChatBoxStore from "Store/Actions/ChatBox";

import {
  AccessTimeIcon,
  DoneIcon,
  DoneAllIcon,
  ErrorOutlineIcon,
  ExpandMoreIcon,
} from "./icons";

interface IMessageText {
  status: string;
  owner: string;
  text: string;
  fullName: string | null;
  header: string;
  date: string;
  messageId: number;
  replyMessageContent?: string;
  replyServiceMessageId?: number;
  replyMessageAuthor?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    messageContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      lineHeight: "140%",
      padding: "6px 6px 4px 8px",
      textAlign: "justify",
    },
    messageContent: {
      position: "relative",
      width: "100%",
      "&:hover svg": {
        display: "block !important",
      },
    },
    readStatus: {
      color: "white",
    },
    nullStatus: {
      color: "white",
    },
    icon: {
      fontSize: 12,
      color: "rgba(255, 255, 255, 0.6)",
    },
    expandMoreIcon: {
      display: "none",
      position: "absolute",
      right: 0,
      top: "-5px",
      cursor: "pointer",
      borderRadius: "50%",
      zIndex: 10,
      "&:hover": {
        zoom: "1.05",
      },
    },
    expandMoreIconAgent: {
      background: "rgba(255,255,255,0.9)",
      color: "rgb(0,178,135)",
    },
    expandMoreIconOwner: {
      background: "rgba(0,0,0,0.4)",
      color: "#FFFFFF",
    },
  }),
);

export default function MessageText({ ...props }: IMessageText) {
  const classes = useStyles();
  let backgroundColor = null;
  if (props.owner === "client") backgroundColor = "white";
  else backgroundColor = "#00B287";
  const chatBox = new ChatBoxStore(useDispatch());
  const chatBoxState = useSelector(
    (state: RootStateOrAny) => state.chatReducer,
  );
  const activeConversation =
    chatBoxState.conversations[chatBoxState.activeConversationId];

  const StyledMessageText = styled("div")({
    backgroundColor,
    wordBreak: "break-word",
    maxWidth: 350,
    padding: 7,
    float: props.owner === "client" ? "left" : "right",
    color: props.owner === "client" ? "rgba(5, 0, 56, 1)" : "white",
    borderRadius:
      props.owner === "client" ? "12px 12px 12px 2px" : "12px 12px 2px 12px",
    marginTop: 5,
    fontSize: 13,
    lineHeight: "160%",
    letterSpacing: "0.02em",
    textAlign: "left",
  });

  const replyMessageContent =
    props.replyMessageContent && props.replyMessageContent.length > 160
      ? props.replyMessageContent.slice(0, 159) + "..."
      : props.replyMessageContent;

  const Date = styled("span")({
    fontSize: "12px",
    lineHeight: "16.8px",
    marginRight: "6px",
    marginLeft: props.owner === "client" ? 25 : 0,
    color:
      props.owner === "client"
        ? "rgba(5, 0, 56, 1)"
        : "rgba(255, 255, 255, 0.6)",
  });

  let icon = null;

  switch (props.status) {
    case null:
      icon = (
        <AccessTimeIcon className={`${classes.nullStatus} ${classes.icon}`} />
      );
      break;
    case "UNDEFINED":
      icon = <DoneIcon className={classes.icon} />;
      break;
    case "DELIVERY":
      icon = <DoneAllIcon className={classes.icon} />;
      break;
    case "READ":
      icon = (
        <DoneAllIcon className={`${classes.readStatus} ${classes.icon}`} />
      );
      break;
    case "EXPIRED":
      icon = (
        <ErrorOutlineIcon className={`${classes.nullStatus} ${classes.icon}`} />
      );
      break;
    default:
      break;
  }

  return (
    <StyledMessageText>
      <div className={classes.messageContainer}>
        {props.replyServiceMessageId && (
          <MessageHeader>
            <ReplyMessageOwner>
              {props.replyMessageAuthor ?? props.owner}
            </ReplyMessageOwner>
            <ReplyMessage>{replyMessageContent}</ReplyMessage>
          </MessageHeader>
        )}
        <span className={classes.messageContent}>
          {props.text}
          {activeConversation && !activeConversation.editMode && (
            <ExpandMoreIcon
              onClick={() => {
                chatBox.setReplyMessageView(true);
                chatBox.setReplyMessageData(props);
              }}
              className={`${classes.expandMoreIcon}
            ${
              props.owner === "agent"
                ? classes.expandMoreIconAgent
                : classes.expandMoreIconOwner
            }`}
            />
          )}
        </span>
      </div>
      <MessageFooter>
        {props.owner === "agent" && <Owner>{props.fullName}</Owner>}
        <Date>{props.date}</Date>
        <span>{props.owner !== "client" && icon}</span>
      </MessageFooter>
    </StyledMessageText>
  );
}

const Owner = styled("span")({
  fontSize: "12px",
  marginRight: "8px",
  color: "rgba(255, 255, 255, 0.6)",
});

const MessageHeader = styled("div")({
  float: "right",
  display: "inline-block",
  lineHeight: "140%",
  marginBottom: "8px",
  paddingLeft: "10px",
  borderLeft: "2px solid black",
  width: "100%",
});

const ReplyMessageOwner = styled("div")({
  fontSize: "12px",
  marginRight: "8px",
  marginTop: 0,
  display: "blok",
});

const ReplyMessage = styled("div")({
  fontSize: "12px",
  marginRight: "8px",
});

const MessageFooter = styled("div")({
  float: "right",
  display: "inline-block",
  lineHeight: "140%",
});
