import React from "react";

import Table from "Components/Table/Table";

interface IInformationTable {
  rows: any[];
  columns: any[];
  fetchRows: any;
  handleRowClick?: any;
}

const InformationTable = ({
  rows,
  columns,
  fetchRows,
  handleRowClick = () => {},
}: IInformationTable) => (
  <Table
    hiddenHeader={true}
    hiddenPagination={true}
    searchFieldShow={false}
    additionalButtons={false}
    canExportData={false}
    fetchRows={fetchRows}
    columns={columns}
    rows={rows}
    handleRowClick={handleRowClick}
  />
);

export default InformationTable;
