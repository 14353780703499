import i18n from "i18n/config";

export const customerColumns = [
  { name: "fullName", alias: i18n.t("request_fullname"), sortable: false },
  {
    name: "dateOfBirth",
    alias: i18n.t("request_date_of_birth"),
    sortable: false,
  },
  { name: "email", alias: i18n.t("request_email"), sortable: false },
  {
    name: "phoneNumber",
    alias: i18n.t("request_phone_number"),
    sortable: false,
  },
  { name: "passport", alias: i18n.t("request_passport"), sortable: false },
  { name: "secretWord", alias: i18n.t("request_secret_word"), sortable: false },
  { name: "cif", alias: i18n.t("request_CIF"), sortable: false },
  { name: "fin", alias: i18n.t("request_FIN"), sortable: false },
  { name: "voen", alias: i18n.t("request_VOEN"), sortable: false },
  { name: "address", alias: i18n.t("request_address"), sortable: false },
  { name: "status", alias: i18n.t("request_status"), sortable: false },
  {
    name: "customerType",
    alias: i18n.t("request_customer_type"),
    sortable: false,
  },
];
