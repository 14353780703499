import { useCallback } from "react";
import menuTypes from "Assets/constants/menuTypes";
import { smsActions as actionTypes } from "../Actions/actionTypes";

export default class Sms {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setAllTemplates = useCallback(
    (menuType: menuTypes, requestId: number, templates: Array<Object>) =>
      this.dispatch({
        type: actionTypes.SET_ALL_TEMPLATES,
        menuType,
        requestId,
        templates,
      }),
    [this],
  );

  setPagination = useCallback(
    (menuType: menuTypes, requestId: number, pagination: any) =>
      this.dispatch({
        type: actionTypes.SET_PAGINATION,
        menuType,
        requestId,
        pagination,
      }),
    [this],
  );

  setChosenTemplate = useCallback(
    (
      menuType: menuTypes,
      requestId: number,
      chosenTemplate: any,
      chosenId: number,
    ) =>
      this.dispatch({
        type: actionTypes.SET_CHOSEN_TEMPLATE,
        menuType,
        requestId,
        chosenTemplate,
        chosenId,
      }),
    [this],
  );

  setIsSearching = useCallback(
    (menuType: menuTypes, requestId: number, isSearching: boolean) =>
      this.dispatch({
        type: actionTypes.SET_SEARCHING,
        menuType,
        requestId,
        isSearching,
      }),
    [this],
  );

  setSearch = useCallback(
    (menuType: menuTypes, requestId: number, search: string) =>
      this.dispatch({
        type: actionTypes.SET_SEARCH,
        menuType,
        requestId,
        search,
      }),
    [this],
  );

  setOnSearch = useCallback(
    (menuType: menuTypes, requestId: number, onSearch: boolean) =>
      this.dispatch({
        type: actionTypes.SET_ON_SEARCH,
        menuType,
        requestId,
        onSearch,
      }),
    [this],
  );

  setSearchString = useCallback(
    (menuType: menuTypes, requestId: number, searchString: string) =>
      this.dispatch({
        type: actionTypes.SET_SEARCH_STRING,
        menuType,
        requestId,
        searchString,
      }),
    [this],
  );
}
