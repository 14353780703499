enum fieldTypes {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  LARGE_TEXT = "LARGE_TEXT",
  SELECT = "SELECT",
  DATE = "DATE",
  DATETIME = "DATETIME",
  TIME = "TIME",
  CHECKBOX = "CHECKBOX",
  RADIO = "RADIO",

  TEXT_RANGE = "TEXT_RANGE",
  NUMBER_RANGE = "NUMBER_RANGE",
  DATE_RANGE = "DATE_RANGE",
  TIME_RANGE = "TIME_RANGE",
  DATETIME_RANGE = "DATETIME_RANGE",

  // Advanced tags
  FREE_TEXT = "FREE_TEXT",
  RADIO_BUTTON = "RADIO_BUTTON",
  DROPDOWN = "DROPDOWN",
}

export default fieldTypes;
