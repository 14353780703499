import Excel from "Assets/images/icons/excel.png";
import File from "Assets/images/icons/file.png";
import Img from "Assets/images/icons/img.png";
import Pdf from "Assets/images/icons/pdf.png";
import Word from "Assets/images/icons/word.png";

import { buttonTypes } from "Components/Buttons/buttonTypes";
import FileTypes from "./fileTypes";

const filesData: any = {
  [FileTypes.PDF]: {
    icon: Pdf,
    buttonType: buttonTypes.danger,
  },
  [FileTypes.JPG]: {
    icon: Img,
    buttonType: buttonTypes.success,
  },
  [FileTypes.JPEG]: {
    icon: Img,
    buttonType: buttonTypes.success,
  },
  [FileTypes.PNG]: {
    icon: Img,
    buttonType: buttonTypes.success,
  },
  [FileTypes.JFIF]: {
    icon: Img,
    buttonType: buttonTypes.success,
  },
  [FileTypes.DOC]: {
    icon: Word,
    buttonType: buttonTypes.primary,
  },
  [FileTypes.DOCX]: {
    icon: Word,
    buttonType: buttonTypes.primary,
  },
  [FileTypes.XLS]: {
    icon: Excel,
    buttonType: buttonTypes.success,
  },
  [FileTypes.XLSX]: {
    icon: Excel,
    buttonType: buttonTypes.success,
  },
  [FileTypes.UNDEFINED]: {
    icon: File,
    buttonType: buttonTypes.warning,
  },
};

export default filesData;
