import { notificationActions as actionTypes } from "../Actions/actionTypes";

const initialState = {
  notifications: [],
};

const enqueue = (state, action) => ({
  ...state,
  notifications: [...state.notifications, action.notification],
});

const dequeue = (state, action) => ({
  ...state,
  notifications: state.notifications.filter(
    (notification) => notification.key !== action.key,
  ),
});

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENQUEUE:
      return enqueue(state, action);
    case actionTypes.DEQUEUE:
      return dequeue(state, action);
    default:
      return state;
  }
};

export default notificationReducer;
