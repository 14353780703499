import React from "react";
import { styled } from "@material-ui/core/styles";

interface IPanelSwitch {
  isOpen: boolean;
  setIsOpen: Function;
  Icon: any;
}

export const PanelSwitch = ({ isOpen, setIsOpen, Icon }: IPanelSwitch) => {
  const SwitchIcon = styled(Icon)({
    color: "#050038",
    float: isOpen ? "right" : "none",
    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
  });

  return (
    <MenuItem onClick={() => setIsOpen()}>
      <IconWrapper>
        <SwitchIcon />
      </IconWrapper>
    </MenuItem>
  );
};

PanelSwitch.displayName = "PanelSwitch";

const IconWrapper = styled("div")({
  paddingLeft: 20,
  paddingTop: "15px",
  cursor: "pointer",
});

const MenuItem = styled("div")({
  width: "100%",
  height: 50,
  position: "absolute",
  bottom: 15,
  right: 10,
  cursor: "pointer",
});
