/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable eqeqeq */
import {
  Avatar,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import {
  fetchIntegrationSearch,
  uploadAttachments,
  downloadAttachment,
} from "Api/crm";

import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { AddIcon, DeleteForeverOutlinedIcon } from "Assets/icons";

import CustomerIntegrationSearchPanel from "Components/Crm/CustomerSearchPanel/CustomerIntegrationSearchPanel";
import LoadingComponent from "Components/Loading/LoadingComponent";
import { customerTypes } from "Components/Request/LeftPanel/constants/customerTypes";
import { styles } from "Components/styles";

import { permissions } from "Config/config";
import Notification from "Notifications/Notification";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { default as CrmActions } from "Store/Actions/Crm";
import { convertImagetoBase64, permit } from "Utils/Utils";

import IbarIntegrationSearchPanel from "./IbarIntegrationSearch/IbarIntegrationSearchPanel";

interface ICustomerCard {
  editable?: boolean;
  identifiers: any;
  formFields?: any;
  avatar?: any;
  headerText?: string;
  onAvatarLoaded?: Function;
  getCrmField: Function;
  getCrmFieldNameRow?: Function;
  getIdentifierField: Function;
  addNewField?: Function;
  removeLastField?: Function;
  onSaveNewCompany?: Function;
  setCompanies: Function;
  customerId: number;
  companies: any;
  showSearch?: boolean;
  showCompanyBlockAsMain?: boolean;
}

const customerSearchUrl = process.env.REACT_APP_CUSTOMER_SEARCH_URL;

export default function CustomerCard(props: ICustomerCard): React.ReactElement {
  const { translate } = useTranslation();
  const classes = styles();
  const formFields = props.formFields ?? [];
  const identifiers = props.identifiers ?? [];
  const showSearch = props.showSearch ?? true;

  const crm = new CrmActions(useDispatch());
  const [, setProfileImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [integrationSearchHtml, setintegrationSearchHtml] = useState("");
  const [avatarImage, setAvatarImage] = useState(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleResetCustomerData = (): void => {
    crm.setClickReset(Math.floor(Math.random() * 9000000000) + 1000000000);
  };

  const handleIntegrationSearch = async (): Promise<void> => {
    setIsLoading(true);
    const searchFields = {};

    formFields
      .filter((field) => field.customerType === customerTypes.PHYSICAL)
      .forEach((field) => {
        searchFields[field.fieldName] = field.fieldValue;
      });

    const result = await fetchIntegrationSearch(
      customerSearchUrl,
      searchFields,
    );

    setintegrationSearchHtml(result);
    setIsLoading(false);
  };

  const handleImageUpload = (input): void => {
    if (input.files && input.files[0]) {
      const file = input.files[0];

      const reader = new FileReader();
      if (file.size > 2000000) {
        Notification.error(translate("crm_card_max_size"));
        return;
      }
      reader.onload = async function (e) {
        setProfileImage(e.target.result);

        const formdata = new FormData();
        formdata.append("files", file, file.name);
        formdata.append("directory", "crm");
        const result = await uploadAttachments(formdata);
        let path = null;
        if (result) path = result[0].path;

        if (props.onAvatarLoaded) {
          props.onAvatarLoaded(path);
          setAvatarImage(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const dataUrlToFile = async (
    dataUrl: string,
    fileName: string,
  ): Promise<File> => {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  };

  useEffect(() => {
    if (formFields.length === 0 || identifiers.length === 0) setIsLoading(true);
    else setIsLoading(false);
  }, [formFields, identifiers]);

  useEffect(() => {
    (async function () {
      if (props.avatar && !props.avatar.includes("base64"))
        setAvatarImage(
          await convertImagetoBase64(props.avatar, downloadAttachment),
        );
      else if (props.avatar && props.avatar.includes("base64")) {
        setAvatarImage(props.avatar);
        const file = await dataUrlToFile(props.avatar, "avatar.png");

        const formdata = new FormData();
        formdata.append("files", file, file.name);
        formdata.append("directory", "crm");

        const result = await uploadAttachments(formdata);
        let path = null;
        if (result) path = result[0].path;

        if (props.onAvatarLoaded) props.onAvatarLoaded(path);
      } else setAvatarImage(null);
    })();
  }, [props.avatar]);

  const getTableRow = (formField, isIdentifier: boolean): React.ReactNode => {
    let rowKey;
    if (isIdentifier) {
      formField.fieldValue = formField.identifier;
      rowKey = formField.channelType;
    } else rowKey = formField.fieldName + formField.customerType + formField.id;

    return (
      <TableRow className={classes.tableRow} key={rowKey}>
        {formField.custom ? (
          <TableCell className={classes.tableCell} align='left'>
            {props.getCrmField(formField, customerTypes.ALL)}
          </TableCell>
        ) : (
          <TableCell className={classes.tableCell} align='left'>
            {isIdentifier
              ? props.getIdentifierField(formField)
              : props.getCrmField(formField, customerTypes.ALL)}
          </TableCell>
        )}
      </TableRow>
    );
  };

  const getNameRow = (formField): React.ReactElement =>
    props.getCrmFieldNameRow &&
    props.getCrmFieldNameRow(formField, customerTypes.ALL);

  const mainCustomerType = props.showCompanyBlockAsMain
    ? customerTypes.LEGAL
    : customerTypes.PHYSICAL;

  const mainBlockFields = formFields
    .filter(
      (formField) =>
        formField.customerType === mainCustomerType &&
        formField.fieldName !== "Name" &&
        formField.fieldName !== "Last Name",
    )
    .map((row) => getTableRow(row, false));

  const nameBlocks = formFields
    .filter(
      (formField) =>
        (formField.fieldName === "Name" ||
          formField.fieldName === "Last Name") &&
        formField.customerType === customerTypes.PHYSICAL,
    )
    .map((row) => getNameRow(row));

  const companyBlocks = formFields
    .filter(
      (formField) =>
        formField.customerType === customerTypes.LEGAL &&
        formField.fieldName !== "Name" &&
        formField.fieldName !== "Last Name",
    )
    .map((row) => getTableRow(row, false));

  const identifierBlocks = identifiers.map((row) => getTableRow(row, true));

  const src = (function () {
    try {
      return avatarImage
        ? avatarImage
        : props.avatar
        ? props.avatar.includes("blob") || props.avatar.includes("data:image/")
          ? props.avatar
          : `data:image/jpeg;base64,${props.avatar}`
        : null;
    } catch (e) {
      return null;
    }
  })();

  return (
    <div className={classes.root}>
      <Buttons>
        {showSearch && (
          <Button onClick={() => crm.showCustomerSearch(true)}>
            {translate("search")}
          </Button>
        )}
        {customerSearchUrl && (
          <Button onClick={handleIntegrationSearch}>
            {translate("crm_card_detail")}
          </Button>
        )}
        {permit(
          <Button onClick={() => setOpen(true)}>
            {translate("crm_card_detail")}
          </Button>,
          permissions.request_ibar_customer_search,
        )}
        <Button onClick={handleResetCustomerData}>{translate("reset")}</Button>
      </Buttons>
      {nameBlocks.length > 0 && (
        <Grid container style={{ marginTop: 10, minHeight: 90, height: 90 }}>
          <Grid item sm={2}>
            <div>
              <Avatar className={classes.avatar} src={src} />
              <input
                accept='image/*'
                onChange={(e) => {
                  handleImageUpload(e.target);
                }}
                id='icon-button-file'
                className={classes.imageInput}
                type='file'
              />
            </div>
          </Grid>
          <Grid item sm={10}>
            {nameBlocks}
          </Grid>
        </Grid>
      )}

      <div>
        {identifierBlocks.length > 0 && (
          <TableContainer className={classes.tableContainer} component={"div"}>
            <Table aria-label='simple table'>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Subtitle>
                      {translate("crm_card_main_information")}
                    </Subtitle>
                  </TableCell>
                </TableRow>
                {identifierBlocks}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {mainBlockFields.length > 0 && (
          <TableContainer className={classes.tableContainer} component={"div"}>
            <Table aria-label='simple table'>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Subtitle>{translate("crm_card_customer")}</Subtitle>
                  </TableCell>
                </TableRow>
                {mainBlockFields}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TableContainer className={classes.tableContainer} component={"div"}>
          <Table aria-label='simple table'>
            <TableBody>
              <TableRow>
                {props.editable ? (
                  <TableCell style={{ paddingTop: 15, paddingBottom: 15 }}>
                    <Grid container>
                      <Grid item xs={6}>
                        <AddFieldButton
                          onClick={() => props.addNewField(mainCustomerType)}>
                          <PlusIcon /> {translate("crm_card_add_custom_field")}
                        </AddFieldButton>
                      </Grid>
                      <Grid item xs={6}>
                        {formFields.length > 0 &&
                          formFields
                            .filter(
                              (field) =>
                                field.customerType === mainCustomerType,
                            )
                            .pop().custom && (
                            <RemoveFieldButton
                              onClick={() =>
                                props.removeLastField(mainCustomerType)
                              }>
                              <DeleteIcon />{" "}
                              {translate("crm_card_remove_field")}
                            </RemoveFieldButton>
                          )}
                      </Grid>
                    </Grid>
                  </TableCell>
                ) : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {companyBlocks.length > 0 && (
          <TableContainer className={classes.tableContainer} component={"div"}>
            <Table aria-label='simple table'>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Subtitle>{translate("crm_card_company")}</Subtitle>
                  </TableCell>
                </TableRow>
                {companyBlocks}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {isLoading ? <LoadingComponent /> : null}

      <CustomerIntegrationSearchPanel
        setHtml={setintegrationSearchHtml}
        html={integrationSearchHtml}
      />

      {permit(
        <IbarIntegrationSearchPanel open={open} setOpen={setOpen} />,
        permissions.request_ibar_customer_search,
      )}
    </div>
  );
}

const Buttons = styled("div")({
  width: "100%",
  paddingTop: 10,
  paddingBottom: 35,
  borderBottom: "1px solid #DCDDE6",
});

const Subtitle = styled("span")({
  display: "block",
  textAlign: "left",
  fontSize: 15,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  color: "#050038",
  marginBottom: 10,
});

const AddFieldButton = styled("div")({
  fontSize: 10,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  color: "#050038",
  cursor: "pointer",
  marginTop: 10,
  textAlign: "left",
});

const RemoveFieldButton = styled("div")({
  fontSize: 10,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  color: "#050038",
  cursor: "pointer",
  marginTop: 10,
  textAlign: "left",
});

const PlusIcon = styled(AddIcon)({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 14,
  color: "#F68F1F",
  position: "relative",
  top: 3,
});

const DeleteIcon = styled(DeleteForeverOutlinedIcon)({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 14,
  color: "rgba(196, 35, 35, 0.8)",
  position: "relative",
  top: 3,
});

const Button = styled("div")({
  width: "26%",
  marginRight: 5,
  display: "inline-block",
  backgroundColor: "#F5F4F7",
  borderRadius: 24,
  padding: 4,
  color: "#81808A",
  fontSize: 10,
  fontWeight: 600,
  letterSpacing: "0.02em",
  lineHeight: "120%",
  textTransform: "uppercase",
  cursor: "pointer",
  float: "left",
});
