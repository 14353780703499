import React, { useEffect, useState } from "react";

import { IInputs } from "../interfaces";

export const useNameInput = ({
  id,
  fieldValue,
  handleChange,
  label,
}: IInputs): any => {
  const [localLabel, setLocalLabel] = useState("");
  const [localValue, setLocalValue] = useState("");

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newInputValue = e.target.value;
    setLocalValue(newInputValue);
  };

  useEffect(() => {
    setLocalLabel(label ? label : "");
    setLocalValue(fieldValue ? fieldValue : "");
  }, []);

  useEffect(() => {
    handleChange(localValue, id, label);
  }, [localValue]);

  return {
    localLabel,
    localValue,
    handleInput,
  };
};

useNameInput.displayName = "useNameInput";
