/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { BlobProvider } from "@react-pdf/renderer";
import { FormControl, styled } from "@material-ui/core";

import { fetchCards, fetchTransactions } from "Api/atbCrmIntegration";

import CardTable from "./CardTable";
import Transactionpdf from "./TransactionPdf";

import FormDateTimePicker from "Components/Form/FormDateTimePicker";
import { roundOffToHour } from "Utils/Utils";
import moment from "moment";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const CardTab = ({ cif, setLoading }) => {
  const { translate } = useTranslation();
  const [rows, setRows] = useState([]);
  const [transactions, setTransactions] = useState<any>({});
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [toDate, setToDate] = useState(roundOffToHour(new Date()));

  useEffect(() => {
    fetchRows();
  }, [cif]);

  useEffect(() => {
    setLoading(true);
    setRows(addNewColumns(rows));
    setLoading(false);
  }, [transactions]);

  useEffect(() => {
    setTransactions({});
  }, [fromDate, toDate]);

  const addNewColumns = (data) => {
    return data.map((row) => {
      row["actions"] = !transactions[row.accountNumber] ? (
        <div
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "blue",
          }}
          onClick={async () => {
            setLoading(true);
            let transaction = await fetchAsyncTransactions(row.accountNumber);
            setTransactions({
              [row.accountNumber]: transaction,
            });
          }}>
          {translate("request_get_transactions")}
        </div>
      ) : (
        <BlobProvider
          document={<Transactionpdf data={transactions[row.accountNumber]} />}>
          {({ url, loading }) => {
            if (loading) return <div>{translate("request_creating_pdf")}</div>;

            setLoading(false);
            return (
              <a href={url} target='_blank' rel='noopener noreferrer'>
                {translate("request_view_transactions")}
              </a>
            );
          }}
        </BlobProvider>
      );

      return row;
    });
  };

  const fetchRows = () => {
    setLoading(true);

    return fetchCards({
      cif, // cif
    })
      .then((res) => {
        if (res) setRows(addNewColumns(res));
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const fetchAsyncTransactions = async (accountNumber) => {
    setLoading(true);
    let result = await fetchTransactions({
      accountNumber,
      startDate: moment(new Date(fromDate)).format("DD.MM.YYYYTHH:MM:SS"),
      endDate: moment(new Date(toDate)).format("DD.MM.YYYYTHH:MM:SS"),
    });
    return result;
  };

  return (
    <Container>
      <FormContainer>
        <DateControl required>
          <FormDateTimePicker
            handleChange={setFromDate}
            value={fromDate}
            label={translate("from_date")}
            auto
            ampm={false}
            variant='inline'
            orientation='landscape'
            openTo='minutes'
          />
        </DateControl>
        <DateControl required>
          <FormDateTimePicker
            inputVariant='standard'
            handleChange={setToDate}
            value={toDate}
            label={translate("to_date")}
            ampm={false}
          />
        </DateControl>
      </FormContainer>

      <CardTable rows={rows} fetchRows={fetchRows} />
    </Container>
  );
};

export default CardTab;

const Container = styled("div")({});

const FormContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const DateControl = styled(FormControl)({ width: 160, marginRight: 5 });
