import React from "react";
import { styled } from "@material-ui/core/styles";
import { useSelector, RootStateOrAny } from "react-redux";
import { colors } from "./constants";
import { PhoneIcon, PauseIcon, CallEndIcon } from "./Icons";
interface ILineButton {
  name: string;
  active?: boolean;
  onClick: any;
  disabled?: boolean;
  isCurrentLine: boolean;
  lineNumber: number;
}

export default function LineButton(props: ILineButton) {
  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );

  let { isCurrentLine, lineNumber } = props;
  const callData = softPhoneState.lines[lineNumber];

  const Line = styled("div")({
    display: "inline-block",
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "160%",
    letterSpacing: "0.04em",
    color: callData?.isOnHold
      ? colors.hold
      : callData?.isMuted
      ? colors.mute
      : isCurrentLine
      ? colors.active
      : colors.default,
  });

  return (
    <LineButtonContainer onClick={props.onClick}>
      {callData?.isOnHold ? (
        <LineOnHoldIcon />
      ) : isCurrentLine ? (
        <ActiveLineIcon />
      ) : (
        <DefaultLineIcon />
      )}
      <Line>{props.name}</Line>
    </LineButtonContainer>
  );
}

const LineButtonContainer = styled("div")({
  display: "inline-block",
  cursor: "pointer",
});

const ActiveLineIcon = styled(PhoneIcon)({
  color: colors.active,
  fontSize: 14,
  marginRight: 6,
  letterSpacing: "0.04em",
});

const LineOnHoldIcon = styled(PauseIcon)({
  color: colors.hold,
  fontSize: 14,
  marginRight: 6,
  letterSpacing: "0.04em",
});

const DefaultLineIcon = styled(CallEndIcon)({
  color: colors.default,
  fontSize: 14,
  marginRight: 6,
  letterSpacing: "0.04em",
});
