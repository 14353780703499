import sessionStatuses from "Assets/constants/sessionStatuses";
import { authActions as actionTypes } from "../Actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  sessionStatus: sessionStatuses.start,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SESSION_STATUS:
      return updateObject(state, {
        sessionStatus: action.status,
      });
    default:
      return state;
  }
};

export default authReducer;
