import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { FormControlLabel, Checkbox } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: "100%",
      display: "inherit",
    },
    checkbox: {},
  }),
);

const FormCheckbox = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(props.value ? props.value[0] : false);

  const handleChange = (_event, value) => {
    setValue(value);
    props.handleChange(value, props.id, props.label);
  };

  return (
    <FormControl variant='filled' className={classes.formControl}>
      <FormControlLabel
        classes={{ label: classes.checkbox }}
        value={value}
        control={<Checkbox checked={value} onChange={handleChange} />}
        label={props.label}
        labelPlacement='start'
      />
    </FormControl>
  );
};

export default FormCheckbox;
