import { styled } from "@material-ui/core/styles";

import { PersonIcon } from "Assets/icons";
import React from "react";

import { tabs } from "../constants";

export const useLeftPanelTabs = (): any => {
  const styleTheIcon = (Icon): React.ReactNode =>
    styled(Icon)({
      color: "#81808A",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 17,
      position: "relative",
      top: 3,
      marginRight: 6,
    });

  const chooseTabIcon = (tab): React.ReactNode => {
    let icon = null,
      ResultIcon = null;

    switch (tab) {
      case tabs.CRM:
        ResultIcon = styleTheIcon(PersonIcon);
        icon = <ResultIcon />;
        break;
      default:
        icon = <div></div>;
    }

    return icon;
  };

  return {
    chooseTabIcon,
  };
};

useLeftPanelTabs.displayName = "useLeftPanelTabs";
