import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import LoadingComponent from "Components/Loading/LoadingComponent";
import React from "react";
import { conversion } from "Utils/Utils";

import AudioPlayerController from "./AudioPlayerController";
import { useTextBox } from "./hooks";
import {
  MicNoneIcon,
  SendIcon,
  AttachFileOutlinedIcon,
  StopIcon,
  DeleteIcon,
  ReplayIcon,
} from "./icons";

/**
 * Sends message on send button click and on enter pressed, if user presses shift + enter cursor goes to the next line
 */

const TextBox = (): React.ReactElement => {
  const {
    classes,
    isLoading,
    copyIconOnclick,
    copyAttachments,
    loading,
    isRecording,
    canBeDeleted,
    timerSeconds,
    blobURL,
    message,
    isVoiceIcon,
    voiceIconRef,
    handleVoiceClose,
    stopRecording,
    convertFileToIcon,
    sendAttachmentMessage,
    translate,
    setTextBoxMessage,
    handleKeyDown,
    handleVoiceClick,
    sendVoiceMessage,
    sendMessage,
  } = useTextBox();

  return (
    <Grid container className={classes.textBoxContainer}>
      {isLoading ? <LoadingComponent /> : null}
      {copyIconOnclick && copyAttachments.length > 0 && (
        <CopyAttachmentStyle className={classes.attachment}>
          {copyAttachments.map((item, index) => {
            return convertFileToIcon(item.originalFileName, item.path, index);
          })}
        </CopyAttachmentStyle>
      )}
      <Grid item xs={1}>
        <AttachmentUpload>
          {loading ? (
            <ReplayIcon className={classes.spinning} />
          ) : (
            <>
              <label htmlFor='icon-button-file'>
                <AttachmentButton />
              </label>
              <UploadInput
                onChange={(e) => {
                  sendAttachmentMessage(e.target);
                }}
                id='icon-button-file'
                type='file'
              />
            </>
          )}
        </AttachmentUpload>
      </Grid>
      <Grid item xs={8}>
        {isRecording ? (
          !canBeDeleted ? (
            <Recorder>
              <Timer>{conversion(timerSeconds)}</Timer>
              <RedDot className={classes.blinking} />
              <DottedLine />
            </Recorder>
          ) : (
            blobURL && <AudioPlayerController src={blobURL} />
          )
        ) : (
          <TextBoxInput
            placeholder={translate("chat_box_type_message")}
            value={message}
            onInput={setTextBoxMessage}
            onKeyDown={handleKeyDown}
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {isVoiceIcon &&
          (isRecording ? (
            canBeDeleted ? (
              <DeleteButtonContainer onClick={handleVoiceClose}>
                <DeleteButton />
              </DeleteButtonContainer>
            ) : (
              <StopVoiceButtonContainer onClick={stopRecording}>
                <StopVoiceButton />
              </StopVoiceButtonContainer>
            )
          ) : (
            <VoiceButtonContainer>
              <VoiceButton ref={voiceIconRef} onClick={handleVoiceClick} />
            </VoiceButtonContainer>
          ))}
        <SendButtonContainer
          style={{
            backgroundColor:
              isRecording && !canBeDeleted ? "#EEEFF3" : "#2AA5DE",
          }}>
          <SendButton
            onClick={() => {
              if (!(isRecording && !canBeDeleted)) {
                if (isRecording) sendVoiceMessage();
                else sendMessage();
              } else alert(translate("chat_box_finish_record"));
            }}
          />
        </SendButtonContainer>
      </Grid>
    </Grid>
  );
};

export default TextBox;

TextBox.displayName = "TextBox";

const SendButton = styled(SendIcon)({
  color: "white",
  transform: "rotate(315deg)",
  fontSize: "16px",
  lineHeight: "19px",
  letterSpacing: "0.04em",
  width: "24px",
  height: "24px",
  margin: "6px auto",
  cursor: "pointer",
});

const VoiceButton = styled(MicNoneIcon)({
  cursor: "pointer",
  fontSize: "16px",
  lineHeight: "19px",
  letterSpacing: "0.04em",
  color: "#414147",
  marginTop: "6px",
  width: "20px",
  height: "20px",
});

const VoiceButtonContainer = styled("div")({
  width: "35px",
  height: "35px",
  display: "inline-block",
  position: "absolute",
  right: "55px",
  top: "calc(50% - 20px)",
  cursor: "pointer",
  marginTop: "5px",
});

const StopVoiceButtonContainer = styled("div")({
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: "#DC0236",
  display: "inline-block",
  marginRight: "20px",
  marginTop: "7px",
  fontSize: "18px",
  lineHeight: "21px",
  letterSpacing: "0.04em",
});

const StopVoiceButton = styled(StopIcon)({
  cursor: "pointer",
  color: "white",
  width: "15px",
  height: "15px",
  marginRight: "1px",
  marginTop: "4px",
});

const DeleteButtonContainer = styled("div")({
  width: "24px",
  height: "24px",
  display: "inline-block",
  marginRight: "20px",
  marginTop: "7px",
  fontSize: "18px",
  lineHeight: "21px",
  letterSpacing: "0.04em",
});

const DeleteButton = styled(DeleteIcon)({
  cursor: "pointer",
  color: "#DC0236",
  width: "24px",
  height: "24px",
});

const Recorder = styled("div")({
  display: "flex",
  marginTop: "10px",
});

const Timer = styled("span")({
  fontSize: "14px",
  letterSpacing: "0.03em",
  color: "#020110",
  marginBottom: "3px",
});

const DottedLine = styled("hr")({
  display: "inline-block",
  border: "none",
  borderTop: "2px dashed rgba(65, 65, 71, 0.4)",
  color: "white",
  backgroundColor: "inherit",
  height: "1px",
  width: "300px",
});

const RedDot = styled("span")({
  height: "10px",
  width: "10px",
  backgroundColor: "#DC0236",
  border: "1px solid #DC0236",
  borderRadius: "50%",
  display: "inline-block",
  margin: "0 12px",
  marginTop: "2px",
});

const AttachmentButton = styled(AttachFileOutlinedIcon)({
  cursor: "pointer",
  color: "#414147",
  transform: "rotate(45deg)",
  fontSize: "16px",
  lineHeight: "19px",
  letterSpacing: "0.04em",
  width: "24px",
  height: "24px",
});

const UploadInput = styled("input")({
  opacity: 0,
  display: "none",
});

const AttachmentUpload = styled("div")({
  position: "absolute",
  left: "8px",
  top: "calc(50% - 20px)",
  width: "40px",
  height: "40px",
  cursor: "pointer",
  display: "inline-block",
  marginTop: "5px",
});

const TextBoxInput = styled("textarea")({
  color: "#020110",
  fontSize: "14px",
  lineHeight: "160%",
  letterSpacing: "0.02em",
  outline: "none",
  textAlign: "left",
  overflowY: "auto",
  backgroundColor: "white",
  display: "inline-block",
  width: "100%",
  minHeight: "30px",
  maxHeight: "90px",
  cursor: "text",
  border: "none",
  resize: "none",
  "&:empty:before": {
    color: "#9F9DAD",
    fontSize: "14px",
    lineHeight: "160%",
    letterSpacing: "0.02em",
    fontStyle: "normal",
  },
});

const CopyAttachmentStyle = styled("div")({
  backgroundColor: "#fff8e1",
  borderRadius: "13px",
  margin: "7px",
  marginTop: "-60px",
  padding: "10px",
  outline: "none",
  maxHeight: "35px",
  overflowY: "scroll",
});

const SendButtonContainer = styled("div")({
  position: "absolute",
  right: "8px",
  top: "calc(50% - 20px)",
  display: "inline-block",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  marginLeft: "10px",
});
