import { IFormDatePicker } from "../interfaces";

export const useDateInput = (props: IFormDatePicker): any => {
  let formattedValue = props.value ?? "";
  if (formattedValue) {
    if (typeof formattedValue === "string" && formattedValue !== "")
      formattedValue = new Date(
        formattedValue.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"),
      );
  } else formattedValue = null;

  const handleChange = (fulldate): void => {
    props.setValue(fulldate);
    props.handleChange(fulldate, props.id, props.label);
  };

  return {
    formattedValue,
    handleChange,
  };
};

useDateInput.displayName = "useDateInput";
