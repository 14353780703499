import { Link, LinkProps } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { buttonTypes } from "./buttonTypes";
import { commonStyle } from "./commonStyle";

export interface ILinkButton extends LinkProps {
  buttonType: buttonTypes;
  active?: boolean;
}

export default function LinkButton(props: ILinkButton) {
  const classes = commonStyle(props);
  const { buttonType, active, ...rest } = props;
  const button = (
    <Link {...rest} className={clsx(classes.link, rest.className)}>
      {props.children}
    </Link>
  );
  return button;
}
