/* eslint-disable eqeqeq */
import Chip from "@material-ui/core/Chip";
import { styled, createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { removeTaskFromRequest } from "Api/request";
import { deleteTaskById, downloadAttachment } from "Api/task";
import menuTypes from "Assets/constants/menuTypes";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { buttonTypes } from "Components/Buttons/buttonTypes";
import IconButton from "Components/Buttons/IconButton";
import { RequestContextStore } from "Components/Layout/AppLayout";
import FileTypes from "Pages/FaqGenerator/fileTypes";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import Cch from "Store/Actions/Cch";
import { defaultStates as defaultCchStates } from "Store/Reducers/cchReducer";

import { downloadFile } from "Utils/Utils";

import filesData from "Pages/FaqGenerator/fileTypesData";
import LoadingComponent from "Components/Loading/LoadingComponent";

const useStyles = makeStyles(() =>
  createStyles({
    attachment: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: 10,
    },
    button: { margin: 5 },
    img: { width: 18 },
  }),
);

const SavedCch = () => {
  const { translate } = useTranslation();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const totalCchState = useSelector(
    (state: RootStateOrAny) => state.cchReducer,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const chatBoxState = useSelector(
    (state: RootStateOrAny) => state.chatReducer,
  );
  const callState = useSelector((state: RootStateOrAny) => state.callReducer);
  const cch = new Cch(useDispatch());

  const requestContextStore: any = useContext(RequestContextStore);
  const cchState = requestContextStore.getCurrentStateFromTotalState(
    totalCchState,
    defaultCchStates,
  );

  const handleClose = async (task: any) => {
    setIsLoading(true);
    deleteTaskById(task.id)
      .then((res) => {
        setIsLoading(false);
        if (res)
          cch.removeSavedCch(
            requestState.menuType,
            requestContextStore.getRequestId(),
            task.id,
          );
        else throw new Error();
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });

    const activeConversation =
      chatBoxState.conversations[chatBoxState.activeConversationId];
    let requestId = null;
    if (requestState.menuType === menuTypes.CHAT && activeConversation)
      requestId = activeConversation.requestId;
    else if (
      requestState.menuType === menuTypes.CALL &&
      callState.activeCallId != 0
    )
      requestId = callState.activeCallId;

    if (requestId) return removeTaskFromRequest(requestId, task.id);
  };

  const handleFileClick = async (path, name) => {
    const type = FileTypes[path.split(".")[1].toUpperCase()];
    const response = await downloadAttachment(path);
    const data = await response.blob();
    await downloadFile(data, name, type);
  };

  const getIconType = (
    name: string,
    index: number,
    file: any,
    buttonType: buttonTypes,
    icon: any,
  ) => {
    return (
      <div
        onClick={() => handleFileClick(file.path, file.originalFileName)}
        key={index}>
        <IconButton
          className={classes.button}
          buttonType={buttonType}
          tooltipText={name}
          Icon={() => <img src={icon} alt={name} className={classes.img} />}
        />
      </div>
    );
  };

  const convertFileToIcon = (item, index, file) => {
    const fileType = FileTypes[item.split(".")[1].toUpperCase()];

    return getIconType(
      item,
      index,
      file,
      filesData[fileType].buttonType,
      filesData[fileType].icon,
    );
  };

  return (
    <StyledSavedCch>
      <Label>{translate("request_saved_tasks")}</Label>
      {cchState.allSavedCchs.map((saved) => (
        <>
          <Icon onClick={() => handleClose(saved)} />
          <SavedCchBox key={saved.id}>
            {(saved?.customer?.fullName ?? saved.agentName) && (
              <BoxItem>
                {translate("request_agent_name")}:{" "}
                {saved.agentName ?? saved.customer.fullName}
              </BoxItem>
            )}
            {saved.categoryName && (
              <BoxItem>
                {translate("request_category")}: {saved.categoryName}
              </BoxItem>
            )}
            {saved.callbackNumber && (
              <BoxItem>
                {translate("request_callback_number")}: {saved.callbackNumber}
              </BoxItem>
            )}
            {saved.globalTagValues.length > 0 && (
              <BoxItem>
                {translate("request_tags")}:{" "}
                {saved.globalTagValues.map((tag, index) => (
                  <Chip
                    key={`${tag}_${index}`}
                    label={tag.value ?? tag}
                    color='default'
                    variant='outlined'
                    size='small'
                    style={{ marginLeft: 15 }}
                  />
                ))}
              </BoxItem>
            )}
            {saved.owners.length > 0 && (
              <BoxItem>
                {translate("request_owners")}:{" "}
                {saved.owners.map((owner, index) => (
                  <span key={`${owner}_${index}`}>
                    {owner.fullName ?? owner}
                    {index !== saved.owners.length - 1 && ", "}
                  </span>
                ))}
              </BoxItem>
            )}
            {saved.attachments.length > 0 && (
              <BoxItem>
                {translate("request_attachments")}:{" "}
                <div style={{ display: "inline-block" }}>
                  <span className={classes.attachment}>
                    {saved.attachments.map((item, index) => {
                      let fileName = item.originalFileName;
                      if (item instanceof File) fileName = item.name;

                      return convertFileToIcon(fileName, index, item);
                    })}
                  </span>
                </div>
              </BoxItem>
            )}
            {saved.content && (
              <BoxItem>
                {translate("request_content")}: {saved.content}
              </BoxItem>
            )}
          </SavedCchBox>
        </>
      ))}
      {isLoading ? <LoadingComponent /> : null}
    </StyledSavedCch>
  );
};

export default SavedCch;

const StyledSavedCch = styled("div")({
  color: "#414147",
  fontSize: 13,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textAlign: "left",
  width: "95%",
  margin: "0 auto",
  marginTop: 10,
});

const SavedCchBox = styled("ul")({
  padding: 0,
  borderBottom: "1px solid  rgba(224, 224, 224, 1)",
});

const BoxItem = styled("li")({ listStyle: "none", marginBottom: 5 });

const Label = styled("span")({
  color: "#414147",
  fontSize: 14,
  fontWeight: 600,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  textAlign: "left",
  marginTop: 5,
});

const Icon = styled(CloseIcon)({
  cursor: "pointer",
  fontSize: 14,
  lineHeight: 17,
  color: "#414147",
  position: "relative",
  top: 3,
  right: 0,
  float: "right",
  "&:hover": {
    color: "#050038",
  },
});
