import { styled } from "@material-ui/core/styles";
import React from "react";

import { useNameInput } from "./hooks";
import { IInputs } from "./interfaces";

const NameInput = ({
  id,
  fieldValue,
  handleChange,
  label,
}: IInputs): React.ReactElement => {
  const { localLabel, localValue, handleInput } = useNameInput({
    id,
    fieldValue,
    handleChange,
    label,
  });

  return (
    <Container>
      <Label htmlFor={localLabel}>{localLabel}</Label>
      <Input
        id={localLabel}
        type='text'
        value={localValue}
        onChange={handleInput}
      />
    </Container>
  );
};

export default NameInput;

NameInput.displayName = "NameInput";

const Container = styled("div")({
  display: "inline-block",
  maxWidth: "35%",
  minWidth: "20%",
  marginRight: 10,
  marginLeft: 15,
});

const Input = styled("input")({
  width: "100%",
  color: "#020110",
  fontSize: 13,
  lineHeight: "100%",
  letterSpacing: "0.02em",
  border: "none",
  outline: "none",
  borderBottom: "1px solid #DCDDE6",
});

const Label = styled("label")({
  color: "#414147",
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  display: "block",
  textAlign: "left",
});
