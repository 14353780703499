export const callActionTypes = {
  MAKE_CALL: "MAKE_CALL",
  ADD_CALL_TO_EDIT: "ADD_CALL_TO_EDIT",
  REMOVE_CALL_FROM_EDIT: "REMOVE_CALL_FROM_EDIT",
  REMOVE_CALL_BY_ID: "REMOVE_CALL_BY_ID",
  SET_ACTIVE_CALL_ID: "SET_ACTIVE_CALL_ID",
  SET_ACW_BREAK_ID: "ACW_BREAK_ID",
  SET_AUTO_ACW: "IS_ACW_BREAK",
  SET_OUT_CALL_ANIMATION: "SET_OUT_CALL_ANIMATION",
  SET_RANDOM_NUMBER: "SET_RANDOM_NUMBER",
};

export const chatActionTypes = {
  TOGGLE: "TOGGLE",
  SET_MATCHED_STRING: "SET_MATCHED_STRING",
  SET_SHORTCUT: "SET_SHORTCUT",
  SET_IGNORED_SHORTCUT: "SET_IGNORED_SHORTCUT",
  ADD_MESSAGE: "ADD_MESSAGE",
  REMOVE_MESSAGE: "REMOVE_MESSAGE",
  SET_ALL_CONVERSATIONS: "SET_ALL_CONVERSATIONS",
  END_CHAT: "END_CHAT",
  TOGGLE_CHATS_VIEW: "TOGGLE_CHATS_VIEW",
  SET_CHAT_INSTANCE: "SET_CHAT_INSTANCE",
  SET_ACTIVE_CONVERSATION: "SET_ACTIVE_CONVERSATION",
  SET_MESSAGE_SENT: "SET_MESSAGE_SENT",
  SET_ARCHIVE_CONVERSATION: "SET_ARCHIVE_CONVERSATION",
  REMOVE_NEW_MESSAGE_COUNT: "REMOVE_NEW_MESSAGE_COUNT",
  SET_NEW_MESSAGE_COUNT: "SET_NEW_MESSAGE_COUNT",
  IS_SEND_MESSAGE: "IS_SEND_MESSAGE",
  CLOSE_ACTIVE_CHAT: "CLOSE_ACTIVE_CHAT",
  SET_MESSAGE_IMAGE: "SET_MESSAGE_IMAGE",
  SET_CHAT_ENDED: "SET_CHAT_ENDED",
  SET_CHAT_SAVED: "SET_CHAT_SAVED",
  ADD_NEW_MESSAGE_COUNT: "ADD_NEW_MESSAGE_COUNT",
  READ_NEW_MESSAGES: "READ_NEW_MESSAGES",
  ADD_CONVERSATION_TO_EDIT: "ADD_CONVERSATION_TO_EDIT",
  REMOVE_CONVERSATION_FROM_EDIT: "REMOVE_CONVERSATION_FROM_EDIT",
  SET_REPLY_MESSAGE_VIEW: "SET_REPLY_MESSAGE_VIEW",
  SET_REPLY_MESSAGE_DATA: "SET_REPLY_MESSAGE_DATA",
  SET_CHAT_FULL_WIDTH: "SET_CHAT_FULL_WIDTH",
  SET_SEND_LAST_MSG: "SET_SEND_LAST_MSG",
  SET_MESSAGE: "SET_MESSAGE",
};

export const webSocketActionTypes = {
  SET_CONNECTED: "SET_CONNECTED",
  IS_CONNECTED: "IS_CONNECTED",
  TOGGLE_ACTIVE: "TOGGLE_ACTIVE",
  IS_DISCONNECTED: "IS_DISCONNECTED",
  IS_PENDING: "IS_PENDING",
  IS_RECONNECTING: "IS_RECONNECTING",
};

export const eventSourceActionTypes = {
  DASHBOARD_SSESOURCE: "DASHBOARD_SSESOURCE",
  SPYCALL_SSESOURCE: "SPYCALL_SSESOURCE",
};

export const faqActions = {
  SET_CATEGORY_SEARCH_VALUE: "SET_CATEGORY_SEARCH_VALUE",
  SET_EXPAND_ALL_CATEGORIES: "SET_EXPAND_ALL_CATEGORIES",
  SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY",
  SET_ALL_CATEGORIES: "SET_ALL_CATEGORIES",
  SET_ORIGINAL_CATEGORIES: "SET_ORIGINAL_CATEGORIES",
  SET_ALL_QUESTIONS: "SET_ALL_QUESTIONS",
  SET_QUESTION_SEARCH_VALUE: "SET_QUESTION_SEARCH_VALUE",
  SET_SELECTED_QUESTION: "SET_SELECTED_QUESTION",
  SET_SELECTED_ANSWER: "SET_SELECTED_ANSWER",
  CLEAR_ALL_DATA: "CLEAR_ALL_DATA",
  CLEAR_ALL_FIELDS: "CLEAR_ALL_FIELDS",
  ADD_SAVED_FAQ: "ADD_SAVED_FAQ",
  SET_ALL_SAVED_FAQ: "SET_ALL_SAVED_FAQ",
  REMOVE_SAVED_FAQ: "REMOVE_SAVED_FAQ",
  CLEAR_ALL_SAVED_FAQ: "CLEAR_ALL_SAVED_FAQ",
  SET_ACTIVE_SAVED_FAQ: "SET_ACTIVE_SAVED_FAQ",
  SET_SEARCH_BY: "SET_SEARCH_BY",
};

export const topicActions = {
  SET_CLASSIFICATOR_SEARCH_VALUE: "SET_CLASSIFICATOR_SEARCH_VALUE",
  SET_CLASSIFICATOR_IS_SEARCHING: "SET_CLASSIFICATOR_IS_SEARCHING",
  SET_EXPAND_ALL_CLASSIFICATORS: "SET_EXPAND_ALL_CLASSIFICATORS",
  SET_ALL_CLASSIFICATORS: "SET_ALL_CLASSIFICATORS",
  SET_ORIGINAL_CLASSIFICATORS: "SET_ORIGINAL_CLASSIFICATORS",
  ADD_NEW_PATH: "SET_ADD_NEW_PATH",
  ADD_PATH_NOTE: "ADD_PATH_NOTE",
  ADD_NEW_PATHS: "ADD_NEW_PATHS",
  REMOVE_PATH: "SET_REMOVE_PATH",
  CLEAR_TOPICS_BY_REQUEST_ID: "CLEAR_TOPICS_BY_REQUEST_ID",
};

export const classifierActions = {
  SET_CLASSIFIER_SEARCH_VALUE: "SET_CLASSIFIER_SEARCH_VALUE",
  SET_ALL_CLASSIFIERS: "SET_ALL_CLASSIFIERS",
  ADD_CLASSIFIER_NEW_PATH: "ADD_CLASSIFIER_NEW_PATH",
  SET_CHOSEN_CLASSIFIERS: "SET_CHOSEN_CLASSIFIERS",
  SET_BUTTON_DISABLED: "SET_BUTTON_DISABLED",
  SET_CLASSIFIER_TO_EDIT: "SET_CLASSIFIER_TO_EDIT",
};

export const noteActions = {
  SET_NEW_NOTE: "SET_NEW_NOTE",
  SET_ALL_NOTES: "SET_ALL_NOTES",
  CLEAR_NOTES_BY_REQUEST_ID: "CLEAR_NOTES_BY_REQUEST_ID",
};

export const smsActions = {
  SET_ALL_TEMPLATES: "SET_ALL_TEMPLATES",
  SET_CHOSEN_TEMPLATE: "SET_CHOSEN_TEMPLATE",
  SET_PAGINATION: "SET_PAGINATION",
  SET_SEARCHING: "SET_SEARCHING",
  SET_SEARCH: "SET_SEARCH",
  SET_ON_SEARCH: "SET_ON_SEARCH",
  SET_SEARCH_STRING: "SET_SEARCH_STRING",
};

export const authActions = {
  SET_SESSION_STATUS: "SET_SESSION_STATUS",
  SET_LOGIN_REGEX: "SET_LOGIN_REGEX",
};

export const crmActions = {
  SET_VALUE_TO_FROM_FIELDS_BY_CRM_TABLE_DATA:
    "SET_VALUE_TO_FROM_FIELDS_BY_CRM_TABLE_DATA",
  SHOW_CUSTOMER_SEARCH: "SHOW_CUSTOMER_SEARCH",
  SET_COMPANIES: "SET_COMPANIES",
  ADD_COMPANY: "ADD_COMPANY",
  SET_FORM_FIELDS: "SET_FORM_FIELDS",
  SET_CHECK_VALIDATION_GROUP: "SET_CHECK_VALIDATION_GROUP",
  SET_FORM_IS_VALID: "SET_FORM_IS_VALID",
  SET_OPTIONS_BY_DICTIONARY_ID: "SET_OPTIONS_BY_DICTIONARY_ID",
  ADD_CUSTOM_FIELD: "ADD_CUSTOM_FIELD",
  REMOVE_LAST_FIELD: "REMOVE_LAST_FIELD",
  SET_FIELD_VALUE: "SET_FIELD_VALUE",
  SET_FIELD_PLACEHOLDER: "SET_FIELD_PLACEHOLDER",
  SET_PROFILE_IMAGE: "SET_PROFILE_IMAGE",
  SET_IDENTIFIERS: "SET_IDENTIFIERS",
  SET_IDENTIFIER_VALUE: "SET_IDENTIFIER_VALUE",
  SET_CUSTOMER_ID: "SET_CUSTOMER_ID",
  SET_CUSTOMER_DATA: "SET_CUSTOMER_DATA",
  CLICK_RESET: "CLICK_RESET",
  CUSTOMER_CIF: "CUSTOMER_CIF",
  ACCOUNT_NUMBER: "ACCOUNT_NUMBER",
  SET_DEFAULT_FIELDS: "SET_DEFAULT_FIELDS",
  COPY_ATTACHMENTS: "COPY_ATTACHMENTS",
  COPY_ANSWER: "COPY_ANSWER",
  COPY_ICON_ONCLICK: "COPY_ICON_ONCLICK",
  CLEAR_ALL_DATA: "CLEAR_ALL_DATA",
  REMOVE_ENDED_CONVERSATION: "REMOVE_ENDED_CONVERSATION",
};

export const requestActions = {
  SET_REQUEST_SENDING: "SET_REQUEST_SENDING",
  SET_REQUEST_TYPES: "SET_REQUEST_TYPES",
  ADD_CHOSEN_OPTION: "ADD_CHOSEN_OPTION",
  REMOVE_OPTION: "REMOVE_OPTION",
  SET_SELECTED_REQUEST_TYPE: "SET_SELECTED_REQUEST_TYPE",
  SET_REQUEST_DATA_ON_EDIT_REQUEST: "SET_REQUEST_DATA_ON_EDIT_REQUEST",
  SET_MENU_TYPE: "menuType",
  SET_EDIT_CUSTOMER_ID: "SET_EDIT_CUSTOMER_ID",
  SET_SHOW_REGISTRATION: "SET_SHOW_REGISTRATION",
  SET_NOTE: "SET_NOTE",
  IS_ACTIVATING: "IS_ACTIVATING",
  SET_LEFT_PANEL_OPEN: "SET_LEFT_PANEL_OPEN",
  SET_IS_EDIT_REQUEST: "SET_IS_EDIT_REQUEST",
};

export const softPhoneActions = {
  PHONE_CONNECTED: "PHONE_CONNECTED",
  PHONE_DISCONNECTED: "PHONE_DISCONNECTED",
  PHONE_PENDING: "PHONE_PENDING",
  PHONE_RECONNECTING: "PHONE_RECONNECTING",
  TOGGLE_SOFT_PHONE: "TOGGLE_SOFT_PHONE",
  SET_PHONE_NUMBER: "SET_PHONE_NUMBER",
  SET_DTMF: "SET_DTMF",
  SET_CONTEXT_NAME: "SET_CONTEXT_NAME",
  SET_REGISTERED: "SET_REGISTERED",
  TRIGGER_CALL: "TRIGGER_CALL",
  TRIGGER_OUTGOING_CALL: "TRIGGER_OUTGOING_CALL",
  SET_CURRENT_LINE: "SET_CURRENT_LINE",
  SET_LINES_PHONES: "SET_LINES_PHONES",
  SET_LINES_PHONES_AFTER_ACCEPTED: "SET_LINES_PHONES_AFTER_ACCEPTED",
  SET_SAVED_IN_CALL: "SET_SAVED_IN_CALL",
  SET_CALL_CONTINUING: "SET_CALL_CONTINUING",
  SET_BREAK_IN_CALL: "SET_BREAK_IN_CALL",
  SET_PHONE_SIZE: "SET_PHONE_SIZE",
  SET_CALL_INFO_STATUS: "SET_CALL_INFO_STATUS",
  SET_SPY_CALL_ACTIVE: "SET_SPY_CALL_ACTIVE",
  SET_IS_COMPLETE_CALL_BY_AGENT: "SET_IS_COMPLETE_CALL_BY_AGENT",
  SET_SAVE_BEFORE_ACW_CALL_OVER: "SET_SAVE_BEFORE_ACW_CALL_OVER",
  SET_SAVE_AFTER_ACW_CALL_OVER: "SET_SAVE_AFTER_ACW_CALL_OVER",
  SET_ACW_CALL_OVER: "SET_ACW_CALL_OVER",
  EMPTY_LINE: "EMPTY_LINE",
  EMPTY_LINE_BY_SIP_CALL_ID: "EMPTY_LINE_BY_SIP_CALL_ID",
  ACCEPT_CALL: "ACCEPT_CALL",
  UPDATE_WAITING_DURATION: "UPDATE_WAITING_DURATION",
  UPDATE_DURATION: "UPDATE_DURATION",
  TOGGLE_CALL_IS_ENDED: "TOGGLE_CALL_IS_ENDED",
  TOGGLE_CALL_IS_REGISTERED: "TOGGLE_CALL_IS_REGISTERED",
  TOGGLE_CALL_IS_ON_HOLD: "TOGGLE_CALL_IS_ON_HOLD",
  TOGGLE_CALL_IS_MUTED: "TOGGLE_CALL_IS_MUTED",
  OUT_CALL_IS_ANSWERED: "OUT_CALL_IS_ANSWERED",
  HOLD_ALL_LINES_EXCEPT_ONE: "HOLD_ALL_LINES_EXCEPT_ONE",
  CONFRIM_REG_PAGE_SAVE: "CONFRIM_REG_PAGE_SAVE",
  SET_SAVED_IN_CALL_AT_LINE: "SET_SAVED_IN_CALL_AT_LINE",
  SET_BREAK_ON_HOLD: "SET_BREAK_ON_HOLD",
  SET_SAVE_BEFORE_HOLD_CALL_OVER: "SET_SAVE_BEFORE_HOLD_CALL_OVER",
  SET_SAVE_AFTER_HOLD_CALL_OVER: "SET_SAVE_AFTER_HOLD_CALL_OVER",
};

export const crmPageActions = {
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  SET_ORDER: "SET_ORDER",
  SET_ROWS_PER_PAGE: "SET_ROWS_PER_PAGE",
  SET_PAGE_NUMBER: "SET_PAGE_NUMBER",
  SET_DEFAULT_FIELDS: "SET_DEFAULT_FIELDS",
  SET_FILTER_FIELD_VALUE: "SET_FILTER_FIELD_VALUE",
  SET_FILTER_OPTIONS_BY_DICTIONARY_ID: "SET_FILTER_OPTIONS_BY_DICTIONARY_ID",
  RESET_FIELD_VALUES: "RESET_FIELD_VALUES",
  SET_SHOWED_COLUMNS: "SET_SHOWED_COLUMNS",
  SET_SEARCH_FIELDSET: "SET_SEARCH_FIELDSET",
  EMPTY_SEARCH_FIELDSET: "EMPTY_SEARCH_FIELDSET",
  SET_FULLTEXT_SEARCH: "SET_FULLTEXT_SEARCH",
};

export const cchActions = {
  SET_FORM_FIELDS_VALUES: "SET_FORM_FIELDS_VALUES",
  ADD_SAVED_CCH: "ADD_SAVED_CCH",
  ADD_ALL_CCHS: "ADD_ALL_CCHS",
  SET_ACTIVE_SAVED_CCH: "SET_ACTIVE_SAVED_CCH",
  REMOVE_SAVED_CCH: "REMOVE_SAVED_CCH",
  CLEAR_TASKS_BY_REQUEST_ID: "CLEAR_TASKS_BY_REQUEST_ID",
  RESET_TASK_FORM_FIELDS: "RESET_TASK_FORM_FIELDS",
};

export const taskActions = {
  TASK_TAGS_NAME: "TASK_TAGS_NAME",
  SET_EMPTY_TAG_FIELDS: "SET_EMPTY_TAG_FIELDS",
  FETCH_TASKS_RANDOM_ID: "FETCH_TASKS_RANDOM_ID",
};

export const socialMediaActions = {
  SET_ACTIVE_PLATFORM_ID: "SET_ACTIVE_PLATFORM_ID",
  SET_ACTIVE_ACCOUNT_ID: "SET_ACTIVE_ACCOUNT_ID",
  SET_PLATFORMS: "SET_PLATFORMS",
  SET_ACCOUNTS_BY_ID: "SET_ACCOUNTS_BY_ID",
  SET_POSTS_BY_ID: "SET_POSTS_BY_ID",
  SET_ACTIVE_POST: "SET_ACTIVE_POST",
  SET_ACTIVE_REPLY_MESSAGE_ID: "SET_ACTIVE_REPLY_MESSAGE_ID",
  SET_COMMENTS_BY_ACTIVE_POST: "SET_COMMENTS_BY_ACTIVE_POST",
  SET_REPLYS_BY_ACTIVE_POST: "SET_REPLYS_BY_ACTIVE_POST",
  SET_UN_REPLIED_COMMENTS: "SET_UN_REPLIED_COMMENTS",
  SET_UN_REPLIED_COMMENTS_PAGE: "SET_UN_REPLIED_COMMENTS_PAGE",
  SET_ACTIVE_ACTION_DATA: "SET_ACTIVE_ACTION_DATA",
};

export const queueActions = {
  ENQUEUE: "ENQUEUE",
  DEQUEUE: "DEQUEUE",
  SET_IS_SAVING: "SET_IS_SAVING",
  SET_EMPTY: "SET_EMPTY",
};

export const breakActions = {
  SET_BREAKS: "SET_BREAKS",
  SET_SELECTED_BREAK_ID: "SET_SELECTED_BREAK_ID",
  SET_CLOSE_BREAK_ID: "SET_CLOSE_BREAK_ID",
  SET_BREAK_NAME: "SET_BREAK_NAME",
  SET_ALLOW_CALL_TIME: "SET_ALLOW_CALL_TIME",
};

export const notificationActions = {
  ENQUEUE: "ENQUEUE",
  DEQUEUE: "DEQUEUE",
};

export const callbackActions = {
  SET_CALLBACK: "SET_CALLBACK",
  SET_REQUEST_ID: "SET_REQUEST_ID",
};
