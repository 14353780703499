import DateFnsUtils from "@date-io/date-fns";

import { styled } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { DateRangeOutlinedIcon } from "Assets/icons";
import FormElement from "Components/Form/FormElement";
import React from "react";

import { useDateInput } from "./hooks";
import { IFormDatePicker } from "./interfaces";
import { useDateInputStyles } from "./styles";

const DateInput = (props: IFormDatePicker): React.ReactElement => {
  const classes = useDateInputStyles();
  const { formattedValue, handleChange } = useDateInput(props);

  return (
    <Container>
      <Label>{props.label}</Label>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <StyledKeyboardDatePicker
          className={classes.root}
          autoOk
          error={props.error}
          size='small'
          variant='inline'
          format='dd-MM-yyyy'
          margin='normal'
          value={formattedValue}
          onChange={(fulldate) => handleChange(fulldate)}
          keyboardIcon={<DateIcon />}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            disableUnderline: true,
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </Container>
  );
};

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)({
  width: "100%",
  marginBottom: 0,
  marginTop: 0,
  "& input:focus": {
    boxShadow: "0 0 1px 1px white !important",
  },
});

const Container = styled("div")({
  display: "inline-block",
  width: "90%",
  float: "left",
  marginRight: 15,
  marginLeft: 15,
});

const Label = styled("span")({
  color: "#414147",
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  display: "block",
  textAlign: "left",
  marginBottom: 10,
  marginTop: 10,
});

const DateIcon = styled(DateRangeOutlinedIcon)({
  color: "#9F9DAD",
  fontSize: 20,
  lineHeight: 17,
  fontWeight: 600,
});

export default FormElement(DateInput);

DateInput.displayName = "DateInput";
