import React from "react";
import ReactDOM from "react-dom";

import App from "App";
import { Wrapper } from "Assets/wrappers";

import "i18n/config";
import * as serviceWorker from "./serviceWorker";

import "./Assets/styles/index.css";

ReactDOM.render(
  <Wrapper>
    <App />
  </Wrapper>,
  document.getElementById("root"),
);

serviceWorker.unregister();
