/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import menuTypes from "Assets/constants/menuTypes";
import { useCallback } from "react";

import { topicActions as actionTypes } from "../Actions/actionTypes";

export default class Topic {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setClassificatorSearchValue = useCallback(
    (menuType: menuTypes, requestId: number, value: string) =>
      this.dispatch({
        type: actionTypes.SET_CLASSIFICATOR_SEARCH_VALUE,
        value,
        menuType,
        requestId,
      }),
    [this],
  );

  setClassificatorIsSearching = useCallback(
    (menuType: menuTypes, requestId: number, isSearching: boolean) =>
      this.dispatch({
        type: actionTypes.SET_CLASSIFICATOR_IS_SEARCHING,
        isSearching,
        menuType,
        requestId,
      }),
    [this],
  );

  setExpandAllClassificators = useCallback(
    (menuType: menuTypes, requestId: number, expandAll: boolean) =>
      this.dispatch({
        type: actionTypes.SET_EXPAND_ALL_CLASSIFICATORS,
        expandAll,
        menuType,
        requestId,
      }),
    [this],
  );

  setAllClassificators = useCallback(
    (menuType: menuTypes, requestId: number, allClassificators: any) =>
      this.dispatch({
        type: actionTypes.SET_ALL_CLASSIFICATORS,
        allClassificators,
        menuType,
        requestId,
      }),
    [this],
  );

  setOriginalClassificators = useCallback(
    (menuType: menuTypes, requestId: number, originalClassificators: any) =>
      this.dispatch({
        type: actionTypes.SET_ORIGINAL_CLASSIFICATORS,
        originalClassificators,
        menuType,
        requestId,
      }),
    [this],
  );

  addNewPath = useCallback(
    (menuType: menuTypes, requestId: number, path: any) =>
      this.dispatch({
        type: actionTypes.ADD_NEW_PATH,
        path,
        menuType,
        requestId,
      }),
    [this],
  );

  addPathNote = useCallback(
    (menuType: menuTypes, requestId: number, id: any, note: string) =>
      this.dispatch({
        type: actionTypes.ADD_PATH_NOTE,
        menuType,
        requestId,
        id,
        note,
      }),
    [this],
  );

  addNewPaths = useCallback(
    (menuType: menuTypes, requestId: number, paths: Array<Object>) =>
      this.dispatch({
        type: actionTypes.ADD_NEW_PATHS,
        paths,
        menuType,
        requestId,
      }),
    [this],
  );

  removePath = useCallback(
    (menuType: menuTypes, requestId: number, id: number) =>
      this.dispatch({
        type: actionTypes.REMOVE_PATH,
        id,
        menuType,
        requestId,
      }),
    [this],
  );

  clearTopicsByRequestId = useCallback(
    (menuType: menuTypes, requestId: number) =>
      this.dispatch({
        type: actionTypes.CLEAR_TOPICS_BY_REQUEST_ID,
        menuType,
        requestId,
      }),
    [this],
  );
}
