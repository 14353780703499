import { CircularProgress, createStyles, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      margin: "auto",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      position: "absolute",
      color: "orange",
      width: "60px !important",
      height: "60px !important",
    },
    backdrop: {
      zIndex: 999,
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "#ffffff6e",
      top: 0,
      left: 0,
    },
  }),
);

const LoadingComponent = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <>
      <Skeleton
        width={"100%"}
        height={"100%"}
        variant='rect'
        className={classes.backdrop}
      />
      <CircularProgress className={classes.icon} />
    </>
  );
};

export default LoadingComponent;

LoadingComponent.displayName = "LoadingComponent";
