const menuItems = {
  SIPPHONE: {
    name: "Phone",
  },
  FB: {
    name: "Facebook",
  },
  WHATSAPP: {
    name: "Whatsapp",
  },
  TELEGRAM: {
    name: "Telegram",
  },
  WEBCHAT: {
    name: "Webchat",
  },
  INSTAGRAM: {
    name: "Instagram",
  },
};

export default menuItems;
