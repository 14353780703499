import axios from "Api/axios";

import WebConf, { ClassificatorUrl } from "Config/WebConf";

import {
  IClassificatorsLikeTree,
  INewClassificator,
} from "./interfaces/classificator";

// GET
export const getClassificatorsLikeTable = async (): Promise<any> =>
  axios
    .get(ClassificatorUrl + WebConf.classifier.getTable)
    .then((response) => response && response.data);

export const getClassificatorsLikeTree = async (): Promise<
  Array<IClassificatorsLikeTree>
> =>
  axios
    .get(ClassificatorUrl + WebConf.classifier.getTree)
    .then((response) => response && response.data);

export const getClassifierPaths = (): Promise<any> =>
  axios
    .get(ClassificatorUrl + WebConf.classifier.getPaths)
    .then((response) => response && response.data);

// DELETE
export const deleteClassificator = async (id: number): Promise<any> =>
  axios.delete(`${ClassificatorUrl}/${id}`);

// POST
export const createNewClassificator = async (
  parentCategoryId: number | null,
  name: string,
): Promise<INewClassificator> => {
  const body = {
    id: null,
    parentCategoryId,
    name,
    createdby: {
      id: localStorage.getItem("userID"),
      fullName:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("lastName"),
    },
  };

  return axios
    .post(`${ClassificatorUrl}`, body)
    .then((response) => response && response.data);
};

export const updateClassificator = async (
  id: number,
  parentCategoryId: number,
  name: string,
): Promise<INewClassificator> => {
  const body = {
    id,
    parentCategoryId,
    name,
    createdby: {
      id: localStorage.getItem("userID"),
      fullName:
        localStorage.getItem("firstName") +
        " " +
        localStorage.getItem("lastName"),
    },
  };

  return axios
    .post(`${ClassificatorUrl}`, body)
    .then((response) => response && response.data);
};
