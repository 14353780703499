import axios from "Api/axios";
import WebConf, { VoipServerUrl } from "Config/WebConf";
import { fetchData } from "networking";

// GET
export const getSipNumberByUserId = (userId: string) =>
  axios
    .get(
      VoipServerUrl + WebConf.voip.getSipNumberByUserId + "?userId=" + userId,
    )
    .then((response) => response && response.data);

export const getUserSipNumbers = () =>
  axios
    .get(VoipServerUrl + WebConf.voip.getUserSipNumbers)
    .then((response) => response && response.data);

export const getIvrsList = () =>
  axios
    .get(VoipServerUrl + WebConf.voip.getIvrsList)
    .then((response) => response && response.data);

export const getCallInfo = (uid: string) =>
  axios
    .get(VoipServerUrl + WebConf.voip.getCallInfo(uid))
    .then((response) => response && response.data);

export const getActiveCallusers = () =>
  axios
    .get(VoipServerUrl + WebConf.voip.getActiveCallUsers)
    .then((response) => response && response.data);

export const getVoipQueueListById = (id: number) =>
  axios
    .get(VoipServerUrl + WebConf.voip.getVoipQueueListById + "/" + id)
    .then((response) => response && response.data);

export const getAllVoipQueueList = () =>
  axios
    .get(VoipServerUrl + WebConf.voip.getAllVoipQueueList)
    .then((response) => response && response.data);

export const getSoundFile = async (fileName: string) => {
  const response = await fetchData(
    VoipServerUrl +
      WebConf.voip.getSoundFile +
      "?fileName=" +
      fileName +
      ".ogg",
  );
  if (response && response.status === 200) {
    try {
      const blob = await response.blob();
      const objectUrl = window.URL.createObjectURL(blob);
      return objectUrl;
    } catch (error) {
      return "";
    }
  } else {
    return "";
  }
};

// POST
export const spyCall = (
  ext: string,
  uniqueId: string,
  context = "spy-call",
) => {
  const body = {
    context,
    ext,
    uniqueId,
  };

  return axios
    .post(VoipServerUrl + WebConf.voip.spyCall, body)
    .then((response) => response && response.status === 200);
};

export const updateVoipQueueList = async (
  id: number,
  firstName: string,
  lastName: string,
  queueNames: string[],
) => {
  const body = {
    userId: id,
    firstName,
    lastName,
    queueNames,
  };

  return axios
    .post(VoipServerUrl + WebConf.voip.updateVoipQueueList, body)
    .then((response) => response && response.status === 200);
};

export const toggleSocketStartStop = async (
  userId: string,
  firstName: string,
  lastName: string,
  status: boolean,
) => {
  const body = {
    userId,
    firstName,
    lastName,
    isStarted: status,
    type: "CALL",
  };

  return axios
    .post(VoipServerUrl + WebConf.voip.toggleSocketStartStop, body)
    .then((response) => response && response.status === 200);
};

export const setIvrPoint = async (id: number, channelId: string) => {
  const body = {
    pointId: id,
    uniqueId: channelId,
  };

  return axios
    .post(VoipServerUrl + WebConf.voip.setIvrPoint, body)
    .then((response) => response && response.status === 200);
};
