import { permissions } from "Config/config";
import i18n from "i18n/config";

export const tabs = {
  Topics: {
    name: i18n.t("request_topics"),
    permission: "",
  },
  Tasks: {
    name: i18n.t("request_tasks"),
    permission: permissions.task_registration_tab,
  },
  Notes: {
    name: "Notes",
    permission: permissions.request_classfier_note,
  },
};
