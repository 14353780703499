export const colors = {
  active: "#09CD87",
  fadedActive: "rgba(9, 205, 135, 0.1)",
  hold: "#F68F1F",
  fadedHold: "rgba(246, 143, 31, 0.1)",
  mute: "#DC0236",
  fadedMute: "rgba(220, 2, 54, 0.1)",
  end: "#DC0236",
  default: "#050038",
};
