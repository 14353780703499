import React, { useContext } from "react";

import { grey } from "@material-ui/core/colors";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

import { StoreContext } from "./Table";

interface EnhancedTableProps {
  classes: any;
  onRequestSort: (event: React.MouseEvent<unknown>, property) => void;
  order: any;
  orderBy: string;
  rowCount: number;
}

/**
 *
 * Enhanced table head with sorting
 */
export function EnhancedTableHead({
  order,
  orderBy,
  onRequestSort,
}: EnhancedTableProps) {
  const store: any = useContext(StoreContext);

  const createSortHandler =
    (property) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead style={{ zIndex: 0 }}>
      <TableRow>
        {store.columns.get().map((headCell) => {
          if (store.showedColumns.get().includes(headCell.name))
            return (
              <TableCell
                style={{
                  textIndent: "15px",
                  borderRight: "1px solid " + grey[200],
                  fontWeight: 700,
                  textTransform: "uppercase",
                  zIndex: 1,
                }}
                key={headCell.name}
                align={"center"}
                sortDirection={orderBy === headCell.name ? order : false}>
                <TableSortLabel
                  active={orderBy === headCell.name}
                  direction={orderBy === headCell.name ? order : "asc"}
                  onClick={createSortHandler(headCell.name)}>
                  {headCell.alias ?? headCell.name}
                </TableSortLabel>
              </TableCell>
            );
          return null;
        })}
      </TableRow>
    </TableHead>
  );
}
