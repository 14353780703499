/* eslint-disable array-callback-return */
import { styled, Popover } from "@material-ui/core";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import {
  DeviceHubIcon,
  FolderIcon,
  LiveHelpIcon,
  PersonIcon,
  PieChartIcon,
  CheckBoxIcon,
  SettingsIcon,
  EqualizerIcon,
  AssistantIcon,
  ExpandMoreIcon,
} from "Assets/icons";
import { availablePages, permissions } from "Config/config";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import { default as RequestActions } from "Store/Actions/Request";
import { permit, replaceRouteIndecies } from "Utils/Utils";

import { INavigationItem, IMenu } from "./interfaces";

const NavigationBarItem = ({
  url,
  label,
  popup,
}: INavigationItem): React.ReactElement => {
  const { translate } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );

  const request = new RequestActions(useDispatch());

  const open = Boolean(anchorEl);

  const location = useLocation();

  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const menus: Array<IMenu> = [
    {
      row: "row1",
      columns: [
        permit(
          {
            header: translate("navbar_crm"),
            url: "",
            icon: <PersonIcon />,
            values: [
              permit(
                {
                  url: availablePages.customers,
                  label: translate("navbar_customers"),
                },
                permissions.crm_customer_tab,
              ),
              permit(
                {
                  url: availablePages.companies,
                  label: translate("navbar_companies"),
                },
                permissions.crm_company_tab,
              ),
            ].filter((item) => item !== null),
          },
          permissions.main_menu_crm,
        ),
        permit(
          {
            header: translate("navbar_requests"),
            url: "",
            icon: <FolderIcon />,
            values: [
              permit(
                {
                  url: availablePages.requestTable,
                  label: translate("navbar_request_table"),
                },
                permissions.request_table_page,
              ),
            ].filter((item) => item !== null),
          },
          permissions.main_menu_requests,
        ),
        permit(
          {
            header: translate("navbar_generator"),
            url: availablePages.categories,
            icon: <LiveHelpIcon />,
            values: [
              permit(
                {
                  url: availablePages.categories,
                  label: translate("navbar_faq"),
                },
                permissions.faq_categories_page,
              ),
              permit(
                {
                  url: availablePages.classificators,
                  label: translate("navbar_classificators"),
                },
                permissions.classifier_list,
              ),
              permit(
                {
                  url: availablePages.smsTemplates,
                  label: translate("navbar_sms_templates"),
                },
                permissions.sms_get,
              ),
              permit(
                {
                  url: availablePages.messageTemplates,
                  label: translate("navbar_message_templates"),
                },
                permissions.omnichannel_get_message_templates,
              ),
              permit(
                {
                  url: availablePages.autoReplyMessage,
                  label: translate("navbar_auto_reply_message"),
                },
                permissions.generator_auto_reply_message,
              ),
              permit(
                {
                  url: availablePages.preDefinedMessage,
                  label: translate("navbar_predefined_message"),
                },
                permissions.generator_predefined_message,
              ),
            ].filter((item) => item !== null),
          },
          permissions.main_menu_generator,
        ),
        permit(
          {
            header: translate("navbar_management"),
            url: "",
            icon: <CheckBoxIcon />,
            values: [
              permit(
                {
                  url: replaceRouteIndecies(availablePages.myTasks, [0]),
                  label: translate("navbar_tasks"),
                },
                permissions.task_mngmt_tasks_page,
              ),
              permit(
                {
                  url: availablePages.breaks,
                  label: translate("navbar_breaks"),
                },
                permissions.break_permission_management,
              ),
              permit(
                {
                  url: availablePages.callback,
                  label: translate("navbar_callback"),
                },
                permissions.callback_tab_menu,
              ),
            ].filter((item) => item !== null),
          },
          permissions.main_menu_management,
        ),
      ],
    },
    {
      row: "row2",
      columns: [
        permit(
          {
            header: translate("navbar_configuration"),
            url: availablePages.userManagement,
            icon: <SettingsIcon />,
            values: [
              permit(
                {
                  url: availablePages.userManagement,
                  label: translate("navbar_user_management"),
                },
                permissions.configuration_user_management,
              ),
              permit(
                {
                  url: availablePages.roleManagement,
                  label: translate("navbar_role_management"),
                },
                permissions.configuration_role_management,
              ),
              permit(
                {
                  url: availablePages.breakManagement,
                  label: translate("navbar_break_management"),
                },
                permissions.configuration_break_management,
              ),
              permit(
                {
                  url: availablePages.taskManagement,
                  label: translate("navbar_task_management"),
                },
                permissions.configuration_task_management,
              ),
              permit(
                {
                  url: availablePages.ivrAnnounceManagement,
                  label: translate("navbar_ivr_announce_management"),
                },
                permissions.configuration_ivr_announce,
              ),
              permit(
                {
                  url: availablePages.queueManagement,
                  label: translate("navbar_queue_management"),
                },
                permissions.configuration_queue_management,
              ),
              permit(
                {
                  url: availablePages.dictionaryManagement,
                  label: translate("navbar_dictionary_management"),
                },
                permissions.task_list_access,
              ),
            ].filter((item) => item !== null),
          },
          permissions.main_menu_configuration,
        ),
        permit(
          {
            header: translate("navbar_report"),
            url: availablePages.classificatorReport,
            icon: <PieChartIcon />,
            values: [
              permit(
                {
                  url: availablePages.classificatorReport,
                  label: translate("navbar_classificator_report"),
                },
                permissions.report_classificator,
              ),
              permit(
                {
                  url: availablePages.queueReport,
                  label: translate("navbar_queue_report"),
                },
                permissions.report_queue,
              ),
              permit(
                {
                  url: availablePages.callQueueReport,
                  label: translate("navbar_call_queue_report"),
                },
                permissions.report_call_queue,
              ),
              permit(
                {
                  url: availablePages.chatQueueReport,
                  label: translate("navbar_chat_queue_report"),
                },
                permissions.report_chat_queue,
              ),
              permit(
                {
                  url: availablePages.callReport,
                  label: translate("navbar_call_report"),
                },
                permissions.report_call,
              ),
              permit(
                {
                  url: availablePages.chatReport,
                  label: translate("navbar_chat_report"),
                },
                permissions.report_chat,
              ),
              permit(
                {
                  url: availablePages.summaryCallReport,
                  label: translate("navbar_summary_call_report"),
                },
                permissions.report_summary_call,
              ),
              permit(
                {
                  url: availablePages.summaryChatReport,
                  label: translate("navbar_summary_chat_report"),
                },
                permissions.report_summary_chat,
              ),
              permit(
                {
                  url: availablePages.agentWorkReport,
                  label: translate("navbar_agent_work_report"),
                },
                permissions.report_agent_work,
              ),
              permit(
                {
                  url: availablePages.agentCallReport,
                  label: translate("navbar_agent_call_report"),
                },
                permissions.report_agent_call,
              ),
              permit(
                {
                  url: availablePages.agentSMMReport,
                  label: translate("navbar_agent_chat_report"),
                },
                permissions.report_agent_chat,
              ),
              permit(
                {
                  url: availablePages.agentSessionReport,
                  label: translate("navbar_agent_session_report"),
                },
                permissions.report_agent_session,
              ),
              permit(
                {
                  url: availablePages.traceByLocationReport,
                  label: translate("navbar_trace_by_location_report"),
                },
                permissions.report_trace_by_location,
              ),
              permit(
                {
                  url: availablePages.missedCallReport,
                  label: translate("navbar_missed_call_report"),
                },
                permissions.report_missed_call,
              ),
              permit(
                {
                  url: availablePages.taskReport,
                  label: translate("navbar_task_report"),
                },
                permissions.report_task,
              ),
              permit(
                {
                  url: availablePages.task2Report,
                  label: translate("navbar_task2_report"),
                },
                permissions.report_task_2,
              ),
              permit(
                {
                  url: availablePages.crmDataReport,
                  label: translate("navbar_crm_data_report"),
                },
                permissions.report_crm_data,
              ),
            ].filter((item) => item !== null),
          },
          permissions.main_menu_reports,
        ),
        permit(
          {
            header: translate("navbar_social_media"),
            url: "",
            icon: <EqualizerIcon />,
            values: [
              permit(
                {
                  url: availablePages.socialMedia,
                  label: translate("navbar_social_media_page"),
                },
                permissions.social_media,
              ),
            ].filter((item) => item !== null),
          },
          permissions.main_menu_smm,
        ),
        permit(
          {
            header: translate("navbar_evaluation"),
            url: "",
            icon: <AssistantIcon />,
            values: [
              permit(
                {
                  url: availablePages.ivrSurvey,
                  label: translate("navbar_ivr_survey"),
                },
                permissions.evaluation_ivr_survey_menu,
              ),
              permit(
                {
                  url: availablePages.qualityControl,
                  label: translate("navbar_evaluation_configuration"),
                },
                permissions.evaluation_configuration_menu,
              ),
              permit(
                {
                  url: availablePages.admin,
                  label: translate("navbar_evaluation_admin"),
                },
                permissions.evaluation_admin_menu,
              ),
              permit(
                {
                  url: availablePages.operation,
                  label: translate("navbar_evaluation_operation"),
                },
                permissions.evaluation_operation_menu,
              ),
            ].filter((item) => item !== null),
          },
          permissions.main_menu_evaluation,
        ),
      ],
    },
    {
      row: "row3",
      columns: [
        permit(
          {
            header: translate("navbar_monitoring"),
            url: "",
            icon: <AssistantIcon />,
            values: [
              permit(
                {
                  url: availablePages.dashboards,
                  label: translate("navbar_dashboards"),
                },
                permissions.monitoring_dashboards_page,
              ),
              permit(
                {
                  url: availablePages.spyCall,
                  label: translate("navbar_spy_call"),
                },
                permissions.monitoring_spy_call,
              ),
            ].filter((item) => item !== null),
          },
          permissions.main_menu_monitoring,
        ),
        permit(
          {
            header: "Jira",
            url: "",
            icon: <DeviceHubIcon />,
            values: [
              permit(
                {
                  url: availablePages.jiraConfiguration,
                  label: translate("navbar_jira_configuration"),
                },
                permissions.jira_configuration,
              ),
              permit(
                {
                  url: availablePages.jiraTickets,
                  label: translate("navbar_jira_tickets"),
                },
                permissions.jira_ticket,
              ),
              permit(
                {
                  url: availablePages.jiraReport,
                  label: translate("navbar_jira_report"),
                },
                permissions.jira_report,
              ),
            ].filter((item) => item !== null),
          },
          permissions.main_menu_jira,
        ),
        {},
        {},
      ],
    },
  ];

  useEffect(() => {
    if (requestState.showRegistration) request.setShowRegistration(false);
    setAnchorEl(null);
  }, [location]);

  return (
    <Container>
      {popup ? (
        <MenuButton onClick={handleClick}>
          <LinkName>{label}</LinkName>
          <DownIcon />
        </MenuButton>
      ) : (
        <MenuLink to={url}>
          <LinkName>{label}</LinkName>
        </MenuLink>
      )}
      <PopoverWrapper
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: 220,
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}>
        <MenuBox>
          {menus.length > 0 &&
            menus.map((menu): React.ReactNode | void => {
              if (menu) {
                return menu.columns.map(
                  (column, index) =>
                    column?.values?.length > 0 && (
                      <MenuItem key={index}>
                        <MenuItemIcon>{column?.icon}</MenuItemIcon>
                        <div>
                          <MenuHeader>{column?.header}</MenuHeader>
                          {column?.values?.slice(0, 4)?.map((value, index) => (
                            <MenuLink
                              key={index}
                              to={value?.url ?? ""}
                              style={{ marginBottom: 8 }}>
                              <SubLabel>{value?.label}</SubLabel>
                            </MenuLink>
                          ))}
                          {column?.values?.length > 4 && (
                            <MenuMoreLink to={column?.url}>
                              <SubLabel>
                                {translate("navbar_view_more")}
                              </SubLabel>
                            </MenuMoreLink>
                          )}
                        </div>
                      </MenuItem>
                    ),
                );
              }
            })}
        </MenuBox>
      </PopoverWrapper>
    </Container>
  );
};

export default NavigationBarItem;

const Container = styled("div")({
  display: "inline-block",
  float: "left",
});

const MenuBox = styled("ul")({
  backgroundColor: "#fff",
  borderRadius: "12px",
  padding: "20px 16px 0",
  boxShadow: "0px 3px 6px rgba(34, 40, 49, 0.1)",
  height: "auto",
  margin: 0,
  maxWidth: "100vh",
  display: "flex",
  flexWrap: "wrap",
  "&>div:not(:last-child)": {
    borderBottom: `1px solid #e4e6eb`,
    marginBottom: 20,
  },
});

const MenuHeader = styled("div")({
  fontSize: 16,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  margin: "4px 0 8px 0",
  color: "#EC8A1F",
  fontWeight: 400,
  textTransform: "uppercase",
  width: "max-content",
});

const PopoverWrapper = styled(Popover)({
  marginTop: 50,
  "& .MuiPaper-root": {
    scrollbarWidth: "thin",
  },
});

const MenuItem = styled("div")({
  display: "flex",
  width: "20%",
  marginBottom: 20,
  paddingRight: 70,
});

const MenuLink = styled(NavLink)({
  display: "flex",
  textDecoration: "none",
  alignItems: "center",
  color: "black",
});

const MenuButton = styled("div")({
  display: "flex",
  textDecoration: "none",
  alignItems: "center",
  cursor: "pointer",
});

const LinkName = styled("span")({
  color: "#fff",
  fontSize: 14,
  fontWeight: "bold",
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
});

const MenuItemIcon = styled("div")({
  width: "20px",
  height: "20px",
  borderRadius: "6px",
  padding: 8,
  color: "#EC8A1F",
});

const MenuMoreLink = styled(NavLink)({
  color: "#363F4F",
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
});

const DownIcon = styled(ExpandMoreIcon)({
  color: "#fff",
});

const SubLabel = styled("span")({
  fontWeight: 300,
  fontSize: 13,
  letterSpacing: "0.04em",
  lineHeight: "160%",
  textTransform: "capitalize",
  width: "max-content",
});
