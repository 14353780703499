import {
  getAccounts,
  getCards,
  getLoans,
  getPersonalInfo,
  getNextProductToBuy,
} from "Api/ibarCrmIntegration";

import { RequestContextStore } from "Components/Layout/AppLayout";

import { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { default as CrmActions } from "Store/Actions/Crm";
import { formatStore } from "Store/ContextStore";
import { defaultStates as defaultCrmStates } from "Store/Reducers/crmReducer";

import { validatePhoneNumbers } from "Utils/Utils";

import { filterTypes } from "../constants";
import {
  IAccounts,
  ICards,
  ILoans,
  INextProduct,
  IPersonalInfo,
  IbarIntegrationSearchPanelHookProps,
} from "../interfaces";

export const useIbarIntegrationSearchPanel = ({
  open,
}): IbarIntegrationSearchPanelHookProps => {
  const [selectedFilter, setSelectedFilter] = useState<string>(
    filterTypes[3].name,
  );
  const [filterValue, setFilterValue] = useState<string>("");
  const [tab, setTab] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<
    IPersonalInfo[] | IAccounts[] | ICards[] | ILoans[] | INextProduct[]
  >([]);

  const requestContextStore: any = useContext(RequestContextStore);

  const crm = new CrmActions(useDispatch());

  const totalCrmState = useSelector(
    (state: RootStateOrAny) => state.crmReducer,
  );

  const crmState = requestContextStore.getCurrentStateFromTotalState(
    totalCrmState,
    defaultCrmStates,
  );

  const clearData = (): void => {
    setSelectedFilter(filterTypes[3].name);
    setFilterValue("");
    setTab(0);
    setRows(null);
  };

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);

    const fetchedData =
      tab === 0
        ? await getPersonalInfo(selectedFilter, filterValue)
        : tab === 1
        ? await getAccounts(selectedFilter, filterValue)
        : tab === 2
        ? await getCards(selectedFilter, filterValue)
        : tab === 3
        ? await getLoans(selectedFilter, filterValue)
        : tab === 4
        ? await getNextProductToBuy(selectedFilter, filterValue)
        : [];

    fetchedData ? setRows(fetchedData) : setRows(null);

    setIsLoading(false);
  };

  const fetchCustomerInfo = async (): Promise<void> => {
    const cifData = await getPersonalInfo(selectedFilter, filterValue);
    const accountNumberData = await getAccounts(selectedFilter, filterValue);

    const cif = cifData?.length > 0 ? cifData?.[0]?.cif : null;
    const accountNumber =
      accountNumberData?.length > 0
        ? accountNumberData?.[0]?.accountNumber
        : null;
    crm.setCustomerCif(cif);
    crm.setAccountNumber(accountNumber);
  };

  const handleFilter = (event): void => {
    setSelectedFilter(event.target.value);
    setFilterValue("");
  };

  const handleSearch = (): void => {
    fetchData();
  };

  useEffect(() => {
    setRows(null);
    filterValue.length > 0 && fetchData();
  }, [tab]);

  useEffect(() => {
    const number =
      crmState.identifiers &&
      crmState.identifiers.filter(
        (identifier) => identifier.channelType === "SIPPHONE",
      )[0]?.identifier;

    setFilterValue(validatePhoneNumbers(number));
  }, [crmState, open]);

  useEffect(() => {
    if (filterValue && selectedFilter === filterTypes[3].name) {
      fetchCustomerInfo();
    }
  }, [filterValue.length > 0]);

  const store = formatStore({
    isLoading: [isLoading, setIsLoading],
    tabIndex: [tab, setTab],
    rows: [rows, setRows],
  });

  return {
    selectedFilter,
    filterValue,
    store,
    clearData,
    handleFilter,
    setFilterValue,
    handleSearch,
  };
};

useIbarIntegrationSearchPanel.displayName = "useIbarIntegrationSearchPanel";
