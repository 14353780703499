import { useCallback } from "react";
import { breakActions as actionTypes } from "./actionTypes";

export default class Breaks {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setBreaks = useCallback(
    (breaks: Array<Object>) =>
      this.dispatch({
        type: actionTypes.SET_BREAKS,
        breaks,
      }),
    [this],
  );

  setSelectedBreakId = useCallback(
    (breakId: number | string) =>
      this.dispatch({
        type: actionTypes.SET_SELECTED_BREAK_ID,
        breakId,
      }),
    [this],
  );

  setCloseBreakId = useCallback(
    (breakId: number | string) =>
      this.dispatch({
        type: actionTypes.SET_CLOSE_BREAK_ID,
        breakId,
      }),
    [this],
  );

  setBreakName = useCallback(
    (breakName: string) =>
      this.dispatch({
        type: actionTypes.SET_BREAK_NAME,
        breakName,
      }),
    [this],
  );

  setAllowCallTime = useCallback(
    (time: number) =>
      this.dispatch({
        type: actionTypes.SET_ALLOW_CALL_TIME,
        time,
      }),
    [this],
  );
}
