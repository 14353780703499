import i18n from "i18n/config";

export const accountColumns = [
  {
    name: "accountNumber",
    alias: i18n.t("request_account_number"),
    sortable: false,
  },
  { name: "branch", alias: i18n.t("request_branch"), sortable: false },
  { name: "currency", alias: i18n.t("request_currency"), sortable: false },
  { name: "balance", alias: i18n.t("request_balance"), sortable: false },
  { name: "status", alias: i18n.t("request_status"), sortable: false },
  { name: "openDate", alias: i18n.t("request_open_date"), sortable: false },
  {
    name: "accountType",
    alias: i18n.t("request_account_type"),
    sortable: false,
  },
  { name: "actions", alias: i18n.t("request_actions"), sortable: false },
  { name: "blockInfo", alias: i18n.t("request_block_info"), sortable: false },
];
