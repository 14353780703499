export enum buttonTypes {
  "info" = "info",
  "success" = "success",
  "danger" = "danger",
  "default" = "default",
  "warning" = "warning",
  "primary" = "primary",
  "secondary" = "secondary",
  "light" = "light",
  "dark" = "dark",
  "white" = "white",
}

export enum buttonTypesRGB {
  "warning" = "rgba(200, 200, 0, 0.7)",
  "success" = "rgba(0, 128, 0, 0.7)",
  "danger" = "rgba(196, 35, 35, 0.7)",
  "default" = "rgba(129, 128, 138, 0.7)",
}
