import axios from "Api/axios";

import WebConf, { OmniServerUrl } from "Config/WebConf";
import { fetchData } from "networking";

// GET
export const loadSource = async (fileName: string, extension) => {
  const response = await fetchData(
    OmniServerUrl +
      WebConf.chat.loadSourceBase64 +
      "?id=" +
      fileName +
      "." +
      extension,
  );
  if (response.status === 200) {
    try {
      const blob = await response.blob();
      return window.URL.createObjectURL(blob);
    } catch (error) {
      return "";
    }
  } else return "";
};

export const getArchiveConversation = (
  conversationId: number,
  channelId: number,
) =>
  axios
    .get(
      `${OmniServerUrl}${WebConf.chat.archiveConversation}?conversationId=${conversationId}&channelId=${channelId}`,
    )
    .then((response) => response && response.data);

export const exitViewer = (id: number) =>
  axios.get(`${OmniServerUrl}${WebConf.chat.exitViewer(id)}`);

export const getAllUserConversationCount = () =>
  axios
    .get(OmniServerUrl + WebConf.chat.getAllUserConversationCount)
    .then((response) => response && response.data);

// POST
export const conversationForward = async (conversationId, userId) => {
  const body = {
    conversationIds: [conversationId],
    userId,
  };
  return axios
    .post(OmniServerUrl + WebConf.chat.conversationForward, body)
    .then((response) => (response && response.data ? true : false));
};

export const multipleConversationForward = async (conversationIds, userId) => {
  const body = {
    conversationIds,
    userId,
  };
  return axios
    .post(OmniServerUrl + WebConf.chat.multipleConversationForward, body)
    .then((response) => response);
};

export const createNewConversation = (data) =>
  axios
    .post(`${OmniServerUrl}${WebConf.chat.createNewConversation}`, data)
    .then((response) => (response ? response : false));

export const setMessageSeen = (conversationId: number): Promise<boolean> => {
  const body = {
    conversationId,
  };
  return axios
    .post(`${OmniServerUrl}${WebConf.chat.setMessageSeen}`, body)
    .then((response) => (response.status === 200 ? true : false));
};

export const getLastMsgList = (): Promise<any> =>
  axios
    .get(OmniServerUrl + WebConf.chat.sendLastMsg)
    .then((response) => response && response.data);

export const setMaxConversationCount = (
  userId: number,
  conversationCount: number,
): Promise<any> => {
  const formdata = new FormData();
  formdata.append("maxConversationCount", String(conversationCount));
  formdata.append("userId", String(userId));
  const body = formdata;
  return axios
    .post(OmniServerUrl + WebConf.chat.maxConversationCount, body)
    .then((response) => response && response.data);
};
