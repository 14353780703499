import React from "react";

interface ITabPanel {
  index: number;
  activeTabIndex: number;
  style?: any;
  display?: string;
  children: any;
}
export default function TabPanel(props: ITabPanel) {
  const display = props.display ?? "block";
  const style = props.style ?? {};

  return (
    <div
      style={{
        display: props.activeTabIndex === props.index ? display : "none",
        ...style,
      }}>
      {props.children}
    </div>
  );
}
