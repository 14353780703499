import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  styled,
} from "@material-ui/core";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import React from "react";
import { formatDateTwo } from "Utils/Utils";

import { headers } from "./constants";

const RequestTable = (props): React.ReactElement => {
  const { translate } = useTranslation();
  const { requests } = props;

  return (
    <Container>
      <Table size='small' style={{ marginTop: 15 }}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <HeaderTableCell key={index}>{header}</HeaderTableCell>
            ))}
          </TableRow>
        </TableHead>
        {requests && requests.length > 0 && (
          <TableBody>
            {requests.map((request) => (
              <TableRow key={request.id}>
                <Cell>{request.agentFullName}</Cell>
                <Cell>{request.queueName}</Cell>
                <Cell>
                  {request.topics &&
                    request.topics.map((topic, index) => (
                      <Topic key={index}>{topic},</Topic>
                    ))}
                </Cell>
                <Cell>{request.communicationType}</Cell>
                <Cell>{request.status}</Cell>
                <Cell>{formatDateTwo(request.startDate)}</Cell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {requests.length === 0 && (
        <Warning>{translate("request_no_previous")}</Warning>
      )}
    </Container>
  );
};

export default RequestTable;

const Container = styled(TableContainer)({
  textAlign: "left",
  height: "100%",
  maxHeight: "100%",
  minHeight: "100%",
  marginTop: 10,
  "&::-webkit-scrollbar": {
    width: 3,
    height: 5,
  },
});

const HeaderTableCell = styled(TableCell)({
  color: "#81808A",
  fontSize: 12,
  fontWeight: 600,
  letterSpacing: "0.02em",
  lineHeight: "140%",
});

const Warning = styled("span")({
  fontWeight: 600,
  marginTop: 30,
  display: "block",
  color: "#81808A",
  fontSize: 12,
  lineHeight: "160%",
  letterSpacing: "0.04em",
  textAlign: "center",
});

const Cell = styled(TableCell)({
  color: "#050038",
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  paddingTop: 5,
  paddingBottom: 5,
});

const Topic = styled("div")({
  textAlign: "left",
  width: "60%",
});
