import React from "react";
import { styled } from "@material-ui/core/styles";

interface ITransferTab {
  name?: string;
  active?: boolean;
  onClick?: Function;
  isCurrentTab?: boolean;
  index?: number;
}

const TransferTab = ({ name, active, onClick }: ITransferTab) => {
  const TabName = styled("div")({
    textAlign: "center",
    padding: "0 10px",
    fontWeight: 600,
    fontSize: 13,
    letterSpacing: "0.04em",
    color: active ? "#3f51b5" : "#050038",
  });

  return (
    <TabContainer onClick={() => onClick()}>
      <TabName>{name}</TabName>
    </TabContainer>
  );
};

export default TransferTab;

const TabContainer = styled("div")({
  display: "inline-block",
  cursor: "pointer",
});

export const TransferContainer = styled("div")({
  display: "flex",
  height: "40px",
  marginTop: "10px",
  alignItems: "center",
  justifyContent: "space-around",
});
