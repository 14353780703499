import React, { useContext, useMemo, useState } from "react";

import {
  Paper,
  Divider,
  IconButton,
  InputBase,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import SearchIcon from "@material-ui/icons/Search";

import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import withFieldOnchangeTImeout from "Hoc/withFieldOnchangeTImeout";

import FilterDrawer from "./FilterDrawer";
import { StoreContext } from "./Table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    paper: {
      padding: "2px 4px",
      display: "flex",
      border: "1px solid " + grey[200],
      alignItems: "center",
      borderRadius: "30px",
      position: "relative",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
);

interface ISearch {
  handleChange(value: string): void;
  showFilterDrawer: boolean;
}

export default function Search(props: ISearch) {
  const classes = useStyles();
  const { translate } = useTranslation();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const store: any = useContext(StoreContext);

  const toggleDrawer = () => {
    if (drawerIsOpen) setDrawerIsOpen(false);
    else setDrawerIsOpen(true);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    props.handleChange(value);
  };

  const MainInput = useMemo(() => withFieldOnchangeTImeout(InputBase), []);

  return (
    <div className={classes.root}>
      <Paper variant='outlined' className={classes.paper}>
        <MainInput
          className={classes.input}
          onClick={toggleDrawer}
          onChange={handleChange}
          placeholder={translate("filter_and_search")}
          inputProps={{ "aria-label": translate("filter_and_search") }}
          value=''
        />
        <Divider className={classes.divider} orientation='vertical' />
        <IconButton
          type='button'
          className={classes.iconButton}
          aria-label={translate("search")}>
          <SearchIcon />
        </IconButton>
      </Paper>
      {props.showFilterDrawer && (
        <FilterDrawer
          open={drawerIsOpen}
          setOpen={setDrawerIsOpen}
          handleChange={() => {}}
          setSearchFieldSet={store.searchByFields}
          resetFieldValues={store.resetFieldValues}
        />
      )}
    </div>
  );
}
