/* eslint-disable @typescript-eslint/no-var-requires */
import { cancelBreak } from "Api/configuration";
import { getSipNumberByUserId } from "Api/voip";

import menuTypes from "Assets/constants/menuTypes";

import { StoreContext } from "Components/Layout/AppLayout";

import { ISoftStore } from "Components/Layout/interfaces";
import useUpdateEffect from "Hook/useUpdateEffect";
import { requestPermission } from "Notifications/DesktopNotification";

import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import Call from "Store/Actions/Call";
import Request from "Store/Actions/Request";
import SoftPhoneStore from "Store/Actions/SoftPhone";

import { ILines } from "Store/Reducers/interfaces/softPhoneReducer.types";

import { callTypes } from "../constants";
import { SoftPhoneHookProps } from "../interfaces";

const tone = require("Assets/sounds/ringtone.mp3");
const _ = require("lodash");

export const useSoftPhone = (): SoftPhoneHookProps => {
  const [ringtone, setRingtone] = useState<HTMLAudioElement>(null);

  const softStore: ISoftStore = useContext(StoreContext);

  const softPhoneStore = new SoftPhoneStore(useDispatch());
  const request = new Request(useDispatch());
  const call = new Call(useDispatch());

  const {
    lines,
    currentLine,
    isSavedBeforeACWCall,
    isSavedBeforeHoldCall,
    contextName,
    phoneNumber,
    softPhoneOpen,
    isCallInfoActive,
    isCompleteCallByAgent,
    selectedBreakId,
  } = useSelector(({ softPhoneReducer, breakReducer }: RootStateOrAny) => ({
    lines: softPhoneReducer.lines,
    currentLine: softPhoneReducer.currentLine,
    isSavedBeforeACWCall: softPhoneReducer.isSavedBeforeACWCall,
    isSavedBeforeHoldCall: softPhoneReducer.isSavedBeforeHoldCall,
    contextName: softPhoneReducer.contextName,
    phoneNumber: softPhoneReducer.phoneNumber,
    softPhoneOpen: softPhoneReducer.open,
    isCallInfoActive: softPhoneReducer.isCallInfoActive,
    isCompleteCallByAgent: softPhoneReducer.isCompleteCallByAgent,
    selectedBreakId: breakReducer.selectedBreakId,
  }));

  const userId = localStorage.getItem("userID");
  const callInfo = lines[currentLine];

  const onComponentIsLoaded = (): void => {
    requestPermission();
    if (!softStore.sipNumber.get()) fetchSipNumberByUserId();
    setRingtone(new Audio(tone));
  };

  const handleCompleteCallBy = async (
    terminatedCallId: string,
  ): Promise<void> => {
    if (terminatedCallId !== null) {
      let completeCallByAgent = "system";
      let isAnswered = false;
      Object.values(lines as ILines).forEach((obj) => {
        if (obj && obj.sipCallId === terminatedCallId) {
          isAnswered = obj.isAnswered !== undefined && obj.isAnswered;
        }
      });
      if (isAnswered) {
        isCompleteCallByAgent
          ? (completeCallByAgent = "agent")
          : (completeCallByAgent = "caller");
      }
      softPhoneStore.setIsCompleteCallByAgent(false);
    }
  };

  const fetchSipNumberByUserId = async (): Promise<void> => {
    const fetchedSipNumber = (await getSipNumberByUserId(userId)) ?? null;
    const filteredSipNum = fetchedSipNumber?.replace(/[^0-9]+/, "") ?? null;
    softStore.sipNumber.set(filteredSipNum);
    localStorage.setItem("sipNumber", filteredSipNum);
  };

  const closeRegisteredCall = (): void => {
    softPhoneStore.emptyLine(currentLine);
    request.setShowRegistration(false);
  };

  const checkIfCallIsAnswered = (): boolean => {
    let callIsAnswered = false;

    _.forIn(lines, function (value) {
      if (value && value.sipCallId === softStore.terminatedCallId.get())
        if (value.isAnswered) callIsAnswered = true;
    });

    return callIsAnswered;
  };

  const handleCallIsTerminated = async (terminatedCallId): Promise<void> => {
    call.setOutCallAnimation(false);
    handleCompleteCallBy(terminatedCallId);
    const callData = lines[currentLine];
    if (callData && callData.callIsRegistered) closeRegisteredCall();
    else {
      softPhoneStore.toggleCallIsEnded(true, currentLine);
      if (checkIfCallIsAnswered()) {
        Object.keys(lines).map((key) => {
          if (lines[key]?.sipCallId === terminatedCallId)
            softPhoneStore.emptyLine(key);
        });

        if (
          localStorage.getItem("autoAcw") === "true" &&
          !isSavedBeforeACWCall
        ) {
          if (selectedBreakId === "default") {
            softPhoneStore.setBreakInCall(true);
          }
        } else if (isSavedBeforeACWCall || isSavedBeforeHoldCall) {
          cancelBreak(+userId).then((response) => {
            if (!response) throw new Error();
          });
        }
      } else {
        if (callData) softPhoneStore.emptyLineBySipCallId(terminatedCallId);
      }
    }
  };

  const makeCall = (): void => {
    const callId = softStore?.sipStorage.makeCall(contextName + phoneNumber);

    softStore && softStore.callIsEstablishing.set(true);
    softStore && softStore.savingCallId.set(callId);
    const updatedLines = { ...lines };
    for (const lineId in Object.keys(updatedLines)) {
      if (!updatedLines[lineId]) {
        updatedLines[lineId] = {
          answeredAt: new Date(),
          callType: callTypes.OUT,
          sipCallId: callId,
          callerName: phoneNumber,
          number: phoneNumber,
          isSavedInCall: false,
          isAnswered: false,
          duration: 0,
        };
        break;
      }
    }
    softPhoneStore.setLines(updatedLines);
    // softPhoneStore.setLinesAfterAccepted(updatedLines);
    softPhoneStore.triggerOutgoingCall(true);
    request.setShowRegistration(true);
    request.setMenuType(menuTypes.CALL);
    request.addRequestOption(menuTypes.CALL, "0", {
      id: 9,
      name: "Tamamlanmamış",
    });

    if (localStorage.getItem("autoAcw") === "true") {
      softPhoneStore.setAcwCallOver(false);
      softPhoneStore.setSaveBeforeACWCallOver(false);
    }
  };

  useEffect(onComponentIsLoaded, []);

  useEffect(() => {
    (async function anyNameFunction() {
      softStore.outgoingRingTone.set(
        new Audio(await require("Assets/sounds/outRing.mp3")),
      );
    })();
  }, []);

  useUpdateEffect(() => {
    if (softStore.newIncomingCallId.get()) {
      ringtone.play();
      ringtone.loop = true;
    } else {
      ringtone.pause();
      ringtone.loop = false;
    }
  }, [softStore.newIncomingCallId.get()]);

  useUpdateEffect(() => {
    handleCallIsTerminated(softStore.terminatedCallId.get());
  }, [softStore.terminatedCallId.get()]);

  useEffect(() => {
    if (callInfo) softPhoneStore.setCallInfoStatus(true);
    else softPhoneStore.setCallInfoStatus(false);
  }, [callInfo]);

  useEffect(() => {
    softPhoneStore.holdAllLinesExceptOne(currentLine);
    _.forIn(lines, (value) => {
      if (value)
        if (softStore.sipStorage) softStore.sipStorage.hold(value.sipCallId);
    });
  }, [currentLine]);

  return {
    softPhoneOpen,
    callInfo,
    isCallInfoActive,
    makeCall,
  };
};

useSoftPhone.displayName = "useSoftPhone";
