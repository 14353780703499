import { styled } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const NoDataComponent = styled("div")({
  position: "absolute",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: grey[500],
  fontSize: 12,
});
