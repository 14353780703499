import sessionStatuses from "Assets/constants/sessionStatuses";
import { authActions as actionTypes } from "./actionTypes";

export default class Auth {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setSessionStatus = (status: sessionStatuses) =>
    this.dispatch({
      type: actionTypes.SET_SESSION_STATUS,
      status: status,
    });
}
