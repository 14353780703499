import { makeStyles, createStyles } from "@material-ui/core";

export const useIbarIntegrationSearchPanelStyles = makeStyles(() =>
  createStyles({
    cell: {
      border: "none",
    },
  }),
);

export const useSearchPanelTabsStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "block",
      width: "100%",
      backgroundColor: "white",
    },
  }),
);
