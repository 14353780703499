import { styled } from "@material-ui/core";
import { RequestContextStore } from "Components/Layout/AppLayout";
import React, { useContext } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";

import Topic from "Store/Actions/Topic";
import { defaultStates as defaultTopicStates } from "Store/Reducers/topicReducer";

export const Notes = (): React.ReactElement => {
  const topic = new Topic(useDispatch());
  const { menuType } = useSelector(({ requestReducer }: RootStateOrAny) => ({
    menuType: requestReducer.menuType,
  }));
  const totalTopicState = useSelector(
    (state: RootStateOrAny) => state.topicReducer,
  );
  const { getCurrentStateFromTotalState, getRequestId }: any =
    useContext(RequestContextStore);
  const topicState = getCurrentStateFromTotalState(
    totalTopicState,
    defaultTopicStates,
  );

  const handleNoteChange = (id, note): void => {
    topic.addPathNote(menuType, getRequestId(), id, note);
  };

  return (
    <Section>
      {topicState.savedPaths.length > 0 ? (
        topicState.savedPaths.map((path) => (
          <FormControl key={path.id}>
            <Label>{path.path}</Label>
            <StyledTextarea
              value={path?.note}
              rows={5}
              placeholder={`Drop a note for classificator ${path.path}`}
              onChange={(event) => {
                handleNoteChange(path.id, event.target.value);
              }}
            />
          </FormControl>
        ))
      ) : (
        <span>Choose a classificator to drop a note</span>
      )}
    </Section>
  );
};

Notes.displayName = "Notes";

const Section = styled("div")({
  padding: 10,
});

const StyledTextarea = styled("textarea")({
  width: "100%",
  color: "#050038",
  height: 100,
  boxSizing: "border-box",
  resize: "none",
  padding: 10,
  borderColor: "rgba(2, 1, 16, 0.4)",
  borderRadius: 5,
  overflow: "hidden",
});

const Label = styled("label")({
  color: "#050038",
  textAlign: "left",
  display: "block",
});

const FormControl = styled("div")({
  margin: "10px auto",
  width: "90%",
});
