import menuTypes from "Assets/constants/menuTypes";

import {
  IState,
  IFormFields,
  ICchReducer,
  IAction,
} from "./interfaces/cchReducer.types";
import { cchActions as actionTypes } from "../Actions/actionTypes";
import {
  getRequestStates,
  updateObject,
  updateTotalState,
  deepClone,
} from "../utility";

const initialState: IState = {
  [menuTypes.CALL]: {},
  [menuTypes.CHAT]: {},
};

const defaultFormFields: IFormFields = {
  id: null,
  categoryId: null,
  dueDate: "",
  owners: [],
  globalTagValues: [],
  advancedTagValues: [],
  attachments: [],
  content: "",
  callbackNumber: "",
  categoryName: "",
  created: false,
  customer: {
    id: localStorage.getItem("userID"),
    fullName:
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName"),
  },
};

export const defaultStates: ICchReducer = {
  formValues: { ...defaultFormFields },
  allSavedCchs: [],
};

const setFormValues = (state: IState, action: Partial<IAction>): IState => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    formValues: action.formValues,
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const addSavedCch = (state: IState, action: Partial<IAction>): IState => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestSatates = updateObject(requestSatates, {
    allSavedCchs: [...requestSatates.allSavedCchs, action.savedCch],
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const addAllCCHs = (state: IState, action: Partial<IAction>): IState => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );
  requestSatates = updateObject(requestSatates, {
    allSavedCchs: [...action.allSavedCchs],
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const removeSavedCch = (state: IState, action: Partial<IAction>): IState => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  const updatedCch = requestSatates.allSavedCchs.filter(
    (cch) => cch.id !== action.id,
  );

  requestSatates = updateObject(requestSatates, { allSavedCchs: updatedCch });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const resetFormValues = (state: IState, action: Partial<IAction>): IState => {
  let requestSatates = getRequestStates(
    defaultStates,
    state,
    action.menuType,
    action.requestId,
  );

  requestSatates = updateObject(requestSatates, {
    formValues: {
      ...defaultFormFields,
      globalTagValues: [],
      advancedTagValues: [],
    },
  });

  return updateTotalState(
    state,
    action.menuType,
    action.requestId,
    requestSatates,
  );
};

const clearTasksByRequestId = (
  state: IState,
  action: Partial<IAction>,
): IState => {
  const tasks = deepClone(state[action.menuType]);

  delete tasks[action.requestId];

  return updateObject(state, { [action.menuType]: tasks });
};

const cchReducer = (state = initialState, action: Partial<IAction>): IState => {
  switch (action.type) {
    case actionTypes.SET_FORM_FIELDS_VALUES:
      return setFormValues(state, action);
    case actionTypes.ADD_SAVED_CCH:
      return addSavedCch(state, action);
    case actionTypes.ADD_ALL_CCHS:
      return addAllCCHs(state, action);
    case actionTypes.REMOVE_SAVED_CCH:
      return removeSavedCch(state, action);
    case actionTypes.CLEAR_TASKS_BY_REQUEST_ID:
      return clearTasksByRequestId(state, action);
    case actionTypes.RESET_TASK_FORM_FIELDS:
      return resetFormValues(state, action);
    default:
      return state;
  }
};

export default cchReducer;
