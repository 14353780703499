import menuTypes from "Assets/constants/menuTypes";
import { useCallback } from "react";
import { faqActions as actionTypes } from "./actionTypes";

export default class Faq {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setCategorySearchValue = useCallback(
    (menuType: menuTypes, requestId: number, value: string) =>
      this.dispatch({
        type: actionTypes.SET_CATEGORY_SEARCH_VALUE,
        value,
        menuType,
        requestId,
      }),
    [this],
  );

  setExpandAllCategories = useCallback(
    (menuType: menuTypes, requestId: number, expandAll: boolean) =>
      this.dispatch({
        type: actionTypes.SET_EXPAND_ALL_CATEGORIES,
        expandAll,
        menuType,
        requestId,
      }),
    [this],
  );

  setSelectedCategory = useCallback(
    (menuType: menuTypes, requestId: number, selectedCategory: any) =>
      this.dispatch({
        type: actionTypes.SET_SELECTED_CATEGORY,
        selectedCategory,
        menuType,
        requestId,
      }),
    [this],
  );

  setQuestionSearchValue = useCallback(
    (menuType: menuTypes, requestId: number, value: string) =>
      this.dispatch({
        type: actionTypes.SET_QUESTION_SEARCH_VALUE,
        value: value,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setSelectedQuestion = useCallback(
    (menuType: menuTypes, requestId: number, selectedQuestion: any) =>
      this.dispatch({
        type: actionTypes.SET_SELECTED_QUESTION,
        selectedQuestion: selectedQuestion,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setAllCategories = useCallback(
    (menuType: menuTypes, requestId: number, categories: any) =>
      this.dispatch({
        type: actionTypes.SET_ALL_CATEGORIES,
        categories: categories,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setOriginalCategories = useCallback(
    (menuType: menuTypes, requestId: number, originalCategories: any) =>
      this.dispatch({
        type: actionTypes.SET_ORIGINAL_CATEGORIES,
        originalCategories,
        menuType,
        requestId,
      }),
    [this],
  );

  setAllQuestions = useCallback(
    (menuType: menuTypes, requestId: number, questions: Array<Object>) =>
      this.dispatch({
        type: actionTypes.SET_ALL_QUESTIONS,
        questions,
        menuType,
        requestId,
      }),
    [this],
  );

  setSelectedAnswer = useCallback(
    (menuType: menuTypes, requestId: number, answer: string) =>
      this.dispatch({
        type: actionTypes.SET_SELECTED_ANSWER,
        answer: answer,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  addSavedFaq = useCallback(
    (menuType: menuTypes, requestId: number, savedFaq: any) =>
      this.dispatch({
        type: actionTypes.ADD_SAVED_FAQ,
        savedFaq,
        menuType,
        requestId,
      }),
    [this],
  );

  setAllSavedFaq = useCallback(
    (menuType: menuTypes, requestId: number, allSavedFaq: any) =>
      this.dispatch({
        type: actionTypes.SET_ALL_SAVED_FAQ,
        allSavedFaq,
        menuType,
        requestId,
      }),
    [this],
  );

  removeSavedFaq = useCallback(
    (menuType: menuTypes, requestId: number, faqId: number) =>
      this.dispatch({
        type: actionTypes.REMOVE_SAVED_FAQ,
        faqId,
        menuType,
        requestId,
      }),
    [this],
  );

  clearAllSavedFaq = useCallback(
    (menuType: menuTypes, requestId: number) =>
      this.dispatch({
        type: actionTypes.CLEAR_ALL_SAVED_FAQ,
        menuType,
        requestId,
      }),
    [this],
  );

  clearAllFields = useCallback(
    (menuType: menuTypes, requestId: number) =>
      this.dispatch({
        type: actionTypes.CLEAR_ALL_FIELDS,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  clearAllData = useCallback(
    (menuType: menuTypes, requestId: number) =>
      this.dispatch({
        type: actionTypes.CLEAR_ALL_DATA,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setActiveSavedFaq = useCallback(
    (menuType: menuTypes, requestId: number, id: number) =>
      this.dispatch({
        type: actionTypes.SET_ACTIVE_SAVED_FAQ,
        id: id,
        menuType: menuType,
        requestId: requestId,
      }),
    [this],
  );

  setSearchBy = useCallback(
    (menuType: menuTypes, requestId: number, searchBy: string) =>
      this.dispatch({
        type: actionTypes.SET_SEARCH_BY,
        searchBy,
        menuType,
        requestId,
      }),
    [this],
  );
}
