import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useInCallActionButtonContainerStyles = makeStyles((theme) => ({
  popover: {
    padding: "20px 30px 30px",
    minWidth: "160px",
    maxHeight: "500px",
    backgroundColor: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sipInput: {
    margin: theme.spacing(1),
    minWidth: 120,
    color: "#050038",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "8px",
  },
  buttonClass: {
    width: "40%",
    alignItems: "center",
    margin: "0 auto",
    backgroundColor: "rgba(210, 210, 210)",
    color: "#050038",
  },
  divider: {
    backgroundColor: "#050038",
  },
  radioGroup: {
    padding: "5px 10px",
  },
  radioItem: {
    fontSize: "13px",
    color: "#050038",
    letterSpacing: "0.04em",
    lineHeight: "160%",
  },
  formContainer: {
    maxHeight: "300px",
    overflowY: "auto",
  },
  listForm: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    background: "white",
    cursor: "pointer",
  },
  inputHolder: {
    fontSize: "0.8rem",
    letterSpacing: "0.05em",
    padding: "10px 12px",
  },
  labelInput: {
    marginLeft: "10px",
    marginTop: "5px",
  },
  ivrTabs: {
    "& .MuiTab-root": {
      minWidth: "auto",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
  },
}));

export const useSoftPhoneStyles = makeStyles(() =>
  createStyles({
    content: {
      padding: 20,
    },
  }),
);
