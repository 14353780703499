import React from "react";

import InformationTable from "./InformationTable";
import { accountColumns as columns } from "./constants";

const AccountTable = ({ rows, fetchRows }) => {
  return (
    <InformationTable rows={rows} fetchRows={fetchRows} columns={columns} />
  );
};

export default AccountTable;
