import {
  Grid,
  Popover,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Container, InputLabel, Input } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

import {
  MicOffOutlinedIcon,
  PauseIcon,
  DialpadIcon,
  ReplyIcon,
} from "Assets/icons";
import Button from "Components/Buttons/Button";
import { buttonTypes } from "Components/Buttons/buttonTypes";

import { permissions } from "Config/config";
import React from "react";

import { permit } from "Utils/Utils";

import { actionTypes, transferTabs, colors } from "./constants";
import { useInCallActionButtonContainer } from "./hooks";
import InCallActionbutton from "./InCallActionButton";
import { useInCallActionButtonContainerStyles } from "./styles";
import TransferTab, { TransferContainer } from "./TransferTab";

const InCallActionButtonContainer = (): React.ReactElement => {
  const { translate } = useTranslation();
  const classes = useInCallActionButtonContainerStyles();

  const {
    muted,
    holded,
    transferred,
    number,
    currentTransferTab,
    selectedVoip,
    voipList,
    selectedUser,
    selectedIvr,
    userList,
    ivrList,
    searchUserString,
    searchIvrString,
    ivrTabValue,
    tabList,
    handleIvrTabChange,
    toggleMuted,
    toggleHolded,
    handleCallInfo,
    toggleTransfer,
    handleClose,
    handleChangeNumber,
    setCurrentTransferTab,
    handleQueueChange,
    handleUserChange,
    handleIvrChange,
    handleSendCall,
    handleSearchUsers,
    handleSearchIvr,
  } = useInCallActionButtonContainer();

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={5}>
            <InCallActionbutton
              active={muted}
              type={actionTypes.muteCall}
              name={translate("softphone_mute")}
              Icon={MicOffOutlinedIcon}
              onClick={toggleMuted}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <InCallActionbutton
              active={holded}
              type={actionTypes.holdCall}
              name={translate("softphone_pause")}
              Icon={PauseIcon}
              onClick={toggleHolded}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 8 }}>
          <Grid item xs={5}>
            <InCallActionbutton
              name={translate("softphone_keypad")}
              Icon={DialpadIcon}
              onClick={handleCallInfo}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <InCallActionbutton
              active={transferred}
              type={actionTypes.transferCall}
              name={translate("softphone_transfer")}
              Icon={ReplyIcon}
              onClick={toggleTransfer}
            />
          </Grid>
        </Grid>
      </Container>
      <Popover
        open={transferred}
        style={{
          left: "390px",
          top: "calc(var(--nav-bar-height) - 18px)",
          maxHeight: "100%",
          borderRadius: 10,
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <div className={classes.popover}>
          <FormControl required className={classes.sipInput}>
            <Input
              type='text'
              value={number}
              onChange={handleChangeNumber}
              placeholder={translate("softphone_type_sip_number")}
            />
          </FormControl>
          <Divider className={classes.divider} />
          <TransferContainer>
            <TransferTab
              name={translate("softphone_voip_queues")}
              active={currentTransferTab === transferTabs.queues}
              onClick={() => {
                setCurrentTransferTab(transferTabs.queues);
              }}
            />
            <TransferTab
              name={translate("softphone_users")}
              active={currentTransferTab === transferTabs.users}
              onClick={() => {
                setCurrentTransferTab(transferTabs.users);
              }}
            />
            {permit(
              <TransferTab
                name={translate("softphone_ivr")}
                active={currentTransferTab === transferTabs.ivr}
                onClick={() => {
                  setCurrentTransferTab(transferTabs.ivr);
                }}
              />,
              permissions.voip_redirect_to_ivr,
            )}
          </TransferContainer>
          <div className={classes.formContainer}>
            {currentTransferTab === transferTabs.queues ? (
              <FormControl component='fieldset' className={classes.formControl}>
                <RadioGroup
                  aria-label='voipQueues'
                  name='voipQueues'
                  className={classes.radioGroup}
                  value={selectedVoip}
                  onChange={handleQueueChange}>
                  {voipList.length > 0 &&
                    voipList.map((voip, index) => {
                      return (
                        <span key={index}>
                          <FormControlLabel
                            disabled={number.length > 0 ? true : false}
                            value={voip.name}
                            control={
                              <Radio
                                color='primary'
                                style={{ color: "#050038" }}
                              />
                            }
                            label={
                              <Typography className={classes.radioItem}>
                                {voip.name}
                              </Typography>
                            }
                          />
                        </span>
                      );
                    })}
                </RadioGroup>
              </FormControl>
            ) : currentTransferTab === transferTabs.users ? (
              <FormControl component='fieldset' className={classes.formControl}>
                <FormControl fullWidth>
                  <InputLabel htmlFor='search' className={classes.labelInput}>
                    {translate("search")}
                  </InputLabel>
                  <Input
                    className={classes.inputHolder}
                    id='search'
                    type='text'
                    placeholder={translate("search")}
                    autoComplete='off'
                    value={searchUserString}
                    onChange={handleSearchUsers}
                    onClick={(e) => e.stopPropagation()}
                  />
                </FormControl>
                <RadioGroup
                  aria-label='users'
                  name='users'
                  className={classes.radioGroup}
                  value={selectedUser}
                  onChange={handleUserChange}>
                  {userList
                    .filter((user) =>
                      `${user.firstName} ${user.lastName}`
                        .toLowerCase()
                        .includes(searchUserString.toLowerCase()),
                    )
                    .sort((a, b) => {
                      const nameA =
                        `${a.firstName} ${a.lastName}`.toLowerCase();
                      const nameB =
                        `${b.firstName} ${b.lastName}`.toLowerCase();
                      return nameA.localeCompare(nameB);
                    })
                    .map((user, index) => (
                      <FormControlLabel
                        key={index}
                        disabled={number.length > 0}
                        value={user?.sipNumber && user?.sipNumber.slice(6)}
                        control={
                          <Radio color='primary' style={{ color: "#050038" }} />
                        }
                        label={
                          <Typography className={classes.radioItem}>
                            {user.firstName} {user.lastName}
                          </Typography>
                        }
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            ) : (
              ivrList.length > 0 && (
                <FormControl
                  component='fieldset'
                  className={classes.formControl}>
                  <Paper>
                    <Tabs
                      value={ivrTabValue}
                      indicatorColor='primary'
                      textColor='primary'
                      className={classes.ivrTabs}
                      onChange={handleIvrTabChange}>
                      {tabList.map((tab) => (
                        <Tab key={tab} label={tab} value={tab} />
                      ))}
                    </Tabs>
                  </Paper>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='search' className={classes.labelInput}>
                      {translate("search")}
                    </InputLabel>
                    <Input
                      className={classes.inputHolder}
                      id='search'
                      type='text'
                      placeholder={translate("search")}
                      autoComplete='off'
                      value={searchIvrString}
                      onChange={handleSearchIvr}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </FormControl>
                  <FormControl
                    component='fieldset'
                    className={classes.formControl}>
                    <RadioGroup
                      aria-label='ivr'
                      name='ivr'
                      className={classes.radioGroup}
                      value={selectedIvr}
                      onChange={handleIvrChange}>
                      {ivrList.length > 0 &&
                        ivrList
                          .filter((ivr) =>
                            ivr.name
                              .toLowerCase()
                              .includes(searchIvrString.toLowerCase()),
                          )
                          .map((ivr, index) => (
                            <span key={index}>
                              <FormControlLabel
                                key={ivr.id}
                                disabled={number.length > 0}
                                value={ivr.id}
                                control={
                                  <Radio
                                    color='primary'
                                    style={{ color: "#050038" }}
                                  />
                                }
                                label={
                                  <Typography className={classes.radioItem}>
                                    {ivr.name}
                                  </Typography>
                                }
                              />
                            </span>
                          ))}
                    </RadioGroup>
                  </FormControl>
                </FormControl>
              )
            )}
          </div>

          <div className={classes.buttonContainer}>
            <Button
              disabled={
                number.length === 0 &&
                selectedVoip.length === 0 &&
                selectedUser.length === 0 &&
                selectedIvr === null
              }
              className={classes.buttonClass}
              onClick={handleSendCall}
              buttonType={buttonTypes.success}>
              {translate("send")}
            </Button>
            <Button
              className={classes.buttonClass}
              onClick={handleClose}
              buttonType={buttonTypes.primary}>
              {translate("close")}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default InCallActionButtonContainer;

InCallActionButtonContainer.displayName = "InCallActionButtonContainer";
