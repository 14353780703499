import i18n from "i18n/config";

export const cardColumns = [
  {
    name: "accountNumber",
    alias: i18n.t("request_account_number"),
    sortable: false,
  },
  { name: "cardId", alias: i18n.t("request_card_id"), sortable: false },
  { name: "cardNo", alias: i18n.t("request_card_no"), sortable: false },
  { name: "cardStatus", alias: i18n.t("request_card_status"), sortable: false },
  { name: "balance", alias: i18n.t("request_balance"), sortable: false },
  { name: "secretWord", alias: i18n.t("request_secret_word"), sortable: false },
  {
    name: "productName",
    alias: i18n.t("request_product_name"),
    sortable: false,
  },
  { name: "expiryDate", alias: i18n.t("request_expiry_date"), sortable: false },
  {
    name: "blockedAmount",
    alias: i18n.t("request_blocked_amount"),
    sortable: false,
  },
  { name: "actions", alias: i18n.t("request_actions"), sortable: false },
];
