/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

/**
 * A custom useEffect hook that only triggers on updates, not on initial mount
 * Idea stolen from: https://stackoverflow.com/a/55075818/1526448
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */
export default async function useUpdateEffect(
  effect,
  dependencies: Array<any> = [],
) {
  const isInitialMount = useRef(true);

  useEffect(() => {
    (async function anyNameFunction() {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        effect();
      }
    })();
  }, dependencies);
}
