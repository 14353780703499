import { Grid, styled } from "@material-ui/core";
import React, { useState } from "react";

import Cch from "./Cch/Cch";
import { tabs } from "./constants";
import { Notes } from "./Notes/Notes";
import RightPanelTabs from "./RightPanelTabs";
import Main from "./Topics/Main";

const RequestRightPanel = (): React.ReactElement => {
  const [currentTab, setCurrentTab] = useState(tabs.Topics.name);

  let CurrentComponent = null;

  switch (currentTab) {
    case tabs.Tasks.name:
      CurrentComponent = <Cch />;
      break;
    case tabs.Topics.name:
      CurrentComponent = <Main />;
      break;
    case tabs.Notes.name:
      CurrentComponent = <Notes />;
      break;
    default:
      CurrentComponent = <div></div>;
  }

  return (
    <StyledRequestRightPanel item xs={8}>
      <RightPanelTabs setCurrentTab={setCurrentTab} currentTab={currentTab} />

      {CurrentComponent}
    </StyledRequestRightPanel>
  );
};

export default RequestRightPanel;

RequestRightPanel.displayName = "RequestRightPanel";

const StyledRequestRightPanel = styled(Grid)({
  overflow: "auto",
  backgroundColor: "white",
  height: "100%",
  "&::-webkit-scrollbar": {
    width: 3,
    height: 6,
  },
});
