/* eslint-disable jsx-a11y/iframe-has-title */
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import Drawer from "Components/Drawer/Drawer";
import React, { useEffect, useState } from "react";

interface ICustomerIntegrationSearchPanel {
  html: any;
  setHtml: Function;
}

export default function CustomerIntegrationSearchPanel(
  props: ICustomerIntegrationSearchPanel,
) {
  const { translate } = useTranslation();
  const [src, setSrc] = useState("");

  useEffect(() => {
    if (props.html.length > 0) {
      const blob = new Blob([props.html], { type: "text/html; charset=utf-8" });
      setSrc(URL.createObjectURL(blob));
    }
  }, [props.html]);

  return (
    <div>
      <Drawer
        open={props.html.length > 0}
        handleClose={() => {
          props.setHtml("");
        }}>
        <DialogTitle>{translate("crm_card_customer_search")}</DialogTitle>
        <DialogContent dividers>
          <iframe
            style={{
              width: "100%",
              display: "block",
              height: "100%",
              overflow: "hidden",
            }}
            src={src}></iframe>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.setHtml("");
            }}>
            {translate("close")}
          </Button>
        </DialogActions>
      </Drawer>
    </div>
  );
}
