/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { styled } from "@material-ui/core";

import { getAllCategories } from "Api/faq";

import { defaultStates as defaultFaqStates } from "Store/Reducers/faqReducer";
import Faq from "Store/Actions/Faq";
import { RequestContextStore } from "Components/Layout/AppLayout";

import Category from "./Category";

export const childrenHasSearchedString = (children, searchValue) => {
  let isMatch = false;
  function recursion(children, searchValue) {
    for (const key in children) {
      if (children.hasOwnProperty(key)) {
        const classificator = children[key];
        if (classificator.title.toLowerCase().includes(searchValue)) {
          isMatch = true;
          return;
        }
        if (classificator.children.length > 0)
          recursion(classificator.children, searchValue);
      }
    }
  }
  recursion(children, searchValue);
  return isMatch;
};

const Categories = () => {
  const totalFaqState = useSelector(
    (state: RootStateOrAny) => state.faqReducer,
  );
  const requestContextStore: any = useContext(RequestContextStore);
  const faqState = requestContextStore.getCurrentStateFromTotalState(
    totalFaqState,
    defaultFaqStates,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const faq = new Faq(useDispatch());

  const timeoutRef = useRef(null);

  const fetchCategories = () =>
    getAllCategories()
      .then((response) => {
        if (response) return response;
      })
      .catch((e) => {
        console.error(e);
      });

  useEffect(() => {
    fetchCategories()
      .then((res) => {
        if (res) {
          faq.setAllCategories(
            requestState.menuType,
            requestContextStore.getRequestId(),
            res,
          );
          faq.setOriginalCategories(
            requestState.menuType,
            requestContextStore.getRequestId(),
            res,
          );
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [
    requestState.menuType,
    requestContextStore.getRequestId(),
    requestState.showRegistration,
  ]);

  const checkIfMatch = (node) => {
    let isMatch = false;
    const moveThroughChildren = (node) => {
      for (const key in node) {
        if (node.hasOwnProperty(key)) {
          const category = node[key];
          if (
            category.title
              .toLowerCase()
              .includes(faqState.searchValue.toLowerCase())
          ) {
            isMatch = true;
            return;
          }
          if (category.children.length > 0)
            moveThroughChildren(category.children);
        }
      }
    };

    moveThroughChildren(node);
    return isMatch;
  };

  const createNewTree = () => {
    let newTree = [];
    if (faqState.searchValue !== "") {
      newTree = faqState.originalCategories
        .map((node) => {
          if (
            node.title
              .toLowerCase()
              .includes(faqState.searchValue.toLowerCase()) ||
            checkIfMatch(node.children)
          )
            return node;
        })
        .filter((elem) => elem !== undefined);
    } else newTree = faqState.originalCategories;

    faq.setAllCategories(
      requestState.menuType,
      requestContextStore.getRequestId(),
      newTree,
    );
  };

  useEffect(() => {
    if (faqState.originalCategories.length > 0) {
      if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;

        createNewTree();
      }, 500);
    }
  }, [faqState.searchValue]);

  return useMemo(
    () => (
      <StyledCategories>
        {faqState.allCategories &&
          faqState.allCategories.length > 0 &&
          faqState.allCategories.map((category) => (
            <Category
              key={category.id}
              id={category.id}
              title={category.title}
              faqCount={category.faqCount}
              children={category.children}
              depth={0}
            />
          ))}
      </StyledCategories>
    ),
    [
      requestState.menuType,
      requestContextStore.getRequestId(),
      faqState.searchValue,
      faqState.allCategories,
    ],
  );
};

export default Categories;

const StyledCategories = styled("div")({
  paddingBottom: 10,
  overflowY: "auto",
  height: "calc(100vh - var(--nav-bar-height) - 240px)",
  marginTop: 20,
  "&::-webkit-scrollbar": {
    width: 3,
    height: 5,
  },
});
