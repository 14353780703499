import axios from "Api/axios";
import WebConf, { JiraUrl } from "Config/WebConf";

import {
  ICustomField,
  IIssueType,
  IWholeTicket,
  ITicketSearchResult,
  IProject,
  IPermission,
  ITicketReportSearchResult,
} from "Pages/Jira/interfaces";

export const getCustomFields = (): Promise<Array<ICustomField>> =>
  axios
    .get(JiraUrl + WebConf.jira.getCustomFields)
    .then((response) => response && response.data);

export const refreshCustomFields = (): Promise<boolean> =>
  axios
    .post(JiraUrl + WebConf.jira.updateCustomFields)
    .then((response) => (response && response.status === 200 ? true : false));

export const getAllIssueTypes = (): Promise<Array<IIssueType>> =>
  axios
    .get(JiraUrl + WebConf.jira.getAllIssueTypes)
    .then((response) => response && response.data);

export const updateAllIssueTypes = (): Promise<boolean> =>
  axios
    .post(JiraUrl + WebConf.jira.updateAllIssueTypes)
    .then((response) => (response && response.status === 200 ? true : false));

export const getCustomFieldsByIssueType = (id): Promise<Array<ICustomField>> =>
  axios
    .get(JiraUrl + WebConf.jira.getCustomFieldsByIssueType(id))
    .then((response) => response && response.data);

export const setCustomFieldsByIssueType = (id, fieldIds): Promise<number> =>
  axios
    .post(JiraUrl + WebConf.jira.saveCustomFieldsByIssueType(id), fieldIds)
    .then((response) => response && response.data);

export const getSearchCustomFields = (): Promise<Array<ICustomField>> =>
  axios
    .get(JiraUrl + WebConf.jira.getSearchCustomFields)
    .then((response) => response && response.data);

export const getIssueTypesByProjectId = (id): Promise<Array<IIssueType>> =>
  axios
    .get(JiraUrl + WebConf.jira.getIssueTypesByProjectId(id))
    .then((response) => response && response.data);

export const searchTickets = (
  page: number,
  size: number,
  id: string,
  value: string,
): Promise<ITicketSearchResult> =>
  axios.get(JiraUrl + WebConf.jira.searchTickets(page, size, id, value)).then(
    (response) =>
      response && {
        content: response.data?.content,
        pageNumber: response.data?.pageable.pageNumber,
        totalElements: response.data?.totalElements,
        size: response.data?.size,
      },
  );

export const getIssueById = (id): Promise<Array<IWholeTicket>> =>
  axios
    .get(JiraUrl + WebConf.jira.getIssueById(id))
    .then((response) => response && response.data);

export const getProjects = (): Promise<Array<IProject>> =>
  axios
    .get(JiraUrl + WebConf.jira.getProjects)
    .then((response) => response && response.data);

export const updateAllProjects = (): Promise<boolean> =>
  axios
    .post(JiraUrl + WebConf.jira.updateProjects)
    .then((response) => (response && response.status === 200 ? true : false));

export const getUserByProjects = (
  permission: string | null,
): Promise<Array<IProject>> => {
  const permissionValue = permission.length > 0 ? permission : null;
  return axios
    .get(JiraUrl + WebConf.jira.getUserByProjects + permissionValue)
    .then((response) => response && response.data);
};

export const getPermissions = (): Promise<Array<IPermission>> =>
  axios
    .get(JiraUrl + WebConf.jira.getPermissions)
    .then((response) => response && response.data);

export const getStatuses = (): Promise<Array<string>> =>
  axios
    .get(JiraUrl + WebConf.jira.getStatuses)
    .then((response) => response && response.data);

export const savePermissionsByProject = (data): Promise<number> =>
  axios
    .post(JiraUrl + WebConf.jira.savePermissionsByProject, data)
    .then((response) => response && response.data);

export const getJiraReport = (data): Promise<ITicketReportSearchResult> =>
  axios.post(JiraUrl + WebConf.jira.getJiraReport, data).then(
    (response) =>
      response && {
        content: response.data?.content,
        pageNumber: response.data?.pageable.pageNumber,
        totalElements: response.data?.totalElements,
        size: response.data?.size,
      },
  );

export const postTicket = (data): Promise<any> =>
  axios
    .post(JiraUrl + WebConf.jira.postTicket, data)
    .then((response) => response && response.data);

export const saveAttachToTicket = (data): Promise<boolean> =>
  axios
    .post(JiraUrl + WebConf.jira.saveAttachToTicket, data)
    .then((response) => response && response.data);
