/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { styled } from "@material-ui/core";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { RequestContextStore } from "Components/Layout/AppLayout";
import React, { useContext, useMemo } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Note from "Store/Actions/Note";
import { defaultStates as defaultNoteStates } from "Store/Reducers/noteReducer";
import { formatDatetoHoursAndSecs } from "Utils/Utils";

import { ChatIcon, TelegramIcon } from "./Icons";
import AgentImage from "./Images/AgentImage.png";

const Notes = () => {
  const { translate } = useTranslation();
  const image = AgentImage;
  const totalNoteState = useSelector(
    (state: RootStateOrAny) => state.noteReducer,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const note = new Note(useDispatch());

  const requestContextStore: any = useContext(RequestContextStore);
  const noteState = requestContextStore.getCurrentStateFromTotalState(
    totalNoteState,
    defaultNoteStates,
  );

  const handleChange = (event) => {
    const { value } = event.target;

    note.setNewNote(
      requestState.menuType,
      requestContextStore.getRequestId(),
      value,
    );
  };

  return useMemo(
    () => (
      <Wrapper>
        <NoteHeader>
          <Icon />
          <Title>{translate("request_notes")}</Title>
        </NoteHeader>

        <InputContainer>
          <Input
            value={noteState.newNote}
            onChange={handleChange}
            placeholder={translate("request_write_comment")}
          />
          {/* <SendIcon /> */}
        </InputContainer>

        <NoteContainer>
          {noteState.notes &&
            noteState.notes.map((note, index) => (
              <RequestNote key={index}>
                <Header>
                  <NoteImage src={image} />
                  {note.agentName} {"  "}
                  {formatDatetoHoursAndSecs(note.createdTime)}
                </Header>
                <RequestBody>{note.note}</RequestBody>
              </RequestNote>
            ))}
        </NoteContainer>
      </Wrapper>
    ),
    [
      noteState.newNote,
      noteState.notes,
      requestState.menuType,
      requestContextStore.getRequestId(),
    ],
  );
};

export default Notes;

const Wrapper = styled("div")({
  zIndex: 2,
  position: "relative",
  backgroundColor: "white",
  maxHeight: "20%",
  height: "20%",
  minHeight: "20%",
  paddingTop: 10,
});

const Icon = styled(ChatIcon)({
  color: "#81808A",
  fontSize: 14,
  fontWeight: 600,
  position: "relative",
  top: 3,
  right: 5,
});

const Input = styled("input")({
  width: "95%",
  border: "none",
  outline: "none",
});

const SendIcon = styled(TelegramIcon)({
  color: "#F68F1F",
  fontSize: 20,
  fontWeight: 600,
  position: "relative",
  top: 2,
});

const InputContainer = styled("div")({
  borderTop: "1px solid #DCDDE6",
  borderBottom: "1px solid #DCDDE6",
  marginTop: 15,
  paddingTop: 10,
  paddingBottom: 10,
});

const NoteContainer = styled("div")({
  maxHeight: "70%",
  height: "70%",
  minHeight: "70%",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 3,
    height: 5,
  },
});

const Header = styled("div")({
  textAlign: "left",
  color: "rgba(5, 0, 56, .6)",
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.02em",
});

const NoteImage = styled("img")({
  width: 15,
  height: 15,
  border: "1px solid orange",
  borderRadius: 64,
  marginRight: 5,
  position: "relative",
  top: 3,
});

const RequestNote = styled("div")({
  padding: 10,
  "&:not(:first-child)": {
    marginTop: 15,
  },
});

const RequestBody = styled("span")({
  color: "#333239",
  fontSize: 13,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  marginLeft: 20,
  marginTop: 5,
  float: "left",
  textAlign: "left",
  display: "block",
});

const Title = styled("span")({
  color: "#020110",
  fontSize: 12,
  fontWeight: 600,
  letterSpacing: "0.04em",
  lineHeight: "160%",
  textTransform: "uppercase",
});

const NoteHeader = styled("div")({
  textAlign: "left",
  marginLeft: 10,
});
