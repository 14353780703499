import { useCallback } from "react";
import { socialMediaActions as actionTypes } from "./actionTypes";

export default class SocialMedia {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setActivePlatformId = useCallback(
    (activePlatformId: number) =>
      this.dispatch({
        type: actionTypes.SET_ACTIVE_PLATFORM_ID,
        activePlatformId,
      }),
    [this],
  );

  setActiveAccountId = useCallback(
    (activeAccountId: number) =>
      this.dispatch({
        type: actionTypes.SET_ACTIVE_ACCOUNT_ID,
        activeAccountId,
      }),
    [this],
  );

  setPlatforms = useCallback(
    (platforms: Array<Object>) =>
      this.dispatch({
        type: actionTypes.SET_PLATFORMS,
        platforms,
      }),
    [this],
  );

  setAccountsById = useCallback(
    (accountsById: Array<Object>) =>
      this.dispatch({
        type: actionTypes.SET_ACCOUNTS_BY_ID,
        accountsById,
      }),
    [this],
  );

  setPostsById = useCallback(
    (postsById: Object) =>
      this.dispatch({
        type: actionTypes.SET_POSTS_BY_ID,
        postsById,
      }),
    [this],
  );

  setActivePost = useCallback(
    (activePost: object) =>
      this.dispatch({
        type: actionTypes.SET_ACTIVE_POST,
        activePost,
      }),
    [this],
  );

  setActiveReplyMessageId = useCallback(
    (activeReplyMessageId: string) =>
      this.dispatch({
        type: actionTypes.SET_ACTIVE_REPLY_MESSAGE_ID,
        activeReplyMessageId,
      }),
    [this],
  );

  setCommentsByActivePost = useCallback(
    (commentsByActivePost: object) =>
      this.dispatch({
        type: actionTypes.SET_COMMENTS_BY_ACTIVE_POST,
        commentsByActivePost,
      }),
    [this],
  );

  setReplysByActivePost = useCallback(
    (replysByActivePost: Array<object>) =>
      this.dispatch({
        type: actionTypes.SET_REPLYS_BY_ACTIVE_POST,
        replysByActivePost,
      }),
    [this],
  );

  setUnRepliedComments = useCallback(
    (unRepliedComments: object) =>
      this.dispatch({
        type: actionTypes.SET_UN_REPLIED_COMMENTS,
        unRepliedComments,
      }),
    [this],
  );

  setActiveActionData = useCallback(
    (activeActionData: object | null) =>
      this.dispatch({
        type: actionTypes.SET_ACTIVE_ACTION_DATA,
        activeActionData,
      }),
    [this],
  );
}
