/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  IChatReducer,
  IAction,
  IActionArxiveConversation,
  INewMessageCount,
} from "./interfaces/chatReducer.types";
import { chatActionTypes } from "../Actions/actionTypes";
import { deepClone, updateObject } from "../utility";

const initialState: IChatReducer = {
  open: false,
  isChatEnded: false,
  isHidden: true,
  conversations: {},
  activeConversationId: null,
  replyMessageView: false,
  replyMessageData: {},
  chatFullWidth: false,
  messages: {},
  isSendLastMsg: null,
  isSendMessage: null,
  closeActiveChat: null,
};

const setMessageSent = (state: IChatReducer, action: IAction): IChatReducer => {
  const updatedConversations = deepClone(state.conversations);
  const messages = deepClone(state.messages);

  updatedConversations[action.conversationId].messageList[
    action.messageId
  ].serviceMessageId = action.serviceMessageId;
  updatedConversations[action.conversationId].messageList[
    action.messageId
  ].status = action.status;
  messages[action.conversationId] = {
    message: action.currentMessage ? action.currentMessage.message : "",
    isChatSaved: action.currentMessage
      ? action.currentMessage.isChatSaved
      : false,
  };
  return updateObject(state, {
    conversations: updatedConversations,
    messages,
  });
};

const setArchiveConversation = (
  state: IChatReducer,
  action: IActionArxiveConversation,
): IChatReducer => {
  const updatedConversations = deepClone(state.conversations);
  const messages = deepClone(state.messages);

  updatedConversations[action.conversationId].lastLoadConversationId =
    action.newConversationId;

  updatedConversations[action.conversationId].messageList = {
    ...action.messageList,
    ...updatedConversations[action.conversationId].messageList,
  };
  return updateObject(state, {
    conversations: updatedConversations,
    messages,
  });
};

const removeNewMessageCount = (
  state: IChatReducer,
  action: INewMessageCount,
): IChatReducer => {
  const updatedConversations = deepClone(state.conversations);
  const messages = deepClone(state.messages);

  updatedConversations[action.conversationId].newMessageCount = null;
  return updateObject(state, {
    conversations: updatedConversations,
    messages,
  });
};

const setNewMessageCount = (
  state: IChatReducer,
  action: INewMessageCount,
): IChatReducer => {
  const updatedConversations = deepClone(state.conversations);
  const messages = deepClone(state.messages);

  updatedConversations[action.conversationId].newMessageCount = action.count;
  return updateObject(state, {
    conversations: updatedConversations,
    messages,
  });
};

const addMessage = (
  state: IChatReducer,
  action: Partial<IAction>,
): IChatReducer => {
  const conversationId = action.addToActiveConversation
    ? state.activeConversationId
    : action.conversationId;

  const updatedConversations = deepClone(state.conversations);
  const messages = deepClone(state.messages);

  const messageList = {};

  action.message.messageList.forEach((message: { id: string | number }) => {
    messageList[message.id] = {
      ...message,
    };
  });

  messages[action.conversationId] = {
    message: action.currentMessage ? action.currentMessage.message : "",
    isChatSaved: action.currentMessage
      ? action.currentMessage.isChatSaved
      : false,
  };

  //conversation exists
  if (updatedConversations[conversationId]) {
    Object.keys(messageList).forEach(function (key) {
      updatedConversations[conversationId].messageList[key] = messageList[key];
    });
  } else {
    updatedConversations[conversationId] = {
      ...action.message,
      messageList: { ...messageList },
    };
  }

  // if it is not active conversation we should upgrade newConversation count
  if (action.message.messageList[0].owner !== "agent") {
    let newMessageCount = updatedConversations[conversationId].newMessageCount;
    newMessageCount = newMessageCount ? ++newMessageCount : 1;
    updatedConversations[conversationId].newMessageCount = newMessageCount;
  }

  return updateObject(state, {
    conversations: updatedConversations,
    messages,
  });
};

const removeMessage = (
  state: IChatReducer,
  action: Partial<IAction>,
): IChatReducer => {
  const conversationId = action.conversationId;

  const updatedConversations = deepClone(state.conversations);

  Object.keys(updatedConversations[action.conversationId].messageList).map(
    (key) => {
      const message =
        updatedConversations[action.conversationId].messageList[key];
      if (message.serviceMessageId === action.serviceMessageId)
        delete updatedConversations[conversationId].messageList[message.id];
    },
  );

  return updateObject(state, {
    conversations: updatedConversations,
  });
};

// const setMessageImage = (state: IChatReducer, _action: any) => {
//   let updatedConversations = deepClone(state.conversations);
//   updatedConversations[action.conversationId].messageList[action.messageId].base64 = action.base64;
//   return updateObject(state, {
//   	conversations: updatedConversations,
//   });
//   return state;
// };

const endChat = (
  state: IChatReducer,
  action: Partial<IAction>,
): IChatReducer => {
  const conversations = deepClone(state.conversations);
  const messages = deepClone(state.messages);
  const updatedCoversations = {};

  Object.keys(conversations).forEach(function (key) {
    const conversation = conversations[key];
    if (conversation.conversationId !== action.conversationId) {
      updatedCoversations[key] = conversation;
    }
  });

  delete messages[action.conversationId];

  return updateObject(state, {
    open: false,
    activeConversationId: null,
    conversations: { ...updatedCoversations },
    messages,
  });
};

const toggleChatsView = (state: IChatReducer): IChatReducer =>
  updateObject(state, {
    isHidden: !state.isHidden,
  });

const setAllConversations = (
  state: IChatReducer,
  action: Partial<IAction>,
): IChatReducer => {
  const conversations = {};
  const messages = {};

  action.conversations.forEach(
    (conversation: { messageList: any[]; conversationId: string | number }) => {
      const messageList = {};

      conversation.messageList.forEach((message: { id: string | number }) => {
        messageList[message.id] = {
          ...message,
        };
      });

      conversations[conversation.conversationId] = {
        ...conversation,
        messageList: messageList,
      };

      messages[conversation.conversationId] = {
        message: "",
        isChatSaved: false,
      };
    },
  );

  return updateObject(state, {
    messages,
    conversations: { ...conversations },
  });
};

const addNewMessageCount = (
  state: IChatReducer,
  action: Partial<IAction>,
): IChatReducer => {
  const updatedConversations = deepClone(state.conversations);

  let newMessageCount =
    updatedConversations[action.conversationId].newMessageCount;

  newMessageCount = newMessageCount ? ++newMessageCount : 1;

  return updateObject(state, {
    conversations: { ...updatedConversations },
  });
};

const readNewMessages = (
  state: IChatReducer,
  action: Partial<IAction>,
): IChatReducer => {
  const updatedConversations = deepClone(state.conversations);

  if (action.conversationId !== 0)
    updatedConversations[action.conversationId].newMessageCount = 0;

  return updateObject(state, {
    conversations: { ...updatedConversations },
  });
};

const addConversationToEdit = (
  state: IChatReducer,
  action: { message: { conversationId: string | number } },
): IChatReducer => {
  const updatedConversations = deepClone(state.conversations);
  const updatedMessages = deepClone(state.messages);

  updatedConversations[action.message.conversationId] = {
    ...action.message,
    editMode: true,
  };
  updatedMessages[action.message.conversationId] = {
    message: "",
    isChatSaved: false,
  };

  return updateObject(state, {
    open: true,
    activeConversationId: action.message.conversationId,
    conversations: updatedConversations,
    messages: updatedMessages,
  });
};

const setMessage = (
  state: IChatReducer,
  action: Partial<IAction>,
): IChatReducer => {
  const updatedMessages = deepClone(state.messages);

  updatedMessages[action.conversationId].message = action.text;

  return updateObject(state, {
    messages: { ...updatedMessages },
  });
};

const setChatSaved = (
  state: IChatReducer,
  action: Partial<IAction>,
): IChatReducer => {
  const updatedMessages = deepClone(state.messages);

  updatedMessages[action.conversationId].isChatSaved = action.isChatSaved;

  return updateObject(state, {
    messages: { ...updatedMessages },
  });
};

const removeConversationFromEdit = (
  state: IChatReducer,
  action: Partial<IAction>,
): IChatReducer => {
  const conversations = deepClone(state.conversations);
  const messages = deepClone(state.messages);

  if (conversations[action.conversationId].editMode) {
    delete conversations[action.conversationId];
    delete messages[action.conversationId];
  }

  return updateObject(state, {
    open: false,
    activeConversationId: null,
    conversations,
  });
};

const chatReducer = (state = initialState, action): IChatReducer => {
  switch (action.type) {
    case chatActionTypes.TOGGLE:
      return updateObject(state, { open: action.open });
    case chatActionTypes.SET_MATCHED_STRING:
      return updateObject(state, { matchedString: action.matchedString });
    case chatActionTypes.SET_ACTIVE_CONVERSATION:
      return updateObject(state, {
        activeConversationId: action.conversationId,
      });
    case chatActionTypes.SET_CHAT_ENDED:
      return updateObject(state, { isChatEnded: action.isChatEnded });

    case chatActionTypes.SET_CHAT_SAVED:
      return setChatSaved(state, action);

    case chatActionTypes.ADD_MESSAGE:
      return addMessage(state, action);

    case chatActionTypes.REMOVE_MESSAGE:
      return removeMessage(state, action);

    case chatActionTypes.SET_ALL_CONVERSATIONS:
      return setAllConversations(state, action);

    case chatActionTypes.SET_CHAT_INSTANCE:
      return updateObject(state, {
        chatInstance: action.chatInstance,
      });
    case chatActionTypes.END_CHAT:
      return endChat(state, action);

    case chatActionTypes.TOGGLE_CHATS_VIEW:
      return toggleChatsView(state);

    case chatActionTypes.SET_MESSAGE_SENT:
      return setMessageSent(state, action);

    case chatActionTypes.SET_ARCHIVE_CONVERSATION:
      return setArchiveConversation(state, action);

    case chatActionTypes.REMOVE_NEW_MESSAGE_COUNT:
      return removeNewMessageCount(state, action);

    case chatActionTypes.SET_NEW_MESSAGE_COUNT:
      return setNewMessageCount(state, action);

    case chatActionTypes.SET_REPLY_MESSAGE_VIEW:
      return updateObject(state, { replyMessageView: action.replyMessageView });

    // case chatActionTypes.SET_MESSAGE_IMAGE:
    //   return setMessageImage(state, action);

    case chatActionTypes.ADD_NEW_MESSAGE_COUNT:
      return addNewMessageCount(state, action);

    case chatActionTypes.READ_NEW_MESSAGES:
      return readNewMessages(state, action);

    case chatActionTypes.ADD_CONVERSATION_TO_EDIT:
      return addConversationToEdit(state, action);

    case chatActionTypes.REMOVE_CONVERSATION_FROM_EDIT:
      return removeConversationFromEdit(state, action);

    case chatActionTypes.IS_SEND_MESSAGE:
      return updateObject(state, { isSendMessage: action.isSendMessage });

    case chatActionTypes.SET_REPLY_MESSAGE_DATA:
      return updateObject(state, { replyMessageData: action.replyMessageData });

    case chatActionTypes.SET_CHAT_FULL_WIDTH:
      return updateObject(state, {
        chatFullWidth: action.chatFullWidth,
      });

    case chatActionTypes.CLOSE_ACTIVE_CHAT:
      return updateObject(state, {
        closeActiveChat: action.closeActiveChat,
      });

    case chatActionTypes.SET_SEND_LAST_MSG:
      return updateObject(state, {
        isSendLastMsg: action.isSendLastMsg,
      });
    case chatActionTypes.SET_MESSAGE:
      return setMessage(state, action);

    default:
      return state;
  }
};

export default chatReducer;
