import { queueActions as actionTypes } from "../Actions/actionTypes";

const initialState = {
  queue: [],
  isSaving: false,
  isEmpty: true,
  size: 0,
  peek: {},
};

const setEmpty = (state, action) => ({ ...state, isEmpty: action.isEmpty });
const setSaving = (state, action) => ({ ...state, isSaving: action.isSaving });

const enqueue = (state, action) => {
  let temp = [...state.queue],
    size = state.size;

  temp.push(action.data);
  size++;

  return { ...state, queue: temp, size };
};

const dequeue = (state) => {
  let temp = [...state.queue],
    size = state.size;

  if (temp.length > 0) {
    temp.shift();
    size--;
  }

  let isEmpty = false,
    peek = {};
  if (temp.length === 0) isEmpty = true;
  else peek = temp[size - 1];

  return { ...state, queue: temp, size, isEmpty, peek };
};

const queueReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMPTY:
      return setEmpty(state, action);

    case actionTypes.SET_IS_SAVING:
      return setSaving(state, action);

    case actionTypes.ENQUEUE:
      return enqueue(state, action);

    case actionTypes.DEQUEUE:
      return dequeue(state);

    default:
      return state;
  }
};

export default queueReducer;
