import { Drawer as MuiDrawer } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";

interface IDrawer {
  open: boolean;
  handleClose: any;
  children: any;
  style?: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      right: "50px !important",
    },
  }),
);

/**
 *
 *  Standard drawer component
 */

export default function Drawer(props: IDrawer) {
  const classes = useStyles();
  const customStyle = props.style ?? {};
  return (
    <MuiDrawer
      classes={{ root: classes.root }}
      PaperProps={{
        style: {
          width: "80%",
          right: 50,
          ...customStyle,
        },
      }}
      BackdropProps={{
        style: {
          right: 50,
        },
      }}
      anchor='right'
      open={props.open}
      onClose={props.handleClose}>
      {props.children}
    </MuiDrawer>
  );
}
