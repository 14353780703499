import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import BreakSystem from "Components/BreakSystem/BreakSystem";
import React, { ReactElement } from "react";

import OneButton from "./OneButton";

const StatusBox = (): ReactElement => (
  <Container>
    <Grid container>
      <Grid item sm={2}>
        <OneButton />
      </Grid>
      <Grid item sm={10}>
        <BreakSystem />
      </Grid>
    </Grid>
  </Container>
);

export default StatusBox;

const Container = styled("div")({
  background: "rgba(255, 255, 255, 0.1)",
  borderRadius: 48,
  height: 40,
  width: "100%",
});
