import React from "react";
import { styled } from "@material-ui/core";
import { FormatListBulletedIcon } from "../Icons";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const Header = () => {
  const { translate } = useTranslation();
  return (
    <Container>
      <Icon />
      <Title>{translate("request_faq_categories")}</Title>
    </Container>
  );
};

export default Header;

const Container = styled("div")({
  paddingTop: 12,
  display: "inline-block",
  width: "95%",
  margin: "0 auto",
  textAlign: "left",
  borderBottom: "1px solid #DCDDE6",
  padding: 10,
});

const Title = styled("span")({
  color: "rgba(5, 0, 56, 1)",
  textTransform: "uppercase",
  fontSize: 12,
  fontWeight: 600,
  letterSpacing: "0.04em",
});

const Icon = styled(FormatListBulletedIcon)({
  color: "#81808A",
  fontSize: 14,
  lineHeight: 17,
  fontWeight: 600,
  position: "relative",
  top: 2,
  right: 6,
});
