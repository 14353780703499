import breakType from "./breakType";

const breakData = {
  id: null,
  name: "",
  breakType: breakType.Flexible,
  timeFrom: null,
  timeTo: null,
  duration: null,
  birdefe: false,
  userBased: 0,
  maxAvailableUser: null,
  active: true,
  allowCallTime: 0,
  breakUserList: [],
  breakDurationUserList: [],
  breakLogList: [],
  isPermissionRequired: false,
};

export default breakData;
