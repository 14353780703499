import { Box } from "@material-ui/core";

import ChatBox from "Components/ChatBox/ChatBox";
import LeftPanel from "Components/Layout/LeftPanel/LeftPanel";
import NavBar from "Components/Layout/NavBar/NavBar";
import Request from "Components/Request/Request";
import SoftPhone from "Components/SoftPhone/SoftPhone";
import WebSocket from "Components/WebSockets/WebSocket";
import WebSocketBM from "Components/WebSockets/WebSocketBM";
import WebSocketSMM from "Components/WebSockets/WebSocketSMM";

import React from "react";

import { useAppLayout } from "./hooks";
import { IAppLayout } from "./interfaces";
import { ChatContainer, ExtensionBox, MainContent } from "./styles/AppLayout";

export const RequestContextStore = React.createContext(null);
export const StoreContext = React.createContext(null);

const AppLayout = ({ children }: IAppLayout): React.ReactElement => {
  const {
    store,
    softStore,
    chatFullWidth,
    chatIsVisible,
    isLeftPanelOPen,
    chatOpen,
    active,
    activePlatformId,
    sessionStatus,
  } = useAppLayout();

  const queueListPermission =
    JSON.parse(localStorage.getItem("authorities"))
      ?.omnichannel_all_queue_list_api === "omnichannel_all_queue_list_api";

  return (
    <RequestContextStore.Provider value={store}>
      <StoreContext.Provider value={softStore}>
        <NavBar />
        <ExtensionBox />
        <MainContent>
          <LeftPanel />
          <ChatContainer className={chatFullWidth && "open"}>
            <div style={{ display: chatIsVisible ? "block" : "none" }}>
              <ChatBox />
            </div>
            <SoftPhone isHidden={chatIsVisible} />
          </ChatContainer>
          <Box
            style={{
              position: "relative",
              flex: 1,
              overflow: "auto",
              borderTopLeftRadius: isLeftPanelOPen ? 0 : 24,
              borderTopRightRadius: 24,
            }}>
            <div style={{ display: chatOpen ? "inline" : "block" }}>
              {children}
            </div>
            <Request />
          </Box>
          {active && !queueListPermission && <WebSocket />}
          {!(activePlatformId === null || activePlatformId === undefined) && (
            <WebSocketSMM />
          )}
          {sessionStatus === "active" &&
            !process.env.REACT_APP_SKIP_BREAK_SOCKET && <WebSocketBM />}
        </MainContent>
      </StoreContext.Provider>
    </RequestContextStore.Provider>
  );
};

export default AppLayout;

AppLayout.displayName = "AppLayout";
