import { webSocketActionTypes as actionTypes } from "../Actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  connected: false,
  pending: false,
  active: false,
  clientRef: null,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONNECTED:
      return updateObject(state, { open: action.connected });
    case actionTypes.IS_CONNECTED:
      localStorage.setItem("chatConnected", "true");
      return updateObject(state, { connected: true, pending: false });
    case actionTypes.TOGGLE_ACTIVE:
      return updateObject(state, { active: action.active });
    case actionTypes.IS_DISCONNECTED:
      localStorage.setItem("chatConnected", "false");
      return updateObject(state, { connected: false, pending: false });
    case actionTypes.IS_RECONNECTING:
      return updateObject(state, { connected: false, pending: true });
    case actionTypes.IS_PENDING:
      return updateObject(state, { pending: true });
    default:
      return state;
  }
};

export default chatReducer;
