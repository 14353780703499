import { useCallback } from "react";
import { callbackActions as actionTypes } from "./actionTypes";

export default class Callback {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setCallback = useCallback(
    (callback: Object) =>
      this.dispatch({
        type: actionTypes.SET_CALLBACK,
        callback,
      }),
    [this],
  );

  setCallbackRequestId = useCallback(
    (requestId: string) =>
      this.dispatch({
        type: actionTypes.SET_REQUEST_ID,
        requestId,
      }),
    [this],
  );
}
