import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { fetchCreditAccounts } from "Api/atbCrmIntegration";
import React, { useState, useEffect } from "react";

import { mainColumns, secondaryColumns } from "./constants";

const Row = ({ row }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {mainColumns.map((column, idx) => (
          <TableCell key={idx}>{row[column.name]}</TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {secondaryColumns.map((column, idx) => (
                      <TableCell key={idx}>{column.alias}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    {secondaryColumns.map((column, idx) => (
                      <TableCell key={idx}>{row[column.name]}</TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const CreditTab = ({ cif, setLoading }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchRows();
  }, [cif]);

  const fetchRows = () => {
    setLoading(true);

    return fetchCreditAccounts({
      cif, // cif
    })
      .then((res) => {
        if (res) setRows(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell />
            {mainColumns.map((column, idx) => (
              <TableCell key={idx}>{column.alias}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 &&
            rows.map((row, idx) => <Row key={idx} row={row} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CreditTab;
