import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";

import { default as useTranslation } from "Assets/hooks/useOLTranslation";

import Drawer from "Components/Drawer/Drawer";
import Row from "Components/Form/Row";

import React, { createContext } from "react";

import { filterTypes } from "./constants";
import { useIbarIntegrationSearchPanel } from "./hooks";
import { IIbarIntegrationSearchPanel } from "./interfaces";
import { useIbarIntegrationSearchPanelStyles } from "./styles";
import {
  SearchButton,
  Input,
  SelectBox,
  Icon,
  Container,
} from "./styles/IbarIntegrationSearchPanel";
import SearchPanelTabs from "./tabs";

export const IbarIntegrationSearchStore = createContext(null);

const IbarIntegrationSearchPanel = ({
  open,
  setOpen,
}: IIbarIntegrationSearchPanel): React.ReactElement => {
  const { translate } = useTranslation();
  const classes = useIbarIntegrationSearchPanelStyles();

  const {
    selectedFilter,
    filterValue,
    store,
    clearData,
    handleFilter,
    setFilterValue,
    handleSearch,
  } = useIbarIntegrationSearchPanel({ open });

  return (
    <IbarIntegrationSearchStore.Provider value={store}>
      <Drawer
        open={open}
        handleClose={() => {
          setOpen(false);
          clearData();
        }}>
        <DialogTitle>{translate("crm_card_customer_search")}</DialogTitle>

        <Row
          label={
            <SelectBox
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlinedt'
              value={selectedFilter}
              onChange={handleFilter}
              label={translate("status")}>
              {filterTypes.map((option, index) => (
                <MenuItem key={index} value={option.name}>
                  <span style={{ paddingTop: 10, paddingBottom: 10 }}>
                    {option.alias}
                  </span>
                </MenuItem>
              ))}
            </SelectBox>
          }
          Component={
            <Container>
              <Input
                type='text'
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
              <SearchButton
                onClick={handleSearch}
                disabled={filterValue.length <= 0}>
                <Icon />
              </SearchButton>
            </Container>
          }
          cellStyle={classes.cell}
        />

        <DialogContent dividers>
          <SearchPanelTabs />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}>
            {translate("close")}
          </Button>
        </DialogActions>
      </Drawer>
    </IbarIntegrationSearchStore.Provider>
  );
};

export default IbarIntegrationSearchPanel;

IbarIntegrationSearchPanel.displayName = "IbarIntegrationSearchPanel";
