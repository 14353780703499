import Popover from "@material-ui/core/Popover";
import { styled } from "@material-ui/core/styles";
import { getAgentDailyReport } from "Api/report";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { InfoOutlinedIcon } from "Assets/icons";
import _, { map } from "lodash";
import React, { useState } from "react";
import { toTimeFormat } from "Utils/Utils";

const AgentDailyInfo = (): React.ReactElement => {
  const { translate } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [data, setData] = useState({});

  const formatValue = (category, value) => {
    switch (category) {
      case "calls":
      case "chats":
      case "answeredCalls":
      case "outCalls":
      case "missedCalls":
      case "comments":
        return value;
      default:
        return toTimeFormat(value);
    }
  };

  const handleClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    setAnchorEl(event.currentTarget);
    const response = await getAgentDailyReport();
    response && setData(response);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Container>
      <div
        onClick={(e) =>
          handleClick(e as unknown as React.MouseEvent<HTMLButtonElement>)
        }>
        <Icon />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <InfoContainer>
          {_.size(data) !== 0 ? (
            map(data, (value, key) => {
              if (key === "breaks") {
                return map(value, (breakValue, breakKey) => (
                  <DailyInfo key={breakKey}>
                    <span>{breakKey}</span>
                    <span>{formatValue(breakKey, breakValue)}</span>
                  </DailyInfo>
                ));
              } else {
                return (
                  <DailyInfo key={key}>
                    <span>{key}</span>
                    <span>{formatValue(key, value)}</span>
                  </DailyInfo>
                );
              }
            })
          ) : (
            <DailyInfo>
              <span>{translate("no_data")}</span>
            </DailyInfo>
          )}
        </InfoContainer>
      </Popover>
    </Container>
  );
};

export default AgentDailyInfo;

const Container = styled("div")({
  textAlign: "left",
  paddingTop: "5px",
  paddingLeft: "10px",
});

const Icon = styled(InfoOutlinedIcon)({
  width: "30px",
  height: "30px",
  cursor: "pointer",
  color: "#C6ECFF",
});

const InfoContainer = styled("div")({
  padding: "10px 10px",
});

const DailyInfo = styled("div")({
  padding: "5px 0",
  display: "flex",
  justifyContent: "space-between",
  fontFamily: "Open Sans !important",
  fontWeight: 500,
  fontSize: "13px",
  letterSpacing: "0.02em",
  color: "#050038",
  borderBottom: "1px solid #EEEFF3",
  textTransform: "uppercase",
  minWidth: "230px",
  "&>span": {
    margin: "0 10px",
  },
});
