import {
  IAction,
  IClassifierReducer,
} from "./interfaces/classifierReducer.types";
import { classifierActions as actionTypes } from "../Actions/actionTypes";

export const defaultStates = {
  id: null,
  name: "",
  parentId: null,
  parentName: "",
  fullPath: "",
  children: [],
};

export const initialState = {
  searchValue: "",
  allClassificators: [],
  path: defaultStates,
  chosenClassificators: [],
  classificatorToEdit: defaultStates,
  isButtonDisabled: true,
};

const classifierReducer = (
  state = initialState,
  action: Partial<IAction>,
): IClassifierReducer => {
  switch (action.type) {
    case actionTypes.SET_CLASSIFIER_SEARCH_VALUE:
      return { ...state, searchValue: action.value };
    case actionTypes.SET_ALL_CLASSIFIERS:
      return { ...state, allClassificators: action.allClassificators };
    case actionTypes.ADD_CLASSIFIER_NEW_PATH:
      return { ...state, path: { ...action.path } };
    case actionTypes.SET_CHOSEN_CLASSIFIERS:
      return { ...state, chosenClassificators: action.data };
    case actionTypes.SET_BUTTON_DISABLED:
      return { ...state, isButtonDisabled: action.disabled };
    case actionTypes.SET_CLASSIFIER_TO_EDIT:
      return { ...state, classificatorToEdit: action.path };
    default:
      return state;
  }
};

export default classifierReducer;
