import {
  AccessTimeIcon,
  DoneIcon,
  DoneAllIcon,
  ErrorOutlineIcon,
} from "Assets/icons";

import React from "react";

import { IIcon } from "../interfaces";

export const Icon = ({ status, useStyles }: IIcon): React.ReactElement => {
  const { icon } = useStyles();
  switch (status) {
    case null:
      return <AccessTimeIcon className={icon} />;
    case "UNDEFINED":
      return <DoneIcon className={icon} />;
    case "DELIVERY":
      return <DoneAllIcon className={icon} />;
    case "READ":
      return <DoneAllIcon className={icon} />;
    case "EXPIRED":
      return <ErrorOutlineIcon className={icon} />;
    default:
      return <></>;
  }
};
