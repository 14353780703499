import { styled, FormControl, MenuItem, Select } from "@material-ui/core";

import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import React from "react";

import { useStatusSelector } from "./hooks";
import { useStatusSelectorStyles } from "./styles";

const StatusSelector = (): React.ReactElement => {
  const { translate } = useTranslation();
  const classes = useStatusSelectorStyles();

  const {
    currentStatus,
    requestState,
    request,
    requestContextStore,
    setCurrentStatus,
  } = useStatusSelector();

  return (
    <Container>
      <span className={classes.label}>{translate("request_status")}: </span>
      <FormControl className={classes.formControl}>
        <div style={{ display: "inline-block" }}>
          <Select classes={{ select: classes.select }} value={currentStatus.id}>
            {requestState.options[requestState.menuType].map((option) => (
              <MenuItem
                key={option.id}
                onClick={() => {
                  const status = {
                    id: option.id,
                    name: option.name,
                  };
                  request.addRequestOption(
                    requestState.menuType,
                    requestContextStore.getRequestId(),
                    status,
                  );
                  setCurrentStatus(status);
                }}
                value={option.id}>
                {option.displayName}
              </MenuItem>
            ))}
          </Select>
        </div>
      </FormControl>
    </Container>
  );
};

export default StatusSelector;

StatusSelector.displayName = "StatusSelector";

const Container = styled("div")({
  backgroundColor: "#F5F4F7",
  borderRadius: 40,
  padding: "8px 16px",
  height: "50%",
  width: "75%",
  margin: "0 auto",
});
