import i18n from "i18n/config";

const columns = [
  {
    name: "break title",
    alias: i18n.t("break_mng_break_title"),
    sortable: false,
  },
  { name: "type", alias: i18n.t("break_mng_type"), sortable: false },
  { name: "period", alias: i18n.t("break_mng_period"), sortable: false },
  {
    name: "duration time",
    alias: i18n.t("break_mng_duration_time"),
    sortable: false,
  },
  {
    name: "per each use",
    alias: i18n.t("break_mng_per_each_use"),
    sortable: false,
  },
  {
    name: "user based",
    alias: i18n.t("break_mng_user_based"),
    sortable: false,
  },
  {
    name: "max alloved users",
    alias: i18n.t("break_mng_max_alloved_users"),
    sortable: false,
  },
  { name: "settings", alias: i18n.t("break_mng_settings"), sortable: false },
];

export default columns;
