import {
  Typography,
  CircularProgress,
  styled,
  TextField,
} from "@material-ui/core";

import { PersonIcon, AssistantPhotoIcon } from "Assets/icons";

import { fetchData } from "networking";
import React, { useEffect, useState } from "react";

import { fields } from "../constants";
import { IFormSelect, IOption } from "../interfaces";

export const useFormSelect = (props: IFormSelect): any => {
  const [options, setOptions] = useState<Array<IOption>>(props.options);
  const [localLabel, setLocalLabel] = useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  const serverSide = props.serverSide ? props.serverSide : false;

  const getRenderedOption = (option): React.ReactNode => {
    if (serverSide && props.handleAddOption && option.value === null)
      return (
        <Typography
          onClick={() => props.handleAddOption(searchInputValue)}
          noWrap>
          {option.name}
        </Typography>
      );

    return <Typography noWrap>{option.name}</Typography>;
  };

  const handleInputChange = (e): void => {
    const value = e.target.value;
    setSearchInputValue(value);
    setLoading(true);
  };

  const getRenderedInput = (params): React.ReactNode => {
    if (serverSide)
      return (
        <TextField
          onChange={(e) => handleInputChange(e)}
          {...params}
          label={props.placeHolder}
          onFocus={props.onFocus ? props.onFocus : null}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      );
    else
      return (
        <TextField
          {...params}
          onFocus={props.onFocus ? props.onFocus : null}
          label={props.placeHolder}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      );
  };

  const handleChange = (
    _event: React.ChangeEvent<any>,
    values,
    reason,
  ): void => {
    //check if value removed and if removed value is newly added
    if (reason === "remove-option") {
      if (typeof props.value === "object") {
        const removedOption = props.value.filter((x) => !values.includes(x));
        if (removedOption[0] && props.handleRemoveAddedOption)
          props.handleRemoveAddedOption(removedOption);
      }
    }

    props.setValue(values);
    props.handleChange(values, props.id, props.label);
  };

  const styledIcon = (Icon): React.ReactNode =>
    styled(Icon)({
      color: "#9F9DAD",
      fontSize: 20,
      lineHeight: 17,
      fontWeight: 600,
    });

  const chooseIcon = (): React.ReactNode => {
    let icon = null,
      ResultIcon = null;

    switch (props.label) {
      case fields.Gender:
      case fields.CompanyGender:
        ResultIcon = styledIcon(PersonIcon);
        icon = <ResultIcon />;
        break;
      case fields.CompanyStatus:
        ResultIcon = styledIcon(AssistantPhotoIcon);
        icon = <ResultIcon />;
        break;
      default:
        ResultIcon = styledIcon(PersonIcon);
        icon = <ResultIcon />;
        break;
    }

    return icon;
  };

  useEffect(() => {
    props.setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    setLocalLabel(props.label ? props.label : "");
  }, [props.label]);

  useEffect(() => {
    let active = true;
    if (!loading || !props.serverSide) return undefined;

    (async () => {
      const response = await fetchData(props.url + searchInputValue);
      const updatedOptions = await response.json();
      if (active) {
        const filteredOptions = Object.keys(updatedOptions).map((key) => {
          return {
            id: updatedOptions[key]["id"],
            name: updatedOptions[key]["name"],
            value: updatedOptions[key]["id"],
          };
        }) as IOption[];
        setOptions(filteredOptions);
      }
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return {
    localLabel,
    open,
    loading,
    options,
    handleChange,
    setOpen,
    getRenderedOption,
    getRenderedInput,
    chooseIcon,
  };
};

useFormSelect.displayName = "useFormSelect";
