import React from "react";
import { styled } from "@material-ui/core";
import { SearchIcon } from "../Icons";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

interface ISearch {
  onInput: any;
  value: string;
}

const Search = (props: ISearch) => {
  const { translate } = useTranslation();

  return (
    <Container>
      <Icon />
      <Input
        value={props.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onInput(e)}
        placeholder={translate("request_faq_search_category")}
        autoComplete='off'
      />
    </Container>
  );
};

export default Search;

const Container = styled("div")({
  borderBottom: "1px solid #DCDDE6",
  textAlign: "left",
  width: "98%",
  margin: "0 auto",
  padding: 5,
  paddingRight: 0,
});

const Icon = styled(SearchIcon)({
  color: "#F68F1F",
  position: "relative",
  right: 3,
  top: 5,
});

const Input = styled("input")({
  width: "90%",
  marginTop: 10,
  border: "none",
  outline: "none",
  fontSize: 13,
  textIndent: 7,
});
