import { fetchData } from "networking";
import WebConf, { FileServerUrl } from "Config/WebConf";

// POST
export const sendFileToFileServer = async (file, directory: string) => {
  let formdata = new FormData();
  formdata.append("files", file, file.name);
  formdata.append("directory", directory);

  const params: RequestInit = {
    method: "POST",
    body: formdata,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const response = await fetchData(
    FileServerUrl + WebConf.file.uploadAttachments,
    params,
  );

  if (response && response.status === 200) {
    try {
      return await response.json();
    } catch (error) {
      return [];
    }
  } else return [];
};
