/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { fetchDepositsByAccountNumber } from "Api/atbCrmIntegration";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { depositColumns as columns } from "./constants";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

interface IDetails {
  accruedAmount: number;
  rate: number;
  endDate: string | null;
}

const Row = ({ row, setLoading }) => {
  const { translate } = useTranslation();
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState<IDetails>({
    accruedAmount: 0,
    rate: 0,
    endDate: null,
  });

  useEffect(() => {
    open && fetchDeposits();
  }, [row, open]);

  const fetchDeposits = () => {
    setLoading(true);
    return fetchDepositsByAccountNumber({
      account: row.accountNumber, //row.accountNumber
    })
      .then((res) => {
        if (res) setDetails(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column, idx) => (
          <TableCell key={idx}>{row[column.name]}</TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Typography variant='h6' gutterBottom component='div'>
                {translate("request_deposit_details")}
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{translate("request_accrued_amount")}</TableCell>
                    <TableCell>{translate("request_rate")}</TableCell>
                    <TableCell>{translate("request_end_date")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{details.accruedAmount}</TableCell>
                    <TableCell>{details.rate}</TableCell>
                    <TableCell>{details.endDate}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const DepositTable = ({ rows, setLoading }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell />
            {columns.map((column, idx) => (
              <TableCell key={idx}>{column.alias}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 &&
            rows.map((row, idx) => (
              <Row key={idx} row={row} setLoading={setLoading} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DepositTable;
