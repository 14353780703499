import { TableCell, TableRow, styled } from "@material-ui/core";
import React from "react";

interface IRow {
  Component?: any;
  label?: string;
}

const TaskTableRow = (props: IRow) => (
  <Row>
    <Cell>
      <Label>{props.label}</Label>
      {props.Component}
    </Cell>
  </Row>
);

export default TaskTableRow;

const Label = styled("span")({
  color: "#414147",
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  display: "block",
  textAlign: "left",
  marginTop: 5,
});

const Cell = styled(TableCell)({
  padding: 0,
  width: "90%",
  border: "none",
  paddingTop: 15,
});

const Row = styled(TableRow)({
  marginTop: 15,
});
