import { webSocketActionTypes as actionTypes } from "./actionTypes";
import { useCallback } from "react";

export default class WebSocket {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  setConnected = useCallback(
    (connected: boolean) =>
      this.dispatch({
        type: actionTypes.SET_CONNECTED,
        connected,
      }),
    [this],
  );

  toggleActive = useCallback(
    (active: boolean) =>
      this.dispatch({
        type: actionTypes.TOGGLE_ACTIVE,
        active,
      }),
    [this],
  );

  isConnected = useCallback(
    () =>
      this.dispatch({
        type: actionTypes.IS_CONNECTED,
      }),
    [this],
  );

  isDisconnected = useCallback(
    () =>
      this.dispatch({
        type: actionTypes.IS_DISCONNECTED,
      }),
    [this],
  );

  isPending = useCallback(
    () =>
      this.dispatch({
        type: actionTypes.IS_PENDING,
      }),
    [this],
  );

  isReconnecting = useCallback(
    () =>
      this.dispatch({
        type: actionTypes.IS_RECONNECTING,
      }),
    [this],
  );
}
