import { styled } from "@material-ui/core/styles";
import React from "react";
import ClassificatorSearch from "./ClassificatorSearch";
import Notes from "./Notes";

const Main = () => (
  <Container>
    <ClassificatorSearch />
    <Notes />
  </Container>
);

export default Main;

const Container = styled("div")({
  padding: 5,
  paddingBottom: 0,
  height: "90%",
});
