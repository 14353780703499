import { Typography, CircularProgress, TextField } from "@material-ui/core";

import { default as useTranslation } from "Assets/hooks/useOLTranslation";

import { fetchData } from "networking";
import React, { useEffect, useState } from "react";

import { IFormSelect, IOption } from "../interfaces";
import { useCompanyAutoCompleteStyles } from "../styles";

export const useCompanyAutoComplete = (props: IFormSelect): any => {
  const { translate } = useTranslation();
  const classes = useCompanyAutoCompleteStyles(props);

  const [options, setOptions] = useState<Array<IOption>>(props.options);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  const serverSide = props.serverSide ? props.serverSide : false;

  const getRenderedOption = (option): React.ReactNode => {
    if (serverSide && props.handleAddOption && option.value === null)
      return (
        <Typography
          onClick={() => props.handleAddOption(searchInputValue)}
          noWrap>
          {option.name}
        </Typography>
      );

    return <Typography noWrap>{option.name}</Typography>;
  };

  const handleInputChange = (e): void => {
    const value = e.target.value;
    setSearchInputValue(value);
    setLoading(true);
  };

  const getRenderedInput = (params): React.ReactNode => (
    <TextField
      {...params}
      onChange={(e) => handleInputChange(e)}
      placeholder={translate("request_add_company")}
      classes={{ root: classes.root }}
      onFocus={props.onFocus ? props.onFocus : null}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        endAdornment: (
          <>
            {loading ? <CircularProgress color='inherit' size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  const handleChange = (
    _event: React.ChangeEvent<any>,
    values,
    reason,
  ): void => {
    //check if value removed and if removed value is newly added
    if (reason === "remove-option") {
      if (typeof props.value === "object") {
        const removedOption = props.value.filter((x) => !values.includes(x));
        if (removedOption[0] && props.handleRemoveAddedOption)
          props.handleRemoveAddedOption(removedOption);
      }
    }

    props.setValue(values);
    props.handleChange(values, props.id, props.placeHolder);
  };

  useEffect(() => {
    props.setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    let active = true;
    if (!loading || !props.serverSide) return undefined;

    (async () => {
      const response = await fetchData(props.url + searchInputValue);
      const updatedOptions = await response.json();
      if (active) {
        const filteredOptions = Object.keys(updatedOptions).map((key) => {
          return {
            id: updatedOptions[key]["id"],
            name: updatedOptions[key]["name"],
            value: updatedOptions[key]["id"],
          };
        }) as IOption[];
        setOptions(filteredOptions);
      }
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return {
    classes,
    open,
    loading,
    options,
    handleChange,
    setOpen,
    translate,
    getRenderedOption,
    getRenderedInput,
  };
};

useCompanyAutoComplete.displayName = "useCompanyAutoComplete";
