import { styled } from "@material-ui/core";

export const MainContent = styled("div")({
  display: "flex",
  height: "calc(100vh - var(--nav-bar-height))",
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
});

export const ChatContainer = styled("div")({
  "&.open": {
    width: "calc(100% - 85px)",
  },
});

export const ExtensionBox = styled("div")({
  position: "relative",
  zIndex: 0,
  height: 40,
  width: "100%",
  backgroundColor: "#fff",
  marginBottom: -40,
});
