/* eslint-disable eqeqeq */

import { Tooltip, Grid, styled } from "@material-ui/core";

import { checkBreak, getOmniWebsocketIsConnect } from "Api/configuration";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { PowerSettingsNewIcon, LocalPhoneIcon, ChatIcon } from "Assets/icons";
import { StoreContext } from "Components/Layout/AppLayout";
import Notification from "Notifications/Notification";
import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

import { default as BreakActions } from "Store/Actions/Breaks";
import ChatBox from "Store/Actions/ChatBox";
import Request from "Store/Actions/Request";
import SoftPhoneStore from "Store/Actions/SoftPhone";
import { default as WebSocketActions } from "Store/Actions/WebSocket";

import { colors } from "./constants";

const OneButton = (): React.ReactElement => {
  const { translate } = useTranslation();

  const [disabled, setDisabled] = useState(false);
  const webSocketState = useSelector(
    (state: RootStateOrAny) => state.webSocketReducer,
  );
  const webSocket = new WebSocketActions(useDispatch());

  const softPhoneState = useSelector(
    (state: RootStateOrAny) => state.softPhoneReducer,
  );
  const softPhoneStore = new SoftPhoneStore(useDispatch());
  const softStore: any = useContext(StoreContext);

  const chatBox = new ChatBox(useDispatch());
  const chatBoxState = useSelector(
    (state: RootStateOrAny) => state.chatReducer,
  );

  const requestStore = new Request(useDispatch());
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );

  const breaks = new BreakActions(useDispatch());
  const breaksState = useSelector(
    (state: RootStateOrAny) => state.breakReducer,
  );

  const queueListPermission =
    JSON.parse(localStorage.getItem("authorities"))
      ?.omnichannel_all_queue_list_api === "omnichannel_all_queue_list_api";

  const sipWebsocketPermission =
    JSON.parse(localStorage.getItem("authorities"))
      ?.configuration_close_sip_websocket ===
    "configuration_close_sip_websocket";

  const toggleStatus = async (): Promise<void> => {
    if (disabled) return;
    if (
      !requestState.isActivating ||
      softPhoneState.registered ||
      softPhoneState.phoneConnected
    )
      softStore.unRegisterUa();
    else {
      softPhoneStore.isPending();

      softStore.registerUa();
    }

    const fetchOmniWebsocketIsConnect = await getOmniWebsocketIsConnect();

    console.log("fetchOmniWebsocketIsConnect", fetchOmniWebsocketIsConnect);
    if (fetchOmniWebsocketIsConnect?.status === 200) {
      if (fetchOmniWebsocketIsConnect.data && !webSocketState.connected)
        Notification.error("Omnichannel connection already active");

      !requestState.isActivating
        ? webSocket.toggleActive(false)
        : fetchOmniWebsocketIsConnect.data
        ? webSocket.toggleActive(false)
        : webSocket.toggleActive(true);
    } else if (fetchOmniWebsocketIsConnect?.status === 400) {
      Notification.error(fetchOmniWebsocketIsConnect?.data?.message);
    }

    chatBox.toggleChatsView();
    if (chatBoxState.activeConversationId) {
      chatBox.toggle(false);
      chatBox.setChatFullWidth(false);
    }

    setDisconnectedIfOffline();
    await closeBreakIfOffline();

    if (requestState.isActivating) requestStore.setIsActivating(false);
    else requestStore.setIsActivating(true);
  };

  const setDisconnectedIfOffline = (): void => {
    if (!requestState.isActivating) {
      softPhoneStore.isDisconnected();

      webSocket.isDisconnected();
      webSocket.toggleActive(false);
    }
  };

  const closeBreakIfOffline = async (): Promise<void> => {
    if (!requestState.isActivating) await closeBreakFunction();
  };

  const closeBreakFunction = (): void => {
    if (
      !(
        breaksState.closeBreakId === "default" ||
        breaksState.closeBreakId == null
      ) &&
      !requestState.isActivating
    )
      checkBreak(localStorage.getItem("userID"));
    breaks.setCloseBreakId(null);
    breaks.setSelectedBreakId("default");
  };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", () => {
  //     // if (
  //     //   breaksState.selectedBreakId !== "default" ||
  //     //   !requestState.isActivating
  //     // )
  //     // closeBreakBeforeUnload();
  //     breaks.setCloseBreakId(null);
  //     breaks.setSelectedBreakId("default");
  //   });
  // }, []);

  // window.onbeforeunload = () => {
  //   if (breaksState.selectedBreakId !== "default" || !requestState.isActivating)
  //     // closeBreakBeforeUnload();
  //     breaks.setCloseBreakId(null);
  //   breaks.setSelectedBreakId("default");
  // };

  useEffect(() => {
    if (softPhoneState.phoneConnected) {
      const userId = localStorage.getItem("userID");
      softPhoneState.phoneConnected && checkBreak(userId);
    } else {
      softPhoneStore.toggle(false);
    }
  }, [softPhoneState.phoneConnected]);

  useEffect(() => {
    const emptyLines = Object.values(softPhoneState.lines).every(
      (value) => value === null,
    );
    emptyLines ? setDisabled(false) : setDisabled(true);
  }, [softPhoneState.lines]);

  return (
    <Container>
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <Tooltip
            title={
              disabled
                ? translate("navbar_unreachable")
                : !(webSocketState.connected || softPhoneState.phoneConnected)
                ? translate("navbar_open")
                : translate("navbar_close")
            }>
            <ActionButton
              style={{
                backgroundColor: disabled
                  ? "rgba(129, 128, 138, 0.14)"
                  : webSocketState.connected || softPhoneState.phoneConnected
                  ? colors.connected
                  : "#81808A",
                cursor: disabled ? "not-allowed" : "pointer",
              }}
              onClick={toggleStatus}>
              <ActionIcon />
            </ActionButton>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <StatusContainer>
            {!sipWebsocketPermission && (
              <Tooltip
                title={
                  softPhoneState.phonePending
                    ? translate("navbar_calls_connecting")
                    : softPhoneState.phoneConnected
                    ? translate("navbar_calls_connected")
                    : translate("navbar_calls_disconnected")
                }>
                <LocalPhoneIcon
                  style={{
                    display: "block",
                    color: softPhoneState.phonePending
                      ? colors.pending
                      : softPhoneState.phoneConnected
                      ? colors.connected
                      : colors.default,
                    fontSize: 14,
                    marginBottom: 5,
                    marginTop: 4,
                  }}
                />
              </Tooltip>
            )}
            {!queueListPermission && (
              <Tooltip
                title={
                  webSocketState.pending
                    ? translate("navbar_chat_connecting")
                    : webSocketState.connected
                    ? translate("navbar_chat_connected")
                    : translate("navbar_chat_disconnected")
                }>
                <ChatIcon
                  style={{
                    display: "block",
                    color: webSocketState.pending
                      ? colors.pending
                      : webSocketState.connected
                      ? colors.connected
                      : colors.default,
                    fontSize: 14,
                    marginBottom: 7,
                  }}
                />
              </Tooltip>
            )}
          </StatusContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OneButton;

const Container = styled("div")({
  width: 80,
});

const ActionButton = styled("div")({
  display: "inline-block",
  cursor: "pointer",
  backgroundColor: "#81808A",
  borderRadius: 48,
  width: 38,
  height: 38,
  marginTop: 1,
});

const ActionIcon = styled(PowerSettingsNewIcon)({
  fontSize: 18,
  position: "relative",
  top: 10,
});

const StatusContainer = styled("div")({
  display: "grid",
  alignItems: "center",
  justifyContent: "space-around",
  height: "100%",
});
