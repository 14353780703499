import { createStyles, makeStyles } from "@material-ui/core";

export const useBreakStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: "0 10px",
      borderRadius: "0 8px 8px 8px",
      boxShadow: "0px 8px 24px rgba(5, 0, 56, 0.1)",
    },
  }),
);

export const useProfileStyles = makeStyles(() => ({
  paperRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: 300,
    paddingTop: 25,
    paddingBottom: 40,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 8,
    boxShadow: "0px 8px 24px rgba(5, 0, 56, 0.1)",
  },
  mainPopover: {
    padding: "0 10px",
    borderRadius: "8px 0 8px 8px",
    boxShadow: "0px 8px 24px rgba(5, 0, 56, 0.1)",
    color: "#050038",
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "160%",
    letterSpacing: "0.04em",
    marginTop: 10,
  },
  formControl: {
    width: "100%",
  },
  closeButton: {
    float: "right",
    width: "45%",
  },
  saveButton: {
    width: "45%",
  },
  buttonsContainer: {
    marginTop: 20,
  },
}));

export const useGoogleAuthDrawerStyles = makeStyles(() =>
  createStyles({
    headerStyle: {
      background: "#f7f7f7",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#746653",
      fontWeight: "bold",
      height: "50px",
      marginTop: "10px",
      marginBottom: "40px",
      fontSize: "15px",
    },
    container: {
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    imgWrap: {
      padding: 10,
      border: "1px solid black",
      margin: "0 auto",
      width: 200,
      height: 200,
      marginBottom: 50,
    },
    img: {
      width: 200,
      height: 200,
    },
    text: {
      background: "#e7e8ea",
      height: 48,
      display: "flex",
      alignItems: "center",
      padding: "0 13px",
      boxSizing: "border-box",
      fontWeight: 600,
      fontSize: 14,
      marginLeft: "-12px",
    },
    buttonVerify: {
      marginTop: 30,
      background: "#1662DD",
      color: "#fff",
      height: 40,
      fontSize: 14,
      width: "100%",
      "&:hover": {
        background: "#73a0ea",
      },
    },
    buttonCancel: {
      marginTop: 30,
      height: 40,
      width: "50%",
    },
  }),
);
