import ErrorBoundary from "ErrorHandler/ErrorBoundary";
import { SentryInit } from "ErrorHandler/Sentry";
import React from "react";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";

import authReducer from "Store/Reducers/authReducer";
import breakReducer from "Store/Reducers/breakReducer";
import callbackReducer from "Store/Reducers/callbackReducer";
import callReducer from "Store/Reducers/callReducer";
import cchReducer from "Store/Reducers/cchReducer";
import chatReducer from "Store/Reducers/chatReducer";
import classifierReducer from "Store/Reducers/classifierReducer";
import crmPageReducer from "Store/Reducers/crmPageReducer";
import crmReducer from "Store/Reducers/crmReducer";
import eventSourceReducer from "Store/Reducers/eventSourceReducer";
import faqReducer from "Store/Reducers/faqReducer";
import noteReducer from "Store/Reducers/noteReducer";
import notificationReducer from "Store/Reducers/notificationsReducer";
import queueReducer from "Store/Reducers/queueReducer";
import requestReducer from "Store/Reducers/requestReducer";
import smsReducer from "Store/Reducers/smsReducer";
import socialMediaReducer from "Store/Reducers/socialMediaReducer";
import softPhoneReducer from "Store/Reducers/softPhoneReducer";
import taskReducer from "Store/Reducers/taskReducer";
import topicReducer from "Store/Reducers/topicReducer";
import webSocketReducer from "Store/Reducers/webSocketReducer";

const mainReducer = combineReducers({
  callReducer,
  cchReducer,
  chatReducer,
  webSocketReducer,
  faqReducer,
  authReducer,
  crmReducer,
  requestReducer,
  crmPageReducer,
  softPhoneReducer,
  taskReducer,
  topicReducer,
  noteReducer,
  smsReducer,
  socialMediaReducer,
  queueReducer,
  breakReducer,
  callbackReducer,
  notificationReducer,
  eventSourceReducer,
  classifierReducer,
});

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  mainReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

// SentryInit();

export const Wrapper = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => (
  <BrowserRouter>
    <ErrorBoundary>
      <Provider store={store}>{children}</Provider>
    </ErrorBoundary>
  </BrowserRouter>
);

Wrapper.displayName = "Wrapper";
