import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: localStorage.getItem("lang") ?? "en",
  debug: true,
  resources: {
    en: {
      translation: require("./locales/en/translation.json"),
    },
    aze: {
      translation: require("./locales/aze/translation.json"),
    },
    ru: {
      translation: require("./locales/ru/translation.json"),
    },
  },
  ns: ["translation"],
  defaultNS: "translation",
});

i18n.languages = ["en", "aze", "ru"];

export default i18n;
