import {
  FormControl,
  ListItem,
  ListItemText,
  Popover,
  TextField,
  Typography,
  Box,
  styled,
  Select,
  MenuItem,
} from "@material-ui/core";

import { getRegex, passwordChange } from "Api/configuration";

import UseOLTranslation from "Assets/hooks/useOLTranslation";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { LogOut } from "Components/Authentication/Authentication";
import ButtonPassword from "Components/Buttons/Button";
import { buttonTypes } from "Components/Buttons/buttonTypes";

import { permissions } from "Config/config";
import Notification from "Notifications/Notification";
import { RegexObject } from "Pages/Login/interfaces";
import React, { useEffect, useState } from "react";
import { getAgentImage, permit } from "Utils/Utils";

import GoogleAuthDrawer from "./GoogleAuthDrawer";
import { useProfileStyles } from "./styles";

const Profile = () => {
  const { translate } = useTranslation();
  const classes = useProfileStyles();
  const { lang, changeLanguage } = UseOLTranslation();

  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElPassword, setAnchorElPassword] = useState(null);
  const [anchorGoogleAuthOpen, setAnchorGoogleAuthOpen] = useState(false);
  const [loginRegex, setLoginRegex] = useState<null | RegexObject>(null);
  const [errorNewPass, setErrorNewPass] = useState(false);

  const openPass = Boolean(anchorElPassword);
  const userActionsopen = Boolean(anchorEl);

  function handleChange(e): void {
    const { name, value } = e.target;
    const isValidPassword = new RegExp(loginRegex?.regex);
    switch (name) {
      case "Old Password":
        setOldPassword(value);
        break;
      case "New Password":
        setErrorNewPass(!isValidPassword.test(value));
        setNewPassword(value);
        break;
      case "Confirm Password":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  }

  const userImage = getAgentImage();

  const checkConfirmkPassword =
    confirmPassword.length > 0 &&
    newPassword.length > 0 &&
    confirmPassword !== newPassword;

  const handleUserImageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserImageClose = () => {
    setAnchorEl(null);
  };

  const handleChangePasswordOpen = (event) => {
    setAnchorElPassword(event.currentTarget);
    setAnchorEl(null);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const handleGoogleAuthOpen = (): void => {
    setAnchorGoogleAuthOpen(true);
    setAnchorEl(null);
  };

  const handleGoogleAuthClose = (): void => {
    setAnchorGoogleAuthOpen(false);
  };

  const handleChangePasswordClose = () => {
    setAnchorElPassword(null);
  };

  const handleChangePassword = async (): Promise<void> => {
    const response = await passwordChange(
      oldPassword,
      newPassword,
      confirmPassword,
    );
    if (response?.status === 200) {
      Notification.success(translate("navbar_changed_password"));
      setAnchorElPassword(null);
    } else Notification.error(translate("navbar_not_changed_password"));
  };

  const username = (function () {
    try {
      const lastName = localStorage.getItem("lastName"),
        firstName = localStorage.getItem("firstName");

      if (lastName && firstName) return `${lastName[0]}. ${firstName}`;
      else return "";
    } catch (e) {
      return "";
    }
  })();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const response = await getRegex();
      const result: RegexObject = JSON.parse(response);
      if (result && result.regex) {
        result.regex = result.regex.replace(/\\d/g, "\\d");
        setLoginRegex(result);
      } else {
        throw new Error("Regex not found in the API response.");
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <UserInfoBlock>
        <StyledProfileLogo
          onClick={handleUserImageClick}
          src={userImage}
          alt='userLogo'
        />
        <UserName>{username}</UserName>
      </UserInfoBlock>
      <Popover
        open={userActionsopen}
        anchorEl={anchorEl}
        classes={{
          paper: classes.mainPopover,
        }}
        onClose={handleUserImageClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <StyledBox>
          <ListItem dense style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <LanguageTitle>{translate("navbar_language")}</LanguageTitle>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={lang}
                onChange={(e) => changeLanguage(e.target.value)}
                style={{
                  marginLeft: 20,
                }}>
                {/* <MenuItem value={"aze"}>Aze</MenuItem> */}
                <MenuItem value={"en"}>En</MenuItem>
                {/* <MenuItem value={"ru"}>Ru</MenuItem> */}
              </Select>
            </div>
          </ListItem>
          <ListItem button dense>
            <ListItemText
              primary={translate("navbar_change_password")}
              onClick={handleChangePasswordOpen}
            />
          </ListItem>
          {permit(
            <ListItem button dense>
              <ListItemText
                primary={translate("navbar_enroll_google_authenticator")}
                onClick={handleGoogleAuthOpen}
              />
            </ListItem>,
            permissions.authentication_enroll_google_auth,
          )}
          <ListItem button dense>
            <ListItemText
              primary={translate("navbar_log_out")}
              onClick={LogOut}
            />
          </ListItem>
        </StyledBox>
      </Popover>
      <Popover
        classes={{
          root: classes.paperRoot,
          paper: classes.paper,
        }}
        open={openPass}
        anchorEl={anchorElPassword}
        anchorReference={"none"}
        onClose={handleChangePasswordClose}>
        <Typography variant='h6' gutterBottom>
          {translate("navbar_change_password")}
        </Typography>
        <FormControl className={classes.formControl}>
          <TextField
            required
            fullWidth
            label={translate("navbar_old_password")}
            name='Old Password'
            type='password'
            value={oldPassword}
            onChange={(e) => handleChange(e)}
            inputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
          />
        </FormControl>
        <FormControl variant='filled' className={classes.formControl}>
          <TextField
            error={errorNewPass}
            required
            fullWidth
            label={translate("navbar_new_password")}
            name='New Password'
            type='password'
            helperText={errorNewPass && loginRegex?.regexTextEn}
            value={newPassword}
            onChange={(e) => handleChange(e)}
            inputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
          />
        </FormControl>
        <FormControl variant='filled' className={classes.formControl}>
          <TextField
            error={checkConfirmkPassword ? true : false}
            required
            fullWidth
            label={translate("navbar_confirm_password")}
            name='Confirm Password'
            type='password'
            value={confirmPassword}
            helperText={
              checkConfirmkPassword
                ? translate("navbar_passwords_not_match")
                : null
            }
            onChange={(e) => handleChange(e)}
            inputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
          />
        </FormControl>
        <div className={classes.buttonsContainer}>
          <ButtonPassword
            className={classes.saveButton}
            onClick={handleChangePassword}
            buttonType={buttonTypes.success}
            disabled={
              checkConfirmkPassword ||
              oldPassword.length === 0 ||
              newPassword.length < 6 ||
              confirmPassword.length < 6
                ? true
                : false
            }>
            {translate("save")}
          </ButtonPassword>
          <ButtonPassword
            className={classes.closeButton}
            onClick={handleChangePasswordClose}
            buttonType={buttonTypes.default}>
            {translate("close")}
          </ButtonPassword>
        </div>
      </Popover>
      {anchorGoogleAuthOpen && (
        <GoogleAuthDrawer
          open={anchorGoogleAuthOpen}
          handleClose={handleGoogleAuthClose}
        />
      )}
    </>
  );
};

export default Profile;

const StyledProfileLogo = styled("img")({
  display: "inline-block",
  height: 25,
  width: 25,
  cursor: "pointer",
  borderRadius: "50%",
  border: "1px solid white",
  objectFit: "cover",
  float: "right",
  marginTop: 2,
});

const UserInfoBlock = styled("div")({
  minWidth: 100,
  right: 0,
  top: 15,
  position: "absolute",
});

const LanguageTitle = styled("div")({
  fontSize: 14,
  fontWeight: 400,
});

const UserName = styled("span")({
  marginRight: 7,
  marginTop: 7,
  fontSize: 11,
  fontWeight: 600,
  lineHeight: "160%",
  letterSpacing: "0.02em",
  textTransform: "uppercase",
  float: "right",
});

const StyledBox = styled(Box)({
  paddingTop: "7px",
  paddingBottom: "7px",
});
