/* eslint-disable eqeqeq */
import {
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  Input,
  Popover,
  Select,
  styled,
} from "@material-ui/core";

import { conversationForward, exitViewer, getLastMsgList } from "Api/chat";
import {
  getAllPreDefinedMessage,
  getUserAvailability,
} from "Api/configuration";

import menuTypes from "Assets/constants/menuTypes";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

import Button from "Components/Buttons/Button";
import { buttonTypes } from "Components/Buttons/buttonTypes";
import { commonStyles } from "Components/commonStyles";
import { PanelSwitch } from "Components/Layout/LeftPanel";
import {
  KeyboardTabIcon,
  SettingsEthernetIcon,
} from "Components/Layout/LeftPanel/Icons";

import { permissions } from "Config/config";
import Notification from "Notifications/Notification";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import ChatBoxStore from "Store/Actions/ChatBox";
import { default as CrmActions } from "Store/Actions/Crm";
import { default as RequestActions } from "Store/Actions/Request";
import { permit } from "Utils/Utils";

import ActionIcon from "./ActionIcon";
import ActiveChat from "./ActiveChat";
import ActiveChatInfo from "./ActiveChatInfo";
import {
  AccountCircleIcon,
  CheckCircleOutlineIcon,
  CloseIcon,
  ForwardOutlinedIcon,
  TextsmsIcon,
  RateReviewIcon,
} from "./icons";
import LastMsgPopover from "./LastMsgPopover";
import { useChatBoxStyles } from "./styles";
import TextBox from "./TextBox";
import PredefinedMessagePopover from "./PredefinedMessagePopover";

import "./styles/style.css";

const ChatBox = (): React.ReactElement => {
  const { translate } = useTranslation();

  const classes = commonStyles();
  const componentClasses = useChatBoxStyles();

  const store = useSelector(
    ({ requestReducer, chatReducer }: RootStateOrAny) => ({
      showRegistration: requestReducer.showRegistration,
      conversations: chatReducer.conversations,
      activeConversationId: chatReducer.activeConversationId,
      chatFullWidth: chatReducer.chatFullWidth,
      open: chatReducer.open,
      replyMessageView: chatReducer.replyMessageView,
      replyMessageData: chatReducer.replyMessageData,
      closeActiveChat: chatReducer.closeActiveChat,
    }),
  );

  const request = new RequestActions(useDispatch());
  const chatBox = new ChatBoxStore(useDispatch());
  const crm = new CrmActions(useDispatch());

  const [userList, setUserList] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [searchString, setSearchString] = useState<string>("");

  const [lastMsgList, setLastMsgList] = useState([]);
  const [selectedLastMsgRange, setSelectedLastMsgRange] = useState("");
  const [openLastMsg, setOpenLastMsg] = useState(false);
  const [openUserList, setOpenUserList] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [clickEndChat, setClickEndChat] = useState(false);

  // New states for predefined messages
  const [predefinedMessages, setPredefinedMessages] = useState([]);
  const [openPredefinedMsg, setOpenPredefinedMsg] = useState(false);
  const [selectedPredefinedMsg, setSelectedPredefinedMsg] = useState("");

  const {
    showRegistration,
    conversations,
    activeConversationId,
    chatFullWidth,
    open,
    replyMessageView,
    replyMessageData,
    closeActiveChat,
  } = store;
  const activeConversation = conversations[activeConversationId];

  const sortUsers = (users) => {
    return users?.sort((a, b) => {
      if (a.isOnline && !b.isOnline) {
        return -1;
      } else if (!a.isOnline && b.isOnline) {
        return 1;
      } else {
        return (
          a.firstName.localeCompare(b.firstName) ||
          a.lastName.localeCompare(b.lastName)
        );
      }
    });
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const handleEndChat = (): void => {
    if (clickEndChat) return;
    setClickEndChat(true);
    chatBox.setChatEnded(true);
  };

  const handleCloseChat = (): void => {
    activeConversation &&
      activeConversation.editMode === true &&
      chatBox.endChat(
        activeConversationId,
        parseInt(localStorage.getItem("userID")),
      );

    crm.removeEndedConversation(
      menuTypes.CHAT,
      activeConversation.conversationId,
    );
  };

  const handleRegistration = (): void => {
    if (showRegistration) request.setShowRegistration(false);
    else request.setShowRegistration(true);
  };

  const handleForward = async (): Promise<void> => {
    const fetchUsersList = await getUserAvailability();
    const currentUserID = localStorage.getItem("userID");
    const filterUserList = fetchUsersList
      .filter((user) => user.id !== currentUserID)
      .map((user) => ({
        ...user,
        fullName: `${user.firstName} ${user.lastName}`,
      }));

    setUserList(sortUsers(filterUserList));
    setOpenUserList(true);
  };

  const handleLastMsg = async (): Promise<void> => {
    const fetchLastMsgList = await getLastMsgList();
    fetchLastMsgList && setLastMsgList(fetchLastMsgList);
    setOpenLastMsg(true);
  };

  const handlePredefinedMessage = async (): Promise<void> => {
    const fetchPredefinedMessages = await getAllPreDefinedMessage();
    if (fetchPredefinedMessages.status === 200) {
      if (fetchPredefinedMessages.data.length > 0) {
        setPredefinedMessages(fetchPredefinedMessages.data);
        setOpenPredefinedMsg(true);
      } else {
        Notification.info(translate("predefined_msg_no_data"));
      }
    } else {
      Notification.error(translate("bad_request"));
    }
  };

  const handleCloseLastMsg = (): void => {
    setSelectedLastMsgRange("");
    setOpenLastMsg(false);
  };

  const handleSelectedLastMsgRange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    setSelectedLastMsgRange(event.target.value as string);
  };

  const handleSendLastMsg = async (): Promise<void> => {
    chatBox.setMessage(selectedLastMsgRange, activeConversationId);
    chatBox.setSendLastMsg(Math.floor(Math.random() * 9000000000) + 1000000000);
    handleCloseLastMsg();
  };

  const handleClose = (): void => {
    setOpenUserList(false);
  };

  const setChatSize = (): void => {
    chatFullWidth
      ? chatBox.setChatFullWidth(false)
      : chatBox.setChatFullWidth(true);
  };
  const handleSelectedUser = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    setSelectedUser(event.target.value as string);
  };

  const handleSendConversation = async (): Promise<void> => {
    const response = await conversationForward(
      activeConversationId,
      selectedUser,
    );
    if (response) {
      activeConversation &&
        chatBox.endChat(
          activeConversationId,
          parseInt(localStorage.getItem("userID")),
        );
      exitViewer(activeConversationId);
      setOpenUserList(false);
      Notification.success(translate("chat_box_conversation_forwarded"));
    } else Notification.error(translate("chat_box__conversation_notforwarded"));
  };
  useEffect(() => {
    open && setClickEndChat(false);
  }, [open]);
  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getUserAvailability();
      const sortedUsers = sortUsers(users);
      setAllUsers(sortedUsers);
      setUserList(sortedUsers);
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const filteredUsers = allUsers.filter((user) =>
      `${user.firstName} ${user.lastName}`
        .toLowerCase()
        .includes(searchString.toLowerCase()),
    );
    setUserList(sortUsers(filteredUsers));
  }, [searchString, allUsers]);

  useEffect(() => {
    activeConversation && closeActiveChat !== null && handleCloseChat();
  }, [closeActiveChat]);

  if (open) {
    return (
      <div className={`${classes.actionChatBox} root`}>
        <Grid container className={componentClasses.headerContainer}>
          <Grid item xs={8}>
            <ActiveChatInfo />
          </Grid>
          <Grid item xs={5} className={componentClasses.actionContainer}>
            <ActionIcon
              name={translate("chat_box_reg")}
              Icon={
                <AccountCircleIcon className={componentClasses.actionIcon} />
              }
              onClick={handleRegistration}
            />
            {activeConversation && !activeConversation.editMode && (
              <ActionIcon
                name={translate("chat_box_fwd")}
                Icon={
                  <ForwardOutlinedIcon
                    className={componentClasses.actionIcon}
                  />
                }
                onClick={handleForward}
              />
            )}
            {activeConversation &&
              !activeConversation.editMode &&
              permit(
                <ActionIcon
                  name={translate("chat_box_prm")}
                  Icon={
                    <RateReviewIcon className={componentClasses.actionIcon} />
                  }
                  onClick={handlePredefinedMessage}
                />,
                permissions.chat_predefined_message,
              )}
            {activeConversation && !activeConversation.editMode ? (
              <ActionIcon
                name={translate("chat_box_end")}
                Icon={
                  <CheckCircleOutlineIcon
                    className={componentClasses.actionIcon}
                  />
                }
                onClick={handleEndChat}
              />
            ) : (
              <ActionIcon
                name={translate("close")}
                Icon={<CloseIcon className={componentClasses.actionIcon} />}
                onClick={handleCloseChat}
              />
            )}
            {activeConversation &&
              !activeConversation.editMode &&
              permit(
                <ActionIcon
                  name={translate("chat_box_bye")}
                  Icon={<TextsmsIcon className={componentClasses.actionIcon} />}
                  onClick={handleLastMsg}
                />,
                permissions.chat_survey_evaluation,
              )}
          </Grid>
        </Grid>
        <Inbox>
          <ActiveChat />
          {activeConversation &&
            !activeConversation.editMode &&
            replyMessageView && (
              <div className={componentClasses.replyMessage}>
                <span className={componentClasses.replyMessageHeader}>
                  {replyMessageData?.header ?? translate("no_name")}
                </span>
                <span className={componentClasses.replyMessageText}>
                  {replyMessageData?.text}
                </span>
                <CloseIcon
                  onClick={() => {
                    chatBox.setReplyMessageView(false);
                    chatBox.setReplyMessageData({});
                  }}
                  className={componentClasses.replyCloseIcon}
                />
              </div>
            )}
          {activeConversation && !activeConversation.editMode && <TextBox />}
          <ChatFUllWidth>
            <PanelSwitch
              Icon={chatFullWidth ? KeyboardTabIcon : SettingsEthernetIcon}
              isOpen={chatFullWidth}
              setIsOpen={setChatSize}
            />
          </ChatFUllWidth>
        </Inbox>
        {openLastMsg && (
          <LastMsgPopover
            openLastMsg={openLastMsg}
            lastMsgList={lastMsgList}
            selectedLastMsgRange={selectedLastMsgRange}
            handleSelectedLastMsgRange={handleSelectedLastMsgRange}
            handleSendLastMsg={handleSendLastMsg}
            handleCloseLastMsg={handleCloseLastMsg}
          />
        )}
        <Popover
          open={openUserList}
          style={{ left: "8%" }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}>
          <div className={componentClasses.popover}>
            <FormControl required className={componentClasses.formControl}>
              <InputLabel id='users'>
                {translate("chat_box_select_user")}
              </InputLabel>
              <Select
                labelId='users'
                id='demo-simple-select-required'
                value={selectedUser}
                onChange={handleSelectedUser}
                className={componentClasses.selectEmpty}
                MenuProps={{ autoFocus: false }}>
                <li role='row'>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor='search'
                      className={componentClasses.labelInput}>
                      {translate("search")}
                    </InputLabel>
                    <Input
                      className={componentClasses.inputHolder}
                      id='search'
                      type='text'
                      placeholder={translate("search")}
                      autoComplete='off'
                      value={searchString}
                      onChange={handleSearchChange}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <li value='' className={componentClasses.listForm}>
                      <em>{translate("none")}</em>
                    </li>
                  </FormControl>
                </li>
                {userList.map((user) => (
                  <li
                    className={componentClasses.listForm}
                    key={user.id}
                    value={user.id}
                    aria-disabled='true'>
                    <ListItemText
                      primary={user.firstName + " " + user.lastName}
                    />
                    <RoundDot className={user.isOnline ? "active" : ""} />
                  </li>
                ))}
              </Select>
            </FormControl>
            <div className={componentClasses.buttonContainer}>
              <Button
                disabled={!selectedUser || selectedUser.length === 0}
                className={componentClasses.buttonClass}
                onClick={handleSendConversation}
                buttonType={buttonTypes.success}>
                {translate("send")}
              </Button>
              <Button
                className={componentClasses.buttonClass}
                onClick={handleClose}
                buttonType={buttonTypes.primary}>
                {translate("close")}
              </Button>
            </div>
          </div>
        </Popover>
        <PredefinedMessagePopover
          open={openPredefinedMsg}
          predefinedMessages={predefinedMessages}
          selectedPredefinedMsg={selectedPredefinedMsg}
          handleClose={() => setOpenPredefinedMsg(false)}
          handleSelect={(e) =>
            setSelectedPredefinedMsg(e.target.value as string)
          }
          handleSend={() => {
            chatBox.setMessage(selectedPredefinedMsg, activeConversationId);
            setOpenPredefinedMsg(false);
          }}
          translate={translate}
        />
      </div>
    );
  } else return <></>;
};

export default ChatBox;

ChatBox.displayName = "ChatBox";

const Inbox = styled("div")({
  borderTopLeftRadius: "24px",
  borderTopRightRadius: "24px",
  paddingTop: "10px",
  marginTop: "-10px",
  borderRight: "5px solid white",
  marginRight: "-6px",
  borderLeft: "7px solid white",
  marginLeft: "-8px",
});

const ChatFUllWidth = styled("div")({
  right: 40,
  bottom: 6,
  zIndex: 1,
  position: "absolute",
});

const RoundDot = styled("div")({
  height: 20,
  width: 20,
  marginLeft: 10,
  backgroundColor: "#BBB",
  borderRadius: "50%",
  display: "inline-block",
  "&.active": {
    backgroundColor: "#09CD87",
  },
});
