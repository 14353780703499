import DateFnsUtils from "@date-io/date-fns";
import { styled } from "@material-ui/core/styles";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import FormElement from "Components/Form/FormElement";
import FormFieldWrapper from "Components/Form/FormFieldWrapper";
import React from "react";

interface IFormDateTimePicker {
  id?: string;
  value?: Date;
  regex?: string;
  required?: boolean;
  errorText?: string;
  handleValidation?: Function;
  checkValidation?: boolean;
  handleChange: Function;
  error?: boolean;
  [x: string]: any;
}

function DateTimeInput(props: IFormDateTimePicker) {
  const handleChange = (fulldate) => {
    props.setValue(fulldate);
    props.handleChange(fulldate, props.id, props.label);
  };

  let formattedValue = props.value ?? "";
  if (formattedValue) {
    if (typeof formattedValue === "string" && formattedValue != "") {
      formattedValue = new Date(
        formattedValue.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"),
      );
    }
  } else {
    formattedValue = null;
  }

  return (
    <FormFieldWrapper>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <StyledKeyboardDateTimePicker
          autoOk
          ampm={false}
          helperText={props.errorText}
          size='small'
          variant='inline'
          format='dd-MM-yyyy HH:mm'
          margin='normal'
          value={formattedValue}
          onChange={(fulldate) => handleChange(fulldate)}
          InputLabelProps={{
            shrink: true,
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          required={props.required}
          error={props.error}
        />
      </MuiPickersUtilsProvider>
    </FormFieldWrapper>
  );
}

const StyledKeyboardDateTimePicker = styled(KeyboardDateTimePicker)({
  width: "100%",
  marginTop: "0",
  "& input:focus": {
    boxShadow: "0 0 1px 1px white !important",
  },
});

export default FormElement(DateTimeInput);
