import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { SearchIcon } from "Assets/icons";
import { RequestContextStore } from "Components/Layout/AppLayout";
import React, { useEffect, useRef, useContext } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import Sms from "Store/Actions/Sms";
import { defaultStates as defaultSmsStates } from "Store/Reducers/smsReducer";

import constants from "./constants";

const Search = (props: {
  onSearchByTitle;
  onSearchByText;
  onSearchByAll;
  fetchTemplates;
}): React.ReactElement => {
  const { translate } = useTranslation();
  const timeoutRef = useRef(null);

  const totalSmsState = useSelector(
    (state: RootStateOrAny) => state.smsReducer,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const requestContextStore: any = useContext(RequestContextStore);
  const smsState = requestContextStore.getCurrentStateFromTotalState(
    totalSmsState,
    defaultSmsStates,
  );
  const sms = new Sms(useDispatch());

  const { onSearchByTitle, onSearchByText, onSearchByAll, fetchTemplates } =
    props;

  const commonStyle = {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 25,
  };

  const SearchByQuestion = styled("div")({
    ...commonStyle,
    display: "inline-block",
    cursor: "pointer",
    textTransform: "uppercase",
    letterSpacing: "0.04em",
    color: smsState.search === constants.searchBy.title ? "#050038" : "#9F9DAD",
  });

  const SearchByAnswer = styled("div")({
    ...commonStyle,
    display: "inline-block",
    cursor: "pointer",
    letterSpacing: "0.04em",
    textTransform: "uppercase",
    color: smsState.search === constants.searchBy.text ? "#050038" : "#9F9DAD",
  });

  const SearchByBoth = styled("div")({
    ...commonStyle,
    display: "inline-block",
    cursor: "pointer",
    letterSpacing: "0.04em",
    textTransform: "uppercase",
    color: smsState.search === constants.searchBy.all ? "#050038" : "#9F9DAD",
  });

  const handleSearch = (value): void => {
    if (value === "") fetchTemplates();
    else if (smsState.search === constants.searchBy.title)
      onSearchByTitle(value, 0);
    else if (smsState.search === constants.searchBy.text)
      onSearchByText(value, 0);
    else if (smsState.search === constants.searchBy.all)
      onSearchByAll(value, 0);
  };

  const handleChange = (event): void => {
    event.persist();

    const type = requestState.menuType,
      requestId = requestContextStore.getRequestId();

    sms.setChosenTemplate(type, requestId, {}, -1);

    const { value } = event.target;

    if (value === "") sms.setOnSearch(type, requestId, false);
    else sms.setOnSearch(type, requestId, true);

    sms.setSearchString(type, requestId, value);
    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;

      handleSearch(value);
    }, 1000);
  };

  const handleKeyDown = (event): void => {
    if (event.key === "Enter") handleSearch(smsState.searchString);
  };

  const handleSearchBy = (value): void => {
    const type = requestState.menuType,
      requestId = requestContextStore.getRequestId();

    sms.setSearch(type, requestId, value);
  };

  useEffect(() => {
    if (smsState.searchString) handleSearch(smsState.searchString);
  }, [smsState.searchString]);

  return (
    <Container>
      <Grid container>
        <Grid container item xs={6} spacing={0}>
          <Icon />
          <Input
            autoFocus
            placeholder={translate("request_search_template")}
            name='Search'
            autoComplete='off'
            value={smsState.searchString}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={6}>
          <SearchContainer>
            <SearchByBoth
              onClick={() => handleSearchBy(constants.searchBy.all)}>
              {translate("request_by_all")}
            </SearchByBoth>
            <SearchByQuestion
              onClick={() => handleSearchBy(constants.searchBy.title)}>
              {translate("request_by_title")}
            </SearchByQuestion>
            <SearchByAnswer
              onClick={() => handleSearchBy(constants.searchBy.text)}>
              {translate("request_by_text")}
            </SearchByAnswer>
          </SearchContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Search;

const Container = styled(Grid)({
  borderBottom: "1px solid #DCDDE6",
  paddingBottom: 10,
  width: "100%",
  margin: "0 auto",
  marginTop: 10,
});

const Icon = styled(SearchIcon)({
  color: "#F68F1F",
  fontWeight: 600,
  fontSize: 14,
  display: "inline-block",
  position: "relative",
  top: 3,
  left: 0,
  marginLeft: 8,
  marginRight: 8,
});

const Input = styled("input")({
  width: "90%",
  border: "none",
  outline: "none",
  display: "inline-block",
  "&::placeholder": {
    color: "rgba(2, 1, 16, 0.4)",
  },
});

const SearchContainer = styled("div")({ float: "right" });
