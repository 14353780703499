/* eslint-disable jsx-a11y/no-autofocus */
import { styled } from "@material-ui/core/styles";

import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import React from "react";

import { useCustomInput } from "./hooks";
import { IFormDynamicField } from "./interfaces";

const CustomInput = (props: IFormDynamicField): React.ReactElement => {
  const { translate } = useTranslation();
  const { label, value, handlePlaceHolderChange, handleChange } =
    useCustomInput(props);

  return (
    <StyledFormFieldWrapper>
      <Input
        autoFocus
        placeholder={translate("request_custom_field_label")}
        onChange={(e) => handlePlaceHolderChange(e)}
        value={label || ""}
      />
      <Input
        placeholder={translate("request_custom_field_label")}
        onChange={(e) => handleChange(e)}
        value={value || ""}
      />
    </StyledFormFieldWrapper>
  );
};

export default CustomInput;

CustomInput.displayName = "CustomInput";

const StyledFormFieldWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  marginTop: 7,
  justifyContent: "space-between",
  width: "90%",
});

const Input = styled("input")({
  border: "none",
  outline: "none",
});
