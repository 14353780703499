import axios from "axios";
import { LogOut } from "Components/Authentication/Authentication";
import WebConf, { AuthServerUrl, IbarServerUrl } from "Config/WebConf";
import { SentryCaptureException } from "ErrorHandler/Sentry";
import i18n from "i18n/config";
import Notification from "Notifications/Notification";

const baseURL = process.env.REACT_APP_SERVER_URL;
const headers = { "Content-Type": "application/json" };
const errorStatusCodes = [417, 403, 404, 409, 500, 502, 503];

const validateStatus = (status) => {
  if (errorStatusCodes.includes(status)) {
    SentryCaptureException(status);
    return false;
  }

  return true;
};

// Function to handle token refresh
const refreshToken = async () => {
  try {
    const response = await axios.post(
      `${AuthServerUrl}${
        WebConf.auth.login
      }?refresh_token=${localStorage.getItem("refresh_token")}`,
      {},
    );

    if (response.status === 200) {
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      return response.data.access_token;
    } else {
      LogOut();
      return null;
    }
  } catch (error) {
    LogOut();
    return null;
  }
};

const instance = axios.create({
  baseURL,
  headers,
  timeout: 600000, // 10 minutes
  validateStatus,
});

// Request interceptor to add the token
instance.interceptors.request.use(
  async (request) => {
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "token",
    )}`;
    return request;
  },
  (error) => {
    Notification.error(i18n.t("error_occurred"));
    return error.response;
  },
);

// Response interceptor to handle token refresh
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 417 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshToken();
      if (newAccessToken) {
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      }
    }
    error.response?.config?.url?.indexOf(IbarServerUrl) === -1 &&
      (error?.response?.data?.errors?.[0]
        ? Notification.error(i18n.t(error.response?.data?.errors[0]))
        : Notification.error(i18n.t("error_occurred")));
    return error.response;
  },
);

export default instance;

export const loginInstance = axios.create({
  baseURL,
  headers: {
    Authorization: `Basic ${btoa("orangeline:orangeline123")}`,
    "Content-Type": "application/x-www-form-urlencoded",
  },
  timeout: 15000,
  validateStatus: (status) => {
    if (errorStatusCodes.includes(status)) return false;

    return true;
  },
});

export const reportAxiosInstance = axios.create({
  baseURL,
  headers,
  timeout: 600000, // 10 min
  validateStatus,
});

reportAxiosInstance.interceptors.request.use(
  async (request) => {
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "token",
    )}`;
    return request;
  },
  (error) => {
    Notification.error(i18n.t("error_occurred"));
    return error.response;
  },
);

reportAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 417 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshToken();
      if (newAccessToken) {
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      }
    }
    Notification.error(i18n.t("error_occurred"));
    return error.response;
  },
);
