import { diagramTypes } from "Pages/Monitoring/Dashboard/diagramTypes";
import { widgetKeys } from "Pages/Monitoring/Dashboard/widgetKeys";

const serverUrl = process.env.REACT_APP_SERVER_URL;
export const localHost = "http://localhost:8123";
export const defaultRoute = serverUrl + "/api";
export const OmniServerUrl = serverUrl + "/api/omnichannel";
export const FaqServerUrl = defaultRoute;
export const AuthServerUrl = defaultRoute;
export const RequestServerUrl = serverUrl + "/api/profile";
export const CrmServerUrl = defaultRoute;
export const AtbServerUrl = defaultRoute + "/atb-crm-integration";
export const IbarServerUrl = defaultRoute + "/iss-integration";
export const FileServerUrl = defaultRoute;
export const ReportServerUrl = defaultRoute;
export const ConfigurationServerUrl = defaultRoute;
export const UserManagementUrl = defaultRoute;
export const BMUrl = defaultRoute + "/break";
export const BreakManagementUrl = defaultRoute + "/break/breaks";
export const RoleManagementUrl = defaultRoute;
export const VoipServerUrl = defaultRoute; //'http://localhost:8123'; // defaultRoute;
export const Monitoring = defaultRoute; //localHost + ':8123';
export const SMSUrl = defaultRoute + "/sms";
export const ClassificatorUrl = defaultRoute + "/profile/classifier";
export const SMMUrl = defaultRoute + "/smm";
export const EvaluationUrl = defaultRoute;
export const EvaluationV2Url = defaultRoute + "/evaluationv2";
export const TaskManagementUrl = defaultRoute + "/task-management";
export const WorkScheduleUrl = defaultRoute + "/task-management/working";
export const DictionaryManagementUrl = defaultRoute + "/dictionaries";
export const CallbackUrl = defaultRoute + "/callback";
export const CallbackV2Url = defaultRoute + "/callbackv2";
export const MessageTemplateUrl = defaultRoute;
export const JiraUrl = defaultRoute + "/jira-integration";

const WebConf = {
  classifier: {
    getTable: "/table",
    getTree: "/tree",
    getPaths: "/paths",
  },
  socket: {
    sendMessage: "/app/sendMessage",
    connect: "/websocket",
    acceptMessage: "/user/queue/reply",
    messageSent: "/user/queue/receivedMessage",
  },
  chat: {
    getAllMessages: "/omni/allActiveConversation",
    endChat: "/omni/endChat",
    sendLastMsg: "/omni/survey-messages",
    loadFileBase64: "/omni/fileById",
    loadSourceBase64: "/omni/sourceFileById",
    getMessagesFromQueue: "/omni/getMessagesFromQueue",
    conversationById: "/omni/conversationById?conversationId=",
    archiveConversation: `/omni/getArchiveConversation`,
    conversationForward: "/omni/conversationForward",
    multipleConversationForward: "/omni/multipleConversationForward",
    createNewConversation: "/omni/template",
    exitViewer: (conversationId: number) =>
      `/omni/viewerExit?conversationId=${conversationId}`,
    getAllUserConversationCount: "/omni/user-conversation-count",
    maxConversationCount: "/omni/set-conversation-count",
    setMessageSeen: "/omni/messages/seen",
  },
  faq: {
    getAllCategories: "/faq-categories",
    getQuestionBySearch: "/faqs/question/search/in/category",
    getAnswerBySearch: "/faqs/answer/search/in/category",
    getQuestionAnswer: "/faq-answers",
    getFaqsByCategoryId: "/faqs/by/category/",
    shiftCategory: "/faq-categories/shift",
    shiftFaqUpAndDown: "/faqs/shift",
    addFaqQuestion: "/faqs/",
    deleteFaqQuestion: "/faqs/",
    deleteCategoryById: "/faq-categories/",
    toggleActivation: "/faq-categories/activation",
    clone: "/faq-categories/clone",
    createCategory: "/faq-categories",
    getCategoryById: "/faq-categories",
    getQuestionsByCategoryId: (id: number) => `/faq/category/${id}/faqs`,
  },
  file: {
    uploadAttachments: "/files/upload",
    downloadAttachment: "/files/download",
  },
  report: {
    trendCategoryListByDate: "/report/trendCategoryListByDate?countCategory=",
    categoryUsingDiagramListByDate: "/report/categoryUsingDiagramListByDate",
    getCategoryCountByDate: "/report/categoryCountByDate",
    getQueueReport: "/report/queueReportByDate",
    getCallQueueReport: "/report/queueCallReport",
    getChatQueueReport: "/report/queueChatReport",
    getAgentWorkReport: "/report/agentWorkReport",
    getSummaryCallReport: "/report/summaryCallReport",
    getSummaryChatReport: "/report/summaryChatReport",
    getCallReport: "/report/callReportByDate",
    getAgentCallReport: "/report/agent-report",
    getTraceByLocationReport: "/report/trace-by-location",
    getTraceStatuses: "/report/statuses",
    getAgentSMMReport: "/report/agentChatReport",
    getChatReport: "/report/chatReport",
    getAgentSessionReport: "/report/agentSessionReport",
    getAgentSession: "/report/agent-session",
    agentDaily: "/report/agent-daily",
    getMissedCallReport: "/report/callEvents",
    getTaskReport: "/report/callTaskReport",
    getIvrReport: "/report/ivr-report",
    getSmsSenderReport: "/report/sms/filter",
    getTaskReportExcel: "/report/task-report/excel",
    getTaskReport2: "/report/callAdvanceTaskReport",
    getFCRReport: "/report/fcr",
    getFCRValue: "/report/fcr/value",
    getCrmDataReport: "/report/crm-data",
    getCrmDataReportFilters: "/report/crm-data-filters",
  },
  request: {
    getRequestStatuses: "/request/requestStatus", //?type=Call
    saveCallRequest: "/request/closeCall",
    saveChatRequest: "/request/closeChat",
    requestListByCrmId: "/request/requestListByCrmId",
    getRequestFields: "/request/getRequestFields",
    searchAllRequest: "/request/searchRequest",
    searchMyRequest: "/request/searchMyRequest",
    getRequestById: "/request/requestById?requestId=",
    exportRequest: "/request/exportRequest",
    getRequestIdBySipCallId: "/request/fetchRequestIdBySipCallId?sipCallId=",
    logIssuedRequest: "/request/logIssuedRequest",
    getStatusDetails: "/request/allRequestStatus",
    getRequestsByCustomerId: "/request/requestDTOListByCrmId",
    removeTaskFromRequest: "/request/task",
  },
  auth: {
    login: "/login/refresh-token",
    otpValidation: "/login/otp-validation",
    loginMfa: "/login/password-check",
    getRegex: "/login/regex",
    passwordChange: "/login/password-change",
  },
  configuration: {
    // User Management
    getUsers: "/users",
    addUser: "/users",
    updateUser: "/users",
    findUserById: "/users",
    deleteUser: "/users",
    usersIsActive: "/users",
    passwordChange: "/users/password/change",
    getAllOmniChannelQueueList: "/omnichannel/omni/getQueueList",
    getOmniChannelQueueListById: "/omnichannel/omni/queueListByUserId?userId",
    updateOmniChannelQueueList: "/omnichannel/omni/createUser",
    getOmniWebsocketIsConnect: "/omnichannel/omni/isConnected",
    getUsersInChatQueue: "/omnichannel/omni/usersInChatQueue",
    resetUserPassword: "/users",
    searchUsers: "/users/filterSearch",
    getAllUsers: "/users/all",
    getUserAvailability: "/omnichannel/omni/users",
    userDisableMFA: "/users",
    getQRCode: "/users/qr-generator",
    setCodeVerify: "/users/activation-mfa-login",
    // Break Management
    getAllBreaksList: "/allBreakCause",
    getAllActiveBreaksList: "/allBreakCauseList",
    addBreak: "/newBreak",
    updateBreak: "/updateBreak",
    findBreakById: "/breakCauseById",
    deleteBreak: "/deleteBreak",
    getAllBreakListByUserId: "/breakCauseList?userId",
    takeBreak: "/takeBreak",
    closeBreak: "/closeBreak",
    requestBreak: "/requestBreak",
    cancelBreak: "/cancelBreakRequest",
    checkBreak: "/closeActiveBreaks",
    toggleAutoAcw: "/configuration/autoAcw/enable",
    getAutoAcw: "/configuration/autoAcw",
    // Role Management
    getAllRoleList: "/roles/table",
    findRoleById: "/roles",
    getAllRoles: "/roles",
    getExtendedUsersList: "/users/extended-list",
    getAllPermissionList: "/permissions",
    addUserToRole: "/roles/user/assignment",
    saveRole: "/roles",
    deleteRole: "/roles",
    // IVR Announce Management
    getIvrAnnounce: "/ivr-announce",
    saveIvrAnnounce: "/ivr-announce",
    addIvrAnnounce: "/ivr-announce/announce",
    getSoundFile: "/ivr-announce/sound-file",
    deleteIvrAnnounce: "/ivr-announce/announce",
    updateIvrAnnounce: "/ivr-announce/announce",
    // Queue Management
    getAllVoipQueueList: "/voip/queue-management",
    getAllOmniQueueList: "/omni/queue-management",
    assignUsersToVoipQueue: "/voip/assignUserListToQueue",
    assignUsersToOmniQueue: "/omni/assign-users-to-queue",
    getUsersByOmniQueueName: "/omni/users",
    getUsersByVoipQueueName: "/voip/users",

    // Callback Management
    callbackConfig: "/callbackv2/configuration",
    configUsers: "/callbackv2/configuration/users",
    getAllConfigUsers: "/callbackv2/configuration/users/all",
    updateConfigUsers: (id: number) => `/callbackv2/configuration/users/${id}`,
    // Dictionary Management
    getAllDictionaries: "/dictionaries",
    saveDictionary: "/dictionaries",
    toggleDictionaryActivation: (id: number, isActive: string) =>
      `/dictionaries/${id}/${isActive}`,
    deleteDictionaryItem: (id: number, itemId: number) =>
      `/dictionaries/${id}/items/${itemId}`,
    // Configuration
    getQueueGroups: "/configuration/queue-groups",
    getOptionsByDictionaryId: "/configuration/getOptionsByDictionaryId",
    getConfigForCall: "/configuration/common/call",
    getConfigForClassifier: "/configuration/common/classifier",
    getCommonConfigKeyByValue: (key: string) =>
      `/configuration/autoAcw/common/${key}`,
    // Auto Reply Message
    getDefaultAutoReplyMessage: "/omni/default-auto-replies",
    getCustomAutoReplyMessage: "/omni/custom-auto-replies",
    saveAutoReplyMessage: "/omni/setCustomAutoReply",
    updateAutoReplyMessage: "/omni/updateCustomAutoReply",
    getAutoReplyMessageById: "/omni/customAutoReplyConfig",
    deleteAutoReplyMessage: "/omni/deleteCustomAutoReply",
    // Predefined Message
    getPreDefinedMessage: "/omni/auto-chat",
    getAllPreDefinedMessage: "/omni/auto-chat/all",
    savePreDefinedMessage: "/omni/auto-chat",
    updatePreDefinedMessage: "/omni/auto-chat",
    getPreDefinedMessageById: "/omni/auto-chat",
    deletePreDefinedMessage: "/omni/auto-chat",
    togglePreDefinedMessage: "/omni/auto-chat",
  },
  socketBM: {
    connect: "/websocket",
    requested: "/queue/break-permission/requested",
    notification: "/queue/break-permission/notification",
    responsed: "/queue/break-permission/responded",
  },
  breakPermission: {
    getAllBreakPermissions: "/permissions",
    acceptBreakPermission: "/permissions",
    rejectBreakPermission: "/permissions",
  },
  crm: {
    getDefaultFields: "/fields/template",
    searchCompanyByName: "/customers/legal/search?companyName=",
    saveCustomer: "/customers",
    getCustomerData: "/customers/search/by/identifier/and/channelType",
    getCrmTableData: "/customers/search/and/order",
    getCustomerDataById: "/customers/",
  },
  atb: {
    searchByCif: "/customers/by-cif",
    searchByFin: "/customers/by-fin",
    searchByVoen: "/customers/by-voen",
    searchByFullnameAndDate: "/customers/by-fullname-and-dob",
    fetchAccounts: "/customers/account-by-cif",
    fetchDepositAccounts: "/accounts/by-cif",
    fetchDepositsByAccountNumber: "/customers/deposit-by-account",
    fetchCreditAccounts: "/credits/by-cif",
    fetchCards: "/cards/by-cif",
    fetchTransactions: "/cards/transactions-by-cardId",
    fetchTransactionsByAccount: "/accounts/transactions-by-account",
  },
  ibar: {
    getAccounts: "/account-info",
    getCards: "/card-info",
    getLoans: "/loan-info",
    getPersonalInfo: "/personal-info",
    getNextProductToBuy: "/next-product-to-buy",
  },
  voip: {
    getSipNumberByUserId: "/voip/user",
    getUserSipNumbers: "/voip/users",
    getIvrsList: "/voip/getIvrPoint",
    setIvrPoint: "/voip/ivr-point",
    getContextsNames: "/voip/contexts",
    sendUserToBreak: "/voip/users/break",
    getSoundFile: "/voip/soundFile",
    getAllVoipQueueList: "/voip/queues",
    getVoipQueueListById: "/voip/queues",
    updateVoipQueueList: "/voip/userQueues",
    toggleSocketStartStop: `/voip/socket`,
    getActiveCallUsers: "/voip/spy-call/table ",
    eventStream: "/voip/event/listener",
    spyCall: "/voip/spy-call",
    getCallInfo: (uid: string) => `/voip/calls/info/${uid}`,
  },
  evaluation: {
    getSurveyPoints: "/evaluation/ivr-survey-menu",
    // Configuration
    getConfigurationUsers: "/user/evaluation",
    getPositionName: "/user/position/getPositionName",
    addUser: "/user/evaluation/save",
    getAllPosition: "/user/position/all",
    savePosition: "/user/position/save",
    getAllCriteria: "/evaluation/all",
    saveCriteria: "/evaluation/save-item",
    deleteCriteria: "/evaluation/delete",
    getCriteriaById: "/evaluation/item",
    getAllCriteriaParent: "/evaluation/get-parents",
    // admin
    adminSearch: "/request/request-table",
    sendCallsToUsers: "/request/assign/mo",
    changeAssignedUser: "/request/reassign/mo",
    getAverageEvaluationScore: "/criteria/average-evaluation-score",
    // operation
    getMyEvaluationsByAdmin: "/request/admin",
    getMyEvaluationsByEmployee: "/request/mo",
    getMyEvaluationsbyOperator: "/request/operator",
    getAllEvaluations: "/request",
    getQualityByRequestId: "/criteria",
    getRiskByRequestId: "/risk",
    saveQuality: "/criteria/save",
    saveRisk: "/risk/save",
    callAgreeByOperator: "/request/operator/close",
    callDisAgreeByOperator: "/request/forward/operator/to/mo",
    assignCallToDivisionHeadByEmployee: "/request/forward/mo/to/admin",
    assignCallToOperatorByEmployee: "/request/forward/mo/to/operator",
    assignCallToEmployeeByDivisionHead: "/request/forward/admin/to/mo",
    getEvaluationComments: "/comment",
    saveEvaluationComment: "/comment/save",
    getIdByEmploye: "/request",
  },
  task: {
    saveGlobalTag: "/global-tags",
    searchGlobalTagByname: "/global-tags?name=",
    saveEmail: "/emails",
    sendEmail: "/email/send",
    searchEmailByname: "/emails?str=",
    saveCategory: "/categories",
    getAllCategories: "/categories/all",
    fetchAllCategories: "/categories/all-category",
    getCategoriesTable: "/categories",
    saveAllTasks: "/tasks",
    editTask: "/tasks",
    createTask: "/tasks/create",
    getTasksByUser: "/tasks/all-by-user",
    getClosedTasksByUser: "/tasks/closed-by-user",
    getAllTasks: "/tasks/all",
    getAllClosedTasks: "/tasks/all-closed",
    saveComment: "/comments",
    getCommentsById: "/comments",
    getTaskLogsById: "/tasks/logs",
    getTaskNotificationByUserId: "/notifications/user",
    readNotificationById: "/notifications",
    searchAdvancedGlobalTagByName: "/advanced-global-tags?name=",
    getAllRegexes: "/advanced-global-tags/regexes",
    saveAdvancedGlobalTag: "/advanced-global-tags",
    toggleCategoryActivation: (categoryId: number, isActive: boolean) =>
      `/categories/${categoryId}/activation?isActive=${isActive}`,
    deleteCategory: (categoryId: number) => `/categories/${categoryId}`,
    findCategoryById: (categoryId: number) => `/categories/${categoryId}`,
    assignTask: (taskId: number) => `/tasks/${taskId}/assignment`,
    reopenTask: (taskId: number) => `/tasks/${taskId}/assignment`,
    getTaskById: (taskId: number) => `/tasks/${taskId}`,
    getGlobalTagsById: (categoryId: number) =>
      `/global-tags/categoryId/${categoryId}`,
    activeTask: (taskId: number) => `/tasks/${taskId}/reopening`,
    closeTask: (taskId: number) => `/tasks/${taskId}/close`,
    searchByNameAdvancedTags: (name: string) =>
      `/advanced-global-tags?name=${name}`,
    getExceptionWorkDays: (fromDate: string, toDate: string) =>
      `?from=${fromDate}&to=${toDate}`,
    getDefaultWorkDays: () => `/default/weekdays`,
  },
  socketTasks: {
    connect: "/websocket",
    notification: "/queue/notification",
  },
  monitoring: {
    changeDashboardItemPosition: `/monitoring/dashboardItem/position`,
    eventStream: "/monitoring/stream/event",
    getAllDashboards: "/monitoring/dashboards",
    saveDashboard: "/monitoring/dashboards",
    changeDashboardItemName: "/monitoring/dashBoardItems",
    deleteDashboard: "/monitoring/dashboards",
    getAllWidgets: "/monitoring/widgets",
    sendAgentSession: "/monitoring/agent-session",
    callStatistics: "/monitoring/homePage/overall",
    callStatisticsByUser: "/monitoring/homePage",
    getAccountNames: "/omnichannel/omni/account-names",
    getDashboarItemsById: (dashboardId: number) =>
      `/monitoring/dashboardItems/${dashboardId}`,
    getDashboarItemById: (dashboardItemId: number) =>
      `/monitoring/dashboardItem/${dashboardItemId}`,
    getWidgetData: (widgetKey: widgetKeys, diagramType: diagramTypes) =>
      `/monitoring/widgetsDataByKey/${widgetKey}/${diagramType}`,
    getWidgetsByDashboardItemId: (dashboardItemId: number) =>
      `/monitoring/widgetsByDashboardItemId/${dashboardItemId}`,
    saveDashboardItem: (dashboardId: number, widgetId: number) =>
      `/monitoring/dashBoardItems/${dashboardId}/${widgetId}`,
    deleteDashboardItem: (dashboardItemId: number) =>
      `/monitoring/dashBoardItems/${dashboardItemId}`,
    updateFilter: (dashboardItemId: number) =>
      `/monitoring/dashboardItems/filter/${dashboardItemId}`,
  },
  sms: {
    searchByText: "/text/search",
    searchByTitle: "/title/search",
    searchByAll: "/title/and/text/search",
  },
  socketSMM: {
    connect: "/websocket",
    acceptPost: "/queue/post",
    acceptComment: "/queue/comment",
    acceptInactiveMessage: "/queue/inactive-message",
    hiddenComment: "/queue/hidden-message",
  },
  smm: {
    getPlatforms: "/data/platforms",
    getPostsByAcountId: "/posts",
    getSinglePostById: "/posts",
    getCommentsByPostId: "/comments",
    getReplysByCommentId: "/comments",
    deleteComment: "/comments",
    doneComment: "/comments",
    hideComment: "/comments",
    createComment: "/comments",
    replyComment: "/comments",
    getUnRepliedComments: "/comments/unreplied",
  },
  callback: {
    getAllMissedCalls: "/missed-calls/list",
    getAssignedMissedCalls: "/missed-calls/assignment-page",
    getMissedCallsInformation: "/missed-calls/information",
    assignMissedCalls: "/missed-calls",
    getMissedCallsStatuses: "/missed-calls/statuses",
    getMissedCallsTypes: "/missed-calls/types",
    getMissedCallsTerminateReasons: "/missed-calls/completion-reason",
    completeMissedCall: (id) => `/missed-calls/${id}/complete `,
  },
  messageTemplates: {
    getAllMessageTemplates: "/omni/templates",
    createMessageTemplate: "/omni/templates",
    updateMessageTemplate: "/omni/templates",
    getMessageTemplateById: (id) => `/omni/templates/${id}`,
    deleteMessageTemplate: (id) => `/omni/templates/${id}`,
  },
  socketCallback: {
    connect: "/websocket",
    acceptMissedCall: "/queue/missed-call",
    changeMissedCallStatus: "/queue/missed-call-status",
  },
  contexts: {
    getAllContexts: "/contexts",
  },
  jira: {
    getCustomFields: "/fields/local",
    updateCustomFields: "/fields/update?integration=SERVER",
    getAllIssueTypes: "/issue-types/local",
    updateAllIssueTypes: "/issue-types/update?integration=SERVER",
    getSearchCustomFields: "/fields/for-search",
    getProjects: "/projects/all",
    updateProjects: "/projects/update?integration=SERVER",
    getUserByProjects: "/projects?integration=SERVER&permissions=",
    savePermissionsByProject: "/projects",
    getPermissions: "/permissions",
    getJiraReport: "/issues/report",
    getStatuses: "/issues/statuses",
    postTicket: "/issues?integration=SERVER",
    saveAttachToTicket: "/issues/attachments",
    saveCustomFieldsByIssueType: (id) => `/issue-types/${id}/custom-fields`,
    getCustomFieldsByIssueType: (id) => `/issue-types/${id}/custom-fields`,
    searchTickets: (page, size, id, value) =>
      `/issues?integration=SERVER&id=${id}&value=${value}&page=${page}&size=${size}`,
    getIssueById: (id) => `/field-values?integration=SERVER&issueId=${id}`,
    getIssueTypesByProjectId: (id) =>
      `/issue-types/jira/${id}?integration=SERVER`,
  },
};

export default WebConf;
