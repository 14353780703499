/* eslint-disable @typescript-eslint/no-empty-function */
import fieldTypes from "Components/Form/fieldTypes";
import Form from "Components/Form/Form";
import FormInput from "Components/Form/FormInput";
import FormRadio from "Components/Form/FormRadio";
import FormSelect from "Components/Form/FormSelect";
import DateTimeInput from "Components/Request/RightPanel/Cch/DateTimeInput";
import i18n from "i18n/config";
import React from "react";

export const getFieldByType = (
  field,
  value,
  handleChange,
  isValidationRequired,
): React.ReactElement => {
  if (field === undefined) return;
  const fieldId = field.id ? field.id.toString() : null;
  const errorText = field.regex
    ? i18n.t("regex_error_" + field.regex?.name)
    : null;

  let element = null;
  const options = field.dictionary ? field.dictionary.items : [];

  switch (field.globalTagType) {
    case fieldTypes.FREE_TEXT:
      element = (
        <FormInput
          id={fieldId}
          fieldType={"text"}
          size={"small"}
          variant={"standard"}
          value={value}
          handleChange={(value, id) => handleChange(id, value)}
          regex={field.regex?.value}
          required={field.mandatory}
          errorText={errorText}
          checkValidation={isValidationRequired}
        />
      );
      break;
    case fieldTypes.DATE:
      element = (
        <DateTimeInput
          id={fieldId}
          value={value}
          handleChange={(value, id) => handleChange(id, value)}
          required={field.mandatory}
          errorText={errorText}
          checkValidation={isValidationRequired}
          regex={field.regex?.value}
        />
      );
      break;
    case fieldTypes.RADIO_BUTTON:
      element = (
        <FormRadio
          id={fieldId}
          value={value}
          handleChange={(value, id) => handleChange(id, value)}
          required={field.mandatory}
          options={
            options &&
            options.map((option) => ({
              ...option,
              name: option.value,
            }))
          }
          errorText={errorText}
          checkValidation={isValidationRequired}
          regex={field.regex?.value}
        />
      );
      break;
    case fieldTypes.DROPDOWN:
      element = (
        <FormSelect
          id={fieldId}
          variant={"standard"}
          value={value}
          handleChange={(value, id) => handleChange(id, value?.value)}
          required={field.mandatory}
          options={
            options &&
            options.map((option) => ({
              ...option,
              name: option.value,
            }))
          }
          errorText={errorText}
          checkValidation={isValidationRequired}
          regex={field.regex?.value}
        />
      );
      break;
  }
  return element;
};

export const checkFieldValidation = (fields): boolean => {
  const form = new Form();
  let hasError = false;

  fields.map((field) => {
    form.validateValue({
      required: field.mandatory,
      value: field.value,
      regex: field.regex,
      setError: (error: boolean) => {
        if (error) hasError = true;
      },
    });
  });

  return hasError;
};
