import {
  FacebookIcon,
  WhatsAppIcon,
  TelegramIcon,
  InsertCommentIcon,
  InstagramIcon,
  ChatIcon,
} from "../Icons";

const menuItems = {
  FB: {
    name: "Facebook",
    icon: FacebookIcon,
    color: "#4267B2",
  },
  INSTAGRAM: {
    name: "Instagram",
    icon: InstagramIcon,
    color: "#fff",
  },
  WHATSAPP: {
    name: "Whatsapp",
    icon: WhatsAppIcon,
    color: "#25D366",
  },
  TELEGRAM: {
    name: "Telegram",
    icon: TelegramIcon,
    color: "#0088cc",
  },
  WEBCHAT: {
    name: "Webchat",
    icon: InsertCommentIcon,
    color: "#0056C1",
  },
  OTHER: {
    name: "Other",
    icon: ChatIcon,
    color: "orange",
  },
};
export default menuItems;
