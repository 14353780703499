import { makeStyles, createStyles } from "@material-ui/core";
import { red, grey } from "@material-ui/core/colors";

import { AudioPlayerStylesProps } from "../interfaces";

export const useTextBoxStyles = makeStyles((theme) => ({
  textBoxContainer: {
    minHeight: "var(--textbox-height)",
    maxHeight: "120px",
    position: "absolute",
    bottom: "15px",
    left: "20px",
    zIndex: 1,
    width: "90%",
    backgroundColor: "white",
    padding: "12px 4px 0 12px",
    borderRadius: "48px",
    overflowY: "auto",
  },
  attachment: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "5px",
  },
  img: {
    width: "8px",
  },
  deleteAttachment: {
    width: "15px",
    color: red[500],
    cursor: "pointer",
    marginLeft: "-9px",
  },
  icons: {
    margin: "3px",
  },
  header: {
    background: "#f7f7f7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#746653",
    fontWeight: "bold",
    height: "40px",
    marginTop: "10px",
    marginBottom: "20px",
    fontSize: "15px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    minWidth: "150px",
  },
  blinking: {
    animation: `$blinking 1s infinite`,
  },
  spinning: {
    animation: `$spin 0.5s linear infinite`,
    color: "#2AA5DE",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.04em",
    width: "24px",
    height: "24px",
  },
  "@keyframes blinking": {
    "0%": { opacity: 0 },
    "50%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
  "@keyframes spin": {
    "100%": {
      "-webkit-transform": "rotate(360deg)",
      transform: "rotate(360deg)",
    },
  },
}));

export const useChatBoxStyles = makeStyles((theme) =>
  createStyles({
    endButton: {
      float: "right",
      right: "10px",
      top: "5px",
    },
    headerContainer: {
      backgroundColor: "white",
      marginTop: "-3px",
      width: "101%",
    },
    replyMessage: {
      minHeight: "50px",
      height: "50px",
      position: "absolute",
      bottom: "75px",
      left: "20px",
      zIndex: 1,
      width: "90%",
      textAlign: "left",
      padding: "5px 20px",
      borderRadius: "14px",
      borderBottom: "1px solid #00B287",
      backgroundColor: "white",
      color: "black",
      overflow: "hidden",
      fontSize: 13,
      lineHeight: "160%",
      letterSpacing: "0.02em",
      boxSizing: "border-box",
    },
    replyMessageHeader: {
      display: "block",
      color: "#00B287",
    },
    replyMessageText: {},
    replyCloseIcon: {
      position: "absolute",
      right: "10px",
      top: "5px",
      cursor: "pointer",
      color: "black",
      "&:hover": {
        zoom: "1.05",
      },
    },
    actionContainer: {
      position: "absolute",
      right: "10px",
      padding: "10px 0 5px 0",
    },
    actionIcon: {
      width: "20px",
      height: "20px",
      fontSize: "16px",
      fontWeight: 600,
      letterSpacing: "0.04em",
      color: "rgba(5, 0, 56, 1)",
      display: "block",
      margin: "0 auto",
    },
    popover: {
      padding: "20px 30px 30px",
      minWidth: "160px",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    listForm: {
      padding: "10px",
      display: "flex",
      alignItems: "center",
      background: "white",
      cursor: "pointer",
    },
    inputHolder: {
      fontSize: "0.8rem",
      letterSpacing: "0.05em",
      padding: "10px 12px",
    },
    labelInput: {
      marginLeft: "10px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
      minWidth: "150px",
      "& .MuiSelect-selectMenu": {
        display: "flex",
        alignItems: "center",
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "30px",
    },
    buttonClass: {
      width: "25%",
      alignItems: "center",
      margin: "0 auto",
    },
    predefinedPopover: {
      padding: theme.spacing(2),
      width: "500px",
      height: "750px",
      display: "flex",
      flexDirection: "column",
    },
    listContainer: {
      flex: 1,
      overflowY: "auto",
      margin: "10px 0",
    },
    buttonPreContainer: {
      display: "flex",
      justifyContent: "space-around",
      marginTop: theme.spacing(2),
    },
    buttonPreClass: {
      margin: theme.spacing(2),
      fontSize: "15px",
      padding: "15px 60px",
    },
    listItem: {
      "&:hover": {
        backgroundColor: grey[200],
      },
    },
    selectedItem: {
      backgroundColor: grey[300],
    },
    tooltip: {
      position: "fixed",
      zIndex: 1000,
      backgroundColor: "#f9f9f9",
      border: "1px solid #ddd",
      borderRadius: "10px",
      padding: "10px",
      maxWidth: "35vw",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
      visibility: "hidden",
      opacity: 0,
      transition: "visibility 0s, opacity 0.5s linear",
    },
    listItemHover: {
      "&:hover $tooltip": {
        visibility: "visible",
        opacity: 1,
      },
    },
  }),
);

export const useAudioPlayerStyles = makeStyles(() =>
  createStyles({
    controllerIcon: {
      fontSize: 22,
    },
    icon: {
      fontSize: 12,
      color: "rgba(255, 255, 255, 0.6)",
    },
    progressBar: {
      "&::-webkit-slider-thumb": {
        "-webkit-appearance": "none",
        border: ({ owner }: AudioPlayerStylesProps) =>
          owner === "client" ? "1px solid #00B287" : "1px solid #fff",
        width: "15px",
        height: "15px",
        marginTop: "-8px",
        borderRadius: "50%",
        background: ({ owner }: AudioPlayerStylesProps) =>
          owner === "client" ? "#fff" : "#00B287",
      },
      "&::-moz-range-thumb": {
        "-webkit-appearance": "none",
        border: ({ owner }: AudioPlayerStylesProps) =>
          owner === "client" ? "1px solid #00B287" : "1px solid #fff",
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        background: ({ owner }: AudioPlayerStylesProps) =>
          owner === "client" ? "#fff" : "#00B287",
      },
      "&::-webkit-slider-runnable-track": {
        width: "100%",
        height: "0.01px",
        background: "inherit",
        border: ({ owner }: AudioPlayerStylesProps) =>
          owner === "client"
            ? "0.5px dashed #00B287"
            : "0.5px dashed rgba(255, 255, 255, 0.4)",
      },
    },
  }),
);

export const useMessageStyles = makeStyles(() =>
  createStyles({
    messageContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginTop: 10,
      background: grey[100],
      padding: 5,
      maxWidth: 350,
    },
    iconContainer: {
      position: "absolute",
      right: 60,
      bottom: 0,
    },
    nullStatus: {
      color: grey[400],
    },
    undefined: {
      color: grey[500],
    },
    readStatus: {
      color: "#3BB5E6",
    },
    icon: {
      fontSize: "15px",
    },
  }),
);

export const useLastMsgPopoverStyles = makeStyles((theme) =>
  createStyles({
    popover: {
      padding: "20px 30px 30px",
      minWidth: "160px",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "30px",
    },
    buttonClass: {
      width: "25%",
      alignItems: "center",
      margin: "0 auto",
    },
  }),
);
