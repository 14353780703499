import { TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";

import { styles } from "Components/styles";
import React, { ReactNode } from "react";

interface IRow {
  label?: string | ReactNode;
  Component: any;
  value?: any;
  fieldName?: string;
  required?: boolean;
  icon?: React.ReactElement;
  lastHovered?: string;
  setLastHovered?: any;
  rowStyle?: any;
  cellStyle?: any;
  colSpan?: number;
}

const Row = (props: IRow): React.ReactElement => {
  const commonClasses = styles();

  return (
    <TableRow className={clsx(commonClasses.tableRow, props.rowStyle)}>
      {(props.label || props.icon) && (
        <TableCell
          className={clsx(commonClasses.tableCell, props.cellStyle)}
          align='left'>
          {props.label} {props.icon}
        </TableCell>
      )}

      <TableCell
        className={clsx(commonClasses.tableCell, props.cellStyle)}
        align='left'
        colSpan={props.colSpan}>
        {props.Component}
      </TableCell>
    </TableRow>
  );
};

export default Row;

Row.displayName = "Row";
