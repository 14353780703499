/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Drawer from "Components/Drawer/Drawer";
import Table from "Components/Table/Table";
import { styled } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const BlockInfo = (props) => {
  const { translate } = useTranslation();
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const handleClose = () => {
    setOpen(false);
    props.setClickedBlockInfo(null);
    props.setBlockInfo([]);
  };

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const fetchRows = () => {};

  useEffect(() => {
    if (props.blockInf) handleClickOpen();
    else handleClose();
  }, [props.clickedBlockInfo]);

  useEffect(() => {
    setRows([Object.assign({}, ...props.blockInf)]);
    const fieldNames = props.blockInf.reduce(
      (acc, row) => Array.from(acc.concat(Object.keys(row))),
      [],
    );

    const tempColumns = fieldNames.map((item) => {
      return { name: item, alias: item, sortable: false };
    });
    setColumns(tempColumns);
  }, [props.blockInf]);

  return (
    <Drawer open={open} handleClose={handleClose} style={{ width: "50%" }}>
      {props.blockInf.length > 0 ? (
        <Table
          hiddenPagination={true}
          searchFieldShow={false}
          additionalButtons={false}
          fetchRows={fetchRows}
          columns={columns}
          rows={rows}
        />
      ) : (
        <NoDataComponent>
          <h1>{translate("no_data")}</h1>
        </NoDataComponent>
      )}
    </Drawer>
  );
};

export default BlockInfo;

const NoDataComponent = styled("div")({
  position: "absolute",
  height: "99%",
  width: "100%",
  backgroundColor: grey[100],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: grey[500],
});
