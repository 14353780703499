import { formatDateTwo, roundOffToHour } from "Utils/Utils";

export const reportTableInitialCredentials: any = Object.freeze({
  fullTextSearch: null,
  fieldName: null,
  fieldSet: {
    createdDate: [
      formatDateTwo(new Date(new Date().setHours(0, 0, 0, 0))),
      formatDateTwo(roundOffToHour(new Date())),
    ],
  },
  order: "desc",
  page: 0,
  recordSize: 50,
});
