import { grey } from "@material-ui/core/colors";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { convertHexToRGBA } from "Utils/Utils";
import { buttonTypes } from "./buttonTypes";
import { colors } from "./colors";

interface ButtonProps {
  buttonType: buttonTypes;
  active?: boolean;
}

export const commonStyle = makeStyles(() =>
  createStyles({
    icon: (props: ButtonProps) => ({
      color: props.active
        ? colors[props.buttonType]
        : convertHexToRGBA(colors[props.buttonType], 70),
      "&:disabled": {
        filter: "contrast(0.2)",
      },
    }),
    link: (props: ButtonProps) => ({
      cursor: "pointer",
      color: props.active
        ? colors[props.buttonType]
        : convertHexToRGBA(colors[props.buttonType], 80),
    }),
    button: (props: ButtonProps) => ({
      color: props.active ? "white" : grey[600],
      fontSize: 11,
      backgroundColor: props.active
        ? convertHexToRGBA(colors[props.buttonType], 50)
        : convertHexToRGBA(colors[props.buttonType], 5),
      "&:hover": {
        backgroundColor: convertHexToRGBA(colors[props.buttonType], 15),
      },
      "&:disabled": {
        filter: "contrast(0.4)",
      },
    }),
  }),
);
