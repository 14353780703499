import i18n from "i18n/config";

export const filterTypes = [
  {
    name: "CIF",
    alias: i18n.t("crm_card_customer_cif"),
    sortable: true,
  },
  {
    name: "PIN",
    alias: i18n.t("crm_card_customer_pin"),
    sortable: true,
  },
  {
    name: "PAN",
    alias: i18n.t("crm_card_customer_pan"),
    sortable: true,
  },
  {
    name: "PHONE_NUMBER",
    alias: i18n.t("crm_card_customer_phone_number"),
    sortable: true,
  },
];
