/* eslint-disable eqeqeq */
import React, { useContext } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { styled } from "@material-ui/core";

import { RequestContextStore } from "Components/Layout/AppLayout";
import { defaultStates as defaultSmsStates } from "Store/Reducers/smsReducer";
import Sms from "Store/Actions/Sms";
import { ChevronLeftIcon, ChevronRightIcon } from "Assets/icons";

const TemplateLists = () => {
  const totalSmsState = useSelector(
    (state: RootStateOrAny) => state.smsReducer,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );
  const requestContextStore: any = useContext(RequestContextStore);
  const smsState = requestContextStore.getCurrentStateFromTotalState(
    totalSmsState,
    defaultSmsStates,
  );
  const sms = new Sms(useDispatch());

  const handleMoveForward = () => {
    let { page, totalPages } = smsState.pagination;
    if (page + 1 === totalPages) return;
    page += 1;
    sms.setPagination(
      requestState.menuType,
      requestContextStore.getRequestId(),
      { page },
    );
  };

  const handleMoveBack = () => {
    let { page } = smsState.pagination;
    if (page === 0) return;
    page -= 1;
    sms.setPagination(
      requestState.menuType,
      requestContextStore.getRequestId(),
      { page },
    );
  };

  const handleChosen = (template: any) => {
    let type = requestState.menuType,
      requestId = requestContextStore.getRequestId();
    if (smsState.chosenId === template.id)
      sms.setChosenTemplate(type, requestId, {}, -1);
    else sms.setChosenTemplate(type, requestId, template, template.id);
  };

  return (
    <Container>
      <ListContainer>
        {smsState.templates &&
          smsState.templates.map((template, index) => (
            <Template
              onClick={() => handleChosen(template)}
              style={{
                borderRight:
                  template.id == smsState.chosenId
                    ? "3px solid #00B287"
                    : "none",
                backgroundColor:
                  template.id == smsState.chosenId ? "#EAF3F0" : "white",
                borderRadius:
                  template.id == smsState.chosenId ? "10px 0 0 10px" : 0,
              }}
              key={index}>
              <Title
                style={{
                  color:
                    template.id == smsState.chosenId ? "#009E65" : "#050038",
                }}>
                {template.title}
              </Title>
              <TextPreview> - {template.text}</TextPreview>
            </Template>
          ))}
        {smsState.templates.length > 0 && (
          <Pagination>
            <div style={{ float: "left", marginLeft: 10 }}>
              <Interval>{smsState.pagination.currentInterval}</Interval>
              <BeforeIcon onClick={handleMoveBack} />
              <AfterIcon onClick={handleMoveForward} />
            </div>
          </Pagination>
        )}
      </ListContainer>
    </Container>
  );
};

export default TemplateLists;

const Container = styled("div")({});

const ListContainer = styled("ul")({
  position: "relative",
  height: "calc(100vh - var(--nav-bar-height) - 300px)",
  overflowY: "auto",
  padding: 0,
});

const Template = styled("li")({
  width: "96%",
  listStyle: "none",
  fontSize: 14,
  textAlign: "left",
  padding: 12,
  cursor: "pointer",
  borderBottom: "1px solid #DCDDE6",
  margin: "0 auto",
  "&:hover": {
    backgroundColor: "#F5F4F7 !important",
  },
  "&:last-child": {
    borderBottom: "none",
  },
});

const Title = styled("span")({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: "140%",
  letterSpacing: "0.02em",
  overflowX: "hidden",
  width: "60%",
});

const Pagination = styled("div")({
  width: "20%",
  float: "left",
  marginTop: 15,
  marginLeft: 15,
});

const Interval = styled("span")({
  fontWeight: 600,
  color: "#050038",
  fontSize: 12,
  lineHeight: "160%",
  letterSpacing: "0.04em",
});

const BeforeIcon = styled(ChevronLeftIcon)({
  textAlign: "left",
  fontSize: 25,
  color: "#414147",
  cursor: "pointer",
  position: "relative",
  marginLeft: 25,
  top: 6,
  "&:hover": {
    color: "#050038",
  },
});
const AfterIcon = styled(ChevronRightIcon)({
  textAlign: "left",
  fontSize: 25,
  color: "#414147",
  cursor: "pointer",
  position: "relative",
  top: 6,
  "&:hover": {
    color: "#050038",
  },
});

const TextPreview = styled("div")({
  display: "inline-block",
  color: "#414147",
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.04em",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  maxWidth: "60vw",
  marginLeft: 5,
  position: "relative",
  top: 3,
});
