/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import SockJsClient from "react-stomp";
import WebConf, { SMMUrl } from "Config/WebConf";
import { default as SocialMediaActions } from "Store/Actions/SocialMedia";
import swalAlert from "Notifications/SwalAlert";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

/**
 * Websocket SMM component
 */

const WebSocketSMM = () => {
  const { translate } = useTranslation();
  const socialMediaState = useSelector(
    (state: RootStateOrAny) => state.socialMediaReducer,
  );
  const socialMedia = new SocialMediaActions(useDispatch());
  const [error, setError] = useState(false);

  const onMessageReceive = (msg, topic) => {
    if (
      topic === WebConf.socketSMM.acceptPost &&
      socialMediaState.activeAccountId === msg?.accountId
    ) {
      const postsOldData = socialMediaState.postsById;
      socialMedia.setPostsById({
        ...postsOldData,
        data: [msg, ...postsOldData?.data],
      });
    }

    if (
      topic === WebConf.socketSMM.acceptComment &&
      socialMediaState.activePost?.id === msg?.postId
    ) {
      if (msg.parent?.id === null) {
        socialMedia.setCommentsByActivePost({
          ...socialMediaState.commentsByActivePost,
          data: [msg, ...socialMediaState.commentsByActivePost?.data],
        });
      } else {
        socialMedia.setReplysByActivePost([
          ...socialMediaState.replysByActivePost,
          msg,
        ]);
      }
    }

    if (
      topic === WebConf.socketSMM.acceptComment &&
      socialMediaState.activeAccountId === msg.accountId &&
      msg.isByAgent === false
    ) {
      socialMedia.setUnRepliedComments({
        ...socialMediaState.unRepliedComments,
        content: [msg, ...socialMediaState.unRepliedComments?.content],
      });
    }

    if (topic === WebConf.socketSMM.acceptInactiveMessage) {
      const filterUnRepliedCommentsData =
        socialMediaState.unRepliedComments?.content &&
        socialMediaState.unRepliedComments?.content.filter(
          (comment) => comment.id != msg,
        );
      socialMedia.setUnRepliedComments({
        ...socialMediaState.unRepliedComments,
        content: filterUnRepliedCommentsData,
      });
    }

    if (topic === WebConf.socketSMM.hiddenComment) {
      const tempUnRepliedCommentsData =
        socialMediaState.unRepliedComments?.content?.map((comment) =>
          comment.id === msg.id
            ? { ...comment, is_hidden: msg.is_hidden }
            : { ...comment },
        );
      socialMedia.setUnRepliedComments({
        ...socialMediaState.unRepliedComments,
        content: tempUnRepliedCommentsData,
      });
    }
  };

  const onConnectionFailure = (event) => {
    if (!error) {
      swalAlert
        .custom({
          text: translate("web_socket_smm_error"),
          title: translate("error"),
          icon: "warning",
          cancelButtonColor: "#6c757db5",
          showCancelButton: true,
          confirmButtonColor: "#328add",
          confirmButtonText: translate("web_socket_reload_page"),
        })
        .then((result) => {
          if (result.isConfirmed) window.location.reload();
        });
      setError(true);
    }
    console.log("WebsocketSMM Connection Failure", event);
  };

  const socketConnected = () => {
    error && setError(false);
  };

  return (
    <SockJsClient
      url={SMMUrl + WebConf.socketSMM.connect}
      topics={[
        WebConf.socketSMM.acceptPost,
        WebConf.socketSMM.acceptComment,
        WebConf.socketSMM.acceptInactiveMessage,
        WebConf.socketSMM.hiddenComment,
      ]}
      onMessage={onMessageReceive}
      onConnectFailure={onConnectionFailure}
      onConnect={socketConnected}
      autoReconnect={true}
      debug={true}
    />
  );
};

export default WebSocketSMM;
