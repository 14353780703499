/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback } from "react";

import { callActionTypes as actionTypes } from "./actionTypes";

export default class Call {
  dispatch: Function = () => {};
  constructor(dispatch: Function) {
    this.dispatch = dispatch;
  }

  addCallToEdit = useCallback(
    (call: boolean, id: number | string) =>
      this.dispatch({
        type: actionTypes.ADD_CALL_TO_EDIT,
        call,
        id,
      }),
    [this],
  );

  removeCallFromEdit = useCallback(
    (id: string) =>
      this.dispatch({
        type: actionTypes.REMOVE_CALL_FROM_EDIT,
        id,
      }),
    [this],
  );

  setActiveCallId = useCallback(
    (activeCallId: number) =>
      this.dispatch({
        type: actionTypes.SET_ACTIVE_CALL_ID,
        activeCallId,
      }),
    [this],
  );

  removeCallById = useCallback(
    (id: boolean) =>
      this.dispatch({
        type: actionTypes.REMOVE_CALL_BY_ID,
        id,
      }),
    [this],
  );

  setAcwBreakId = useCallback(
    (id: number | string) =>
      this.dispatch({
        type: actionTypes.SET_ACW_BREAK_ID,
        id,
      }),
    [this],
  );

  setRandomNumber = useCallback(
    (randomNumber: number | null) =>
      this.dispatch({
        type: actionTypes.SET_RANDOM_NUMBER,
        randomNumber,
      }),
    [this],
  );

  setUserBreakToAcw = useCallback(
    (status: boolean) =>
      this.dispatch({
        type: actionTypes.SET_AUTO_ACW,
        status,
      }),
    [this],
  );

  setOutCallAnimation = useCallback(
    (status: boolean) =>
      this.dispatch({
        type: actionTypes.SET_OUT_CALL_ANIMATION,
        status,
      }),
    [this],
  );
}
