import i18n from "i18n/config";

export const options = [
  {
    id: 7,
    name: "Tamamlanmış",
    type: "Universal",
    availableForAgents: "YES",
    active: 1,
    displayName: i18n.t("completed_status"),
  },
  {
    id: 9,
    name: "Tamamlanmamış",
    type: "Universal",
    availableForAgents: "YES",
    active: 1,
    displayName: i18n.t("incomplete_status"),
  },
  {
    id: 10,
    name: "Texniki xəta",
    type: "Universal",
    availableForAgents: "YES",
    active: 1,
    displayName: i18n.t("technical_problem_status"),
  },
  {
    id: 11,
    name: "Əsassız müraciət",
    type: "Universal",
    availableForAgents: "YES",
    active: 1,
    displayName: i18n.t("unfounded_appeal_status"),
  },
  {
    id: 12,
    name: "Düzəliş olunmuşdur",
    type: "Universal",
    availableForAgents: "YES",
    active: 1,
    displayName: i18n.t("corrected_status"),
  },
  {
    id: 13,
    name: "Əlaqə kəsildi",
    type: "Call",
    availableForAgents: "YES",
    active: 1,
    displayName: i18n.t("connection_lost_status"),
  },
  {
    id: 14,
    name: "Zəng çatmır",
    type: "Call",
    availableForAgents: "YES",
    active: 1,
    displayName: i18n.t("call_missing_status"),
  },
];

export const defaultStatus = {
  id: 9,
  name: "Tamamlanmamış",
};
