import { styled } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { uploadAttachments, downloadAttachment } from "Api/crm";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

import { RequestContextStore } from "Components/Layout/AppLayout";
import Notification from "Notifications/Notification";

import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import { default as CrmActions } from "Store/Actions/Crm";
import { defaultStates as defaultCrmStates } from "Store/Reducers/crmReducer";

import { convertImagetoBase64 } from "Utils/Utils";

export const useProfileImage = (): any => {
  const { translate } = useTranslation();

  const [avatar, setAvatar] = useState(null);

  const crm = new CrmActions(useDispatch());
  const totalCrmState = useSelector(
    (state: RootStateOrAny) => state.crmReducer,
  );
  const requestState = useSelector(
    (state: RootStateOrAny) => state.requestReducer,
  );

  const requestContextStore: any = useContext(RequestContextStore);
  const crmState = requestContextStore.getCurrentStateFromTotalState(
    totalCrmState,
    defaultCrmStates,
  );

  let userPhoto = null;
  if (!crmState.profileImage) userPhoto = <StyledAccountCircleIcon />;
  else userPhoto = <StyledProfileImage src={avatar} />;

  const handleImageUpload = (input): void => {
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      if (file.size > 2000000) {
        Notification.error(translate("request_max_size"));
        return;
      }
      reader.onload = async function (e) {
        const formdata = new FormData();
        formdata.append("files", file, file.name);
        formdata.append("directory", "crm");

        const result = await uploadAttachments(formdata);
        let path = null;
        if (result) path = result[0].path;

        crm.setProfileImage(
          requestState.menuType,
          requestContextStore.getRequestId(),
          path,
        );
        setAvatar(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (crmState.profileImage)
      setAvatar(
        convertImagetoBase64(crmState.profileImage, downloadAttachment),
      );
    else setAvatar(null);
  }, [crmState.profileImage]);

  return {
    userPhoto,
    handleImageUpload,
    translate,
  };
};

useProfileImage.displayName = "useProfileImage";

const StyledProfileImage = styled("img")({
  width: "100%",
  height: "auto",
  borderRadius: "50%",
  minHeight: "150px",
  objectFit: "cover",
});

const StyledAccountCircleIcon = styled(AccountCircleIcon)({
  width: "100%",
  height: "50%",
  color: grey[300],
});
