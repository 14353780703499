import React, { useState } from "react";

import Drawer from "Components/Drawer/Drawer";
import LoadingComponent from "Components/Loading/LoadingComponent";

import DrawerTabs from "./DrawerTabs";
import AccountTab from "./AccountTab";
import CreditTab from "./CreditTab";
import CardTab from "./CardTab";

import { styled } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

const tabs = ["Account Information", "Card Information", "Credit Information"];

const TableDrawer = ({ open, onClose, cif, customerType }) => {
  const { translate } = useTranslation();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return (
          <AccountTab
            cif={cif}
            customerType={customerType}
            setLoading={setLoading}
          />
        );
      case 1:
        return <CardTab cif={cif} setLoading={setLoading} />;
      case 2:
        return <CreditTab cif={cif} setLoading={setLoading} />;
      default:
        return <div>{translate("request_card_information")}</div>;
    }
  };

  return (
    <Drawer
      style={{ width: "73%", right: 0, padding: 20 }}
      open={open}
      handleClose={onClose}>
      <Header>
        <Title>{translate("request_tables")}</Title>
        <Close onClick={onClose} />
      </Header>
      <DrawerTabs tab={tab} setTab={setTab} tabs={tabs} />
      {renderComponent()}
      {loading && <LoadingComponent />}
    </Drawer>
  );
};

export default TableDrawer;

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 15,
});

const Title = styled("span")({
  display: "block",
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "24px",
  letterSpacing: 0.7,
  color: "#1E2B41",
});

const Close = styled(CloseIcon)({
  cursor: "pointer",
  "&:hover": {
    color: "#1E2B41",
  },
});
