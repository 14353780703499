export const defaultFilterData = {
  startCreatedDateTime: new Date(
    new Date().setMonth(new Date().getMonth() - 1),
  ).setHours(0, 0, 0, 0),
  endCreatedDateTime: new Date(new Date().setHours(23, 59, 59, 59)),
  startAssignedDateTime: null,
  endAssignedDateTime: null,
  callDuration: [],
  agentIdList: [],
  assignedUserIdList: [],
  lastMonitoringOfficerIdList: [],
  topic: [],
  evaluationIvr: [],
  evaluationType: [],
  telephoneNumber: null,
  omniChannelId: null,
  requestId: "",
  qualityEvaluationStatus: "all",
  type: "All",
};

export const defaultMyPendingFilterData = {
  startCreatedDateTime: null,
  endCreatedDateTime: null,
  startAssignedDateTime: null,
  endAssignedDateTime: null,
  callDuration: [],
  agentIdList: [],
  assignedUserIdList: [],
  lastMonitoringOfficerIdList: [],
  topic: [],
  evaluationIvr: [],
  evaluationType: [],
  telephoneNumber: null,
  omniChannelId: null,
  requestId: "",
  qualityEvaluationStatus: "all",
  type: "All",
};

export const status = {
  all: "All",
  INITIATED: "Qiymətləndirilməyən",
  IN_PROGRESS: "Qiymətləndirilir",
  EVALUATED: "Qiymətləndirilib",
  REVIEW: "Yoxlanılır",
  DECLINED: "Geri qaytarılıb",
  CLOSED: "Yekunlaşmış",
};

export const typeList = [
  { value: "All", name: "All" },
  { value: "CALL", name: "Call" },
  { value: "CHAT", name: "Chat" },
];

export const evaluationIds = {
  quality: 1,
  risk: 2,
};

export const positionIds = {
  divisionHead: 1,
  employee: 2,
  sectionHead: 3,
};

export const lastAssigner = {
  ADMIN: "ADMIN",
  MONITORING_OFFICER: "MONITORING_OFFICER",
  OPERATOR: "OPERATOR",
};

export const rowColor = "#FF0000";
