/* eslint-disable array-callback-return */
import { getElementFormattedValues } from "Components/Request/LeftPanel/CrmForm";
import { customerTypes } from "../../Components/Request/LeftPanel/constants/customerTypes";
import { crmPageActions as actionTypes } from "../Actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  activeTab: customerTypes.PHYSICAL,
  order: {
    type: "asc",
    orderBy: "creationDate",
  },
  rowsPerPage: 10,
  pageNumber: 0,
  defaultFields: [],
  showedColumns: [],
  searchFieldSet: {},
  fullTextSearch: "",
};

const setOptionsByDictionaryId = (state, action) => {
  let updatedFieldValueList = [...state.defaultFields];

  updatedFieldValueList.map((field, index) => {
    if (field.dictionaryId === action.dictionaryId) {
      updatedFieldValueList[index].options = action.options;
    }
  });
  return updateObject(state, { defaultFields: updatedFieldValueList });
};

const setFieldValue = (state, action) => {
  let updatedFieldValueList = [...state.defaultFields];
  updatedFieldValueList.map((field, index) => {
    if (field.fieldName === action.fieldName) {
      updatedFieldValueList[index].fieldValue = action.value;
    }
  });
  return updateObject(state, { defaultFields: updatedFieldValueList });
};

const resetFieldsValues = (state) => {
  let updatedFieldValueList = [...state.defaultFields];
  updatedFieldValueList.map((_field, index) => {
    updatedFieldValueList[index].fieldValue = null;
  });
  return updateObject(state, {
    defaultFields: updatedFieldValueList,
    searchFieldSet: {},
  });
};

const setSearchFieldSet = (state) => {
  let fieldSet = {};

  state.defaultFields.forEach((field) => {
    const formattedValues = getElementFormattedValues(field);

    if (field.fieldValue) {
      fieldSet[field.fieldName] = formattedValues.map(
        (valueObject) => valueObject.value,
      );
    }
  });
  return updateObject(state, { searchFieldSet: fieldSet });
};

const crmReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_TAB:
      return updateObject(state, {
        activeTab: action.activeTab,
        order: {
          type: "asc",
          orderBy: "creationDate",
        },
        rowsPerPage: 10,
        pageNumber: 0,
        searchFieldSet: {},
      });
    case actionTypes.SET_ORDER:
      return updateObject(state, { order: action.order });

    case actionTypes.SET_ROWS_PER_PAGE:
      return updateObject(state, { rowsPerPage: action.rowsPerPage });

    case actionTypes.SET_PAGE_NUMBER:
      return updateObject(state, { pageNumber: action.pageNumber });

    case actionTypes.SET_DEFAULT_FIELDS:
      return updateObject(state, {
        defaultFields: action.defaultFields,
      });

    case actionTypes.SET_FILTER_FIELD_VALUE:
      return setFieldValue(state, action);

    case actionTypes.RESET_FIELD_VALUES:
      return resetFieldsValues(state);

    case actionTypes.SET_SHOWED_COLUMNS:
      return updateObject(state, { showedColumns: action.showedColumns });

    case actionTypes.SET_FILTER_OPTIONS_BY_DICTIONARY_ID:
      return setOptionsByDictionaryId(state, action);

    case actionTypes.SET_SEARCH_FIELDSET:
      return setSearchFieldSet(state);

    case actionTypes.EMPTY_SEARCH_FIELDSET:
      return updateObject(state, { searchFieldSet: {} });

    case actionTypes.SET_FULLTEXT_SEARCH:
      return updateObject(state, { fullTextSearch: action.fullTextSearch });
    default:
      return state;
  }
};

export default crmReducer;
