import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import WebConf, { OmniServerUrl } from "Config/WebConf";

import { fetchData } from "networking";
import swalAlert from "Notifications/SwalAlert";

import React, { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import SockJsClient from "react-stomp";

import ChatBox from "Store/Actions/ChatBox";
import { default as WebSocketActions } from "Store/Actions/WebSocket";

/*
 * Websocket component
 */
const WebSocket = (): React.ReactElement => {
  const { translate } = useTranslation();

  const [error, setError] = useState(false);

  const chatBoxState = useSelector(
    (state: RootStateOrAny) => state.chatReducer,
  );
  const chatBox = new ChatBox(useDispatch());
  const webSocket = new WebSocketActions(useDispatch());

  const onMessageReceive = (msg, topic): void => {
    const currentMessage = chatBoxState.messages[msg.conversationId] || null;

    if (topic === WebConf.socket.acceptMessage) {
      const message = msg.messageList[0];
      if (message.isDeleted) {
        chatBox.removeMessage(message.serviceMessageId, msg.conversationId);
        return;
      }
    }
    // if message object has serviceMessageId, It means that message has been sent to client and we should change message color
    if (msg.serviceMessageId)
      chatBox.setMessageSent(
        msg.conversationId,
        msg.messageId,
        msg.serviceMessageId,
        msg.status,
        currentMessage,
      );
    else chatBox.addMessage(msg, currentMessage);
  };

  const onDisonnect = (): (() => void) => {
    webSocket.isDisconnected();
    return socketDisConnected;
  };

  const onConnectionFailure = (): void => {
    if (!error) {
      swalAlert
        .custom({
          text: translate("web_socket_connection_error"),
          title: translate("error"),
          icon: "warning",
          cancelButtonColor: "#6c757db5",
          showCancelButton: true,
          confirmButtonColor: "#328add",
          confirmButtonText: translate("web_socket_reload_page"),
        })
        .then((result) => {
          if (result.isConfirmed) window.location.reload();
        });
      setError(true);
    }
    webSocket.isReconnecting();
  };

  const socketConnected = (): void => {
    error && setError(false);
    webSocket.setConnected(true);
    fetchData(
      OmniServerUrl +
        WebConf.chat.getAllMessages +
        "?userId=" +
        localStorage.getItem("userID"),
    )
      .then((response) => {
        return response.json();
      })
      .then((conversations) => {
        webSocket.isConnected();
        chatBox.setAllConversations(conversations);

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        conversations?.forEach((conversation) => {
          const unseenCount = conversation.messageList.reduce(
            (count, message) => {
              return message.seen === false ? count + 1 : count;
            },
            0,
          );
          if (unseenCount > 0) {
            chatBox.setNewMessageCount(
              conversation.conversationId,
              unseenCount,
            );
          }
        });

        return getMessagesFromQueue();
      })
      .catch(() => {
        webSocket.isReconnecting();
      });
  };

  const getMessagesFromQueue = (): void => {
    return fetchData(
      OmniServerUrl +
        WebConf.chat.getMessagesFromQueue +
        "?userId=" +
        localStorage.getItem("userID"),
    );
  };

  const socketDisConnected = (): void => {
    webSocket.setConnected(false);
  };

  const handleSocketClientAssigned = useCallback((client) => {
    chatBox.setChatInstance(client);
  }, []);

  useEffect(() => {
    webSocket.isPending();
  }, []);

  return (
    <SockJsClient
      url={OmniServerUrl + WebConf.socket.connect}
      headers={{ user: localStorage.getItem("userID") }}
      topics={[WebConf.socket.acceptMessage, WebConf.socket.messageSent]}
      onMessage={onMessageReceive}
      ref={handleSocketClientAssigned}
      onConnect={socketConnected}
      onDisconnect={onDisonnect}
      onConnectFailure={onConnectionFailure}
      autoReconnect={true}
      debug={true}
    />
  );
};

export default WebSocket;

WebSocket.displayName = "WebSocket";
